const baseURLs = {
  development: 'http://localhost:8009',
  stage: 'https://products.staging.boloo.co',
  production: 'https://products.boloo.co',
};

const getBaseURL = () => {
  switch (process.env.TARGET_ENV) {
    case 'development':
      return baseURLs.development;
    case 'stage':
      return baseURLs.stage;
    default:
      return baseURLs.production;
  }
};

export default getBaseURL;
