import useSWR, { KeyedMutator } from 'swr';
import type { OfferProductInterface } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useOffersProductDetail(offerId: string | null): {
  offerProduct: OfferProductInterface;
  isLoading: boolean;
  isError: Error;
  mutate: KeyedMutator<OfferProductInterface>;
} {
  const { data, error, isLoading, mutate } = useSWR(
    offerId ? `/offers/${offerId}/` : null,
    (url) => productsAxios.get(url).then((res) => res.data),

    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    offerProduct: data || null,
    isLoading,
    isError: error,
    mutate,
  };
}
