/**
 * Filter query parameters.
 * @description This function filters out query parameters that are null, undefined, empty strings, or empty arrays.
 */
export const filterQueryParams = <T>(params: T) => {
  return Object.fromEntries(
    Object.entries(params)
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          value !== '' &&
          (Array.isArray(value) ? value.length > 0 : true),
      ) // Remove null, undefined, empty strings, and empty arrays
      .map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(',') : String(value),
      ]), // Convert arrays to comma-separated strings
  );
};

/**
 * Append query parameters to a URL.
 * @description This function appends query parameters to a URL. It filters out parameters that are null, undefined, or empty arrays.
 * @note This function will be deprecated once react-router v6 is available. Use the `useSearchParams` hook instead to manage query parameters.
 */
export const appendQueryParams = <T>(url: string, params: T): string => {
  // TODO: Will be deprecated once react-router v6 is available
  const filteredParams = filterQueryParams<T>(params);
  if (Object.keys(filteredParams).length > 0) {
    const queryParams = new URLSearchParams(filteredParams);
    // Check if the URL already has query parameters
    const separator = url.includes('?') ? '&' : '?';
    // Append the query parameters to the URL
    return url + separator + queryParams.toString();
  }
  return url;
};
