import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  message,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import { If } from 'tsx-control-statements/components';

import './style.scss';

import ProfitCalculator, { ProfitCalculatorProps } from '../profitCalculator';
import {
  CostPrice,
  CostType,
  OfferProductInterface,
  getBoxSizeOptions,
  useOfferCostPrices,
  useOfferCostPricesAdd,
  useOfferCostPricesDelete,
  useOfferCostPricesUpdate,
  useOffersProductEdit,
  useProductCommissionByPrice,
} from '../../../../services/productsService';
import GenericCard from '../../../../../components/bearCards/genericCard';
import captureErrorAndShowMessage from '../../../../../utilities/errors';
import { BOL_VAT_TARIFFS } from '../../../../constants';
import InputNumberEuro from '../../../../components/inputNumberEuro';
import { FormValuesInterface } from './types';
import { getProductVAT } from '../../../utilities';
import { BoxSize } from '../../../../services/productTrackerService';
import { redoBoxSizeChecks } from '../../../../services/financialReportingService/api';

const { TabPane } = Tabs;
export const CostTypeLabels: Record<CostType, string> = {
  [CostType.PRODUCT_COST_PRICE]: 'Inkoopprijs',
  [CostType.PACKAGING]: 'Verpakkingskosten',
  [CostType.SHIPPING]: 'Verzendkosten',
  [CostType.TRANSPORT]: 'Transportkosten',
  [CostType.BRANDING]: 'Branding (Stickers & Insert cards etc.)',
  [CostType.TRANSACTION]: 'Transactiekosten',
  [CostType.OTHER]: 'Overige kosten',
  [CostType.SURCHARGE_BE_SHIPMENT]: 'Toeslag voor verzenden naar België',
  [CostType.PICK_PACK]: 'Pick & Pack kosten',
};

function deductVat(value) {
  return Math.round((value / 1.21) * 100) / 100;
}

function computeTotals(
  commissionFixedAmount = 0,
  commissionPercentage = 0,
  price = 0,
  costPrice = 0,
  otherCosts = 0,
  shippingCost = 0,
  vatPercentage = 0,
) {
  let totalCost = 0;
  totalCost += deductVat(commissionFixedAmount);
  totalCost += deductVat((price * commissionPercentage) / 100);
  totalCost += costPrice;
  totalCost += shippingCost;
  totalCost += otherCosts;

  return {
    total: totalCost,
    profit: price - totalCost - getProductVAT(price, vatPercentage),
  };
}

function ProductCostPricesForm({
  offerProduct,
}: {
  offerProduct: OfferProductInterface | null;
}) {
  const OGBoxSize: BoxSize =
    offerProduct?.box_size || offerProduct?.product?.box_size || null;
  const { offerCostPrices, isLoading: isOfferCostPricesLoading } =
    useOfferCostPrices(String(offerProduct?.id));

  const [boxSizeOptions, setBoxSizeOptions] = useState([]);

  const addCostPrice = useOfferCostPricesAdd(String(offerProduct?.id));
  const deleteCostPrice = useOfferCostPricesDelete(String(offerProduct?.id));
  const updateCostPrices = useOfferCostPricesUpdate(String(offerProduct?.id));
  const editOfferProduct = useOffersProductEdit(String(offerProduct?.id));

  const { commission, isLoading: isCommissionLoading } =
    useProductCommissionByPrice(
      String(offerProduct?.product?.ean),
      offerProduct?.price,
    );

  const [form] = Form.useForm<FormValuesInterface>();
  const formValues: FormValuesInterface = Form.useWatch([], form);

  const [profitValues, setProfitValues] =
    useState<ProfitCalculatorProps | null>(null);
  const [profitValuesFBB, setProfitValuesFBB] =
    useState<ProfitCalculatorProps | null>(profitValues);
  const [activeTab, setActiveTab] = useState(
    offerProduct?.fulfilment_method === 'FBB' ? 'lvb' : 'eigen',
  );

  const [isSaving, setIsSaving] = useState(false);
  const [boxSize, setBoxSize] = useState(OGBoxSize?.name);
  const [isBoxSizeChanged, setIsBoxSizeChanged] = useState(false);

  // Check if the box size has been changed
  useEffect(() => {
    if (offerProduct?.box_size === null) {
      setIsBoxSizeChanged(true);
    } else {
      setIsBoxSizeChanged(boxSize !== OGBoxSize?.name);
    }
  }, [boxSize, OGBoxSize]);

  // Fetch the box size options
  useEffect(() => {
    const fetchBoxSizeOptions = async () => {
      const options = await getBoxSizeOptions();
      setBoxSizeOptions(options);
    };
    fetchBoxSizeOptions();
  }, []); // Empty dependency array to run only once

  // Set the box size value in the form
  useEffect(() => {
    const boxValue =
      offerProduct?.box_size?.name || offerProduct?.product?.box_size?.name;
    form.setFieldValue('boxSize', boxValue);
  }, [offerProduct]);

  // Set the other costs and shipping cost in the form based on DB values
  useEffect(() => {
    form.setFieldValue(
      'otherCosts',
      offerCostPrices?.filter((cp) => cp.type !== CostType.SHIPPING),
    );
    if (offerCostPrices?.find((cp) => cp.type === CostType.SHIPPING)) {
      form.setFieldValue(
        'shippingCost',
        offerCostPrices.find((cp) => cp.type === CostType.SHIPPING)?.amount,
      );
    }
  }, [offerCostPrices]);

  // Update the box size state when the form value changes
  useMemo(() => {
    setBoxSize(form.getFieldValue('boxSize'));
  }, [form.getFieldValue('boxSize')]);

  // Update values for profit calculator
  useEffect(() => {
    if (isCommissionLoading || isOfferCostPricesLoading) {
      return;
    }
    const commissionFixedAmount = commission?.fixedAmount;
    const commissionPercentage = commission?.percentage;

    const price = offerProduct?.price;
    const vat = offerProduct?.vat;

    const costPrice = formValues?.otherCosts?.find(
      (cp: CostPrice) => cp.type === CostType.PRODUCT_COST_PRICE,
    )?.amount;
    const others = formValues?.otherCosts?.filter(
      (cp: CostPrice) =>
        cp.type !== CostType.PRODUCT_COST_PRICE &&
        cp.type !== CostType.SURCHARGE_BE_SHIPMENT,
    );

    const totals = computeTotals(
      commissionFixedAmount,
      commissionPercentage,
      price,
      costPrice,
      others?.reduce((total, item) => total + Number(item.amount), 0),
      formValues?.shippingCost,
      vat,
    );

    const totalsFBB = computeTotals(
      commissionFixedAmount,
      commissionPercentage,
      price,
      costPrice,
      others?.reduce((total, item) => total + Number(item.amount), 0),
      offerProduct?.box_size?.total_costs_per_ordered_item ||
        offerProduct?.product.box_size?.total_costs_per_ordered_item,
      vat,
    );

    // Exclude the cost price from the other costs. Also exclude BE surcharge temporarily.
    const otherCostsForProfit = offerCostPrices?.filter(
      (cp: CostPrice) =>
        cp.type !== CostType.PRODUCT_COST_PRICE &&
        cp.type !== CostType.SURCHARGE_BE_SHIPMENT,
    );

    // Replace shipping cost with box_size cost
    const otherCostsForProfitFBB = otherCostsForProfit.map((cost) => {
      if (cost.type === CostType.SHIPPING) {
        return {
          ...cost,
          amount:
            offerProduct?.box_size?.total_costs_per_ordered_item ||
            offerProduct?.product.box_size?.total_costs_per_ordered_item,
        };
      }
      return cost;
    });

    setProfitValuesFBB({
      totals: totalsFBB,
      costs: {
        costPrice,
        otherCosts: otherCostsForProfitFBB,
        shippingCost:
          offerProduct?.box_size?.total_costs_per_ordered_item ||
          offerProduct?.product.box_size?.total_costs_per_ordered_item,
        vatPercentage: vat,
        boxSize,
      },
      ean: offerProduct?.product?.ean,
      vat,
      price,
      commission: {
        fixedAmount: commissionFixedAmount,
        percentage: commissionPercentage,
      },
    });

    setProfitValues({
      totals,
      costs: {
        costPrice,
        otherCosts: otherCostsForProfit,
        shippingCost: offerCostPrices?.find(
          (cp: CostPrice) => cp.type === CostType.SHIPPING,
        )?.amount,
        vatPercentage: vat,
        boxSize,
      },
      ean: offerProduct?.product?.ean,
      vat,
      price,
      commission: {
        fixedAmount: commissionFixedAmount,
        percentage: commissionPercentage,
      },
    });
  }, [commission, formValues]);

  // Update the available other cost types
  const availableOtherCostTypes = useMemo(() => {
    const res = Object.keys(CostTypeLabels).filter(
      (costTypeLabelKey: CostType) =>
        !formValues?.otherCosts?.find(
          (otherCostField: CostPrice) =>
            otherCostField.type === costTypeLabelKey,
        ) && costTypeLabelKey !== CostType.SHIPPING, // Exclude shipping cost from dropdown.
    );
    return res;
  }, [formValues?.otherCosts]);

  async function handleShippingCost(
    shippingCost: CostPrice,
    otherCosts: CostPrice[],
  ) {
    if (shippingCost) {
      // If shipping cost exists in the DB
      shippingCost.amount = formValues.shippingCost;
      if (shippingCost.amount === 0) {
        await deleteCostPrice(shippingCost.id);
      } else {
        await updateCostPrices([...otherCosts, shippingCost]);
      }
    } else if (formValues.shippingCost !== 0) {
      // If shipping cost does not exist in the DB
      await addCostPrice({
        type: CostType.SHIPPING,
        amount: formValues.shippingCost,
      });
    } else {
      // If shipping cost does not exist in the DB and is 0, no need to add it
      await updateCostPrices(otherCosts);
    }
  }

  async function handleBoxSizeChange(data, values) {
    if (!isBoxSizeChanged) {
      return;
    }
    const box = boxSizeOptions.find(
      (choice) =>
        choice.name === values.boxSize &&
        choice.min_price <= values.price &&
        choice.max_price >= values.price,
    );
    if (!box) {
      console.error('Unable to find box size');
      return;
    }
    data.box_size = box.id;
    await redoBoxSizeChecks(offerProduct?.product?.ean);
    message.info(
      'De lvb box size checks worden opnieuw uitgevoerd, dit kan enkele minuten duren voordat de wijzigingen zichtbaar zijn op je dashboard.',
    );
  }

  return (
    <GenericCard title="Productinformatie (per stuk)">
      <If condition={isOfferCostPricesLoading}>
        <Spin />
      </If>
      <If condition={!isOfferCostPricesLoading}>
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          scrollToFirstError
          className="product-cost-prices-form"
          initialValues={{
            vatPercentage: offerProduct?.vat,
            otherCosts: offerCostPrices,
            shippingCost:
              offerCostPrices?.find((cp) => cp.type === CostType.SHIPPING)
                ?.amount || 0,
            price: offerProduct?.price,
          }}
          onFinish={async (values) => {
            try {
              setIsSaving(true);
              const shippingCost = offerCostPrices.find(
                (cp) => cp.type === CostType.SHIPPING,
              );
              await handleShippingCost(shippingCost, values.otherCosts);

              const data = { vat: values.vatPercentage };
              await handleBoxSizeChange(data, values);

              await editOfferProduct(data);
              message.success(
                `De kostprijzen en het BTW percentage succesvol bijgewerkt!`,
              );
            } catch (e) {
              captureErrorAndShowMessage(e);
            } finally {
              setIsSaving(false);
            }
          }}
        >
          <Row
            gutter={{
              xxl: 24,
              xl: 24,
              lg: 24,
              md: 12,
              sm: 12,
              xs: 12,
            }}
          >
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Verkoopprijs (incl. BTW)" name="price">
                <InputNumber
                  disabled
                  size="large"
                  className="bear-width"
                  formatter={(value) =>
                    `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="BTW-tarief"
                name="vatPercentage"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                ]}
              >
                <Select placeholder="Selecteer het BTW-tarief" size="large">
                  {BOL_VAT_TARIFFS.map((item) => {
                    return (
                      <Select.Option key={item} value={item}>
                        {item}%
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{
              xxl: 24,
              xl: 24,
              lg: 24,
              md: 12,
              sm: 12,
              xs: 12,
            }}
          >
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={
                  <Tooltip
                    className="bear-has-tooltip"
                    title="Vul hier de juiste LVB pakketmaat in. We gebruiken deze afmeting alleen voor het
                  berekenen van de verzendkosten wanneer dit product middels LVB wordt verkocht.
                  Verkoop je nooit via LVB, dan mag je deze leeg laten. Wanneer je dit product niet
                  verkoopt via LVB, vul dan de verzend en of Pick & Pack kosten in. Deze gebruiken
                  wij dan voor alle niet-LVB bestellingen."
                  >
                    LVB Tariefgroep (formaat pakket)
                  </Tooltip>
                }
                name="boxSize"
              >
                <Select placeholder="Kies een LVB tariefgroep" size="large">
                  {boxSizeOptions.slice(6).map((item) => {
                    const itemValue = item.name;
                    const itemLabel = `${item.name} (Max. ${item.max_width} x ${item.max_length} x ${item.max_height} CM ${item.max_weight / 1000} KG)`;
                    return (
                      <Select.Option key={itemValue} value={itemValue}>
                        {itemLabel}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Verzendkosten eigen fulfilment"
                name="shippingCost"
                rules={[
                  {
                    required: true,
                    message: 'verplicht veld',
                  },
                  () => ({
                    validator(_, value) {
                      if (value === undefined || value === null) {
                        return Promise.reject();
                      }
                      if (Number(value) < 0) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject(
                          'De kosten mogen niet negatief zijn',
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumberEuro min={0} size="large" className="bear-width" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Bijkomende kosten per product</Divider>
          <Form.List name="otherCosts">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => {
                    return (
                      <Row
                        gutter={{
                          xxl: 24,
                          xl: 24,
                          lg: 24,
                          md: 12,
                          sm: 12,
                          xs: 12,
                        }}
                        align="middle"
                        key={field.key}
                        className="other-costs-form-row"
                      >
                        <Col className="bear-full-flex">
                          <Row
                            gutter={{
                              xxl: 24,
                              xl: 24,
                              lg: 24,
                              md: 12,
                              sm: 12,
                              xs: 12,
                            }}
                          >
                            <Col lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name={[field.name, 'type']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'verplicht veld',
                                  },
                                ]}
                              >
                                <Select size="large">
                                  {
                                    // Add the current item if exists
                                    (formValues?.otherCosts?.[field.name]?.type
                                      ? [
                                          ...[
                                            formValues?.otherCosts?.[field.name]
                                              ?.type,
                                          ],
                                          ...availableOtherCostTypes,
                                        ]
                                      : availableOtherCostTypes
                                    ).map((item) => {
                                      return (
                                        <Select.Option key={item} value={item}>
                                          {CostTypeLabels[item]}
                                        </Select.Option>
                                      );
                                    })
                                  }
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name={[field.name, 'amount']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'verplicht veld',
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (!value) {
                                        return Promise.reject();
                                      }
                                      if (Number(value) <= 0) {
                                        // eslint-disable-next-line prefer-promise-reject-errors
                                        return Promise.reject(
                                          'Kost moet groter zijn dan 0',
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumberEuro
                                  min={0.01}
                                  size="large"
                                  className="bear-width"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="item-delete-trigger">
                          <i
                            role="presentation"
                            className="fad icon-trash"
                            onClick={async () => {
                              await deleteCostPrice(
                                formValues.otherCosts?.[field.name].id,
                              );
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <If condition={availableOtherCostTypes.length}>
                    <p
                      onClick={async () => {
                        try {
                          await addCostPrice({
                            type: availableOtherCostTypes[0] as CostType,
                            amount: 0,
                          });
                          add({
                            type: availableOtherCostTypes[0],
                            amount: 0,
                          });
                        } catch (e) {
                          captureErrorAndShowMessage(e);
                        }
                      }}
                      className="bear-link"
                    >
                      + Kosten toevoegen
                    </p>
                  </If>
                </>
              );
            }}
          </Form.List>
          <Divider />
          <div className="product-detail-menu-actions">
            <Button
              size="large"
              type="primary"
              className="bear-mar-r-16"
              loading={isSaving}
              onClick={() => form.submit()}
            >
              Kostprijzen &amp; BTW-tarief opslaan
            </Button>
          </div>
        </Form>
        <Divider />
        <Row align="top">
          <Col flex="auto">
            {activeTab === 'eigen' ? (
              <ProfitCalculator values={profitValues} />
            ) : (
              <ProfitCalculator values={profitValuesFBB} />
            )}
          </Col>
          <Col flex="none">
            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              style={{ paddingTop: '11px' }}
            >
              <TabPane tab="Eigen fulfilment" key="eigen" />
              <TabPane tab="LVB" key="lvb" />
            </Tabs>
          </Col>
        </Row>
      </If>
    </GenericCard>
  );
}

export default ProductCostPricesForm;
