import React from 'react';
import { Input } from 'antd';

function BearSearchInput({
  onSearch,
  defaultValue = '',
  placeholder = 'Zoeken',
}) {
  return (
    <Input.Search
      className="bear-min-input-width"
      size="large"
      defaultValue={defaultValue}
      placeholder={placeholder}
      onSearch={onSearch}
    />
  );
}

export default BearSearchInput;
