import React from 'react';
import { Popover } from 'antd';

import { formatAmount } from '../../../utilities';

function SellingPriceBreakupPopover({
  priceExclVat,
  priceInclVat,
  vatPercentage,
}) {
  return (
    <Popover
      overlayClassName="inventory-selling-price-breakup-popover"
      content={
        <>
          <div className="bear-flex-sb">
            <p>Verkoopprijs (incl. BTW)&nbsp;</p>
            <p>{formatAmount(priceInclVat)}</p>
          </div>
          <div className="bear-flex-sb">
            <p>BTW ({vatPercentage}%)</p>
            <p>{formatAmount(priceInclVat - priceExclVat)}</p>
          </div>
        </>
      }
      title="Details"
      className="bear-has-tooltip"
    >
      {formatAmount(priceExclVat)}
    </Popover>
  );
}

export default SellingPriceBreakupPopover;
