import useSWRMutation from 'swr/mutation';
import { CostPrice } from '../types';
import productsAxios from '../../axiosInstance';

export function useOfferCostPricesUpdate(
  offerId: string,
): (costPrices: CostPrice[]) => Promise<CostPrice[]> {
  const { trigger } = useSWRMutation(
    offerId ? `/offers/${offerId}/cost_prices` : null,
    (url: string, { arg }: { arg: CostPrice[] }) =>
      productsAxios
        .patch(url, JSON.stringify(arg))
        .then((response) => response.data as CostPrice[]),
  );
  return trigger;
}
