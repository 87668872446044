import React from 'react';

import { ProfileDataConsumer } from '../context/profileData';
import {
  UserProfileInterface,
  setProfileData as profileSetter,
} from '../reducers/profileReducer';

function ProfileHook(BaseComponent) {
  return function WithProfileData(props) {
    const [profileDataConsumer, dispatch] = ProfileDataConsumer();
    const profileData: UserProfileInterface = profileDataConsumer?.detail || {};

    function setProfileData(data: UserProfileInterface) {
      dispatch(profileSetter(data));
    }

    return (
      <BaseComponent
        {...props}
        profileData={profileData}
        setProfileData={(data) => setProfileData(data)}
      />
    );
  };
}

export default ProfileHook;
