import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select, Button, Row, Col, Space } from 'antd';

import { If } from 'tsx-control-statements/components';
import {
  getStoreStatistics,
  getStoreTopProducts,
  getStoreGraphMetrics,
  getStoreReturnStatistics,
} from '../../actions/store';
import {
  sortOptions,
  DEFAULT_PRODUCTS_SORT,
  DEFAULT_COUNTRY_FILTER,
  DEFAULT_STORE_OPTION,
} from './constants';
import { getAccounts } from '../../actions';
import { getStatisticValue } from '../utilities';
import { getLastMonthRange } from '../../../utilities/date';
import { getQueryStringValue } from '../../utilities/queryString';
import { DASHBOARD_PRODUCTS_ROUTE } from '../../routes';
import { getAllCampaignsStatistics } from '../../actions/campaign';

import DashboardGraph from './graph';
import ProfileHook from '../../../hooks/profile';
import useQueryString from '../../hooks/useQueryString';
import BearStatistics from '../../../components/bearStatistics';
import BearDatePicker from '../../../components/bearDatePicker';
import StoreCountrySelector from '../components/countrySelector';
import StoreStatisticSubValue from '../components/statisticSubValue';
import StoreSelector from '../components/storeSelector';
import { StoreTopInventoryProducts } from '../components/topInventoryProducts';

import StoreDashboardInsights from './insights';

import StoreNotConnected from '../../components/notConnectedState';
import GenericCard from '../../../components/bearCards/genericCard';

import './style.scss';
import captureErrorAndShowMessage from '../../../utilities/errors';
import { ROW_GUTTER } from '../../constants';

const statisticsSpan = {
  span: {
    lg: 4,
    xs: 8,
  },
};

function StoreDashboard({ profileData }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accounts = useSelector((state) => state.accountsReducer.accounts);
  const history = useHistory();
  const isStoreEnabled = profileData.is_store_enabled;

  const [graphLoading, setGraphLoading] = useState(true);
  const [productsLoading, setTopProductsLoading] = useState(true);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [returnStatisticsLoading, setReturnStatisticsLoading] = useState(true);
  const [campaignStatisticsLoading, setCampaignStatisticsLoading] =
    useState(true);

  const [statistics, setStatistics] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [returnStatistics, setReturnStatistics] = useState({});
  const [campaignStatistics, setCampaignStatistics] = useState([]);

  const [dateRange, setDateRange] = useQueryString(
    'interval',
    getLastMonthRange(),
  );
  const [sortBy, setSortBy] = useState(DEFAULT_PRODUCTS_SORT);
  const [selectedCountry, setSelectedCountry] = useState(
    DEFAULT_COUNTRY_FILTER,
  );
  const [unit, setUnit] = useState();
  const isDateInUrl = getQueryStringValue('interval');

  const allAccounts = true;
  const [stores, setStores] = useState([DEFAULT_STORE_OPTION]);
  const [selectedStore, setSelectedStore] = useState(DEFAULT_STORE_OPTION);

  useEffect(() => {
    dispatch(getAccounts);
  }, []);

  useEffect(() => {
    setStores([DEFAULT_STORE_OPTION].concat(accounts || []));
  }, [accounts]);

  useEffect(() => {
    if (isStoreEnabled) {
      setGraphLoading(true);
      getStoreGraphMetrics(dateRange, selectedCountry, unit, allAccounts)
        .then((response) => {
          setUnit(response.data[0].data.unit);
          setGraphData(response.data);
        })
        .catch((e) => captureErrorAndShowMessage(e))
        .finally(() => setGraphLoading(false));
    }
  }, [dateRange, selectedCountry]);

  useEffect(() => {
    if (isStoreEnabled) {
      setStatisticsLoading(true);
      setReturnStatisticsLoading(true);
      getStoreStatistics(dateRange, allAccounts)
        .then((response) => {
          setStatistics(response.data);
        })
        .catch((e) => captureErrorAndShowMessage(e))
        .finally(() => setStatisticsLoading(false));
    }
  }, [dateRange]);

  useEffect(() => {
    if (isStoreEnabled) {
      setReturnStatisticsLoading(true);
      getStoreReturnStatistics(dateRange, allAccounts)
        .then((response) => {
          setReturnStatistics(response.data);
        })
        .catch((e) => captureErrorAndShowMessage(e))
        .finally(() => setReturnStatisticsLoading(false));
    }
  }, [dateRange]);

  useEffect(() => {
    if (isStoreEnabled) {
      getAllCampaignsStatistics(dateRange, allAccounts)
        .then((response) => {
          setCampaignStatistics(response.data);
        })
        .catch((e) => captureErrorAndShowMessage(e))
        .finally(() => setCampaignStatisticsLoading(false));
    }
  }, [dateRange]);

  useEffect(() => {
    setTopProductsLoading(true);
    if (isStoreEnabled) {
      getStoreTopProducts(
        dateRange,
        sortBy,
        selectedCountry,
        5,
        1,
        selectedStore,
      )
        .then((response) => {
          setTopProducts(response.data.results);
        })
        .catch((e) => captureErrorAndShowMessage(e))
        .finally(() => setTopProductsLoading(false));
    }
  }, [selectedCountry, selectedStore, dateRange, sortBy]);

  function renderStatisticsValue({ fieldKey, isAmount = true }) {
    return getStatisticValue({
      statistics,
      selectedCountry,
      valueKey: fieldKey,
      isLoading: statisticsLoading,
      isAmount,
      selectedStore,
    });
  }

  function handleMoreProductsClick() {
    const path = DASHBOARD_PRODUCTS_ROUTE.concat(
      '?interval=',
      dateRange[0],
      '&interval=',
      dateRange[1],
    );
    history.push(path, { selectedStore });
  }

  const statisticsItems = [
    {
      key: 'revenue',
      title: {
        text: 'Omzet',
      },
      ...statisticsSpan,
      value: renderStatisticsValue({ fieldKey: 'revenue' }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="revenue"
            tooltip={`De stijging / daling vergeleken met de vorige periode. Als je de laatste 7 dagen 
            Oktober selecteert in het dashboard, dan zal je de stijging / daling zien vergeleken met de 
            maand ervoor, oftewel September. Voorbeeld: Als de omzet in Oktober 15K is en in 
            September 10K, dan zie je onder de omzet een stijging van 5K.`}
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
    {
      key: 'price',
      title: {
        text: 'Kosten',
      },
      ...statisticsSpan,
      value: renderStatisticsValue({ fieldKey: 'price' }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="price"
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
    {
      key: 'profit',
      title: {
        text: 'Winst',
      },
      ...statisticsSpan,
      value: renderStatisticsValue({ fieldKey: 'profit' }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            statistics={statistics}
            selectedCountry={selectedCountry}
            valueKey="profit"
            isLoading={statisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
    {
      key: 'orders',
      title: {
        text: 'Bestellingen',
      },
      ...statisticsSpan,
      value: renderStatisticsValue({ fieldKey: 'orders', isAmount: false }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            isAmount={false}
            statistics={statistics}
            valueKey="orders"
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
    {
      key: 'sales',
      title: {
        text: 'Producten verkocht',
      },
      ...statisticsSpan,
      value: renderStatisticsValue({ fieldKey: 'sales', isAmount: false }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="sales"
            isAmount={false}
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
    {
      key: 'returns',
      title: {
        text: 'Retouren',
      },
      ...statisticsSpan,
      value: getStatisticValue({
        statistics: returnStatistics,
        selectedCountry,
        valueKey: 'returns',
        isLoading: returnStatisticsLoading,
        isAmount: false,
        selectedStore,
      }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            isOppositeCorrect
            isAmount={false}
            valueKey="returns"
            statistics={returnStatistics}
            selectedCountry={selectedCountry}
            isLoading={returnStatisticsLoading}
            selectedStore={selectedStore}
          />
        ),
      },
    },
  ];

  if (!isStoreEnabled) {
    return <StoreNotConnected />;
  }

  return (
    <div className="dashboard-container">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={ROW_GUTTER} align="middle">
          <Col xs={24} className="bear-col-fa">
            <BearDatePicker
              onApply={(selectedRange) => setDateRange(selectedRange)}
              defaultValues={isDateInUrl ? dateRange : null}
            />
          </Col>
          <Col xs={24} className="bear-col-fa">
            <StoreCountrySelector
              selectedValue={selectedCountry}
              onChange={(value) => setSelectedCountry(value)}
            />
          </Col>
          <Col xs={24} className="bear-col-fa">
            <StoreSelector
              selectedValue={selectedStore}
              options={stores}
              onChange={(value) => setSelectedStore(value)}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <BearStatistics
              items={statisticsItems}
              isCompleteLoading={statisticsLoading}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <DashboardGraph
              unit={unit}
              graphData={graphData}
              isLoading={graphLoading}
              stores={stores}
              selectedStore={selectedStore}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <GenericCard
              className="store-top-products-card"
              title="Prestaties per product"
              extra={
                <div className="bear-flex-ac">
                  <Select
                    size="large"
                    value={sortBy}
                    optionLabelProp="label"
                    onChange={(value) => setSortBy(value)}
                  >
                    {sortOptions.map((option) => (
                      <Select.Option
                        key={option.value}
                        value={option.value}
                        label={`Sorteren op: ${option.title}`}
                      >
                        {option.title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              }
            >
              <StoreTopInventoryProducts
                dateRange={dateRange}
                products={topProducts}
                isLoading={productsLoading}
                selectedCountry={selectedCountry}
                selectedStore={selectedStore}
                allStores={stores}
              />
              <If condition={topProducts.length}>
                <Button
                  size="large"
                  className="reverse top-more-products-btn"
                  onClick={() => handleMoreProductsClick()}
                >
                  Bekijk meer producten
                </Button>
              </If>
            </GenericCard>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <StoreDashboardInsights
              statistics={statistics}
              isLoading={statisticsLoading}
              campaignStatisticsLoading={campaignStatisticsLoading}
              campaignStatistics={campaignStatistics}
              selectedStore={selectedStore}
              dateRange={dateRange}
            />
          </Col>
        </Row>
      </Space>
    </div>
  );
}

export default ProfileHook(StoreDashboard);
