import { DEFAULT_CAMPAIGN_ACCESS_USER_ID } from '../../constants';
import * as endpoints from '../../endpoints';

import {
  updatePathWithPagination,
  updatePathWithTimeValues,
  updatePathWithPaginationAndTimeValues,
  addAccessUserId,
} from '../../../actions';
import instance from '../../../axios';

export const getCampaignsAnalytics = () => {
  return instance.get(endpoints.CAMPAIGN_ANALYTICS_API_PATH, {
    hideNotification: true,
  });
};

export const getCampaignList = (pageNum, extraQuery, interval) => {
  let path = updatePathWithPaginationAndTimeValues(
    endpoints.CAMPAIGN_LIST_API_PATH,
    pageNum,
    interval
  );
  path = path.concat(extraQuery);
  return instance.get(path);
};

export const getDefaultCampaignList = () => {
  const path = endpoints.DEFAULT_CAMPAIGN_LIST_API_PATH;
  return instance.get(path);
};

export const createCampaign = (data) => {
  return instance.post(endpoints.CAMPAIGN_LIST_API_PATH, data);
};

export const updateCampaign = (data, campaignId, accessUserId = undefined) => {
  let path = endpoints.CAMPAIGN_LIST_API_PATH.concat(campaignId, '/');
  path = addAccessUserId(path, accessUserId);

  return instance.patch(path, data);
};

export const deleteCampaign = (campaignId) => {
  const path = endpoints.CAMPAIGN_LIST_API_PATH.concat(campaignId, '/');
  return instance.delete(path);
};

export const getCampaignEmailCategories = (isUserCategories = false) => {
  let path = '';
  if (isUserCategories) {
    path = endpoints.USER_EMAIL_CATEGORIES_LIST_API_PATH;
  } else {
    path = endpoints.DEFAULT_EMAIL_CATEGORIES_LIST_API_PATH;
  }
  return instance.get(path);
};

export const getCampaignEmailTemplates = (
  pageNum,
  isUserTemplates = false,
  categorySlug = ''
) => {
  let path = '';
  if (isUserTemplates) {
    path = updatePathWithPagination(
      endpoints.USER_EMAIL_TEMPLATES_LIST_API_PATH,
      pageNum
    );
  } else {
    path = endpoints.DEFAULT_EMAIL_TEMPLATES_LIST_API_PATH;
  }
  if (categorySlug) {
    path = path.concat(
      `${isUserTemplates ? '&' : '?'}category__slug=${categorySlug}`
    );
  }
  return instance.get(path);
};

export const deleteUserEmailTemplate = (templateId) => {
  const path = endpoints.USER_EMAIL_TEMPLATES_LIST_API_PATH.concat(
    templateId,
    '/'
  );
  return instance.delete(path);
};

export const renameUserEmailTemplate = (templateId, templateData) => {
  const path = endpoints.USER_EMAIL_TEMPLATES_LIST_API_PATH.concat(
    templateId,
    '/'
  );
  return instance.patch(path, templateData);
};

export const renameUserEmailCategory = (catrgoryId, categoryData) => {
  const path = endpoints.USER_EMAIL_CATEGORIES_LIST_API_PATH.concat(
    catrgoryId,
    '/'
  );
  return instance.patch(path, categoryData);
};

export const getCampaignMergeTags = () => {
  return instance.get(endpoints.CAMPAIGN_MERGE_TAGS, {
    hideNotification: true,
  });
};

export const createCampaignFlow = (
  campaignId,
  data,
  accessUserId = undefined
) => {
  let path = endpoints.CAMPAIGN_FLOW_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = addAccessUserId(path, accessUserId);

  return instance.post(path, data);
};

export const updateCampaignFlow = (
  campaignId,
  flowId,
  flowData,
  accessUserId = DEFAULT_CAMPAIGN_ACCESS_USER_ID,
  isMultipart = false,  
) => {
  let path = endpoints.CAMPAIGN_FLOW_API_PATH.replace('{campaign_id}', campaignId);
  path = path.concat(flowId, '/');
  path = addAccessUserId(path, accessUserId);

  let config = {};

  if (isMultipart) {
    config.headers = {"Content-Type": "multipart/form-data"}
  }

  return instance.patch(path, flowData, config);
};

export const duplicateCampaignFlow = (
  campaignId,
  flowId,
  accessUserId = undefined
) => {
  let path = endpoints.CAMPAIGN_FLOW_DUPLICATE_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.replace('{flow_id}', flowId);
  path = addAccessUserId(path, accessUserId);

  return instance.post(path);
};

export const deleteCampaignFlow = (
  campaignId,
  flowId,
  accessUserId = undefined
) => {
  let path = endpoints.CAMPAIGN_FLOW_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.concat(flowId, '/');
  path = addAccessUserId(path, accessUserId);

  return instance.delete(path);
};

export const saveEmailTemplate = (data) => {
  return instance.post(endpoints.USER_EMAIL_TEMPLATES_LIST_API_PATH, data);
};

export const sendTestCampaignFlowEmail = (campaignId, flowId, email) => {
  let path = endpoints.CAMPAIGN_FLOW_TEST_EMAIL_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.replace('{flow_id}', flowId);
  return instance.post(path, email);
};

export const getCampaignInventory = (
  pageNum,
  searchTerm,
  campaignId,
  showAll
) => {
  let path = updatePathWithPagination(
    endpoints.CAMPAIGN_INVENTORY_LIST_API_PATH,
    pageNum
  );
  path = path.concat(`&show_all=${showAll}`);
  path = path.concat(`&campaign_id=${campaignId}`);
  path = path.concat(`&search=${searchTerm}`);
  return instance.get(path);
};

export const getCampaignFlows = (campaignId, accessUserId = undefined) => {
  let path = endpoints.CAMPAIGN_FLOW_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = addAccessUserId(path, accessUserId);

  return instance.get(path);
};

export const getCampaignDetails = (
  campaignId,
  dateRange,
  query,
  accessUserId = undefined
) => {
  let path = endpoints.CAMPAIGN_DETAILS_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  if (dateRange) {
    path = updatePathWithTimeValues(path, ...dateRange);
  }
  if (query) {
    path = path.concat(query);
  }
  if (accessUserId) {
    if (!dateRange && !query) {
      path = addAccessUserId(path, accessUserId);
    } else {
      path = path.concat('&access_user_id=', accessUserId);
    }
  }

  return instance.get(path);
};

export const getCampaignTaskCount = (campaignId) => {
  const path = endpoints.CAMPAIGN_LIST_API_PATH.concat(
    campaignId,
    '/get_task_count/'
  );
  return instance.post(path);
};

export const getCampaignFlowTaskCount = (campaignId, flowId) => {
  let path = endpoints.CAMPAIGN_FLOW_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.concat(flowId, '/get_task_count/');
  return instance.post(path);
};
export const getAllCampaignsTags = () => {
  return instance.get(endpoints.CAMPAIGN_TAGS_API_PATH);
};

export const translateCampaignFlowEmail = (data) => {
  return instance.post(endpoints.CAMPAIGN_TRANSLATE_API_PATH, data);
};

export const getAllCampaignsStatistics = (dateRange, allAccounts = false) => {
  const path = updatePathWithTimeValues(
    endpoints.CAMPAIGN_STATISTICS_API_PATH,
    ...dateRange
  ).concat('&all_accounts=', allAccounts);
  return instance.get(path);
};

export const getCampaignStatistics = (campaignId, dateRange) => {
  const path = updatePathWithTimeValues(
    endpoints.CAMPAIGN_DETAIL_STATISTICS_API_PATH.replace(
      '{campaign_id}',
      campaignId
    ),
    ...dateRange
  );
  return instance.get(path);
};

export const getCampaignLinksStatistics = (campaignId, dateRange) => {
  const path = updatePathWithTimeValues(
    endpoints.CAMPAIGN_LINK_STATISTICS_API_PATH.replace(
      '{campaign_id}',
      campaignId
    ),
    ...dateRange
  );
  return instance.get(path);
};

export const getCampaignEmails = (
  campaignId,
  pageNum,
  interval,
  extraQuery
) => {
  let path = endpoints.CAMPAIGN_EMAILS_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = updatePathWithPaginationAndTimeValues(path, pageNum, interval);
  path = path.concat(extraQuery);
  return instance.get(path);
};

export const campaignSchedulePendingEmail = (campaignId, taskId, data) => {
  let path = endpoints.CAMPAIGN_EMAILS_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.concat(taskId, '/schedule_email/');
  return instance.post(path, data);
};

export const campaignCancelPendingEmail = (campaignId, taskId) => {
  let path = endpoints.CAMPAIGN_EMAILS_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.concat(taskId, '/cancel_email/');
  return instance.post(path);
};

export const campaignViewEmail = (campaignId, taskId) => {
  let path = endpoints.CAMPAIGN_EMAILS_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  path = path.concat(taskId, '/view_email/');
  return instance.get(path);
};

export const duplicateCampaign = (data, campaignId) => {
  const path = endpoints.CAMPAIGN_DUPLICATE_API_PATH.replace(
    '{campaign_id}',
    campaignId
  );
  return instance.post(path, data);
};
