import React from 'react';

import { IMAGE_STATIC_PATH } from '../../../constants';
import { useBolProduct } from '../../services/productsService/api';

function ProductImage({ ean }: { ean: string }) {
  const { product } = useBolProduct(ean);

  return (
    <img
      src={
        product?.image ||
        `${IMAGE_STATIC_PATH}/icons/placeholder_default_image.svg`
      }
      alt={product?.name}
    />
  );
}

export default ProductImage;
