import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Tooltip,
  Checkbox,
  message,
  Button,
  Space,
} from 'antd';

import { TooltipPlacement } from 'antd/lib/tooltip';
import { If } from 'tsx-control-statements/components';
import { allowNumbersOnly } from '../../../../utilities';

import {
  OfferProductInterface,
  StockNotificationsInterface,
  useOffersProductEdit,
  useStockNotifications,
  useStockNotificationsEdit,
} from '../../../../services/productsService/api';

import captureErrorAndShowMessage from '../../../../../utilities/errors';
import { MAXIMUM_NOTIFICATION_COUNT } from '../../constants';

import './style.scss';
import { useUserProfile } from '../../../../services/userService/api';
import { ROW_GUTTER } from '../../../../constants';
import GenericCard from '../../../../../components/bearCards/genericCard';

function ProductStockNotificationSettings({
  tooltipPlacement = 'top',
  offerProduct,
}: {
  tooltipPlacement?: TooltipPlacement;
  offerProduct: OfferProductInterface | null;
}) {
  const { user } = useUserProfile();

  const editOfferProduct = useOffersProductEdit(String(offerProduct?.id));
  const { stockNotifications } = useStockNotifications(
    offerProduct?.bol_offer_id,
  );
  const editStockNotification = useStockNotificationsEdit(
    offerProduct?.bol_offer_id,
  );
  const [form] = Form.useForm<StockNotificationsInterface>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    form.setFieldsValue(stockNotifications);
  }, [stockNotifications]);

  return (
    <GenericCard title="Notificaties" className="product-stock-settings">
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        scrollToFirstError
        className="stock-settings-form"
        onFinish={async (values) => {
          try {
            setIsSaving(true);
            await editStockNotification(values);
            message.success(`Je notificatie instellingen zijn opgeslagen.`);
          } catch (e) {
            captureErrorAndShowMessage(e);
          } finally {
            setIsSaving(false);
          }
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row gutter={ROW_GUTTER}>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    <span>
                      Hoeveel dagen duurt het voordat de voorraad is bijgevuld?
                    </span>

                    <Tooltip
                      placement={tooltipPlacement}
                      className="bear-form-label-tooltip"
                      title={`
                      We sturen je een notificatie met de verzendtijd vanaf de 
                      leverancier tot je warenhuis ingecalculeerd, 
                      zodat je op tijd weet dat je producten moet 
                      inkopen. Zo raak je nooit uitverkocht.`}
                    >
                      <i className="fad icon-question-circle" />
                    </Tooltip>
                  </>
                }
                name="days_to_refill"
              >
                <InputNumber
                  min={0}
                  max={365}
                  size="large"
                  className="bear-width"
                  onKeyDown={(event) => allowNumbersOnly(event, false)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col span={24}>
              <Form.List name="stock_notification_schedules">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <Row
                          gutter={ROW_GUTTER}
                          align="middle"
                          className="notification-row"
                          key={field.key}
                        >
                          <Col className="bear-full-flex" span={24}>
                            <Form.Item
                              {...field}
                              name={[field.name]}
                              label={
                                <If condition={index === 0}>
                                  <div>
                                    <span>
                                      Hoeveel dagen vóórdat je moet gaan
                                      bijvullen wil je een notificatie
                                      ontvangen?&nbsp;
                                    </span>
                                    <Tooltip
                                      className="bear-form-label-tooltip"
                                      title={`
                          We sturen je een notificatie op de aantal dagen toegevoegd vóórdat je moet gaan bijvullen/bestellen.
                           Stel het duurt 20 dagen om bij te vullen, en je vult hier 5 dagen in,
                            dan sturen we je een notificatie 25 dagen voordat je bent uitverkocht,
                             en dus 5 dagen voordat je moet gaan bijvullen. We controleren dit elke dag, zodat we je precies op het juiste moment een notificatie kunnen sturen.`}
                                    >
                                      <i className="fad icon-question-circle" />
                                    </Tooltip>
                                  </div>
                                </If>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field',
                                },
                              ]}
                            >
                              <Input
                                min={1}
                                size="large"
                                addonAfter="dagen"
                                onKeyDown={(event) =>
                                  allowNumbersOnly(event, false)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col className="item-delete-trigger">
                            <i
                              role="presentation"
                              className="fad icon-trash"
                              onClick={() => remove(field.name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      <If
                        condition={fields.length < MAXIMUM_NOTIFICATION_COUNT}
                      >
                        <Row gutter={ROW_GUTTER}>
                          <Col>
                            <p onClick={() => add()} className="bear-link">
                              + Notificatie toevoegen
                            </p>
                          </Col>
                        </Row>
                      </If>
                    </>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
          <If condition={user?.retailer?.is_hijacker_alerts_enabled}>
            <Row gutter={ROW_GUTTER}>
              <Col
                span={24}
                className="stock-settings-form"
                style={{ marginTop: '1.6rem' }}
              >
                <Checkbox
                  checked={offerProduct?.check_hijacking}
                  onChange={async (event) => {
                    const isChecked = event.target.checked;
                    try {
                      await editOfferProduct({ check_hijacking: isChecked });
                      message.success('Instellingen succesvol opgeslagen');
                    } catch (e) {
                      captureErrorAndShowMessage(e);
                    }
                  }}
                >
                  <p
                    className="bear-regular-text"
                    data-testid="hijacker-notification-checkbox"
                  >
                    Hijacker alerts ingeschakeld
                  </p>
                </Checkbox>
              </Col>
            </Row>
          </If>
          <Row gutter={ROW_GUTTER}>
            <Col>
              <Button
                size="large"
                type="primary"
                loading={isSaving}
                onClick={() => form.submit()}
              >
                Opslaan
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </GenericCard>
  );
}

export default ProductStockNotificationSettings;
