import useBreakpoint from '../hooks/breakpoint';
import {
  MOBILE_BREAKPOINTS,
  LARGE_DESKTOP_BREAKPOINTS,
  MOBILE_AND_TABLET_BREAKPOINTS,
} from '../constants';

export const getBreakpoint = () => {
  return useBreakpoint();
};

export const isMobileDevice = () => {
  const point = useBreakpoint();
  return MOBILE_AND_TABLET_BREAKPOINTS.indexOf(point) > -1;
};

export const isMediumBreakpoint = () => {
  const point = useBreakpoint();
  return ['md'].indexOf(point) > -1;
};

export const isMobileOnlyDevice = () => {
  const point = useBreakpoint();
  return MOBILE_BREAKPOINTS.indexOf(point) > -1;
};

export const isLargeDesktop = () => {
  const point = useBreakpoint();
  return LARGE_DESKTOP_BREAKPOINTS.indexOf(point) > -1;
};
