/*eslint-disable*/

export const initialState = {
  campaigns: [],
};

const SET_CAMPAIGNS = 'EVENT/SET_CAMPAIGNS';

export const setCampaigns = (campaigns) => {
  return {
    type: SET_CAMPAIGNS,
    payload: campaigns,
  };
};

const campaignListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: [...action.payload],
      };
    default:
      return state;
  }
};

export default campaignListReducer;
