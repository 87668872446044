// eslint-disable-next-line import/prefer-default-export
export const INVOICE_COUNTRIES = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albanië', code: 'AL' },
  { name: 'Algerije', code: 'DZ' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Antigua en Barbuda', code: 'AG' },
  { name: 'Argentinië', code: 'AR' },
  { name: 'Armenië', code: 'AM' },
  { name: 'Australië', code: 'AU' },
  { name: 'Azerbeidzjan', code: 'AZ' },
  { name: "Bahama's", code: 'BS' },
  { name: 'Bahrein', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'België', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnië en Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Brazilië', code: 'BR' },
  { name: 'Brunei', code: 'BN' },
  { name: 'Bulgarije', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodja', code: 'KH' },
  { name: 'Canada', code: 'CA' },
  { name: 'Centraal-Afrikaanse Republiek', code: 'CF' },
  { name: 'Chili', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoren', code: 'KM' },
  { name: 'Congo-Brazzaville', code: 'CG' },
  { name: 'Congo-Kinshasa', code: 'CD' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Denemarken', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominicaanse Republiek', code: 'DO' },
  { name: 'Duitsland', code: 'DE' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypte', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatoriaal-Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estland', code: 'EE' },
  { name: 'Ethiopië', code: 'ET' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Filipijnen', code: 'PH' },
  { name: 'Finland', code: 'FI' },
  { name: 'Frankrijk', code: 'FR' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgië', code: 'GE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Griekenland', code: 'GR' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guinee', code: 'GN' },
  { name: 'Guinee-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haïti', code: 'HT' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hongarije', code: 'HU' },
  { name: 'Ierland', code: 'IE' },
  { name: 'IJsland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesië', code: 'ID' },
  { name: 'Irak', code: 'IQ' },
  { name: 'Iran', code: 'IR' },
  { name: 'Israël', code: 'IL' },
  { name: 'Italië', code: 'IT' },
  { name: 'Ivoorkust', code: 'CI' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jemen', code: 'YE' },
  { name: 'Jordanië', code: 'JO' },
  { name: 'Kaapverdië', code: 'CV' },
  { name: 'Kameroen', code: 'CM' },
  { name: 'Kazachstan', code: 'KZ' },
  { name: 'Kenia', code: 'KE' },
  { name: 'Kirgizië', code: 'KG' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Koeweit', code: 'KW' },
  { name: 'Kroatië', code: 'HR' },
  { name: 'Laos', code: 'LA' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Letland', code: 'LV' },
  { name: 'Libanon', code: 'LB' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libië', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Litouwen', code: 'LT' },
  { name: 'Luxemburg', code: 'LU' },
  { name: 'Madagaskar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Maldiven', code: 'MV' },
  { name: 'Maleisië', code: 'MY' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marokko', code: 'MA' },
  { name: 'Marshalleilanden', code: 'MH' },
  { name: 'Mauritanië', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia', code: 'FM' },
  { name: 'Moldavië', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolië', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibië', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nederland', code: 'NL' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Nieuw-Zeeland', code: 'NZ' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Noord-Korea', code: 'KP' },
  { name: 'Noord-Macedonië', code: 'MK' },
  { name: 'Noorwegen', code: 'NO' },
  { name: 'Oeganda', code: 'UG' },
  { name: 'Oekraïne', code: 'UA' },
  { name: 'Oezbekistan', code: 'UZ' },
  { name: 'Oman', code: 'OM' },
  { name: 'Oostenrijk', code: 'AT' },
  { name: 'Oost-Timor', code: 'TL' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papoea-Nieuw-Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Polen', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Roemenië', code: 'RO' },
  { name: 'Rusland', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Kitts en Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Vincent en de Grenadines', code: 'VC' },
  { name: 'Salomonseilanden', code: 'SB' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Saoedi-Arabië', code: 'SA' },
  { name: 'Sao Tomé en Principe', code: 'ST' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Servië', code: 'RS' },
  { name: 'Seychellen', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovenië', code: 'SI' },
  { name: 'Slowakije', code: 'SK' },
  { name: 'Soedan', code: 'SD' },
  { name: 'Somalië', code: 'SO' },
  { name: 'Spanje', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Syrië', code: 'SY' },
  { name: 'Tadzjikistan', code: 'TJ' },
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad en Tobago', code: 'TT' },
  { name: 'Tsjaad', code: 'TD' },
  { name: 'Tsjechië', code: 'CZ' },
  { name: 'Tunesië', code: 'TN' },
  { name: 'Turkije', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Verenigde Arabische Emiraten', code: 'AE' },
  { name: 'Verenigde Staten', code: 'US' },
  { name: 'Verenigd Koninkrijk', code: 'GB' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Wit-Rusland', code: 'BY' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'Zuid-Afrika', code: 'ZA' },
  { name: 'Zuid-Korea', code: 'KR' },
  { name: 'Zuid-Soedan', code: 'SS' },
  { name: 'Zweden', code: 'SE' },
  { name: 'Zwitserland', code: 'CH' },
];
