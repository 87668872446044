import React, { ReactNode } from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import * as routes from '../../../routes';
import {
  BOL_STORE_WARNING,
  hasBolStoreDataMissingWarning,
  hasBolStoreWarning,
} from '../../../utilities/app';
import { UserProfileInterface } from '../../../../reducers/profileReducer';
import {
  isSubscriptionStarterPlan,
  SubscriptionInterface,
} from '../../../services/subscriptionService/api';

export const SUBSCRIPTION_SETTINGS_TITLE = 'Lidmaatschap';
export const UNSUBSCRIBE_TITLE = 'Afmelden';

interface MenuItem {
  key?: string;
  title: string;
  icon?: ReactNode | string;
  lock_key?: string;
  enabled_for_substore?: boolean;
  video_id?: string;
  target?: boolean;
  pageTitle?: string;
  is_beta?: boolean;
  helpDocLink?: string;
  path?: string;
  isHidden?: (
    profileData: UserProfileInterface,
    subscription?: SubscriptionInterface,
  ) => boolean;
  rightIcon?: (
    profileData: UserProfileInterface,
    subscription?: SubscriptionInterface,
  ) => ReactNode | null;
}

export interface MenuGroup {
  title: string;
  key: string;
  lock_key?: string;
  enabled_for_substore: boolean;
  is_enabled?: string;
  items: MenuItem[];
  disabledForStarterAccounts?: (subscription: SubscriptionInterface) => boolean;
}

export const MENU: MenuGroup[] = [
  {
    title: 'Mijn store',
    key: 'store',
    lock_key: 'is_seller_store_enabled',
    enabled_for_substore: true,
    disabledForStarterAccounts: (subscription) => {
      return !subscription || isSubscriptionStarterPlan(subscription);
    },
    items: [
      {
        key: 'ai',
        title: 'Boloo AI',
        icon: (
          <ThunderboltOutlined
            style={{ fontSize: '16px', verticalAlign: 'middle' }}
          />
        ),
        helpDocLink: 'https://help.boloo.co/nl/collections/4184942-boloo-ai',
        path: routes.BOLOO_AI_BASE_ROUTE,
        pageTitle: 'Boloo AI',
      },
      {
        key: 'dashboard',
        title: 'Dashboard',
        video_id: 'wcnn3nqc3z',
        path: routes.INDEX_ROUTE,
        icon: 'fad icon-cross',
        pageTitle: 'Dashboard – Mijn Store',
        enabled_for_substore: true,
      },
      {
        key: 'dashboard-v2',
        title: 'Dashboard [BETA]',
        path: routes.FRS_DASHBOARD,
        icon: 'fad icon-cross',
        pageTitle: 'Dashboard – Mijn Store',
        enabled_for_substore: true,
      },
      {
        key: 'products',
        title: 'Producten',
        icon: 'fad icon-box-heart',
        path: routes.STORE_INVENTORY_ROUTE,
        pageTitle: 'Producten – Mijn Store',
      },
      {
        key: 'orders',
        title: 'Bestellingen',
        icon: 'fad icon-truck',
        path: routes.STORE_ORDERS_ROUTE,
        pageTitle: 'Bestellingen – Mijn Store',
      },
      {
        key: 'orders',
        title: 'Bestellingen',
        icon: 'fad fa-truck',
        path: routes.STORE_ORDERS_EDIT_ROUTE,
        pageTitle: 'Bestellingen – Mijn Store',
        isHidden: () => true,
      },
      {
        key: 'invoicing',
        title: 'Klantfacturen',
        icon: 'fad icon-invoice',
        path: routes.INVOICING_BASE_ROUTE,
        pageTitle: 'Klantfacturen',
        is_beta: true,
        rightIcon: (profileData) => {
          if (!hasBolStoreDataMissingWarning(profileData)) {
            return null;
          }

          return BOL_STORE_WARNING;
        },
      },
      {
        key: 'campaigns',
        title: 'E-mailcampagnes',
        video_id: 'lo97mblof0',
        icon: 'fad icon-bullhorn',
        path: routes.CAMPAIGN_BASE_ROUTE,
        pageTitle: 'E-mailcampagnes - Mijn Store',
      },
      {
        title: 'Campagne aanpassen',
        key: 'campaignEdit',
        icon: 'fad icon-bullhorn',
        path: routes.CAMPAIGN_EDIT_ROUTE,
        pageTitle: 'E-mailcampagnes - Campagne aanpassen',
        isHidden: () => true,
      },
      {
        title: 'Campaign details',
        key: 'campaignDetails',
        icon: 'fad icon-bullhorn',
        path: routes.CAMPAIGN_DETAIL_ROUTE,
        pageTitle: 'E-mailcampagnes - Campaign Details',
        isHidden: () => true,
      },
      {
        key: 'campaignCreate',
        title: 'Nieuwe campagne',
        icon: 'fad icon-bullhorn',
        path: routes.CAMPAIGN_CREATE_ROUTE,
        pageTitle: 'E-mailcampagnes - Nieuwe campagne',
        isHidden: () => true,
      },
      {
        icon: 'fad icon-bullhorn',
        key: 'performance_products',
        title: 'Prestaties per producten',
        pageTitle: 'Prestaties per producten - Mijn Store',
        path: routes.DASHBOARD_PRODUCTS_ROUTE,
        isHidden: () => true,
      },
      {
        key: 'products',
        title: 'Producten',
        icon: 'fad icon-bullhorn',
        pageTitle: 'Producten - Mijn Store',
        path: routes.STORE_INVENTORY_PRODUCT_DETAIL_ROUTE,
        isHidden: () => true,
      },
      {
        key: 'products',
        title: 'Producten',
        icon: 'fad icon-bullhorn',
        pageTitle: 'Producten - Mijn Store',
        path: routes.STORE_INVENTORY_PRODUCT_EDIT_ROUTE,
        isHidden: () => true,
      },
    ],
  },
  {
    title: 'Product Research',
    key: 'research',
    enabled_for_substore: true,
    items: [
      {
        key: 'tracker',
        video_id: '7defwhcwe7',
        title: 'Product Tracker',
        icon: 'fad icon-analytics',
        pageTitle: 'Product Tracker – Research',
        path: routes.RESEARCH_TRACKER_ROUTE_V2, // TODO Replace the path route of the next item to enable the new product tracker
        isHidden: (profileData) => !profileData.is_parent,
      },
      {
        key: 'tracker',
        video_id: '7defwhcwe7',
        title: 'Product Tracker',
        icon: 'fad icon-analytics',
        pageTitle: 'Product Tracker – Research',
        path: routes.RESEARCH_TRACKER_ROUTE_DETAIL_V2,
        isHidden: () => true,
      },
      {
        key: 'database',
        video_id: 'omvexzqpc6',
        title: 'Product Database',
        icon: 'fad icon-database',
        pageTitle: 'Product Database – Research',
        path: routes.RESEARCH_DATABASE_ROUTE_V2, // TODO Replace the path route of the next item to enable the new product database
        isHidden: (profileData) => !profileData.is_parent,
      },
      {
        key: 'explorer',
        video_id: 'sn7aocfkci',
        title: 'Keyword Verkenner',
        icon: 'fad icon-spell-check',
        lock_key: 'is_keyword_planner_enabled',
        pageTitle: 'Keyword Verkenner – Research',
        path: routes.RESEARCH_EXPLORER_ROUTE,
        isHidden: (profileData) => !profileData.is_parent,
      },
      {
        key: 'calculator',
        video_id: 'dsabxxr8l0',
        title: 'Winstcalculator',
        icon: 'fad icon-calculator',
        pageTitle: 'Winstcalculator – Research',
        path: routes.RESEARCH_CALCULATOR_ROUTE,
        isHidden: (profileData) => !profileData.is_parent,
      },
      {
        key: 'product-ranking-checker',
        video_id: 'sm3petdhc6',
        title: 'Ranking Checker',
        icon: 'fad icon-history',
        pageTitle: 'Product Ranking Checker',
        path: routes.RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE,
        isHidden: (profileData, subscription) => {
          return !subscription || isSubscriptionStarterPlan(subscription);
        },
      },
    ],
  },
  {
    title: 'Educatie',
    key: 'learn',
    enabled_for_substore: false,
    items: [
      {
        key: 'courses',
        title: 'Videocursussen',
        icon: 'fad icon-camcorder',
        path: routes.ACADEMY_ROUTE,
        lock_key: 'is_course_enabled',
        pageTitle: 'Videocursussen - Educatie',
      },
      {
        key: 'youtube',
        icon: 'fad icon-video',
        title: 'YouTube Channel',
        path: 'https://www.youtube.com/c/boloo',
        target: true,
      },
    ],
  },
  {
    title: 'Accounting',
    key: 'accounting',
    is_enabled: 'is_accounting_enabled',
    lock_key: 'is_accounting_enabled',
    enabled_for_substore: true,
    items: [
      {
        key: 'result',
        title: 'Resultaat',
        icon: 'fad icon-results',
        path: routes.ACCOUNTING,
        pageTitle: 'Resultaat – Accounting',
      },
      {
        key: 'cost',
        title: 'Kosten',
        pageTitle: 'Kosten – Accounting',
        icon: 'fad icon-file-invoice-dollar',
        path: routes.ACCOUNTING_USER_COST_INVOICES,
      },
      {
        key: 'revenue',
        title: 'Inkomsten',
        icon: 'fad icon-money-bill-alt',
        pageTitle: 'Inkomsten – Accounting',
        path: routes.ACCOUNTING_USER_REVENUE_INVOICES,
      },
      {
        key: 'declaration',
        title: 'BTW aangifte',
        icon: 'fad icon-vat-declaration',
        path: routes.ACCOUNTING_DECLARATION,
        pageTitle: 'BTW aangifte – Accounting',
      },
    ],
  },
  {
    title: 'Account',
    key: 'setting',
    enabled_for_substore: true,
    items: [
      {
        key: 'account',
        icon: 'fad icon-settings',
        title: 'Accountinformatie',
        rightIcon: (profileData, subscription) => {
          if (!hasBolStoreWarning(profileData, subscription)) {
            return null;
          }

          return BOL_STORE_WARNING;
        },
        path: routes.SETTINGS_ROUTE,
        pageTitle: 'Accountinformatie',
      },
      {
        key: 'feedback',
        icon: 'fad icon-bell',
        title: 'Feedback',
        path: routes.FEEDBACK_ROUTE,
        pageTitle: 'Feedback',
      },
    ],
  },
];

export const SUBMENU = MENU.flatMap((item) => item.items);
