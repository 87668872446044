import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Row } from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/react';
import {
  getBoxSizeCheckerStats,
  getBoxSizeRecords,
} from '../../../services/financialReportingService/api';
import { formatNumberToCurrency } from '../../../../utilities';
import GenericCard from '../../../../components/bearCards/genericCard';
import { GENERIC_ERROR_MSG } from '../../../../constants';

interface LvbBoxSizeCheckerProps {
  dateRange: number[];
}

export default function LvbBoxSizeChecker({
  dateRange,
}: LvbBoxSizeCheckerProps) {
  const [boxSizeCheckerStats, setBoxSizeCheckerStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const startDate = dayjs.unix(dateRange[0]).toISOString();
  const endDate = dayjs.unix(dateRange[1]).toISOString();

  useEffect(() => {
    if (!dateRange) {
      return;
    }
    setIsLoading(true);
    getBoxSizeCheckerStats(startDate, endDate)
      .then((response) => {
        setBoxSizeCheckerStats(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  const exportSheet = () => {
    setIsLoadingExport(true);
    getBoxSizeRecords(startDate, endDate)
      .then((response) => {
        const data = response.data.map((record) => {
          return {
            'Bol bestelnummer': record.bol_order_id,
            'Bol besteldatum': record.bol_order_date,
            'Product EAN': record.product_ean,
            Land: record.country,
            'Werkelijke verzendkosten': record.actual_shipping_costs,
            'Werkelijke tariefgroep': record.actual_box_size,
            'Verwachtte verzendkosten': record.expected_shipping_costs,
            'Verwachtte tariefgroep': record.expected_box_size,
          };
        });

        const dateRangeString = `${dayjs(boxSizeCheckerStats.start_date).format(
          'DD-MM-YYYY',
        )} - ${dayjs(boxSizeCheckerStats.end_date).format('DD-MM-YYYY')}`;
        const filename = `Boloo Box Size Checker (${dateRangeString}).xlsx`;
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });

        saveAs(blob, filename);
      })
      .finally(() => {
        setIsLoadingExport(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
        notification.error({ message: GENERIC_ERROR_MSG });
      });
  };

  if (
    !boxSizeCheckerStats ||
    isLoading ||
    boxSizeCheckerStats.total_checks === 0
  ) {
    return null;
  }

  return (
    <GenericCard
      title={
        <>
          Box Size Checker{' '}
          <a
            href="https://help.boloo.co/nl/articles/8942899-lvb-box-size-checker"
            target="_blank"
            rel="noreferrer"
          >
            Lees meer
          </a>
        </>
      }
      className="bear-mar-b-16"
    >
      <Row>
        <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
          <p className="bear-light-regular-text bear-text-uc statistics-title">
            Te claimen bij LVB
          </p>
          <p className="bear-bold-title statistics-value bear-pad-b-8 bear-pad-t-8">
            {formatNumberToCurrency(boxSizeCheckerStats.incorrect_sum)}
          </p>
          <p className="bear-light-regular-text bear-pad-b-16">
            Over bestellingen tussen{' '}
            {dayjs(boxSizeCheckerStats.start_date).format('DD-MMM')} -{' '}
            {dayjs(boxSizeCheckerStats.end_date).format('DD-MMM')}
          </p>
          <Button
            className="prc-dashboard-stats-button bear-mar-b-16"
            loading={isLoadingExport}
            disabled={isLoadingExport}
            size="middle"
            type="primary"
            onClick={() => exportSheet()}
          >
            Download sheet
          </Button>
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <p>
            <strong>Checks uitgevoerd</strong>
          </p>
          <p>Totaal checks: {boxSizeCheckerStats.total_checks}</p>
          <p>Correct: {boxSizeCheckerStats.total_correct}</p>
          <p>Incorrect: {boxSizeCheckerStats.total_incorrect}</p>
          <p>Onbekend: {boxSizeCheckerStats.total_unknown}</p>
        </Col>
      </Row>
    </GenericCard>
  );
}
