import React from 'react';
import { Tooltip } from 'antd';

import { formatAmount, getStatisticSubValue } from '../../utilities';

const StoreStatisticSubValue = ({
  tooltip,
  valueKey,
  isLoading,
  statistics,
  selectedCountry,
  isAmount = true,
  isOppositeCorrect = false,
  showAlways = true,
  selectedStore = {},
}) => {
  const data = getStatisticSubValue(
    statistics,
    selectedCountry,
    valueKey,
    isLoading,
    selectedStore
  );
  const value = Math.abs(data.value || 0);
  const isNegative = data.value < 0;
  function getClassName() {
    if (isNegative) {
      return isOppositeCorrect ? 'bear-green-tc' : 'bear-red-tc';
    }
    if (data.value > 0) {
      return isOppositeCorrect ? 'bear-red-tc' : 'bear-green-tc';
    }
    return '';
  }
  if (showAlways || value) {
    if (tooltip) {
      return (
        <Tooltip
          title={tooltip}
          className={`bear-has-tooltip ${getClassName()}`}
        >
          <span className={getClassName()}>
            {isNegative ? '- ' : '+ '}
            {isAmount ? formatAmount(value, data.currency || '') : value}
          </span>
        </Tooltip>
      );
    }
    return (
      <span className={getClassName()}>
        {isNegative ? '- ' : '+ '}
        {isAmount ? formatAmount(value, data.currency || '') : value}
      </span>
    );
  }
  return '';
};

export default StoreStatisticSubValue;
