import axios from 'axios';
import { store } from './retailer/store';
import { API_ENDPOINT } from './endpoints';

const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 250000,
  headers: {
    // eslint-disable-next-line no-undef
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  // Fetch token from store
  const token = store.getState().tokensReducer.activeToken;

  if (token) {
    // Set token in Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
