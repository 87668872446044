/*eslint-disable*/
export const initialState = {
  data: [],
};

const SET_TRACKER_CATEGORIES = 'EVENT/SET_TRACKER_CATEGORIES';

export const setTrackerCategories = (data) => {
  return {
    type: SET_TRACKER_CATEGORIES,
    payload: data,
  };
};

export const trackerCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRACKER_CATEGORIES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default trackerCategoriesReducer;
