import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';

import { EURO } from '../../constants';

function InputNumberEuro({ min = 0.01, ...props }: InputNumberProps) {
  return (
    <InputNumber
      {...props}
      addonBefore={EURO}
      min={min}
      decimalSeparator=","
      formatter={(value) =>
        `${value}`.replace(/\./, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      parser={(x) =>
        parseFloat(
          `${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.'),
        ) as unknown as 0.01
      }
    />
  );
}

export default InputNumberEuro;
