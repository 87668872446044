import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Card, Row, Col, Button, message } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, When, Choose, Otherwise } from 'tsx-control-statements/components';

import { cancelOrder } from '../../../actions/store';
import InvoicingDropdown from '../invoicingDropdown';
import StoreDrawerOrderItems from './orderItems';
import StoreDrawerReturnItems from './returnItems';
import StoreDrawerCustomerCard from './customerCard';
import StoreDrawerSentEmails from './sentEmails';
import ShipmentList from '../shipmentsList';
import api from '../../../services/shipmentsService/api';
import './style.scss';

import captureErrorAndShowMessage from '../../../../utilities/errors';

const orderTitle = (order) => {
  if (Object.keys(order).length) {
    return (
      <div className="retailer-drawer-title">
        <p className="bear-heading-2-medium">{order.order_id}</p>
        <p className="bear-light-help-text">
          <span>{dayjs(order.order_date).format('MMM DD, YYYY HH:mm')}</span>
          <span> | </span>
          <span>{(order.customer && order.customer.country_code) || '-'}</span>
        </p>
      </div>
    );
  }
  return '';
};

const returnTitle = (returnItem) => {
  if (Object.keys(returnItem).length) {
    return (
      <div className="retailer-drawer-title">
        <p className="bear-heading-2-medium">{returnItem.return_id}</p>
        <p className="bear-light-help-text">
          <span>
            {dayjs(returnItem.return_date).format('MMM DD, YYYY HH:mm')}
          </span>
          <span> | </span>
          <span>
            {(returnItem.customer && returnItem.customer.country_code) || '-'}
          </span>
        </p>
      </div>
    );
  }
  return '';
};

function StoreOrderDetailDrawer({
  order,
  isVisible,
  doRefresh,
  selectedTab,
  isReturnItem,
  onItemChange,
  handleOnClose,
}) {
  const customer = order.customer || {};

  const [pressedKey, setPressedKey] = useState();
  const [keyPressed, setKeyPressed] = useState(false);
  const [cancellingOrder, setCancellingOrder] = useState(false);
  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    if (keyPressed) {
      onItemChange(pressedKey === 39);
      setKeyPressed(false);
    }
  }, [keyPressed]);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 37 || keyCode === 39) {
      setPressedKey(keyCode);
      setKeyPressed(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('keydown', handleUserKeyPress);
    } else {
      window.removeEventListener('keydown', handleUserKeyPress);
    }
  }, [isVisible]);

  function handleCancelOrder(orderId) {
    setCancellingOrder(true);
    cancelOrder(orderId).then(() => {
      setCancellingOrder(false);
      handleOnClose();
      if (doRefresh) {
        doRefresh();
      }
      message.success('De bestelling is verwijderd uit Boloo!');
    });
  }

  function loadShipments(orderId) {
    api
      .getOrderShipment(orderId)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then((response) => setShipments(response))
      .catch((e) => captureErrorAndShowMessage(e));
  }

  useEffect(() => {
    if (isVisible) {
      loadShipments(order.id);
    }
  }, [isVisible]);

  return (
    <Drawer
      closable
      width={550}
      placement="right"
      open={isVisible}
      rootClassName="retailer-drawer bear-drawer"
      onClose={() => handleOnClose()}
      title={isReturnItem ? returnTitle(order) : orderTitle(order)}
    >
      <Row className="drawer-actions">
        <Col>
          {selectedTab !== 'returns' && (
            <>
              <InvoicingDropdown
                order={isReturnItem ? order.order : order}
                doRefresh={doRefresh}
              />
              {isReturnItem && (
                <span className="bear-mar-l-8">
                  <InvoicingDropdown order={order} isCreditInvoice />
                </span>
              )}
            </>
          )}
        </Col>
        <Col className="bear-flex-je bear-item-changer">
          <div>
            <i
              role="presentation"
              className="fal icon-arrow-left"
              onClick={() => onItemChange()}
            />
            <i
              role="presentation"
              className="fal icon-arrow-right"
              onClick={() => onItemChange(true)}
            />
          </div>
        </Col>
      </Row>
      <StoreDrawerCustomerCard customer={customer || {}} />
      <If condition={!!Object.keys(order).length}>
        <Card
          title="Producten"
          className="retailer-items-card"
          bordered={false}
        >
          <Choose>
            <When condition={isReturnItem}>
              <StoreDrawerReturnItems
                items={order.items}
                fulfilmentMethod={order.fulfilment_display}
              />
            </When>
            <Otherwise>
              <StoreDrawerOrderItems
                tax={order.total_vat}
                items={order.order_items}
                subTotal={order.sub_total}
                revenue={order.total_revenue}
              />
            </Otherwise>
          </Choose>
        </Card>
      </If>
      <If condition={shipments?.length}>
        <Card title="Verzendnummers" className="bear-mar-t-16" bordered={false}>
          <ShipmentList shipments={shipments} />
        </Card>
      </If>
      <StoreDrawerSentEmails order={order} />
      <If condition={!!order.can_cancel}>
        <div className="bear-pad-16">
          <Button
            block
            size="large"
            danger
            loading={cancellingOrder}
            onClick={() => handleCancelOrder(order.id)}
          >
            Verwijder uit Boloo
          </Button>
        </div>
      </If>
    </Drawer>
  );
}

export default StoreOrderDetailDrawer;

StoreOrderDetailDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  doRefresh: PropTypes.func,
  onItemChange: PropTypes.func.isRequired,
  isReturnItem: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  order: PropTypes.objectOf(PropTypes.any).isRequired,
};

StoreOrderDetailDrawer.defaultProps = {
  doRefresh: null,
};
