import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Timeline, Button, Spin, Progress } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CSVBackendResponse } from '../../../actions/store';

interface UploadedContentProps {
  uploadedFileName: string;
  progressData: CSVBackendResponse;
  resetDraggerState: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  handleUploadClick;
  setUploadedFileName: (
    value: ((prevState: string) => string) | string,
  ) => void;
  percentage: number;
  setIsDraggerDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

function SmoothProgress({ targetPercentage }) {
  const [displayedPercentage, setDisplayedPercentage] = useState(0);

  useEffect(() => {
    const timeBetweenSteps = 50;
    const stepSize = 1;
    const interval = setInterval(() => {
      setDisplayedPercentage((prev) => {
        if (prev < targetPercentage) {
          return prev + stepSize;
        }
        clearInterval(interval);
        return prev;
      });
    }, timeBetweenSteps);

    return () => clearInterval(interval);
  }, [targetPercentage]);

  return <Progress percent={displayedPercentage} />;
}

function UploadedContent({
  uploadedFileName,
  progressData,
  resetDraggerState,
  handleUploadClick,
  setUploadedFileName,
  percentage,
  setIsDraggerDisabled,
}: UploadedContentProps): React.ReactElement {
  if (!uploadedFileName) {
    return null;
  }

  if (!progressData) {
    return (
      <>
        <p>Bestand is geupload!</p>
        <p>
          ({uploadedFileName}){' '}
          <Button
            type="link"
            icon={<CloseOutlined style={{ color: 'red' }} />}
            onClick={(e) => {
              e.stopPropagation();
              setUploadedFileName(null);
              setIsDraggerDisabled(false);
            }}
          />
        </p>
        <Button
          onClick={handleUploadClick}
          type="primary"
          style={{ marginTop: '10px' }}
        >
          Start met verwerken
        </Button>
      </>
    );
  }

  switch (progressData.status) {
    case 'completed':
      return (
        <Typography.Text style={{ textAlign: 'center', color: '#52c41a' }}>
          {progressData.message}
        </Typography.Text>
      );

    case 'completed-partially':
    case 'failed':
      return (
        <div>
          <Typography.Text
            style={{
              color: progressData.status === 'failed' ? '#ff4d4f' : '#faad14',
            }}
          >
            {progressData.message}
          </Typography.Text>
          <Row justify="center" style={{ width: '100%', paddingTop: '16px' }}>
            <Col xs={16} sm={16} md={12} lg={8}>
              <Timeline>
                {Object.entries(progressData.errors).map(
                  ([rowNumber, errorDetails], idx) => (
                    <Timeline.Item
                      key={idx}
                      color="red"
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <span>
                        Product {rowNumber}
                        {errorDetails.ean && ` (EAN: ${errorDetails.ean})`}:
                      </span>
                      {errorDetails.errors.map((error, errorIdx) => (
                        <span
                          key={errorIdx}
                          style={{
                            display: 'block',
                            marginLeft: '10px',
                            color: '#78909c',
                          }}
                        >
                          {error}
                        </span>
                      ))}
                    </Timeline.Item>
                  ),
                )}
              </Timeline>
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  resetDraggerState(false);
                }}
              >
                Opnieuw proberen
              </Button>
            </Col>
          </Row>
        </div>
      );

    default:
      return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col span={8}>
            <SmoothProgress targetPercentage={percentage} />
            <div style={{ textAlign: 'center', marginTop: '12px' }}>
              <span>
                <Spin size="small" style={{ marginRight: '12px' }} />
              </span>
              <span>{progressData.message}</span>
            </div>
          </Col>
        </Row>
      );
  }
}

export default UploadedContent;
