import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import BearEmptyState from '../emptyStateContainer';
import { PLANS_ROUTE } from '../../routes';

import './style.scss';

function UpgradeAccount() {
  const history = useHistory();

  return (
    <BearEmptyState
      className="upgrade-account"
      image="limited_access.png"
      title="Jouw account type heeft geen toegang tot deze functie"
    >
      <Button
        size="large"
        type="primary"
        className="bear-mar-t-12"
        onClick={() => history.push(PLANS_ROUTE)}
      >
        <span>Upgrade nu</span>
      </Button>
    </BearEmptyState>
  );
}

export default UpgradeAccount;
