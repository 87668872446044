import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';
import { BolSearchSortOptions } from '../../search';

import productsAxios from '../../axiosInstance';

import { CountedResponse } from '../../../types';
import type { ProductInterface } from '../types';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export const useProductsInfinite = (
  value: string | null,
  categoryIds: number[] = [],
  sort: BolSearchSortOptions = BolSearchSortOptions.Relevance,
) => {
  const getKey = (pageIndex: number, previousPageData) => {
    if (previousPageData && !previousPageData.results) return null;
    let path: string;
    if (value || categoryIds.length > 0 || sort) {
      path = `/search`;
      const queryParams = [];
      if (value) queryParams.push(`q=${value}`);
      if (categoryIds.length > 0)
        queryParams.push(`category_ids=${categoryIds.join(',')}`);
      queryParams.push(`page=${pageIndex + 1}`);
      if (sort) queryParams.push(`sort=${sort}`);
      path += `?${queryParams.join('&')}`;
    } else {
      path = `/products?page=${pageIndex + 1}`;
    }
    return path;
  };

  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite(
      getKey,
      (url) =>
        productsAxios
          .get(url)
          .then(
            (res) => res.data as CountedResponse<ProductInterface, 'results'>,
          ),
      {
        revalidateFirstPage: false,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError(e) {
          captureErrorAndShowMessage(e, null);
        },
      },
    );

  const result = useMemo(() => {
    if (!data) {
      return {
        count: 0,
        results: [],
      } as CountedResponse<ProductInterface>;
    }

    const allResults = data.flatMap((page) => page.results);
    const { count } = data[0];

    return {
      count,
      results: allResults,
    };
  }, [data]);

  return {
    results: result.results,
    count: result.count,
    isValidating,
    mutate,
    isLoading,
    size,
    setSize,
    isError: error,
  };
};
