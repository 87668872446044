import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import {
  DEFAULT_COUNTRY_FILTER,
  DEFAULT_STORE_OPTION,
} from '../../../storeManagement/dashboard/constants';

import BearDatePicker from '../../../../components/bearDatePicker';
import { getAccounts } from '../../../actions';
import useQueryString from '../../../hooks/useQueryString';

import StoreCountrySelector from '../../../storeManagement/components/countrySelector';
import StoreSelector from '../../../storeManagement/components/storeSelector';
import { MONTH_DAYS } from '../../../../constants';
import { Selection } from '../../types';

const getLastMonthRange = () => {
  return [
    dayjs().subtract(MONTH_DAYS, 'days').utc().startOf('day').unix(),
    dayjs().utc().unix(),
  ];
};

interface SelectionBarProps {
  selectionChanged: (value: Selection) => void;
}

function SelectionBar({ selectionChanged }: SelectionBarProps) {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accounts = useSelector((state) => state.accountsReducer.accounts);
  const [selectedCountry, setSelectedCountry] = useState(
    DEFAULT_COUNTRY_FILTER,
  );

  const [dateRange, setDateRange] = useQueryString(
    'interval',
    getLastMonthRange(),
  );

  const [stores, setStores] = useState([DEFAULT_STORE_OPTION]);
  const [selectedStore, setSelectedStore] = useState(DEFAULT_STORE_OPTION);

  useEffect(() => {
    dispatch(getAccounts);
  }, []);
  useEffect(() => {
    setStores([DEFAULT_STORE_OPTION].concat(accounts || []));
  }, [accounts]);

  useEffect(() => {
    let groupBy = 'day';
    const startDate = dayjs.unix(dateRange[0]);
    const endDate = dayjs.unix(dateRange[1]);
    if (endDate.diff(startDate, 'month') >= 3) {
      groupBy = 'month';
    }

    selectionChanged({
      country: selectedCountry,
      start_date: dateRange[0],
      end_date: dateRange[1],
      group_by: groupBy,
      store: selectedStore,
    });
  }, [selectedCountry, dateRange, selectedStore]);

  return (
    <Row
      gutter={{
        xxl: 24,
        xl: 24,
        lg: 24,
        md: 12,
        sm: 12,
        xs: 12,
      }}
    >
      <Col xs={24} lg={8} className="bear-col-fa">
        <BearDatePicker onApply={(value) => setDateRange(value)} />
      </Col>
      <Col xs={24} lg={8} className="bear-col-fa">
        <StoreCountrySelector
          selectedValue={selectedCountry}
          onChange={(value) => setSelectedCountry(value)}
        />
      </Col>
      <Col xs={24} lg={8} className="bear-col-fa">
        <StoreSelector
          selectedValue={selectedStore}
          options={stores}
          onChange={(value) => setSelectedStore(value)}
        />
      </Col>
    </Row>
  );
}

export default SelectionBar;
