import React, { ReactNode } from 'react';
import { Button, Dropdown } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

interface ImportExportMenuProps {
  setShowDragger: (value: ((prevState: boolean) => boolean) | boolean) => void;
  exportButtonClick: (e: React.MouseEvent) => void;
}

function ImportExportMenu({
  setShowDragger,
  exportButtonClick,
}: ImportExportMenuProps) {
  const handleImportClick = () => {
    setShowDragger((prevState) => !prevState);
  };

  interface MenuItem {
    key: string;
    label: ReactNode;
  }

  const menuItems: MenuItem[] = [
    {
      key: '1',
      label: (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          style={{ color: 'grey' }}
          onClick={handleImportClick}
        >
          Importeer kostprijzen
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button
          type="link"
          icon={<UploadOutlined />}
          style={{ color: 'grey' }}
          onClick={exportButtonClick}
        >
          Exporteer producten
        </Button>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }}>
      <i className="fad icon-ellipsis-v bear-table-action" />
    </Dropdown>
  );
}

export default ImportExportMenu;
