import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';
import type { StockFilter } from '../../stock';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import type {
  OfferProductInterface,
  OffersProductListResponseInterface,
} from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useOffersProductListInfinate(
  should_ignore_hidden?: boolean,
  search?: string,
  fulfilment_method?: string,
  ordering?: string,
  stockFilter?: StockFilter,
  pageSize: number = DEFAULT_PAGE_SIZE,
): {
  isLoading: boolean;
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<OffersProductListResponseInterface[] | undefined>;
  isError: Error;
  size: number;
  offersProductList: OfferProductInterface[] | null;
  hasMore: boolean;
  mutate: () => void;
} {
  const { data, size, setSize, isValidating, isLoading, error, mutate } =
    useSWRInfinite(
      (index) =>
        `/offers?page_size=${pageSize}&page=${index + 1}${should_ignore_hidden !== undefined ? `&should_ignore_hidden=${should_ignore_hidden}` : ''}${search ? `&search=${search}` : ''}${fulfilment_method ? `&fulfilment_method=${fulfilment_method}` : ''}${ordering ? `&ordering=${ordering}` : ''}${stockFilter ? `&stock=${stockFilter}` : ''}`,
      (url) =>
        productsAxios
          .get(url)
          .then((res) => res.data as OffersProductListResponseInterface),
      {
        revalidateFirstPage: false,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError(e) {
          captureErrorAndShowMessage(e, null);
        },
      },
    );

  const retailerProductList = useMemo(() => {
    if (!data) {
      return [];
    }
    return [].concat(...data.map((page) => page.results));
  }, [data]);

  return {
    offersProductList: retailerProductList,
    isLoading: isLoading || isValidating,
    size,
    setSize,
    hasMore: data?.[data.length - 1]?.has_more || false,
    isError: error,
    mutate,
  };
}
