import useSWR from 'swr';
import type { ProductInterface } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useBolProduct(ean?: string | null): {
  product: ProductInterface;
  isLoading: boolean;
  error: Error;
} {
  let refreshRetryAttemptsLeft = 15;
  const refreshIntervalInMs = 5000;
  const { data, error, isLoading } = useSWR(
    ean ? `/products/ean/${ean}` : null,
    (url) => productsAxios.get(url).then((res) => res.data),
    {
      refreshInterval: (product: ProductInterface | undefined) => {
        if (product && product.is_updating) {
          refreshRetryAttemptsLeft -= 1;
          return refreshRetryAttemptsLeft > 0 ? refreshIntervalInMs : 0;
        }
        return 0;
      },
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e, null);
      },
    },
  );

  return {
    product: data,
    isLoading,
    error,
  };
}
