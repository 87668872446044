import React from 'react';
import { Spin } from 'antd';

import './style.scss';

function BearPageLoader() {
  return <Spin className="bear-page-loader" />;
}

export default BearPageLoader;
