import instance from '../../../axios';
import { Selection } from '../../financialReportingService/types';

let baseURL = 'https://financial-reporting.boloo.co';

if (process.env.TARGET_ENV === 'development') {
  baseURL = 'http://localhost:8005';
}

if (process.env.TARGET_ENV === 'stage') {
  baseURL = 'https://financial-reporting.staging.boloo.co';
}

function selectionToQueryString(selection: Selection) {
  return `country=${selection.country}&start_date=${selection.start_date}&end_date=${selection.end_date}&retailer_id=${selection.store.id}`;
}

export function getRevenue(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/revenue?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getCosts(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/costs?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getProfit(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/profit?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getOrderQuantity(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/orders/quantity?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getProductsQuantity(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/products/quantity?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getReturnQuantity(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/returns/quantity?${selectionToQueryString(
      selection,
    )}&group_by=${group_by}`,
  );
}

export function getVat(selection: Selection, group_by: string) {
  return instance.get(
    `${baseURL}/vat?${selectionToQueryString(selection)}&group_by=${group_by}`,
  );
}

export function getProductStats(selection: Selection, sortBy) {
  return instance.get(
    `${baseURL}/products/stats?${selectionToQueryString(
      selection,
    )}&sort_by=${sortBy}`,
  );
}

export function getBoxSizeRecords(startDate, endDate) {
  return instance.get(
    `${baseURL}/box_size_records?start_date=${startDate}&end_date=${endDate}`,
  );
}

export function getBoxSizeCheckerStats(startDate, endDate) {
  return instance.get(
    `${baseURL}/box_size_records/stats?start_date=${startDate}&end_date=${endDate}`,
  );
}
export function redoBoxSizeChecks(ean) {
  return instance.post(`${baseURL}/box_size_records/redo/${ean}`);
}

export function getCostsAndCorrections(startDate: number, endDate: number) {
  return instance.get(
    `${baseURL}/costs-and-corrections?start_date=${startDate}&end_date=${endDate}`,
  );
}

export function getInvoices() {
  return instance.get(`${baseURL}/invoices`);
}

export function syncInvoice(invoice_id) {
  return instance.post(`${baseURL}/invoices/sync/${invoice_id}`);
}
