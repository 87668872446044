import useSWRMutation from 'swr/mutation';
import productsAxios from '../../axiosInstance';

export function useOfferCostPricesDelete(
  offerId: string,
): (costPriceId: number) => Promise<{ detail: string }> {
  const { trigger } = useSWRMutation(
    offerId ? `/offers/${offerId}/cost_prices` : null,
    (url: string, { arg }: { arg: number }) =>
      productsAxios.delete(`${url}/${arg}`).then((response) => response.data),
  );
  return trigger;
}
