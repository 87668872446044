import React, { useState, useEffect } from 'react';

import { Spin, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { formatAmount } from '../../utilities';
import { getInventoryProductDetailRoute } from '../../../routes';
import {
  ProductData,
  getInventoryProductVisitors,
} from '../../../actions/store';

import ProductCard from '../../../components/productCard';
import BearInfiniteList from '../../../../components/bearList';
import { UserAccountInterface, getTokens } from '../../../actions';
import { switchAccount } from '../../../utilities/app';

import './style.scss';
import { toFixedValue } from '../../../../utilities';
import { DEFAULT_STORE_OPTION } from '../../dashboard/constants';

import { useUserProfile } from '../../../services/userService/api';
import { Retailer } from '../../../../reducers/profileReducer';
import { getOfferProductByEan } from '../../../services/productsService/api';

function ProfitCell({ profit, percentage }) {
  const className =
    percentage > 0 ? 'bear-green-tc' : percentage < 0 ? 'bear-red-tc' : '';
  return (
    <div className={className}>
      <p className="bear-medium-text">{formatAmount(profit)}</p>
      <span>({formatAmount(percentage, '')}%)</span>
    </div>
  );
}

export function StoreTopInventoryProducts({
  products,
  isLoading,
  dateRange,
  hasMore = false,
  handleLoadMore,
  selectedCountry,
  selectedStore = null,
  allStores = [],
}: {
  products: ProductData[];
  isLoading: boolean;
  dateRange: [number, number];
  handleLoadMore?: () => void;
  hasMore?: boolean;
  selectedCountry: string;
  selectedStore?: UserAccountInterface;
  allStores?: UserAccountInterface[];
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: profileData } = useUserProfile();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accounts = useSelector((state) => state.accountsReducer.accounts);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accountTokens = useSelector((state) => state.tokensReducer.tokens);

  const [productsVisitors, setProductsVisitors] = useState({});
  const [stores, setStores] = useState([DEFAULT_STORE_OPTION]);

  useEffect(() => {
    dispatch(getTokens());
  }, []);

  useEffect(() => {
    if (!accounts) {
      return;
    }
    setStores([DEFAULT_STORE_OPTION].concat(accounts));
  }, [accounts]);

  function getCountryBasedMetric(metric, isAmount = true) {
    let value = 0;
    if (!isLoading) {
      if (selectedCountry) {
        const geoValue = metric.geographical[selectedCountry];
        value = geoValue ? geoValue.value : 0;
      } else {
        value = metric.total;
      }
    }
    return isAmount ? formatAmount(value) : value;
  }

  function renderVisitorCell(product) {
    const data = productsVisitors[product.product_id];
    if (data) {
      if (Object.keys(data).length) {
        const geo = data.geographical || {};
        const value = selectedCountry
          ? (geo[selectedCountry] && geo[selectedCountry].value) || 0
          : data.total || 0;
        return <p className="bear-medium-text">{value}</p>;
      }
      return '-';
    }
    return <Spin size="small" />;
  }

  function renderConverstionRateCell(product) {
    let percentageValue = 0;
    const data = productsVisitors[product.product_id];
    if (data) {
      if (Object.keys(data).length) {
        const visitors = getCountryBasedMetric(data, false);
        const { sales } = product;
        if (visitors && sales) {
          percentageValue = (sales / Number(visitors)) * 100;
        }
        return (
          <Tooltip
            title={`CR is het conversieratio van je product. 
      Dit geeft aan hoeveel procent van de bezoekers 
      van je productpagina (listing) overgaan tot een bestelling. 
      Wanneer je 100 bezoekers hebt en vijf bestellingen, dan is je CR 5%. 
      Hoe hoger je CR, hoe meer mensen gemiddeld overgaan tot het maken van een bestelling. 
      Hoe hoger, hoe beter! `}
          >
            <p className="bear-medium-text">{`${formatAmount(
              percentageValue,
              '',
            )}%`}</p>
          </Tooltip>
        );
      }
      return '-';
    }
    return <Spin size="small" />;
  }

  const inventoryListItems = [
    {
      title: 'Product',
      key: 'product',
      size: 7,
      mobile: {
        span: 22,
        order: 1,
      },
      render: (product) => <ProductCard ean={product.ean} shouldFetch />,
    },
    {
      title: 'Verkopen',
      key: 'sales',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (product) => <p className="bear-medium-text">{product.sales}</p>,
    },
    {
      title: 'Omzet',
      key: 'revenue',
      size: 3,
      mobile: {
        span: 8,
        order: 4,
      },
      render: (product) => (
        <p className="bear-medium-text">{formatAmount(product.revenue)}</p>
      ),
    },
    {
      title: (
        <Tooltip
          title={`De winstmarge van het product: Hoeveel procent je 
        overhoudt van de verkoopprijs na alle kosten voor het product, zoals inkoop en transport.`}
          className="bear-has-tooltip"
        >
          Winst
        </Tooltip>
      ),
      key: 'profit',
      size: 3,
      render(product) {
        const profit = toFixedValue(product.profit, 2, false);
        const rev = toFixedValue(product.revenue, 2, false);
        return (
          <ProfitCell
            profit={product.profit}
            percentage={(profit / rev) * 100}
          />
        );
      },
    },
    {
      title: 'Bezoekers',
      key: 'visitors',
      size: 3,
      mobile: {
        span: 8,
        order: 6,
      },
      render: (product) => renderVisitorCell(product),
    },
    {
      title: 'CR',
      key: 'cr',
      size: 3,
      mobile: {
        span: 8,
        order: 7,
      },
      render: (product) => renderConverstionRateCell(product),
    },
    {
      title: 'Store',
      key: 'store_icon',
      size: 2,
      mobile: {
        span: 6,
        order: 2,
      },
      render: (product) => <p>{product.store_icon}</p>,
    },
  ];

  useEffect(() => {
    if (products.length) {
      products.forEach((product) => {
        getInventoryProductVisitors(
          String(product.product_id),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...dateRange,
          selectedStore,
        )
          .then((response) => {
            productsVisitors[product.product_id] = response.data;
            setProductsVisitors({ ...productsVisitors });
          })
          .catch(() => {
            productsVisitors[product.product_id] = {};
            setProductsVisitors({ ...productsVisitors });
          });
      });
    }
  }, [products, dateRange]);

  async function handleItemClick(productId) {
    const clickedProduct = products.find(
      (product) => product.product_id === productId,
    );
    const clickedShopID = clickedProduct?.store_id;
    const currentShopID = profileData.id;

    const isProductOwner =
      clickedShopID !== undefined && clickedShopID === currentShopID;

    const offerProduct = await getOfferProductByEan(clickedProduct.ean);

    if (!isProductOwner) {
      const storesArray = allStores.length ? allStores : stores;

      const necessaryStore = storesArray.find(
        (store) => store.id === clickedShopID,
      );

      switchAccount(
        necessaryStore,
        accountTokens,
        `/store/inventory/${String(offerProduct?.id)}`,
      );
    } else {
      history.push(getInventoryProductDetailRoute(String(offerProduct?.id)));
    }
  }

  return (
    <BearInfiniteList
      useWindow
      data={products}
      hasMore={hasMore}
      isLoading={isLoading}
      items={inventoryListItems}
      className="store-top-products"
      handeLoadMore={() => handleLoadMore()}
      handleItemClick={async (item) => handleItemClick(item.product_id)}
    />
  );
}

export default StoreTopInventoryProducts;
