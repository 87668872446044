import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { Spin } from 'antd';
import * as routes from '../routes';
import { LoggedInRoute } from './utilities';
import { TrackerCategoriesProvider } from '../context/trackerCategories';
import {
  trackerCategoriesReducer,
  initialState as trackerCategoriesInitialState,
} from '../reducers/trackerCategoriesReducer';

import { UserProfileInterface } from '../../reducers/profileReducer';
import { PlanFeatures } from '../services/subscriptionService/api';

const ProductTrackerV2 = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../researchManagement/productTrackerV2'
    ),
);
// TODO: This import includes cloned code for future migration to ProductTrackerV2.
//  The productTrackerDetail component should be imported from the productTrackerV2 folder once the migration is complete.
const ProductTrackerV2Detail = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../researchManagement/productTracker/productTrackerDetail'
    ),
);

const ProductDatabaseV2 = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../researchManagement/productDatabaseV2'
    ),
);

const ProfitCalculator = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../researchManagement/profitCalculator'
    ),
);

const ProfitCalculatorCreateOrEdit = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../researchManagement/profitCalculator/createOrEdit'
    ),
);

const PRCProductsOverview = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../productRankingChecker/pages/productsOverview'
    ),
);

const PRCProductDetail = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../productRankingChecker/pages/productDetail'
    ),
);

const KeywordExplorer = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../researchManagement/keywordExplorer'),
);

const UpgradeAccount = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../components/upgradeAccountEmptystate'
    ),
);

function ResearchRoute({
  profileData,
  subscriptionPlanFeatures,
}: {
  profileData: UserProfileInterface;
  subscriptionPlanFeatures: PlanFeatures[];
}) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <>
      <TrackerCategoriesProvider
        initialState={trackerCategoriesInitialState}
        reducer={trackerCategoriesReducer}
      >
        <Switch>
          <Suspense fallback={<Spin className="app-spinner" />}>
            <LoggedInRoute
              exact
              path={routes.RESEARCH_TRACKER_ROUTE_V2}
              component={ProductTrackerV2}
              isAuthenticated={isAuthenticated}
            />
            <LoggedInRoute
              exact
              path={routes.RESEARCH_TRACKER_ROUTE_DETAIL_V2}
              component={ProductTrackerV2Detail}
              isAuthenticated={isAuthenticated}
            />
            <LoggedInRoute
              exact
              path={routes.RESEARCH_DATABASE_ROUTE_V2}
              component={ProductDatabaseV2}
              isAuthenticated={isAuthenticated}
            />
          </Suspense>
        </Switch>
      </TrackerCategoriesProvider>
      <Switch>
        <Suspense fallback={<Spin className="app-spinner" />}>
          <LoggedInRoute
            exact
            path={routes.RESEARCH_EXPLORER_ROUTE}
            component={
              subscriptionPlanFeatures?.includes(
                PlanFeatures.IsKeywordExplorerEnabled,
              )
                ? KeywordExplorer
                : UpgradeAccount
            }
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={routes.RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE}
            component={PRCProductsOverview}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={routes.RESEARCH_PRODUCT_RANKING_CHECKER_PRODUCT_ROUTE}
            component={PRCProductDetail}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            component={ProfitCalculator}
            isAuthenticated={isAuthenticated}
            path={routes.RESEARCH_CALCULATOR_ROUTE}
          />
          <LoggedInRoute
            exact
            isAuthenticated={isAuthenticated}
            component={ProfitCalculatorCreateOrEdit}
            path={routes.RESEARCH_CALCULATION_CREATE_ROUTE}
          />
          <LoggedInRoute
            exact
            isAuthenticated={isAuthenticated}
            component={ProfitCalculatorCreateOrEdit}
            path={routes.RESEARCH_CALCULATION_UPDATE_ROUTE}
          />
        </Suspense>
      </Switch>
    </>
  );
}

export default ResearchRoute;
