import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { MONTH_DAYS } from '../constants';

export function getLastMonthRange(): [number, number] {
  return [
    dayjs().subtract(MONTH_DAYS, 'days').utc().startOf('day').unix(),
    dayjs().utc().unix(),
  ];
}

// TODO unused so remove
// export const getGraphUnit = (startTimestamp, endTimeStamp) => {
//  const startDate = dayjs.unix(startTimestamp);
//  const endDate = dayjs.unix(endTimeStamp);
//  const diff = endDate.diff(startDate, 'day');
//  return diff <= 30 ? 'day' : 'month';
// };

// TODO unused so remove
// export const formatUnixTimestamp = (period) => {
//  return dayjs.unix(period).format();
// };

export const formatDateWithoutDay = (period: string): string => {
  return dayjs(period).format('MMM D, YYYY');
};

// TODO unused so remove
// export const formatDateWithSlash = (period) => {
//  return dayjs(period).format('DD/MM/YYYY');
// };

export const formatDateToTime = (period) => {
  return dayjs(period).format('h:mm A');
};

export const formatUTCtoLocalTimezone = (period) => {
  return dayjs.utc(`${period}Z`).local().format('h:mm A');
};

// TODO unused so remove
// export const getDaysBetweenInterval = (startTimestamp, endTimeStamp) => {
//  const startDate = dayjs.unix(startTimestamp);
//  const endDate = dayjs.unix(endTimeStamp);
//  return endDate.diff(startDate, 'day');
// };
