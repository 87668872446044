import React from 'react';
import { Select } from 'antd';

function StoreSelector({
  selectedValue,
  onChange,
  options,
  sourceComponent = null,
}) {
  function getLabel(item) {
    if (sourceComponent === 'campaignMenu') {
      if (item.icon) {
        return `${item.icon} ${item.nickname}`;
      }

      return `${item.nickname}`;
    }

    return `Store: ${item.nickname}`;
  }

  return (
    <Select
      size="large"
      value={selectedValue.id}
      optionLabelProp="label"
      className="bear-min-input-width"
      placeholder={
        sourceComponent === 'campaignMenu'
          ? 'Kies account om te dupliceren'
          : ''
      }
      onChange={(value) => {
        onChange(options?.find((option) => option.id === value));
      }}
    >
      {options?.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id} label={getLabel(item)}>
            <div className="bear-flex bear-flex-ac">
              <If condition={item.icon}>
                <span
                  role="img"
                  aria-label={item.nickname}
                  className="bear-mar-r-4"
                >
                  {item.icon}
                </span>
              </If>
              {item.nickname}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default StoreSelector;
