import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Dropdown, Menu } from 'antd';
import { useHistory, withRouter } from 'react-router-dom';

import { INDEX_ROUTE, PLANS_ROUTE } from '../../routes';
import { clearSessionData, sendTokenToChromeExtension } from '../../../actions';
import ProfileHook from '../../../hooks/profile';
import { getAccounts, getTokens } from '../../actions';
import AddSubAccountModal from '../../userSettingsManagement/accountSettings/subaccounts/modal';
import { getMenuItem, switchAccount } from '../../utilities/app';
import './style.scss';
import { UserProfileInterface } from '../../../reducers/profileReducer';

function AppHeaderDrowdown({
  profileData,
}: {
  profileData: UserProfileInterface;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accounts = useSelector((state) => state.accountsReducer.accounts);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accountTokens = useSelector((state) => state.tokensReducer.tokens);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Stop fetching accounts and stuff if the registration is not yet complete
    if (!profileData.is_registration_complete) return;

    if (!accountsLoading) {
      setAccountsLoading(true);

      dispatch(getAccounts());
      dispatch(getTokens());
    }
  }, []);

  useEffect(() => {
    setAccountsLoading(false);
  }, [accounts]);

  useEffect(() => {
    // Stop fetching accounts and stuff if the registration is not yet complete
    if (!profileData.is_registration_complete) return;

    dispatch(getAccounts());
    dispatch(getTokens());
  }, [isModalVisible]);

  function handleLogout() {
    clearSessionData();
    sendTokenToChromeExtension(null);
    // Do a hard redirect, so we can restart stuff like Intercom properly
    // Also wait for 500ms, since the store needs some time to handle all the
    setTimeout(() => {
      document.location = INDEX_ROUTE;
    }, 500);
  }

  function getAvatar() {
    let avatar = profileData.first_name && profileData.first_name.charAt(0);

    if (profileData.icon) {
      avatar = profileData.icon;
    }

    return <Avatar>{avatar}</Avatar>;
  }

  function getNickname() {
    const nickname = profileData.nickname
      ? profileData.nickname
      : profileData.full_name;

    return (
      <div className="bear-light-regular-text" style={{ marginLeft: '1em' }}>
        {nickname}
      </div>
    );
  }

  function getDropdownHeader() {
    return (
      <div className="header-dropdown-trigger">
        {getAvatar()}
        {getNickname()}
        <i className="fas icon-angle-down" />
      </div>
    );
  }

  const menuItems = [
    // Accounts
    ...(accounts?.map((account) =>
      getMenuItem(
        <div
          onClick={() => {
            switchAccount(
              account,
              accountTokens,
              history.location.pathname === PLANS_ROUTE ? INDEX_ROUTE : null,
            );
          }}
        >
          {account.icon}{' '}
          {account.nickname ? account.nickname : `Account #${account.id}`}
        </div>,
        `subaccount-${account.id}`,
      ),
    ) || []),
    // Extra store
    ...(profileData?.is_parent
      ? [
          getMenuItem(
            <div
              data-testid="add-account-button"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Extra store toevoegen
            </div>,
            'add-account',
          ),
        ]
      : []),
    // Logout
    ...[
      getMenuItem(
        <div data-testid="logout-button" onClick={() => handleLogout()}>
          Uitloggen
        </div>,
        'logout',
      ),
    ],
  ];

  return (
    <>
      <Dropdown
        trigger={['click']}
        /* eslint-disable-next-line react/no-unstable-nested-components */
        dropdownRender={() => <Menu items={menuItems} />}
      >
        {getDropdownHeader()}
      </Dropdown>
      <AddSubAccountModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
}

export default withRouter(ProfileHook(AppHeaderDrowdown));
