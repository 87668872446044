import { Row, Card, Col, Form, Button, Spin, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { saveAs } from 'file-saver';
import {
  getOrderInvoiceSettings,
  saveOrderInvoiceSettings,
  sendInvoicingTestEmail,
} from '../actions/store/index';

import InvoiceTemplateSettings from './templateSettings';
import OrderInvoiceTemplate from '../components/orderInvoiceTemplate';
import { SETTING_STORE_ROUTE } from '../routes';
import StoreDataMissingWarning from '../userSettingsManagement/storeSettings/storeWarning';
import ProfileHook from '../../hooks/profile';

import './style.scss';
import { UserProfileInterface } from '../../reducers/profileReducer';

function InvoiceManagement({
  profileData,
}: {
  profileData: UserProfileInterface;
}) {
  const history = useHistory();
  const [form] = Form.useForm();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [settingsData, setSettingsData] = useState({
    id: null,
    logo: null,
    logo_url: null,
  });

  useEffect(() => {
    getOrderInvoiceSettings().then((response) => {
      const { data } = response;
      setSettingsData(data);
      form.setFieldsValue(data);
      setLoading(false);
    });
  }, []);

  function handleSettingsUpdate(data) {
    setSaving(false);
    setSettingsData(data);
    form.setFieldsValue(data);
    message.success('Instellingen succesvol opgeslagen');
  }

  function handleLogoChange(data) {
    settingsData.logo = data.url;
    settingsData.logo_url = data.relative_url;
    setSettingsData({ ...settingsData });
    form.setFieldsValue(settingsData);
  }

  function handleLogoRemove() {
    settingsData.logo = null;
    settingsData.logo_url = null;
    setSettingsData({ ...settingsData });
    form.setFieldsValue(settingsData);
  }

  function handleOnFormSubmit(values) {
    setSaving(true);
    saveOrderInvoiceSettings(values, settingsData.id)
      .then((response) => {
        handleSettingsUpdate(response.data);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.error('Something went wrong');
        }
        setSaving(false);
      });
  }

  function sendTestEmail(isDownload, values?) {
    if (isDownload) {
      message.loading('De factuur wordt gedownload');
    }
    sendInvoicingTestEmail({
      ...values,
      is_downalod: isDownload,
      ...settingsData,
    })
      .then((response) => {
        if (isDownload) {
          saveAs(response.data.URL, 'Invoice');
          message.success({
            content: 'Factuur wordt gedownload',
            duration: 2,
          });
        } else {
          message.success('Test-e-mail verzonden');
        }
      })
      .catch(() => {
        message.error(
          'Het verzenden/downloaden van een test e-mail is mislukt. Probeer het a.u.b. opnieuw',
        );
      });
  }

  if (loading) {
    return (
      <div className="invoice-loader">
        <Spin />
      </div>
    );
  }

  if (!profileData.is_store_data_complete) {
    return (
      <Row>
        <Col span={14} style={{ margin: 'auto', textAlign: 'center' }}>
          <Card>
            <StoreDataMissingWarning />
            <img src="/static/images/limited_access.png" width="50%" />
            <div>
              <Button onClick={() => history.push(SETTING_STORE_ROUTE)}>
                Gegevens invullen
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div className="invoice-management">
      <Row gutter={16}>
        <Col
          lg={10}
          md={24}
          sm={24}
          xs={24}
          className="invoice-template-panels"
        >
          <InvoiceTemplateSettings
            form={form}
            initalValues={settingsData}
            onLogoRemove={() => handleLogoRemove()}
            onLogoChange={(data) => handleLogoChange(data)}
            onFormSubmit={(values) => handleOnFormSubmit(values)}
            onFormValuesChange={(values) => setSettingsData({ ...values })}
          />
        </Col>
        <Col lg={14} md={24} sm={24} xs={24}>
          <OrderInvoiceTemplate isDummy templateData={settingsData} />
          <div className="bear-text-r">
            <p
              className="bear-link bear-mar-r-16"
              data-testid="download-test-invoice"
              onClick={() => sendTestEmail(true)}
            >
              Downloaden
            </p>
            <Button
              size="large"
              type="primary"
              loading={saving}
              className="bear-mar-t-16"
              data-testid="submit-invoice"
              onClick={() => form.submit()}
            >
              Opslaan
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ProfileHook(InvoiceManagement);
