import useSWRMutation from 'swr/mutation';
import type { StockInterface } from '../types';
import productsAxios from '../../axiosInstance';

export function useProductStockEdit(
  offerId: string,
): (stocks: Partial<StockInterface>) => Promise<StockInterface> {
  const { trigger } = useSWRMutation(
    offerId ? `/offers/${offerId}/stocks/` : null,
    (url: string, { arg }: { arg: StockInterface }) => {
      // eslint-disable-next-line
      return arg.stock === 0
        ? productsAxios
            .delete(`${url}${arg.id}/`)
            .then((response) => response.data as StockInterface)
        : arg.id
          ? productsAxios
              .put(`${url}${arg.id}/`, JSON.stringify(arg))
              .then((response) => response.data as StockInterface)
          : productsAxios
              .post(`${url}`, JSON.stringify(arg))
              .then((response) => response.data as StockInterface);
    },
  );
  return trigger;
}
