import { formatNumberToCurrency, abbreviateAmount } from '../../utilities';

const LAYOUT_OPTIONS = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 0,
      right: 0,
      left: -25,
      bottom: -20,
    },
  },
};

function toolTipOptions(datasetArray, isAmount, currencySymbol?) {
  return {
    mode: 'label',
    xPadding: 12,
    yPadding: 12,
    fontSize: 12,
    borderWidth: 1,
    bodySpacing: 6,
    intersect: false,
    pointDotRadius: 1,
    titleMarginBottom: 12,
    pointDotStrokeWidth: 8,
    borderColor: '#f0f0f0',
    titleFontColor: '#78909C',
    bodyFontColor: '#78909C',
    backgroundColor: '#FFFFFF',
    pointHitDetectionRadius: 20,
    callbacks: {
      label(tooltipItems) {
        return `${datasetArray[tooltipItems.datasetIndex].label}
        ${
          isAmount
            ? formatNumberToCurrency(tooltipItems.yLabel, currencySymbol)
            : tooltipItems.yLabel
        }`;
      },
    },
  };
}

function scaleOptions(
  maxXTicks,
  unit,
  isAmount,
  currencySymbol?,
  abbreviateYaxisLabels?,
  reversYAxis = false,
) {
  return {
    stacked: true,
    xAxes: [
      {
        type: unit ? 'time' : 'category',
        ticks: {
          maxRotation: 0,
          maxTicksLimit: maxXTicks,
          fontColor: '#78909C',
        },
        gridLines: {
          color: '#ECEFF1',
          borderDash: [10, 5],
        },
        scaleLabel: {
          fontSize: 12,
          display: true,
          fontColor: '#78909C',
        },
        time: {
          round: unit,
          unit,
          displayFormats: {
            hour: 'hA',
            day: 'D MMM',
            week: 'D MMM',
            month: 'MMM',
            quarter: 'MMM',
            year: 'D MMM',
          },
          parser: 'YYYY-MM-DD HH:mm',
          tooltipFormat:
            unit === 'hour' ? 'hA' : unit === 'day' ? 'D MMM' : 'MMM',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: '#ECEFF1',
          showBorder: false,
          drawBorder: false,
          borderDash: [8, 4],
        },
        scaleLabel: {
          fontSize: 12,
          display: true,
          fontColor: '#78909C',
        },
        ticks: {
          padding: 0,
          maxTicksLimit: 6,
          beginAtZero: true,
          reverse: reversYAxis,
          userCallback: (label) => {
            if (Math.floor(label) === label) {
              if (isAmount && abbreviateYaxisLabels) {
                return abbreviateAmount(label);
              }
              return `${
                isAmount ? formatNumberToCurrency(label, currencySymbol) : label
              }`;
            }
            return '';
          },
          fontColor: '#78909C',
        },
      },
    ],
  };
}

export function lineChartOptions(
  unit,
  datasetArray,
  maxXTicks = 5,
  showLegends = true,
  isAmount = true,
  reverse = false,
) {
  const currencySymbol = '€';
  return {
    ...LAYOUT_OPTIONS,
    plugins: {
      filler: {
        propagate: true,
      },
    },
    tooltips: toolTipOptions(datasetArray, isAmount, currencySymbol),
    legend: {
      align: 'end',
      display: showLegends,
      position: 'top',
      labels: {
        fontSize: 12,
        fontColor: '#78909C',
      },
    },
    scales: scaleOptions(
      maxXTicks,
      unit,
      isAmount,
      currencySymbol,
      false,
      reverse,
    ),
  };
}

export function barChartOptions(
  unit,
  datasetArray,
  isAmount = false,
  abbreviateYaxisLabels = false,
  isStacked = false,
  maxXTicks = 5,
) {
  return {
    ...LAYOUT_OPTIONS,
    propagate: false,
    legend: {
      display: false,
    },
    tooltips: toolTipOptions(datasetArray, isAmount),
    scales: {
      xAxes: [
        {
          ...{
            offset: true,
            stacked: isStacked,
          },
          ...scaleOptions(maxXTicks, unit, isAmount).xAxes[0],
        },
      ],
      yAxes: [
        {
          ...{
            stacked: isStacked,
          },
          ...scaleOptions(maxXTicks, unit, isAmount, '€', abbreviateYaxisLabels)
            .yAxes[0],
        },
      ],
    },
  };
}

export function hortizontalBarChartOptions(datasetArray, maxXTicks = 6) {
  return {
    maintainAspectRatio: false,
    propagate: false,
    legend: {
      display: false,
    },
    tooltips: {
      ...toolTipOptions(datasetArray, false),
      ...{
        callbacks: {
          title: () => null,
          label(tooltipItems) {
            return `${tooltipItems.yLabel}
            ${tooltipItems.xLabel}`;
          },
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: '#ECEFF1',
            zeroLineColor: '#ECEFF1',
            borderDash: [10, 5],
            zeroLineBorderDash: [10, 5],
            showBorder: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#78909C',
            maxTicksLimit: maxXTicks,
            suggestedMin: 0,
            stepSize: 10,
            suggestedMax: 50,
          },
        },
      ],
    },
  };
}
