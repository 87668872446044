import React from 'react';
import { Switch } from 'react-router-dom';

import * as routes from '../routes';

import { LoggedInRoute } from './utilities';

import Feedback from '../feedback';
import ProfileHook from '../../hooks/profile';

function FeedbackRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;
  return (
    <Switch>
      <LoggedInRoute
        exact
        component={Feedback}
        isAuthenticated={isAuthenticated}
        path={routes.FEEDBACK_ROUTE}
      />
    </Switch>
  );
}

export default ProfileHook(FeedbackRoute);
