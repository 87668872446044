import React from 'react';
import './style.scss';
import { Button } from 'antd';
import { IMAGE_STATIC_PATH } from '../../../../constants.ts';

function ErrorFallback() {
  return (
    <div
      className="error-fallback"
      style={{ backgroundImage: `url("${IMAGE_STATIC_PATH}/error_404.png")` }}
    >
      <div className="error-fallback__header">
        <div>
          <img
            alt="boloo-logo"
            src={`${IMAGE_STATIC_PATH}/boloo_color_logo.png`}
          />
        </div>
        <div>
          <a
            href="https://www.facebook.com/groups/BolooCommunity/"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="fb-logo" src={`${IMAGE_STATIC_PATH}/fb_logo.png`} />
            <span>Boloo Community</span>
          </a>
        </div>
      </div>
      <div className="error-fallback__content">
        <p className="error-fallback__title">Something went wrong.</p>
        <p className="error-fallback__text">
          Nothing here. Just a friendly bear who meditates.
        </p>
        <p className="error-fallback__text">Keep Calm, Stay Great!</p>
        <a className="error-fallback__btn" href={window.location.href}>
          <Button type="primary">
            <span>Reload</span>
          </Button>
        </a>
      </div>
    </div>
  );
}

export default ErrorFallback;
