export const BE_FLAG = '🇧🇪';
export const NL_FLAG = '🇳🇱';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PRODUCT_SEARCH = '';
export const DEFAULT_NOT_FOUND_POSITION = 300;

export const DEFAULT_ANNOTATION_DATE = null;
export const DEFAULT_ANNOTATION_TEXT = '';

export const UNKNOWN_AVERAGE_VALUES = [null, 0];

export const SORT_OPTIONS = [
  {
    title: 'Stijging (hoog naar laag)',
    filter_by: 'change',
    order_by: 'desc',
    value: '-change',
  },
  {
    title: 'Stijging (laag naar hoog)',
    filter_by: 'change',
    order_by: 'asc',
    value: 'change',
  },
  {
    title: 'Positie (hoog naar laag)',
    filter_by: 'position',
    order_by: 'desc',
    value: '-position',
  },
  {
    title: 'Positie (laag naar hoog)',
    filter_by: 'position',
    order_by: 'asc',
    value: 'position',
  },
];
export const DEFAULT_SORT_OPTION = SORT_OPTIONS[0].value;

export const PRODUCT_DASHBOARD_TABS = [
  {
    value: 'gainers',
    label: 'Stijgers',
  },
  {
    value: 'losers',
    label: 'Dalers',
  },
];
export const DEFAULT_PRODUCT_DASHBOARD_TAB = PRODUCT_DASHBOARD_TABS[0].value;

export const QUERY_NO_PRODUCT_USER = 'add=true';
