export const initialState = {
  accessUserId: null,
  preselectedProductIDs: [],
};

const SET_CAMPAIGN_ACCESS_USER_ID = 'EVENT/SET_CAMPAIGN_ACCESS_USER_ID';
const SET_CAMPAIGN_PRESELECTED_PRODUCTS_IDS =
  'EVENT/SET_CAMPAIGN_PRESELECTED_PRODUCTS_IDS';
const RESET_CAMPAIGN_DATA = 'EVENT/RESET_CAMPAIGN_DATA';

export const setCampaignAccessUserId = (accessUserId) => {
  return {
    type: SET_CAMPAIGN_ACCESS_USER_ID,
    payload: accessUserId,
  };
};

export const setCampaignPreselectedProductIDs = (products) => {
  return {
    type: SET_CAMPAIGN_PRESELECTED_PRODUCTS_IDS,
    payload: products,
  };
};

export const resetCampaignData = () => {
  return {
    type: RESET_CAMPAIGN_DATA,
  };
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_ACCESS_USER_ID:
      return {
        ...state,
        accessUserId: action.payload,
      };
    case SET_CAMPAIGN_PRESELECTED_PRODUCTS_IDS:
      return {
        ...state,
        preselectedProductIDs: action.payload,
      };
    case RESET_CAMPAIGN_DATA:
      return initialState;
    default:
      return state;
  }
};

export default campaignReducer;
