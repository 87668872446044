import React from 'react';
import { Select } from 'antd';

import { STORE_COUNTRIES } from '../../constants';

const StoreCountrySelector = ({
  selectedValue,
  onChange,
  className,
  selectOptions = null,
}) => {
  const OPTIONS = selectOptions !== null ? selectOptions : STORE_COUNTRIES;

  return (
    <Select
      size="large"
      value={selectedValue}
      optionLabelProp="label"
      className={`bear-min-input-width ${className}`}
      onChange={(value) => onChange(value)}
    >
      {OPTIONS.map((item) => {
        return (
          <Select.Option
            key={item.value}
            value={item.value}
            label={`Land: ${item.label}`}
          >
            <div className="bear-flex bear-flex-ac">
              <If condition={item.icon}>
                <span
                  role="img"
                  aria-label={item.value}
                  className="bear-mar-r-4"
                >
                  {item.icon}
                </span>
              </If>
              {item.label}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default StoreCountrySelector;
