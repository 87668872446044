import React, { ReactNode, useState } from 'react';
import { Radio } from 'antd';

import { If } from 'tsx-control-statements/components';

import './style.scss';
import { SubscriptionPaymentMethods } from '../../services/subscriptionService/api';

function PaymentMethods({
  onPaymentMethodSelect,
  hideMandate = false,
  initialPaymentMethod = SubscriptionPaymentMethods.Ideal,
  children,
}: {
  onPaymentMethodSelect: (paymentMethod: SubscriptionPaymentMethods) => void;
  hideMandate?: boolean;
  initialPaymentMethod?: SubscriptionPaymentMethods;
  children?: ReactNode;
}) {
  const [paymentMethod, setPaymentMethod] =
    useState<SubscriptionPaymentMethods>(initialPaymentMethod);

  return (
    <div className="payment-methods">
      <Radio.Group
        size="large"
        buttonStyle="solid"
        defaultValue={paymentMethod}
        onChange={(event) => {
          setPaymentMethod(event.target.value);
          onPaymentMethodSelect(event.target.value);
        }}
      >
        <Radio.Button value={SubscriptionPaymentMethods.Ideal}>
          <div className="method-radio-content">
            <img alt="Ideal" src="/static/images/ideal.svg" />
            <span>Ideal</span>
          </div>
        </Radio.Button>
        <Radio.Button value={SubscriptionPaymentMethods.CreditCard}>
          <div className="method-radio-content">
            <img alt="Credit Card" src="/static/images/credit_card.svg" />
            <span>Credit Card</span>
          </div>
        </Radio.Button>
        <Radio.Button value={SubscriptionPaymentMethods.BanContact}>
          <div className="method-radio-content">
            <img alt="Bancontact" src="/static/images/bancontact.svg" />
            <span>Bancontact</span>
          </div>
        </Radio.Button>
      </Radio.Group>
      {children}
      <div className="mandate-acceptance bear-mar-t-16">
        <If condition={!hideMandate}>
          <p className="bear-light-medium-text bear-pad-b-8">
            Om je betaalgegevens te verifiëren brengen we €1 cent in rekening.
            We storten dit bedrag direct terug op je rekening.
          </p>
        </If>
        <If condition={paymentMethod !== SubscriptionPaymentMethods.CreditCard}>
          <p className="bear-light-regular-text bear-pad-b-8">
            Door deze betaling te bevestigen, machtig je Boloo om via Mollie,
            onze betalingsdienstaanbieder, je rekening maandelijks te debiteren
            overeenkomstig de prijs van het lidmaatschap. Altijd opzeggen: Je
            kunt elke maand je lidmaatschap opzeggen (tenzij je een jaarlijks
            lidmaatschap hebt afgesloten, dan elk jaar)
          </p>
        </If>
      </div>
    </div>
  );
}

export default PaymentMethods;
