/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { SETTING_STORE_ROUTE } from '../../routes';

import './style.scss';
import useScript from '../../hooks/useScript';

let handle = null;

const StoreNotConnected = () => {
  const history = useHistory();
  function initializeWistia() {
    window._wq = window._wq || [];
    window._wq.push({
      id: '_all',
      options: {
        playerColor: '#20313b',
        autoPlay: false,
      },
      onHasData: (video) => {
        handle = video;
      },
    });
  }

  useEffect(() => {
    initializeWistia();
  }, []);
  useScript('https://fast.wistia.com/assets/external/E-v1.js', 'wistia_script');

  return (
    <div className="store-not-connected">
      <p className="bear-regular-title">
        Haal het maximale uit je business door het Boloo Dashboard te
        koppelen&nbsp;
        <span>🏆</span>
      </p>
      <div className="wistia-container">
        <div className="wistia_embed wistia_async_bo8x01jaeg videoFoam=true" />
      </div>
      <Button
        type="primary"
        size="large"
        onClick={() => history.push(SETTING_STORE_ROUTE)}
      >
        <span>Connect bol.com-winkel</span>
      </Button>
    </div>
  );
};

export default StoreNotConnected;
