import React, { useEffect, useState } from 'react';
import {
  Link,
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import { If } from 'tsx-control-statements/components';
import {
  STORE_INVENTORY_PRODUCT_DETAIL_ROUTE,
  STORE_INVENTORY_PRODUCT_EDIT_ROUTE,
  STORE_INVENTORY_ROUTE,
} from '../../../routes';
import { PRODUCT_DETAIL_MENU_ITEMS } from '../constants';
import StoreProductPerformance from '../performance';
import AppFeatureMenu from '../../../components/appFeatureMenu';
import BearPageLoader from '../../../components/bearLoader/pageLoader';

import './style.scss';
import { useOffersProductDetail } from '../../../services/productsService/api';

import ProductCard from '../../../components/productCard';
import StoreProductEdit from '../edit';

function InventoryProductDetail() {
  const { pathname } = useLocation();
  const { offerId } = useParams<{ offerId?: string }>();
  const history = useHistory();

  const { offerProduct, isLoading: isLoadingOfferProduct } =
    useOffersProductDetail(offerId);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    PRODUCT_DETAIL_MENU_ITEMS.forEach((item) => {
      const matchedPath = matchPath(pathname, {
        path: item.path,
        exact: true,
      });
      if (matchedPath) {
        setSelectedOption(item.key);
      }
    });
  }, [pathname]);

  useEffect(() => {
    if (offerProduct?.id) {
      const index = PRODUCT_DETAIL_MENU_ITEMS.findIndex(
        (item) => item.key === selectedOption,
      );
      if (index > -1) {
        const path = PRODUCT_DETAIL_MENU_ITEMS[index].path.replace(
          ':offerId',
          String(offerProduct?.id),
        );
        history.push(path);
      }
    }
  }, [selectedOption]);

  if (isLoadingOfferProduct) {
    return <BearPageLoader />;
  }

  return (
    <div className="store-product-detail">
      <div className="item-detail-long-card">
        <Link
          to={STORE_INVENTORY_ROUTE}
          className="bear-back-text bear-light-regular-text"
        >
          <i className="fad icon-long-arrow-left" />
          <span>Producten</span>
        </Link>

        <ProductCard ean={offerProduct?.product.ean} shouldFetch />
      </div>
      <div className="product-detail-menu">
        <AppFeatureMenu
          selectedOption={selectedOption}
          options={PRODUCT_DETAIL_MENU_ITEMS}
          onOptionSelection={(option) => setSelectedOption(option)}
        />
      </div>
      <Switch>
        <Route exact path={STORE_INVENTORY_PRODUCT_DETAIL_ROUTE}>
          <If condition={offerProduct}>
            <StoreProductPerformance offerProduct={offerProduct} />
          </If>
        </Route>
        <Route exact path={STORE_INVENTORY_PRODUCT_EDIT_ROUTE}>
          <If condition={offerProduct}>
            <StoreProductEdit offerProduct={offerProduct} />
          </If>
        </Route>
      </Switch>
    </div>
  );
}

export default InventoryProductDetail;
