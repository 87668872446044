import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { NonLoggedInRoute } from './utilities';
import { FORGOT_PASSWORD_ROUTE } from '../routes';
import ProfileHook from '../../hooks/profile';

const ForgotPassword = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../accountManagement/forgotPassword'),
);

function ForgotPasswordRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <Switch>
      <Suspense fallback={<Spin className="app-spinner" />}>
        <NonLoggedInRoute
          exact
          path={FORGOT_PASSWORD_ROUTE}
          component={ForgotPassword}
          isAuthenticated={isAuthenticated}
        />
      </Suspense>
    </Switch>
  );
}

export default ProfileHook(ForgotPasswordRoute);
