import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';
import { Spin } from 'antd';
import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';

import { lineChartOptions, barChartOptions } from './constants';

import GenericCard from '../bearCards/genericCard';

import './style.scss';

function BearChart({
  unit,
  type,
  title,
  emptyText,
  isLoading,
  labelsArray,
  datasetArray,
  maxTicks,
  showLegends,
  extra = '',
  isAmount,
  abbreviateYaxisLabels,
  isStacked = false,
  reverseYAxis = false,
}) {
  return (
    <GenericCard title={title} extra={extra} className="bear-graph-card">
      <Choose>
        <When condition={isLoading}>
          <div className="graph-card-loader">
            <Spin />
          </div>
        </When>
        <Otherwise>
          <Choose>
            <When condition={type === 'line'}>
              <Line
                data={{
                  labels: labelsArray,
                  datasets: datasetArray,
                }}
                options={lineChartOptions(
                  unit,
                  datasetArray,
                  maxTicks,
                  showLegends,
                  isAmount,
                  reverseYAxis,
                )}
              />
            </When>
            <Otherwise>
              <Bar
                data={{
                  labels: labelsArray,
                  datasets: datasetArray,
                }}
                options={barChartOptions(
                  unit,
                  datasetArray,
                  isAmount,
                  abbreviateYaxisLabels,
                  isStacked,
                )}
              />
            </Otherwise>
          </Choose>
          <If condition={!datasetArray || !labelsArray}>
            <p className="bear-light-help-text empty-graph-text">{emptyText}</p>
          </If>
        </Otherwise>
      </Choose>
    </GenericCard>
  );
}

BearChart.propTypes = {
  labelsArray: PropTypes.array.isRequired,
  datasetArray: PropTypes.array.isRequired,
  unit: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  emptyText: PropTypes.string,
  maxTicks: PropTypes.number,
  showLegends: PropTypes.bool,
  isAmount: PropTypes.bool,
  abbreviateYaxisLabels: PropTypes.bool,
  reverseYAxis: PropTypes.bool,
};

BearChart.defaultProps = {
  emptyText: 'No data for the selected date range or filters.',
  maxTicks: 5,
  unit: '',
  showLegends: true,
  isAmount: false,
  abbreviateYaxisLabels: false,
  reverseYAxis: false,
};

export default BearChart;
