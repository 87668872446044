import useSWRMutation from 'swr/mutation';
import type { StockNotificationsInterface } from '../types';
import productsAxios from '../../axiosInstance';

export function useStockNotificationsEdit(
  bolOfferId: string | null,
): (
  offerProduct: StockNotificationsInterface,
) => Promise<StockNotificationsInterface> {
  const { trigger } = useSWRMutation(
    bolOfferId ? `/offers/${bolOfferId}/stock_setting` : null,
    (url: string, { arg }: { arg: StockNotificationsInterface }) =>
      productsAxios
        .post(url, JSON.stringify(arg))
        .then((response) => response.data as StockNotificationsInterface),
  );
  return trigger;
}
