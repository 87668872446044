import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin, message } from 'antd';

import { Choose, Otherwise, When } from 'tsx-control-statements/components';
import { CloseOutlined } from '@ant-design/icons';
import { clearSessionData, getUserProfile } from '../../actions';
import ProfileHook from '../../hooks/profile';
import RouteService from '../routes/route';
import Intercom from '../intercom';
import OneSignal from '../oneSignal';
import AnalyticsTracking from '../analyticsTracking';
import { useSubscription } from '../services/subscriptionService/api';
import useScript from '../hooks/useScript';

import { RootState } from '../store';

function App({ profileData, setProfileData }) {
  useScript('https://fast.wistia.com/assets/external/E-v1.js', 'wistia_script');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const activeToken = useSelector((state) => state.tokensReducer.activeToken);
  const [loading, setLoading] = useState(true);

  const { isActiveSubscription, subscriptionPlanFeatures, isLoading } =
    useSubscription(Object.keys(profileData).length > 0);

  const isServiceWorkerUpdated = useSelector(
    (state: RootState) => state.appUpdateReducer.serviceWorkerUpdated,
  );
  const serviceWorkerRegistration = useSelector(
    (state: RootState) => state.appUpdateReducer.serviceWorkerRegistration,
  );

  function reloadPageOnActivated(e) {
    if (e.target.state === 'activated') {
      window.location.reload();
    }
  }

  useEffect(() => {
    serviceWorkerRegistration?.waiting?.addEventListener(
      'statechange',
      reloadPageOnActivated,
    );
  }, [serviceWorkerRegistration]);

  function updateServiceWorker() {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  useEffect(() => {
    if (!activeToken) {
      setLoading(false);
      return;
    }

    getUserProfile()
      .then((response) => setProfileData(response.data))
      .catch(() => clearSessionData())
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!isServiceWorkerUpdated) {
      return;
    }
    message.info({
      key: 'app-update-message',
      content: (
        <>
          {' '}
          Er is een nieuwe versie van Boloo beschikbaar, klik{' '}
          <a
            onClick={() => {
              message.destroy('app-update-message');
              updateServiceWorker();
            }}
          >
            hier
          </a>{' '}
          om het dashboard te herladen{' '}
          <a onClick={() => message.destroy('app-update-message')}>
            <CloseOutlined style={{ margin: 0 }} />
          </a>
        </>
      ),
      duration: 5000,
    });
  }, [isServiceWorkerUpdated]);

  return (
    <Choose>
      <When condition={loading || isLoading}>
        <Spin className="app-spinner" />
      </When>
      <Otherwise>
        <div className="bear-container">
          <Intercom />
          <OneSignal />
          <AnalyticsTracking />
          <RouteService
            isActiveSubscription={isActiveSubscription}
            subscriptionPlanFeatures={subscriptionPlanFeatures}
          />
        </div>
      </Otherwise>
    </Choose>
  );
}

export default ProfileHook(App);
