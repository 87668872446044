import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { If } from 'tsx-control-statements/components';
import { setActiveToken, setParentToken } from '../../reducers/tokensReducer';

import { loginUser } from '../../actions';
import { updatePageMetaData } from '../../../utilities/dom';
import { REGISTER_ROUTE } from '../../routes';
import { isMobileDevice } from '../../utilities/breakpoint';

import AccountManagementLayout from '../layout';
import LoginForm from './loginForm';
import { clearSessionData } from '../../../actions';

function Login() {
  const dispatch = useDispatch();
  const isMobile = isMobileDevice();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    updatePageMetaData({ title: 'Log in met je Boloo account' });
    clearSessionData();
  }, []);

  const handleSubmit = (values) => {
    setProcessing(true);
    loginUser(values)
      .then((response) => {
        const token = response.data.user_jwt;
        dispatch(setParentToken(token));
        dispatch(setActiveToken(token));
        localStorage.setItem('activeToken', token); // For OneSignal requests
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error('Foutje, dit wachtwoord is onjuist');
        }
        setProcessing(false);
      });
  };

  return (
    <AccountManagementLayout
      title="Welkom terug! ❤️"
      description="Log in met je Boloo account"
      backgroundImage="login.png"
      className="login-container"
      headerExtra={
        <p className="bear-regular-text bear-hide-sm">
          <span>
            Heb je nog geen account?{' '}
            <Link to={REGISTER_ROUTE}>Registreren</Link>
          </span>
        </p>
      }
    >
      <LoginForm
        onSubmit={(values) => handleSubmit(values)}
        loading={processing}
      />
      <If condition={isMobile}>
        <p className="bear-regular-text">
          <span>
            Heb je nog geen account?{' '}
            <Link to={REGISTER_ROUTE}>Registreren</Link>
          </span>
        </p>
      </If>
    </AccountManagementLayout>
  );
}

export default Login;
