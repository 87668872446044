import React from 'react';
import './style.scss';
import { IMAGE_STATIC_PATH } from '../../../../../constants';
import {
  getPlanImageName,
  SubscriptionPlanInterface,
} from '../../../../services/subscriptionService/api';

function SubAccountPlan({ plan }: { plan: SubscriptionPlanInterface }) {
  return (
    <div className="subaccount-plan">
      <p>
        <img
          src={`${IMAGE_STATIC_PATH}/${getPlanImageName(plan)}`}
          alt="Extra store plan"
        />
      </p>
      <p className="bear-bold-title">Extra store</p>
      <p>
        <span className="bear-medium-title"> + €{plan.price}</span>
        <span className="bear-light-regular-text"> / maand (ex. BTW)</span>
      </p>
      <p className="list-header">
        Alles wat je nodig hebt om een extra bol.com store toe te voegen:
      </p>
      <ul className="included">
        <li>
          <i className="fad icon-check-circle" />
          <span>Koppel een extra bol.com store</span>
        </li>
        <li>
          <i className="fad icon-check-circle" />
          <span>Alle statistieken in één dashboard</span>
        </li>
        <li>
          <i className="fad icon-check-circle" />
          <span>Eenvoudig switchen tussen stores</span>
        </li>
        <li>
          <i className="fad icon-check-circle" />
          <span>Emailcampagnes</span>
        </li>
        <li>
          <i className="fad icon-check-circle" />
          <span>Klant facturen</span>
        </li>
        <li>
          <i className="fad icon-check-circle" />
          <span>Order beheer</span>
        </li>
      </ul>
      <p className="list-header">Zonder de dingen die je toch al hebt:</p>
      <ul className="not-included">
        <li>
          <i className="fad icon-error cancel" />
          <span>Winstcalculator</span>
        </li>
        <li>
          <i className="fad icon-error cancel" />
          <span>Keyword verkenner</span>
        </li>
        <li>
          <i className="fad icon-error cancel" />
          <span>Product Tracker</span>
        </li>
        <li>
          <i className="fad icon-error cancel" />
          <span>Educatie</span>
        </li>
        <li>
          <i className="fad icon-error cancel" />
          <span>Coaching</span>
        </li>
      </ul>
      <p className="list-header">
        En ook niet onbelangrijk: 7 dagen <strong>gratis</strong> proberen!
      </p>
    </div>
  );
}

export default SubAccountPlan;
