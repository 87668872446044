import ReactGA from 'react-ga4';
import { GA4_MEASUREMENT_ID } from '../constants';
import { UserProfileInterface } from '../../reducers/profileReducer';

const getGA4Config = (isProd) => {
  return {
    trackingId: GA4_MEASUREMENT_ID,
    gaOptions: {
      debug_mode: !isProd,
    },
    gtagOptions: {
      debug_mode: !isProd,
    },
  };
};

const GA4_USER_PROPERTIES = {
  userId: 'user_id',
  isUser: 'is_user',
  lastName: 'last_name',
  sourceForm: 'source_form',
  pageType: 'page_type',
  userProperties: 'user_properties',
};

const setGTag = (propertyName: string, value) => {
  ReactGA.gtag('set', propertyName, value);
};

const trackUserDataChange = (profileData: UserProfileInterface) => {
  setGTag(GA4_USER_PROPERTIES.userProperties, {
    [GA4_USER_PROPERTIES.userId]: profileData.id ? profileData.id : null,
    [GA4_USER_PROPERTIES.isUser]: !!profileData.id,
    [GA4_USER_PROPERTIES.sourceForm]: profileData.source_form
      ? profileData.source_form
      : null,
  });
};

export { trackUserDataChange, getGA4Config };
