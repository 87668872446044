import instance from '../../../axios';
import {
  updatePathWithPagination,
  updatePathWithTimeValues,
} from '../../../actions';
import * as endpoints from '../../endpoints';
import { STORE_INVENTORY_UPDATE_PRODUCT_IS_HIDDEN } from '../../endpoints';
import { isEmpty } from '../../storeManagement/utilities';
import { Retailer } from '../../../reducers/profileReducer';
import { UserAccountInterface } from '../index';

export interface StoreSettings {
  id: number;
  active: boolean;
  client_id: string;
  client_secret: string;
  advertsing_client_id: string;
  advertising_client_secret: string;
  status: string;
  is_sales_notification_enabled: boolean;
  created_at: string;
  updated_at: string;
  is_accounting_enabled: boolean;
  store_name: string;
  store_address: string;
  is_daily_report_enabled: boolean;
  is_hijacker_alerts_enabled: boolean;
  vat_number: string;
  coc_number: string;
  iban_number: string;
  has_no_vat_rule: boolean;
  upload_invoices_to_bol: boolean;
  is_valid_credentials: boolean;
  is_valid_advertising_credentials: boolean;
}

export async function getStoreCredentials(): Promise<{ data: StoreSettings }> {
  return instance.get(endpoints.STORE_CREDENTIALS_API_PATH);
}

export const createOrUpdateStoreCredentials = (data, storeId) => {
  let path = endpoints.STORE_CREDENTIALS_API_PATH;
  let method = 'post';
  if (storeId) {
    path = path.concat(storeId, '/');
    method = 'patch';
  }
  return instance.request({
    method,
    url: path,
    data,
  });
};

export const deleteStore = () => {
  return instance.delete(endpoints.STORE_DELETE_API_PATH);
};

export const deleteAdvertiser = () => {
  return instance.post(endpoints.STORE_DELETE_ADVERTISER_API_PATH);
};

export const startStoreSynchronization = () => {
  return instance.post(endpoints.STORE_SYNCHRONIZATION_API_PATH);
};

export const restartSync = () => {
  return instance.post(endpoints.STORE_RESTART_SYNCHRONIZATION_API_PATH);
};

export const getStoreStatistics = (dateRange, allAccounts = false) => {
  const path = updatePathWithTimeValues(
    endpoints.STORE_STATISTICS_API_PATH,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
  ).concat('&all_accounts=', allAccounts);
  return instance.get(path);
};

export const getStoreReturnStatistics = (dateRange, allAccounts = false) => {
  const path = updatePathWithTimeValues(
    endpoints.STORE_RETURN_STATISTICS_API_PATH,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
  ).concat('&all_accounts=', allAccounts);
  return instance.get(path);
};

export interface ProductData {
  product_id: number;
  revenue: number;
  profit: number;
  ean: string;
  sales: number;
  price: number;
  title: string;
  image: string;
  rating: number;
  url: string;
  revenue_ex_vat: number;
  profit_percentage: number;
  store_id: number;
  store_icon: string;
  store_nickname: string;
}

export async function getStoreTopProducts(
  dateRange,
  sort,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  countryCode = '',
  pageSize,
  currentPage = 1,
  selectedStore: Partial<Retailer> = {},
): Promise<{
  data: { count: number; next: string; results: ProductData[] };
}> {
  let path = updatePathWithTimeValues(
    endpoints.STORE_INVENTORY_TOP_PRODUCTS_API_PATH,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
  );
  path = path.concat('&sort=', sort);
  if (pageSize) {
    path = path.concat('&page_size=', pageSize);
  }
  if (countryCode) {
    path = path.concat('&country_code=', countryCode);
  }
  if (currentPage) {
    path = path.concat('&page=', currentPage);
  }
  if (!isEmpty(selectedStore)) {
    path = path.concat('&vendor_id=', selectedStore.id);
  }
  return instance.get(path);
}

export function getInventoryTopSales(excludeIds = null, pageSize = 10) {
  let path = endpoints.STORE_INVENTORY_TOP_SALES_API_PATH;
  path = path.concat(`?page_size=${pageSize}`);
  if (excludeIds && excludeIds.length) {
    path = path.concat('&exclude=', excludeIds.join(','));
  }
  return instance.get(path);
}

export const getStoreGraphMetrics = (
  dateRange,
  selectedCountry,
  unit,
  allAccounts = false,
) => {
  let path = updatePathWithTimeValues(
    endpoints.STORE_GRAPH_API_PATH,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
    unit,
  );
  if (selectedCountry) {
    path = path.concat('&order__customer__country_code=', selectedCountry);
  }
  if (allAccounts) {
    path = path.concat('&all_accounts=', allAccounts);
  }
  return instance.get(path);
};

export function getInventory(pageNum, extraQuery) {
  let path = updatePathWithPagination(
    endpoints.STORE_INVENTORY_API_PATH,
    pageNum,
  );
  path = path.concat(extraQuery);
  return instance.get(path);
}

export function getInventoryProductDetailApiPath(productEan: string) {
  return endpoints.STORE_INVENTORY_PRODUCT_DETAIL_API_PATH.replace(
    '{}',
    productEan,
  );
}

export function getInventoryProductDetail(productEan: string) {
  return instance.get(getInventoryProductDetailApiPath(productEan));
}

export async function getInventoryProductOverview(
  productIds: number[],
): Promise<unknown[]> {
  return (
    await instance.get(endpoints.STORE_INVENTORY_PRODUCT_OVERVIEW_API_PATH, {
      params: { product_ids: productIds.join(',') },
    })
  ).data.results;
}

export function getInventoryProductMetricsApiPath(
  productEan: string,
  startTime,
  endTime,
) {
  let path = getInventoryProductDetailApiPath(productEan);
  path = path.concat('performance');
  return updatePathWithTimeValues(path, startTime, endTime);
}

export function getVisitorsApiPath(productEan: string, startTime, endTime) {
  let path = getInventoryProductDetailApiPath(productEan);
  path = path.concat('visitors/');
  return updatePathWithTimeValues(path, startTime, endTime);
}

export function getInventoryProductStatisticsApiPath(
  productEan: string,
  startTime,
  endTime,
) {
  let path = getInventoryProductDetailApiPath(productEan);
  path = path.concat('statistics');
  return updatePathWithTimeValues(path, startTime, endTime);
}

export function getInventoryReturnsStatisticsApiPath(
  productEan: string,
  startTime,
  endTime,
) {
  let path = getInventoryProductDetailApiPath(productEan);
  path = path.concat('returns_statistics');
  return updatePathWithTimeValues(path, startTime, endTime);
}

export const getInventoryProductMetrics = (
  productId,
  country,
  startTime,
  endTime,
) => {
  let path = getInventoryProductMetricsApiPath(productId, startTime, endTime);
  path = path.concat('&country=', country);
  return instance.get(path);
};

export const getInventoryProductVisitors = (
  productEan: string,
  startTime,
  endTime,
  selectedStore: UserAccountInterface | null = null,
) => {
  let path = getVisitorsApiPath(productEan, startTime, endTime);
  if (selectedStore) {
    path = path.concat('&vendor_id=', selectedStore.id);
  }

  return instance.get(path);
};

export const getInventoryProductStatistics = (
  productId,
  startTime,
  endTime,
) => {
  const path = getInventoryProductStatisticsApiPath(
    productId,
    startTime,
    endTime,
  );
  return instance.get(path);
};

export const getInventoryReturnsStatistics = (
  productId,
  startTime,
  endTime,
) => {
  const path = getInventoryReturnsStatisticsApiPath(
    productId,
    startTime,
    endTime,
  );
  return instance.get(path);
};

export const getInventorySearchTermVolumes = (searchTerms) => {
  return instance.post(endpoints.STORE_INVENTORY_SEARCH_TERM_VOLUMES, {
    search_terms: searchTerms,
  });
};

export const updateOrderCustomer = (payload, customerId) => {
  const path = endpoints.STORE_GET_ORDER_CUSTOMER_DETAILS.replace(
    '{}',
    customerId,
  );
  return instance.patch(path, payload);
};

export const getOrders = (pageNum, extraQuery) => {
  let path = updatePathWithPagination(endpoints.STORE_ORDERS_API_PATH, pageNum);
  path = path.concat(extraQuery);
  return instance.get(path);
};

export const getOrderDetails = (orderId) => {
  const path = endpoints.STORE_GET_ORDER_DETAILS.replace('{}', orderId);
  return instance.get(path);
};

export const getOrderSentEmails = (orderId) => {
  const path = endpoints.STORE_GET_ORDER_SENT_EMAILS.replace('{}', orderId);
  return instance.get(path);
};

export const sendOrderInvoice = (orderId, force = false) => {
  const path = endpoints.STORE_SEND_ORDER_INVOICE.replace('{}', orderId);
  return instance.post(path, { hideNotification: true, params: { force } });
};

export const sendOrderCreditInvoice = (orderId) => {
  const path = endpoints.STORE_ORDER_CREDIT_INVOICE.replace('{}', orderId);
  return instance.post(path);
};

export const generateCreditInvoice = (returnId, isDownload = false) => {
  const path = endpoints.STORE_RETURNS_CREDIT_INVOICE_API_PATH.replace(
    '{}',
    returnId,
  );
  return instance.post(path, { is_downalod: isDownload });
};

export const getOrderInvoice = (orderId, force = false) => {
  const path = endpoints.STORE_GET_ORDER_INVOICE.replace('{}', orderId);
  return instance.get(path, { params: { force } });
};

export const getOrderCreditInvoice = (orderId) => {
  const path = endpoints.STORE_GET_ORDER_CREDIT_INVOICE.replace('{}', orderId);
  return instance.get(path);
};

export const cancelOrder = (orderId) => {
  const path = endpoints.STORE_CANCEL_ORDER.replace('{}', orderId);
  return instance.post(path, { hideNotification: true });
};

export const getReturns = (pageNum, extraQuery) => {
  let path = updatePathWithPagination(
    endpoints.STORE_RETURNS_API_PATH,
    pageNum,
  );
  path = path.concat(extraQuery);
  return instance.get(path);
};

export const deleteStoreStockSettings = (productId, settingsId) => {
  let path = endpoints.STORE_STOCK_SETTINGS.replace('{}', productId);
  path = path.concat(settingsId, '/');
  return instance.delete(path);
};

export const deleteDynamicPriceSettings = (productId, settingsId) => {
  let path = endpoints.STORE_DYNAMIC_PRICE_SETTINGS.replace('{}', productId);
  path = path.concat(settingsId, '/');
  return instance.delete(path);
};

export const bulkCreateOrUpdateStockSettings = (data) => {
  return instance.post(
    endpoints.STORE_INVENTORY_BULK_EDIT_STOCK_SETTINGS,
    data,
  );
};

export function updateHiddenProduct(productId) {
  return instance.patch(
    STORE_INVENTORY_UPDATE_PRODUCT_IS_HIDDEN.replace('{}', productId),
  );
}

export const copyProductCosts = (data) => {
  return instance.post(endpoints.STORE_INVENTORY_COPY_PRODUCT_COSTS, data);
};

export const getOrderInvoiceSettings = () => {
  return instance.get(endpoints.STORE_ORDER_INVOICE_SETTINGS_API_PATH);
};

export const saveOrderInvoiceSettings = (data, settingsId) => {
  let path = endpoints.STORE_ORDER_INVOICE_SETTINGS_API_PATH;
  let method = 'post';
  if (settingsId) {
    path = path.concat(settingsId, '/');
    method = 'patch';
  }
  return instance.request({
    method,
    url: path,
    data,
  });
};

export const uploadOrderInvoiceLogo = (file) => {
  const formdata = new FormData();
  formdata.append('file', file);
  const path = endpoints.STORE_ORDER_INVOICE_LOGO_UPLOAD_API_PATH;
  return instance.post(path, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const sendInvoicingTestEmail = (data) => {
  const path = endpoints.INVOICING_SEND_TEST_EMAIL_API_PATH;
  return instance.post(path, data);
};

export type CSVBackendResponse = {
  job_id: string;
  status: string;
  progress: {
    current_step: number;
    total_steps: number;
    percentage: number;
  };
  errors?: {
    [row_number: number]: {
      errors: string[];
      ean?: string;
    };
  };
  message: string;
  submitted_at: string;
};
