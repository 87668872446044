import React from 'react';

import './style.scss';
import { IMAGE_STATIC_PATH } from '../../../constants';

interface BearEmptyStateProps {
  image: string;
  title: string;
  className?: string;
  description?: string;
  children?: React.ReactNode;
}

function BearEmptyState({
  image,
  title,
  className,
  description,
  children,
}: BearEmptyStateProps) {
  return (
    <div className={`bear-empty-state ${className}`}>
      <img
        src={`${IMAGE_STATIC_PATH}/${image}`}
        alt={title}
        className="bear-mar-b-12"
      />
      <div className="bear-empty-state-content">
        <p className="bear-bold-title bear-pad-b-4">{title}</p>
        {description && (
          <p className="bear-regular-text bear-mar-b-8">{description}</p>
        )}
        {children}
      </div>
    </div>
  );
}

export default BearEmptyState;
