import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Select } from 'antd';

import {
  DEFAULT_PRODUCTS_SORT,
  DEFAULT_COUNTRY_FILTER,
  sortOptions,
} from '../dashboard/constants';

import { INDEX_ROUTE } from '../../routes';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { getLastMonthRange } from '../../../utilities/date';
import { getStoreTopProducts } from '../../actions/store';
import { getQueryStringValue } from '../../utilities/queryString';

import useQueryString from '../../hooks/useQueryString';
import BearDatePicker from '../../../components/bearDatePicker';
import StoreCountrySelector from '../components/countrySelector';
import { StoreTopInventoryProducts } from '../components/topInventoryProducts';
import { isEmpty } from '../utilities';
import ProfileHook from '../../../hooks/profile';
import captureErrorAndShowMessage from '../../../utilities/errors';
import { UserAccountInterface } from '../../actions';

function ProductPerformance() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useQueryString(
    'country',
    DEFAULT_COUNTRY_FILTER,
  );
  const [selectedStore, setSelectedStore] =
    useState<UserAccountInterface | null>(null);
  const [sorter, setSorter] = useQueryString('sort', DEFAULT_PRODUCTS_SORT);
  const [dateRange, setDateRange] = useQueryString(
    'interval',
    getLastMonthRange(),
  );

  const isDateInUrl = getQueryStringValue('interval');
  const { state: locationState } = useLocation();

  function navigateToDashboard() {
    const path = INDEX_ROUTE.concat(
      '?interval=',
      dateRange[0],
      '&interval=',
      dateRange[1],
    );
    history.push(path);
  }

  function handleResponse(response, currentPage = 1) {
    setPage(currentPage);
    const { results } = response.data;
    if (currentPage === 1) {
      setProducts(results);
    } else {
      setProducts([...products, ...results]);
    }
    setHasMore(!!response.data.next);
    setLoading(false);
  }

  useEffect(() => {
    const localSelectedStore =
      locationState !== undefined && 'selectedStore' in locationState
        ? locationState.selectedStore
        : {};

    getStoreTopProducts(
      dateRange,
      sorter,
      selectedCountry,
      DEFAULT_PAGE_SIZE,
      page,
      localSelectedStore,
    )
      .then((response) => {
        if (!isEmpty(localSelectedStore)) {
          setSelectedStore(localSelectedStore);
        }

        setInitialLoaded(true);
        handleResponse(response);
      })
      .catch((e) => captureErrorAndShowMessage(e));
  }, []);

  useEffect(() => {
    if (initialLoaded && !loading) {
      setLoading(true);
      getStoreTopProducts(
        dateRange,
        sorter,
        selectedCountry,
        DEFAULT_PAGE_SIZE,
        page,
        selectedStore,
      )
        .then((response) => {
          handleResponse(response);
        })
        .catch((e) => captureErrorAndShowMessage(e));
    }
  }, [dateRange, sorter, selectedCountry]);

  function onLoadMore() {
    if (!loading && initialLoaded) {
      const nextPage = page + 1;
      setLoading(true);
      setPage(nextPage);
      getStoreTopProducts(
        dateRange,
        sorter,
        selectedCountry,
        DEFAULT_PAGE_SIZE,
        nextPage,
        selectedStore,
      )
        .then((response) => {
          handleResponse(response, nextPage);
        })
        .catch((e) => captureErrorAndShowMessage(e));
    }
  }

  return (
    <>
      <div
        onClick={() => navigateToDashboard()}
        className="bear-back-text bear-light-regular-text"
      >
        <i className="fad icon-long-arrow-left" />
        <span>Dashboard</span>
      </div>
      <Row justify="space-between" className="bear-mar-b-16">
        <Col className="mobile-fw-input">
          <BearDatePicker
            onApply={(values) => setDateRange(values)}
            defaultValues={isDateInUrl ? dateRange : null}
          />
        </Col>
        <Col>
          <Row
            gutter={{
              xxl: 24,
              xl: 24,
              lg: 24,
              md: 12,
              sm: 12,
              xs: 12,
            }}
          >
            <Col xs={24} className="bear-col-fa">
              <StoreCountrySelector
                selectedValue={selectedCountry}
                onChange={(value) => setSelectedCountry(value)}
              />
            </Col>
            <Col xs={24} className="bear-col-fa">
              <Select
                size="large"
                value={sorter}
                onChange={(value) => setSorter(value)}
                className="bear-min-input-width bear-width"
              >
                {sortOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.title}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <StoreTopInventoryProducts
        hasMore={hasMore}
        isLoading={loading}
        products={products}
        dateRange={dateRange}
        selectedCountry={selectedCountry}
        selectedStore={selectedStore}
        handleLoadMore={() => onLoadMore()}
      />
    </>
  );
}

export default ProductPerformance;
