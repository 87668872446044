import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, message, Button } from 'antd';
import { saveAs } from 'file-saver';
import api from '../../../services/shipmentsService/api';

import {
  getOrderInvoice,
  sendOrderCreditInvoice,
  generateCreditInvoice,
  getOrderCreditInvoice,
} from '../../../actions/store';
import { STORE_ORDERS_EDIT_ROUTE } from '../../../routes';
import captureErrorAndShowMessage from '../../../../utilities/errors';

function InvoicingDropdown({
  order,
  doRefresh,
  isCreditInvoice = false,
  isHandledReturn = false,
}: {
  order: any;
  doRefresh?: () => void;
  isCreditInvoice?: boolean;
  isHandledReturn?: boolean;
}) {
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  function onEditAction(event) {
    event.stopPropagation();
    const route = STORE_ORDERS_EDIT_ROUTE.replace(':orderId', String(order.id));
    history.push(route);
  }

  function unUploadAction(event) {
    event?.stopPropagation();

    setIsUploading(true);

    api
      .uploadInvoice(order.id)
      .then((response) => {
        if (doRefresh) {
          doRefresh();
        }
        const successMessage =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.detail ||
          'De factuur wordt zsm geüpload, dit kan een paar minuten duren.';
        message.success({
          content: successMessage,
        });
        setIsUploading(false);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.detail ||
          'Factuur kon niet worden geüpload. Probeer later opnieuw.';
        message.error(errorMessage);
        setIsUploading(false);
      });
  }

  async function onViewCreditInvoice(event) {
    if (event) {
      event.stopPropagation();
    }
    message.loading('Credit wordt geopend');
    try {
      const result = await getOrderCreditInvoice(order.id);
      saveAs(result.data.invoice, 'Invoice');
    } catch (e) {
      captureErrorAndShowMessage(e);
    }
  }

  async function onViewInvoice(event) {
    event.stopPropagation();
    message.loading('Factuur wordt geopend');
    try {
      const result = isCreditInvoice
        ? await generateCreditInvoice(order.id, true)
        : await getOrderInvoice(order.id);
      saveAs(result.data.invoice, 'Invoice');
    } catch (e) {
      captureErrorAndShowMessage(
        e,
        'Factuur kon niet worden geopend. Probeer later opnieuw.',
      );
    }
  }

  async function onSendCreditInvoice(event) {
    event.stopPropagation();

    messageApi.open({
      key: 'uploading_credit_invoice',
      type: 'loading',
      content: 'Credit factuur wordt verzonden...',
      duration: 0,
    });
    sendOrderCreditInvoice(order.id)
      .then(() => {
        if (doRefresh) {
          doRefresh();
        }
        message.destroy('uploading_credit_invoice');
        message.success({
          content: 'Creditfactuur is verzonden',
          duration: 2,
        });
      })
      .catch(() => {
        message.error('Factuur niet verstuurd. Probeer later opnieuw.');
      });
  }

  function getOrderActionMenu() {
    const orderItems =
      order.order === undefined ? order.order_items : order.order.order_items;

    const canUploadOrder =
      orderItems.length > 0 &&
      orderItems[0].status !== 'open' &&
      !order.is_invoice_uploaded;

    const items = [];
    if (canUploadOrder) {
      items.push({
        key: 'send',
        label: (
          <span onClick={isUploading ? null : (event) => unUploadAction(event)}>
            {isUploading ? 'Uploading...' : 'Uploaden'}
          </span>
        ),
        disabled: isUploading,
      });
    }
    items.push({
      label: <span onClick={(event) => onViewInvoice(event)}>Bekijken</span>,
      key: 'view',
    });
    if (!order.is_credited && !isHandledReturn) {
      items.push({
        key: 'credit',
        label: (
          <span onClick={(event) => onSendCreditInvoice(event)}>Crediteer</span>
        ),
      });
    }
    if (order.is_credited) {
      items.push({
        key: 'credit',
        label: (
          <span onClick={(event) => onViewCreditInvoice(event)}>
            Bekijk credit
          </span>
        ),
      });
    }
    if (!isCreditInvoice) {
      items.push({
        key: 'edit',
        label: <span onClick={(event) => onEditAction(event)}>Aanpassen</span>,
      });
    }
    return { items };
  }

  return (
    <Dropdown
      trigger={['click']}
      menu={getOrderActionMenu()}
      placement="bottomRight"
      forceRender
    >
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        size="large"
        ghost={!!isCreditInvoice}
        type="primary"
        data-testid="invoicing-dropdown"
      >
        <div className="bear-flex bear-flex-ac">
          {isCreditInvoice ? 'Creditfactuur' : 'Factuur'}
          <span className="bear-mar-l-8 invoice-options">
            <i className="icon-arrow-right" />
          </span>
        </div>
      </Button>
    </Dropdown>
  );
}

export default InvoicingDropdown;
