import useSWR from 'swr';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';
import { InventoryStats } from '../types';

export function useOffersStats(): {
  stats: InventoryStats;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `/offers/stats`,
    (url) => productsAxios.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    stats: data || null,
    isLoading,
    isError: error,
  };
}
