import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Tooltip, message, Button, Space } from 'antd';

import { If } from 'tsx-control-statements/components';
import { allowNumbersOnly } from '../../../../utilities';
import { MAXIMUM_DYNAMIC_PRICES_COUNT } from '../../constants';
import GenericCard from '../../../../../components/bearCards/genericCard';

import './style.scss';
import {
  DynamicPricers,
  OfferProductInterface,
  useDynamicPricers,
  useDynamicPricersEdit,
} from '../../../../services/productsService/api';
import captureErrorAndShowMessage from '../../../../../utilities/errors';
import { ROW_GUTTER } from '../../../../constants';

function ProductDynamicPriceSettings({
  offerProduct,
}: {
  offerProduct: OfferProductInterface;
}) {
  const { dynamicPricers } = useDynamicPricers(
    String(offerProduct?.bol_offer_id),
  );

  const editDymanicPricers = useDynamicPricersEdit(offerProduct?.bol_offer_id);

  const [form] = Form.useForm<{ dynamic_pricers: DynamicPricers[] }>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(
    () => form.setFieldValue('dynamic_pricers', dynamicPricers),
    [dynamicPricers],
  );

  return (
    <GenericCard
      title={
        <Tooltip
          className="bear-has-tooltip"
          title={`De prijs wordt automatisch verhoogt 
      wanneer de voorraad op het aantal stuks komt dat je hier invult. 
      Zo maak je meer winst en raak je minder snel uitverkocht. LET OP: 
      eventuele ingestelde volumekortingen op bol zullen 
      worden overschreven. Alleen de prijs voor 1 product wordt 
      aangepast`}
        >
          Automatische prijsverhoging
        </Tooltip>
      }
      className="store-dynamic-price-card"
    >
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        scrollToFirstError
        onFinish={async (values) => {
          try {
            setIsSaving(true);
            await editDymanicPricers(values.dynamic_pricers);
            message.success(
              `Je automatische prijsverhogingen zijn nu opgeslagen`,
            );
          } catch (e) {
            captureErrorAndShowMessage(e);
          } finally {
            setIsSaving(false);
          }
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Form.List name="dynamic_pricers">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Row
                      gutter={{
                        xxl: 24,
                        xl: 24,
                        lg: 24,
                        md: 12,
                        sm: 12,
                        xs: 12,
                      }}
                      align="middle"
                      key={field.key}
                    >
                      <Col className="bear-full-flex">
                        <Row
                          gutter={{
                            xxl: 24,
                            xl: 24,
                            lg: 24,
                            md: 12,
                            sm: 12,
                            xs: 12,
                          }}
                        >
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'stock']}
                              label={index === 0 ? 'Voorraad Level' : ''}
                              rules={[
                                {
                                  required: true,
                                  message: 'verplicht veld',
                                },
                              ]}
                            >
                              <Input
                                min={1}
                                size="large"
                                addonBefore="<="
                                className="bear-width"
                                onKeyDown={(event) =>
                                  allowNumbersOnly(event, false)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'price']}
                              label={index === 0 ? 'Verkoopprijs' : ''}
                              rules={[
                                {
                                  required: true,
                                  message: 'verplicht veld',
                                },
                              ]}
                            >
                              <Input
                                min={0}
                                prefix="€"
                                size="large"
                                type="number"
                                className="bear-width"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="item-delete-trigger">
                        <i
                          role="presentation"
                          className="fad icon-trash"
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <If condition={fields.length < MAXIMUM_DYNAMIC_PRICES_COUNT}>
                    <p onClick={() => add()} className="bear-link">
                      + Voeg automatische prijsverhoging toe
                    </p>
                  </If>
                </>
              );
            }}
          </Form.List>
          <Row gutter={ROW_GUTTER}>
            <Col>
              <Button
                size="large"
                type="primary"
                loading={isSaving}
                onClick={() => form.submit()}
              >
                Opslaan
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </GenericCard>
  );
}

export default ProductDynamicPriceSettings;
