import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LoggedInRoute } from './utilities';
import { AcademyCoursesProvider } from '../context/academyCourses';
import {
  academyCoursesReducer,
  initialState as academyCoursesInitialState,
} from '../reducers/academyCoursesReducer';
import {
  ACADEMY_ROUTE,
  ACADEMY_SECTIONS_ROUTE,
  ACADEMY_LESSONS_ROUTE,
} from '../routes';
import ProfileHook from '../../hooks/profile';

const AcademyManagement = lazy(
  () => import(/* webpackPrefetch: true */ '../academyManagement'),
);

const AcademySections = lazy(
  () => import(/* webpackPrefetch: true */ '../academyManagement/sections'),
);

const AcademyLessons = lazy(
  () => import(/* webpackPrefetch: true */ '../academyManagement/lessons'),
);

function AcademyRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <Switch>
      <AcademyCoursesProvider
        initialState={academyCoursesInitialState}
        reducer={academyCoursesReducer}
      >
        <Suspense fallback={<Spin className="app-spinner" />}>
          <LoggedInRoute
            exact
            path={ACADEMY_ROUTE}
            component={AcademyManagement}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={ACADEMY_SECTIONS_ROUTE}
            component={AcademySections}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={ACADEMY_LESSONS_ROUTE}
            component={AcademyLessons}
            isAuthenticated={isAuthenticated}
          />
        </Suspense>
      </AcademyCoursesProvider>
    </Switch>
  );
}

export default ProfileHook(AcademyRoute);
