import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tooltip, Spin } from 'antd';
import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';

import { TOOLTIP_COLOUR } from '../../constants';
import './style.scss';
import { ROW_GUTTER } from '../../retailer/constants';

const renderNode = (value) => {
  const { text, tooltip } = value;
  if (text && tooltip) {
    return (
      <Tooltip
        title={tooltip}
        placement="bottom"
        color={TOOLTIP_COLOUR}
        className="bear-has-tooltip"
      >
        {text}
      </Tooltip>
    );
  }
  return text;
};

function BearStatistics({ items, isCompleteLoading, dropdownMenu }) {
  return (
    <Row className="bear-statistics-row" gutter={ROW_GUTTER}>
      {items.map((item) => {
        return (
          <Col key={item.key} flex={1}>
            <div>
              <p className="bear-light-regular-text bear-text-uc statistics-title">
                {renderNode(item.title)}
              </p>
              <Choose>
                <When condition={item.loading || isCompleteLoading}>
                  <Spin size="small" />
                </When>
                <Otherwise>
                  <If condition={item.value}>
                    <div className="bear-bold-title statistics-value">
                      {item.value}
                    </div>
                  </If>
                  <If condition={item.sub_value}>
                    <div className="bear-light-regular-text statistics-sub-value">
                      {renderNode(item.sub_value)}
                    </div>
                  </If>
                </Otherwise>
              </Choose>
            </div>
          </Col>
        );
      })}
      {dropdownMenu && (
        <Col
          style={{
            textAlign: 'right',
            position: 'absolute',
            top: 30,
            right: 30,
          }}
        >
          {dropdownMenu}
        </Col>
      )}
    </Row>
  );
}

BearStatistics.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isCompleteLoading: PropTypes.bool,
  dropdownMenu: PropTypes.node,
};

BearStatistics.defaultProps = {
  isCompleteLoading: false,
};

export default BearStatistics;
