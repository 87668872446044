export const INDEX_ROUTE = '/';

export const FRS_DASHBOARD = '/frs-dashboard/';

// Plans
export const PLANS_ROUTE = '/plans/';
export const PLANS_PAYMENT_ROUTE = `${PLANS_ROUTE}payment/`;

export const IMPERSONATION_ROUTE = '/switch/:id';

// Register
export const REGISTER_ROUTE = '/register/';
export const REGISTER_PAYMENT_ROUTE = `${REGISTER_ROUTE}activate-trial/`;
export const REGISTER_BILLING_ROUTE = `${REGISTER_ROUTE}billing/`;

export const REGISTER_YEAR_DEAL_ROUTE = '/register/jaardeal/';

export const FORGOT_PASSWORD_ROUTE = '/forgot-password/';
export const RESET_PASSWORD_ROUTE = '/reset-password/';

// Store
export const STORE_BASE_ROUTE = '/store/';
export const DASHBOARD_PRODUCTS_ROUTE = `${STORE_BASE_ROUTE}products/`;
export const STORE_INVENTORY_ROUTE = `${STORE_BASE_ROUTE}inventory/`;
export const STORE_INVENTORY_PRODUCT_DETAIL_ROUTE = `${STORE_INVENTORY_ROUTE}:offerId/`;
export const STORE_INVENTORY_PRODUCT_EDIT_ROUTE = `${STORE_INVENTORY_ROUTE}:offerId/edit/`;
export const STORE_ORDERS_ROUTE = `${STORE_BASE_ROUTE}orders/`;
export const STORE_ORDERS_EDIT_ROUTE = `${STORE_BASE_ROUTE}orders/:orderId/edit/`;

// Boloo AI
export const BOLOO_AI_BASE_ROUTE = '/ai/';
export const BOLOO_AI_LISTING_ROUTE = `${BOLOO_AI_BASE_ROUTE}listing/:listingId?`;
export const BOLOO_AI_ASK_ROUTE = `${BOLOO_AI_BASE_ROUTE}ask/:conversationId?`;
export const BOLOO_AI_KEYWORD_EXTRACT = `${BOLOO_AI_BASE_ROUTE}keyword-extractor/:keywordExtractorId?`;

export const BOLOO_AI_REVIEW_ANALYSER = `${BOLOO_AI_BASE_ROUTE}review-analyser/:reviewAnalysisId?`;

// Campaigns
export const CAMPAIGN_BASE_ROUTE = '/campaigns/';
export const CAMPAIGN_CREATE_ROUTE = `${CAMPAIGN_BASE_ROUTE}create/`;
export const CAMPAIGN_EDIT_ROUTE = `${CAMPAIGN_BASE_ROUTE}:campaignId/edit/`;
export const CAMPAIGN_DETAIL_ROUTE = `${CAMPAIGN_BASE_ROUTE}:campaignId/`;

export const INVOICING_BASE_ROUTE = '/store/invoicing/';

// Research
export const RESEARCH_BASE_ROUTE = '/research/';
export const RESEARCH_TRACKER_ROUTE = `${RESEARCH_BASE_ROUTE}tracker/`;

export const RESEARCH_TRACKER_ROUTE_V2 = `${RESEARCH_BASE_ROUTE}tracker`;
export const RESEARCH_TRACKER_ROUTE_DETAIL_V2 = `${RESEARCH_BASE_ROUTE}tracker/:productEan`;

export const RESEARCH_DATABASE_ROUTE = `${RESEARCH_BASE_ROUTE}database/`;
export const RESEARCH_EXPLORER_ROUTE = `${RESEARCH_BASE_ROUTE}explorer/`;

export const RESEARCH_DATABASE_ROUTE_V2 = `${RESEARCH_BASE_ROUTE}database/`;

export const RESEARCH_CALCULATOR_ROUTE = `${RESEARCH_BASE_ROUTE}calculator/`;
export const RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE = `${RESEARCH_BASE_ROUTE}product-ranking-checker/`;
export const RESEARCH_PRODUCT_RANKING_CHECKER_PRODUCT_ROUTE = `${RESEARCH_BASE_ROUTE}product-ranking-checker/:productId/`;
export const RESEARCH_CALCULATION_CREATE_ROUTE = `${RESEARCH_BASE_ROUTE}calculator/create/`;
export const RESEARCH_CALCULATION_UPDATE_ROUTE = `${RESEARCH_BASE_ROUTE}calculator/edit/:slug/`;

// Academy
export const ACADEMY_ROUTE = '/academy/';
export const ACADEMY_SECTIONS_ROUTE = `${ACADEMY_ROUTE}:id/sections/`;
export const ACADEMY_LESSONS_ROUTE = `${ACADEMY_SECTIONS_ROUTE}:sectionId/lessons/:lessonId/`;

// Accounting
export const ACCOUNTING = '/accounting/';
export const ACCOUNTING_USER_COST_INVOICES = `${ACCOUNTING}user-cost-invoices/`;
export const ACCOUNTING_USER_COST_INVOICE_ADD = `${ACCOUNTING}user-cost-invoices/add/`;
export const ACCOUNTING_USER_COST_INVOICE_EDIT = `${ACCOUNTING}user-cost-invoices/:itemId/`;
export const ACCOUNTING_USER_REVENUE_INVOICES = `${ACCOUNTING}user-income-invoices/`;
export const ACCOUNTING_USER_REVENUE_INVOICE_ADD = `${ACCOUNTING}user-income-invoices/add/`;
export const ACCOUNTING_USER_REVENUE_INVOICE_EDIT = `${ACCOUNTING}user-income-invoices/:itemId/`;
export const ACCOUNTING_DECLARATION = `${ACCOUNTING}declaration/`;

// eslint-disable-next-line max-len
// Deprecated /account routes in favour of /settings path, app will redirect to /settings
export const ACCOUNT_SETTINGS_ROUTE = '/account/';
export const ACCOUNT_SETTING_STORE_ROUTE = `${ACCOUNT_SETTINGS_ROUTE}store/`;
export const ACCOUNT_SETTING_MEMBERSHIP_ROUTE = `${ACCOUNT_SETTINGS_ROUTE}membership/`;
export const ACCOUNT_SETTING_MEMBERSHIP_DEACTIVATE_ROUTE = `${ACCOUNT_SETTINGS_ROUTE}membership/unsubscribe/`;
export const ACCOUNT_SETTING_PAYMENT_METHOD_ROUTE = `${ACCOUNT_SETTINGS_ROUTE}payment-method/`;

// Settings
export const SETTINGS_ROUTE = '/settings/';
export const SETTING_STORE_ROUTE = `${SETTINGS_ROUTE}store/`;
export const SETTING_MEMBERSHIP_ROUTE = `${SETTINGS_ROUTE}membership/`;
export const SETTING_INVOICES_ROUTE = `${SETTINGS_ROUTE}invoices/`;
export const SETTING_NOTIFICATIONS_ROUTE = `${SETTINGS_ROUTE}notifications/`;
export const SETTING_ACCOUNTING_ROUTE = `${SETTINGS_ROUTE}accounting/`;
export const SETTING_MEMBERSHIP_DEACTIVATE_ROUTE = `${SETTINGS_ROUTE}membership/unsubscribe/`;
export const SETTING_PAYMENT_METHOD_ROUTE = `${SETTINGS_ROUTE}payment-method/`;

export const PAYMENT_PROCESS_ROUTE = '/payment/process/';

export const FEEDBACK_ROUTE = '/feedback/';

export const getInventoryProductDetailRoute = (offerId: string) => {
  return STORE_INVENTORY_PRODUCT_DETAIL_ROUTE.replace(':offerId', offerId);
};

export const getInventoryProductEditRoute = (offerId: string) => {
  return STORE_INVENTORY_PRODUCT_EDIT_ROUTE.replace(':offerId', offerId);
};

export const getCampaignDetailRoute = (campaignId, routeParams) => {
  const route = CAMPAIGN_DETAIL_ROUTE.replace(':campaignId', campaignId);
  return route.concat(routeParams);
};

export const getCampaignEditRoute = (campaignId: string) => {
  return CAMPAIGN_EDIT_ROUTE.replace(':campaignId', campaignId);
};

export const getBooAIConversationRoute = (conversationId?: string): string => {
  return BOLOO_AI_ASK_ROUTE.replace(':conversationId?', conversationId || '');
};

export const getBooloAIListingRoute = (listingId?: string): string => {
  return BOLOO_AI_LISTING_ROUTE.replace(':listingId?', listingId || '');
};

export const getBooloAIKeywordExtractorRoute = (
  keywordExtractorId?,
): string => {
  return BOLOO_AI_KEYWORD_EXTRACT.replace(
    ':keywordExtractorId?',
    keywordExtractorId || '',
  );
};

export const getBooloAIReviewAnalyserRoute = (
  reviewAnalyserId?: string,
): string => {
  return BOLOO_AI_REVIEW_ANALYSER.replace(
    ':reviewAnalysisId?',
    reviewAnalyserId || '',
  );
};

export function getProductTrackerV2Route(productEan?: string): string {
  return RESEARCH_TRACKER_ROUTE_DETAIL_V2.replace(
    ':productEan',
    productEan || '',
  );
}
