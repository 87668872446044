import React from 'react';
import { Row, Col, Tag } from 'antd';
import PropTypes from 'prop-types';

import { If } from 'tsx-control-statements/components';
import { STORE_ORDER_SHIPPED_STATUS } from '../../../../constants';
import ProductCard from '../../../../components/productCard';

function StoreDrawerReturnItems({ items, fulfilmentMethod }) {
  return (
    <>
      {items.map((item) => {
        return (
          <Row gutter={32} className="drawer-item-row" key={item.rma_id}>
            <Col span={24}>
              <ProductCard ean={item.product.ean} shouldFetch>
                <p className="bear-light-help-text">
                  <span>RMA ID: {item.rma_id}</span>
                  <span> | </span>
                  <span>{fulfilmentMethod}</span>
                </p>
                <If
                  condition={
                    item.order_item.status === STORE_ORDER_SHIPPED_STATUS
                  }
                >
                  <Tag className="bear-mar-t-4 light-success">
                    {item.order_item.status_display}
                  </Tag>
                </If>
                <div className="bear-mar-t-16 customer-reason-block">
                  <p className="bear-light-help-text">Reden:</p>
                  <p className="bear-regular-text">{item.reason}</p>
                </div>
                <div className="bear-mar-t-16 customer-reason-block">
                  <p className="bear-light-help-text">Bericht klant:</p>
                  <p className="bear-regular-text">{item.reason_comments}</p>
                </div>
              </ProductCard>
            </Col>
          </Row>
        );
      })}
    </>
  );
}

export default StoreDrawerReturnItems;

StoreDrawerReturnItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
