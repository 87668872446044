import { Button, Col, Radio, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Choose, When, Otherwise } from 'tsx-control-statements/components';
import {
  BE_FLAG,
  NL_FLAG,
  DEFAULT_PRODUCT_DASHBOARD_TAB,
  PRODUCT_DASHBOARD_TABS,
  DATE_FORMAT,
  QUERY_NO_PRODUCT_USER,
} from '../../constants';
import { RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE } from '../../../routes';

import './style.scss';

import captureErrorAndShowMessage from '../../../../utilities/errors';
import ProductCard from '../../../components/productCard';
import api from '../../../services/productPositionTrackerService/api';
import CommonCard from '../../../components/commonCard';

function ProductDashboardStats({ dateRange }) {
  const history = useHistory();
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  const [productsCount, setProductsCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [tabOption, setTabOption] = useState(DEFAULT_PRODUCT_DASHBOARD_TAB);
  useEffect(() => {
    setIsLoading(true);

    let startDate = null;
    let endDate = null;

    if (dateRange) {
      startDate = dayjs.utc(dateRange[0] * 1000);
      endDate = dayjs.utc(dateRange[1] * 1000);

      startDate = startDate.format(DATE_FORMAT);
      endDate = endDate.format(DATE_FORMAT);
    }

    const fetchData = async () => {
      const data = await api.getProductDashboardStats(startDate, endDate);
      setGainers(data.gainers);
      setLosers(data.losers);
      setProductsCount(data.products_count);
    };

    fetchData()
      .catch((e) => captureErrorAndShowMessage(e))
      .finally(() => setIsLoading(false));
  }, [dateRange]);

  function getPositionChangeElement(positionChange) {
    let color = null;
    let sign = null;

    if (positionChange.direction === 'down') {
      color = 'red';
      sign = '';
    } else if (positionChange.direction === 'up') {
      color = 'green';
      sign = '+';
    }

    return color ? (
      <div style={{ color }}>
        {sign}
        {positionChange.difference}
      </div>
    ) : null;
  }

  function openProductOverview() {
    let route = RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE;
    if (tabOption === 'losers') {
      route += '?sort=change';
    }
    history.push(route);
  }

  function getProducts() {
    const products = tabOption === 'gainers' ? gainers : losers;

    const productStats = products.map((product) => {
      const positionChange = product.position_change;

      const changeLabelNL = getPositionChangeElement(positionChange.nl);
      const changeLabelBE = getPositionChangeElement(positionChange.be);

      return (
        <Row className="prc-dashboard-stats-row" key={product.id}>
          <Col span={18} className="prc-dashboard-stats-center">
            <ProductCard ean={product.ean} shouldFetch />
          </Col>

          <Col span={3} className="prc-dashboard-stats-position bear-text-c">
            <div>{product.average_position.nl}</div>
            {changeLabelNL}
          </Col>
          <Col span={3} className="prc-dashboard-stats-position bear-text-c">
            <div>{product.average_position.be}</div>
            {changeLabelBE}
          </Col>
        </Row>
      );
    });

    const viewProductBtn = (
      <Row className="prc-dashboard-stats-row prc-dashboard-stats-view-products-row">
        <Button
          size="middle"
          type="primary"
          className="prc-dashboard-stats-button"
          onClick={() => openProductOverview()}
        >
          Bekijk meer producten
        </Button>
      </Row>
    );

    return (
      <>
        {productStats}
        {viewProductBtn}
      </>
    );
  }

  return (
    <CommonCard
      title="Ranking Checker"
      className="prc-dashboard-stats-card-wrapper"
    >
      <Choose>
        <When condition={isLoading}>
          <Spin className="spin-loader" />
        </When>
        <When condition={!productsCount}>
          <div className="bear-pad-16 prc-dashboard-stats-row prc-dashboard-stats-wistia">
            <div
              className={`wistia_embed wistia_async_${'sm3petdhc6'} videoFoam=true`}
            >
              &nbsp;
            </div>
          </div>
          <Row className="prc-dashboard-stats-row prc-dashboard-stats-add-product-row">
            <Button
              size="middle"
              type="primary"
              className="prc-dashboard-stats-button"
              onClick={() =>
                history.push(
                  `${RESEARCH_PRODUCT_RANKING_CHECKER_ROUTE}?${QUERY_NO_PRODUCT_USER}`,
                )
              }
            >
              Product toevoegen
            </Button>
          </Row>
        </When>
        <Otherwise>
          <Row className="prc-dashboard-stats-header bear-flex-ac">
            <Col span={12}>
              <Radio.Group
                value={tabOption}
                onChange={(event) => setTabOption(event.target.value)}
              >
                {PRODUCT_DASHBOARD_TABS.map((tab) => {
                  return (
                    <Radio.Button key={tab.value} value={tab.value}>
                      {tab.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col offset={6} span={3} className="bear-text-c">
              {NL_FLAG}
            </Col>
            <Col span={3} className="bear-text-c">
              {BE_FLAG}
            </Col>
          </Row>
          {getProducts()}
        </Otherwise>
      </Choose>
    </CommonCard>
  );
}

export default ProductDashboardStats;
