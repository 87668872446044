import React, { useEffect, useState } from 'react';
import { Badge, Col, Radio, Row, Spin } from 'antd';

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { Selection } from '../../types';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import {
  getProfit,
  getRevenue,
  getProductsQuantity,
} from '../../../services/financialReportingService/api';
import { formatNumberToCurrency } from '../../../../utilities';
import CommonCard from '../../../components/commonCard';

interface CountryDonutProps {
  selection: Selection;
}

function CountryDonut({ selection }: CountryDonutProps) {
  const REVENUE_TAB = 'revenue';
  const PROFIT_TAB = 'profit';
  const PRODUCTS_SOLD_TAB = 'productsSold';

  const [isLoading, setIsLoading] = useState(false);
  const [profit, setProfit] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [productsSold, setProductsSold] = useState([]);
  const [selectedTab, setSelectedTab] = useState(REVENUE_TAB);

  const [nlValue, setNlValue] = useState(0);
  const [beValue, setBeValue] = useState(0);

  const [nlPerc, setNlPerc] = useState(0);
  const [bePerc, setBePerc] = useState(0);

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['NL', 'BE'],
    colors: ['#6A75F3', '#AB47BC'],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
      },
    },
  };

  const tabs = [
    {
      value: REVENUE_TAB,
      label: 'Omzet',
    },

    {
      value: PROFIT_TAB,
      label: 'Winst',
    },
    {
      value: PRODUCTS_SOLD_TAB,
      label: 'Verkocht',
    },
  ];

  function setValues(data) {
    const nl = data.find((r) => r.country === 'NL')?.value || 0;
    const be = data.find((r) => r.country === 'BE')?.value || 0;
    const total = nl + be;

    setNlValue(nl);
    setBeValue(be);

    if (nl === 0 || be === 0) {
      setNlPerc(0);
      setBePerc(0);
      return;
    }

    setNlPerc(Math.round((nl / total) * 100));
    setBePerc(Math.round((be / total) * 100));
  }

  useEffect(() => {
    if (selectedTab === PROFIT_TAB) {
      setValues(profit);
      return;
    }
    if (selectedTab === REVENUE_TAB) {
      setValues(revenue);
      return;
    }
    if (selectedTab === PRODUCTS_SOLD_TAB) {
      setValues(productsSold);
    }
  }, [selectedTab, profit, revenue, productsSold]);

  useEffect(() => {
    if (selection == null) return;

    setIsLoading(true);

    Promise.all([
      getProfit(selection, 'country'),
      getRevenue(selection, 'country'),
      getProductsQuantity(selection, 'country'),
    ])
      .then(([profitResponse, revenueResponse, productsSoldResponse]) => {
        setProfit(profitResponse.data);
        setRevenue(revenueResponse.data);
        setProductsSold(productsSoldResponse.data);
      })
      .catch(captureErrorAndShowMessage)
      .finally(() => {
        setIsLoading(false);
      });
  }, [selection]);

  if (isLoading) {
    return (
      <CommonCard title="Nederland vs België">
        <div className="graph-loading">
          <Spin />
        </div>
      </CommonCard>
    );
  }

  return (
    <CommonCard title="Nederland vs België">
      <Radio.Group
        value={selectedTab}
        onChange={(event) => setSelectedTab(event.target.value)}
      >
        {tabs.map((item) => {
          return (
            <Radio.Button key={item.value} value={item.value}>
              {item.label}
            </Radio.Button>
          );
        })}
      </Radio.Group>
      <div className="bear-mar-t-20 bear-mar-b-20">
        <Row>
          <Col span={10}>
            <ReactApexChart
              width="100%"
              options={options}
              series={[nlValue, beValue]}
              type="donut"
            />
          </Col>
          <Col span={7}>
            <Badge color="#6A75F3" text="Nederland" />
            <p className="bear-bold-title">
              {selectedTab === PRODUCTS_SOLD_TAB
                ? nlValue
                : formatNumberToCurrency(nlValue)}
            </p>
            <p className="bear-light-help-text">{nlPerc}%</p>
          </Col>
          <Col span={7}>
            <Badge color="#AB47BC" text="België" />
            <p className="bear-bold-title">
              {selectedTab === PRODUCTS_SOLD_TAB
                ? beValue
                : formatNumberToCurrency(beValue)}
            </p>
            <p className="bear-light-help-text">{bePerc}%</p>
          </Col>
        </Row>
      </div>
    </CommonCard>
  );
}

export default CountryDonut;
