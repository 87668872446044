import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Row, Col, Radio, Badge, Spin } from 'antd';
import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';

import { STORE_COUNTRY_LABELS } from '../../constants';
import { formatAmount, getGeographicalData, isEmpty } from '../../utilities';
import GenericCard from '../../../../components/bearCards/genericCard';

import './style.scss';
import { Retailer } from '../../../../reducers/profileReducer';

const chartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
};

function GeographicMetricsCard({
  title,
  tabs,
  isLoading,
  defaultTab,
  statistics,
  selectedStore = {},
}) {
  const [tabOption, setTabOption] = useState(defaultTab);
  const currentTab = tabs.filter((item) => item.value === tabOption)[0];

  let currentStatistics = statistics[tabOption] || {};
  let { geographical } = currentStatistics;

  if (!isEmpty(selectedStore) && Array.isArray(statistics)) {
    [currentStatistics, geographical] = getGeographicalData(
      statistics,
      tabOption,
      selectedStore as Retailer,
    );
  }

  function getGraphData() {
    if (geographical && Object.keys(geographical).length) {
      return {
        labels: STORE_COUNTRY_LABELS,
        datasets: [
          {
            data: [
              (geographical.NL && geographical.NL.value.toFixed(2)) || 0,
              (geographical.BE && geographical.BE.value.toFixed(2)) || 0,
            ],
            backgroundColor: ['#6A75F3', '#AB47BC'],
            hoverOffset: 4,
          },
        ],
      };
    }
    return {
      labels: [''],
      datasets: [
        {
          data: [100],
          hoverOffset: 4,
          backgroundColor: ['#ECEFF1'],
        },
      ],
    };
  }

  function getValue(value) {
    const isAmount = currentTab.is_amount;
    const isPercent = currentTab.is_percent;
    if (typeof value !== 'undefined') {
      if (isAmount && typeof value !== 'undefined') {
        return formatAmount(value);
      }
      if (isPercent) {
        return `${formatAmount(value, '')}%`;
      }
    }
    return value || '-';
  }

  function getPercentageValue(metric) {
    let percentageValue = 0;
    const totalValue = currentStatistics.total;
    if (metric && totalValue) {
      percentageValue = (metric.value / totalValue) * 100;
    }
    return `${formatAmount(percentageValue, '')}%`;
  }

  return (
    <GenericCard
      title={title}
      className="geographical-data-card__wrapper bear-graph-card"
    >
      <Choose>
        <When condition={isLoading}>
          <Spin className="graph-loader" />
        </When>
        <Otherwise>
          <Radio.Group
            value={tabOption}
            onChange={(event) => setTabOption(event.target.value)}
          >
            {tabs.map((item) => {
              return (
                <Radio.Button key={item.value} value={item.value}>
                  {item.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <div className="geographical-data-card">
            <Row gutter={32} justify="center">
              <Col className="geographical-chart" lg={8} md={8} sm={8} xs={24}>
                <Doughnut data={getGraphData()} options={chartOptions} />
              </Col>
              <Col
                lg={16}
                md={16}
                sm={16}
                xs={24}
                className="geographical-chart-statistics"
              >
                <Row gutter={32}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div>
                      <Badge color="#6A75F3" text="NL" />
                      <p className="bear-bold-title">
                        {(geographical &&
                          geographical.NL &&
                          getValue(geographical.NL.value)) ||
                          '-'}
                      </p>
                      <If
                        condition={
                          geographical && !currentTab.hide_split_percentage
                        }
                      >
                        <p className="bear-light-help-text">
                          {getPercentageValue(geographical.NL)}
                        </p>
                      </If>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div>
                      <Badge color="#AB47BC" text="BE" />
                      <p className="bear-bold-title">
                        {(geographical &&
                          geographical.BE &&
                          getValue(geographical.BE.value)) ||
                          '-'}
                      </p>
                      <If
                        condition={
                          geographical && !currentTab.hide_split_percentage
                        }
                      >
                        <p className="bear-light-help-text">
                          {getPercentageValue(geographical.BE)}
                        </p>
                      </If>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Otherwise>
      </Choose>
    </GenericCard>
  );
}

export default GeographicMetricsCard;
