export const initialState = {
  tokens: [],
  parentToken: null,
  activeToken: null,
  adminToken: null,
};

const SET_TOKENS = 'EVENT/SET_TOKENS';
const SET_PARENT_TOKEN = 'EVENT/SET_PARENT_TOKEN';
const SET_ACTIVE_TOKEN = 'EVENT/SET_ACTIVE_TOKEN';
const SET_ADMIN_TOKEN = 'EVENT/SET_ADMIN_TOKEN';
const RESET_ALL_TOKENS = 'EVENT/RESET_ALL_TOKENS';

export const setTokens = (data) => {
  return {
    type: SET_TOKENS,
    payload: data,
  };
};

export const setParentToken = (data) => {
  return {
    type: SET_PARENT_TOKEN,
    payload: data,
  };
};

export const setActiveToken = (data) => {
  return {
    type: SET_ACTIVE_TOKEN,
    payload: data,
  };
};

export const setAdminToken = (data) => {
  return {
    type: SET_ADMIN_TOKEN,
    payload: data,
  };
};

export const resetAllTokens = () => {
  return {
    type: RESET_ALL_TOKENS,
  };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const tokensReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKENS:
      return { ...state, tokens: action.payload };
    case SET_PARENT_TOKEN:
      return { ...state, parentToken: action.payload };
    case SET_ACTIVE_TOKEN:
      return { ...state, activeToken: action.payload };
    case SET_ADMIN_TOKEN:
      return { ...state, adminToken: action.payload };
    case RESET_ALL_TOKENS:
      return initialState;
    default:
      return state;
  }
};

export default tokensReducer;
