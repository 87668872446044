/*eslint-disable*/
import { useState, useEffect } from 'react';

const getDeviceConfig = (width) => {
  let breakpoint = '2xlg';
  if (width <= 320) {
    breakpoint = 'xs';
  } else if (width > 320 && width <= 576) {
    breakpoint = 'sm';
  } else if (width > 576 && width <= 992) {
    breakpoint = 'md';
  } else if (width > 992 && width <= 1200) {
    breakpoint = 'lg';
  } else if (width > 1200 && width <= 1366) {
    breakpoint = 'xlg';
  }
  return breakpoint;
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  const calcInnerWidth = function () {
    setBrkPnt(getDeviceConfig(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
