import React, { useEffect, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import { If } from 'tsx-control-statements/components';
import { Link } from 'react-router-dom';
import './style.scss';
import { getMenu } from '../../../routes/utilities';
import { IMAGE_STATIC_PATH } from '../../../../constants';
import { useSubscription } from '../../../services/subscriptionService/api';
import { UserProfileInterface } from '../../../../reducers/profileReducer';
import { INDEX_ROUTE } from '../../../routes';
import { getMenuItem } from '../../../utilities/app';

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  zIndex: 999,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

function AppSidebar({
  isCollapsed,
  selectedMenuItemKey,
  profileData,
  onMenuClick,
  onSideBarTriggerClick,
}: {
  isCollapsed: boolean;
  selectedMenuItemKey: string;
  profileData: UserProfileInterface;
  onMenuClick: () => void;
  onSideBarTriggerClick: () => void;
}) {
  const { subscription } = useSubscription();

  const menu = useMemo(() => {
    return getMenu(profileData, subscription);
  }, [subscription]);

  useEffect(() => {
    const element = document.getElementsByClassName('app-layout')[0];
    if (element) {
      if (isCollapsed) {
        element.classList.add('collapsed');
      } else {
        element.classList.remove('collapsed');
      }
    }
  }, [isCollapsed]);

  const items = menu.map((menuItem) => {
    return getMenuItem(
      menuItem.title,
      menuItem.key,
      null,
      menuItem.items.map((item) =>
        getMenuItem(
          <Link
            to={{ pathname: item.path }}
            target={item.target ? '_blank' : undefined}
          >
            {item.title}
          </Link>,
          item.key,
          <>
            <If condition={React.isValidElement(item.icon)}>
              <i className="menu-item-icon">{item.icon}</i>
            </If>
            <If condition={!React.isValidElement(item.icon)}>
              <i className={`menu-item-icon ${item.icon}`} />
              <span>
                {item.rightIcon && item.rightIcon(profileData, subscription)}
              </span>
            </If>
          </>,
        ),
      ),
      'group',
    );
  });

  return (
    <Sider
      style={siderStyle}
      className="app-sidebar"
      width={250}
      trigger={null}
      collapsible
      collapsedWidth={0}
      collapsed={isCollapsed}
      onCollapse={() => onSideBarTriggerClick()}
    >
      <div className="logo-container bear-flex-sb bear-flex-ac">
        <Link to={INDEX_ROUTE}>
          <img
            alt="Boloo Company Logo"
            src={`${IMAGE_STATIC_PATH}/boloo_color_logo.png`}
            className="company-logo"
            onClick={() => onMenuClick()}
          />
        </Link>
        <i className="fad icon-bars" onClick={() => onSideBarTriggerClick()} />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selectedMenuItemKey]}
        onClick={() => onMenuClick()}
        items={items}
      />
    </Sider>
  );
}

export default AppSidebar;
