import React from 'react';

import { AppHeaderTitleConsumer } from '../context/appHeaderTitle';
import { setAppHeaderTitle as titleSetter } from '../reducers/appHeaderTitleReducer';

function AppHeaderTitleHook(BaseComponent) {
  return function AppHeaderTitle(props) {
    const [appHeaderTitleConsumer, dispatch] = AppHeaderTitleConsumer();
    const { title } = appHeaderTitleConsumer;

    function setAppHeaderTitle(data) {
      dispatch(titleSetter(data));
    }

    return (
      <BaseComponent
        {...props}
        title={title}
        setTitle={(data) => setAppHeaderTitle(data)}
      />
    );
  };
}

export default AppHeaderTitleHook;
