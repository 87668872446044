import React, { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Spin, Button, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatNumberToCurrency } from '../../../../utilities';
import './style.scss';
import {
  getCostsAndCorrections,
  getInvoices,
  syncInvoice,
} from '../../../services/financialReportingService/api';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import CommonCard from '../../../components/commonCard';
import CostByCategoryTableTitle from '../CostByCategoryTableTitle';
import { GENERIC_ERROR_MSG } from '../../../../constants';
import { ROW_GUTTER } from '../../../constants';

interface CostItem {
  category: string;
  value: number | string;
}

interface CostsAndCorrections {
  costs: CostItem[];
  corrections: CostItem[];
}
interface Invoice {
  invoice_id: string;
  start_date: string;
  end_date: string;
  invoice_date: string;
  is_synced: boolean;
}

const createColumns = (title: string) => [
  {
    title,
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Totaal',
    dataIndex: 'value',
    key: 'value',
    render: (text: number | string, record: CostItem) => {
      if (record.category === 'Sponsored Products' && text === 'Coming Soon') {
        return <Link to="/settings/store">Coming Soon</Link>;
      }
      return typeof text === 'number'
        ? formatNumberToCurrency(Math.abs(text))
        : text;
    },
    align: 'right' as const,
  },
];
const costsColumns = createColumns('Kosten');
const correctionColumns = createColumns('Kosten correcties');

function TableSummary({ total, title }: { total: number; title: string }) {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} className="bear-bold-text">
        {title}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} align="right">
        {formatNumberToCurrency(total)}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
}

function calculateTotal(data: CostItem[]): number {
  return data.reduce((sum, item) => {
    if (typeof item.value === 'number') {
      return sum + Math.abs(item.value);
    }
    return sum;
  }, 0);
}

function CostsTable({ data, title }: { data: CostItem[]; title: string }) {
  const total = calculateTotal(data);

  return (
    <Table
      columns={title === 'Totaal kosten' ? costsColumns : correctionColumns}
      dataSource={data}
      pagination={false} // eslint-disable-next-line
      summary={() => <TableSummary total={total} title={title} />}
    />
  );
}

interface CostsByCategoryTableProps {
  dateRange: [number, number] | null;
}

function CostsByCategoryTable({ dateRange }: CostsByCategoryTableProps) {
  const [costsAndCorrections, setCostsAndCorrections] =
    useState<CostsAndCorrections | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<Error | null>(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState<boolean>(false);
  const [bolInvoices, setBolInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (dateRange) {
      setIsLoading(true);
      getCostsAndCorrections(dateRange[0], dateRange[1])
        .then((response) => {
          setCostsAndCorrections(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          captureErrorAndShowMessage(error);
          setErrorState(error);
          setIsLoading(false);
        });
    }
  }, [dateRange]);

  function openInvoiceModal() {
    getInvoices().then((response) => {
      setBolInvoices(response.data);
      setInvoiceModalOpen(true);
    });
  }

  function startInvoiceSync(invoiceId: string) {
    syncInvoice(invoiceId)
      .then(() => {
        message.success(
          'De factuur wordt gesynchroniseerd! Binnen enkele minuten tot een uur zal de data beschikbaar zijn op je dashboard.',
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.warning(
            'Deze factuur wordt al gesynchroniseerd. Probeer het later opnieuw.',
          );
          return;
        }
        if (error.response.status === 404) {
          message.error('Deze factuur bestaat niet');
          return;
        }
        message.error(GENERIC_ERROR_MSG);
      });
  }

  if (errorState) {
    return <Card title="Kosten per categorie">Error loading data.</Card>;
  }

  if (isLoading || !costsAndCorrections) {
    return (
      <CommonCard title="Kosten per categorie">
        <div className="graph-loading">
          <Spin />
        </div>
      </CommonCard>
    );
  }

  return (
    <Card title={<CostByCategoryTableTitle />}>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={12} md={12}>
          <CostsTable data={costsAndCorrections.costs} title="Totaal kosten" />
        </Col>
        <Col xs={24} sm={12} md={12}>
          <CostsTable
            data={costsAndCorrections.corrections}
            title="Totaal correcties"
          />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24} className="bear-text-c">
          <Button
            className="bear-mar-t-16"
            type="link"
            onClick={() => openInvoiceModal()}
          >
            Welke facturen zijn gesynchroniseerd?
          </Button>
          <Modal
            width="90%" // Adjust width to 90% on mobile for better viewing
            title="Bol facturen"
            open={invoiceModalOpen}
            onCancel={() => setInvoiceModalOpen(false)}
            footer={null}
          >
            <Table
              dataSource={bolInvoices}
              columns={[
                {
                  title: 'Factuur nummer',
                  key: 'invoice_id',
                  dataIndex: 'invoice_id',
                },
                {
                  title: 'Periode',
                  key: 'period',
                  render(record) {
                    return (
                      <>
                        {dayjs(record.start_date).format('DD MMM YYYY')}
                        <br />
                        {dayjs(record.end_date).format('DD MMM YYYY')}
                      </>
                    );
                  },
                },
                {
                  title: 'Synchronisatie',
                  key: 'is_synced',
                  render(record: Invoice) {
                    if (record.is_synced) {
                      return (
                        <>
                          <i className="fad icon-check-circle" />{' '}
                          Gesynchroniseerd
                        </>
                      );
                    }
                    return (
                      <>
                        <i className="fad icon-question-circle" /> Niet
                        gesynchroniseerd
                      </>
                    );
                  },
                },
                {
                  title: 'Acties',
                  key: 'actions',
                  render: (record: Invoice) => {
                    return (
                      <Button
                        type="link"
                        onClick={() => startInvoiceSync(record.invoice_id)}
                      >
                        {record.is_synced
                          ? 'Opnieuw synchroniseren'
                          : 'Start synchronisatie'}
                      </Button>
                    );
                  },
                },
              ]}
            />
          </Modal>
        </Col>
      </Row>
    </Card>
  );
}

export default CostsByCategoryTable;
