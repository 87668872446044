import React, { ReactNode } from 'react';
import { Card } from 'antd';
import { If } from 'tsx-control-statements/components';

function GenericCard({
  title,
  extra,
  description,
  className = '',
  children,
  subTitle,
}: {
  title?: ReactNode | string;
  extra?: ReactNode;
  description?: string;
  className?: string;
  children?: ReactNode;
  subTitle?: string;
}) {
  return (
    <Card
      bordered={false}
      className={`bear-card ${className}`}
      title={
        <If condition={title}>
          <div>
            {title}
            <If condition={subTitle}>
              <div className="bear-light-help-text">{subTitle}</div>
            </If>
          </div>
        </If>
      }
      extra={extra}
    >
      <If condition={description}>
        <p className="bear-light-regular-text">{description}</p>
      </If>

      {children}
    </Card>
  );
}

export default GenericCard;
