/*eslint-disable*/
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import campaignReducer from '../reducers/campaignReducer';
import defaultCampaignReducer from '../reducers/defaultCampaignReducer';
import campaignListReducer from '../reducers/campaignListReducer';
import mergeTagsReducer from '../reducers/mergeTagsReducer';
import accountsReducer from '../reducers/accountsReducer';
import tokensReducer from '../reducers/tokensReducer';
import appUpdateReducer from '../reducers/appUpdateReducer';

const rootReducer = combineReducers({
  campaignReducer,
  defaultCampaignReducer,
  campaignListReducer,
  mergeTagsReducer,
  accountsReducer,
  tokensReducer,
  appUpdateReducer,
});
const persistedReducer = persistReducer(
  { key: 'root', storage, blacklist: ['appUpdateReducer'] },
  rootReducer,
);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store, enhancer);

export type RootState = ReturnType<typeof rootReducer>;
