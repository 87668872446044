export const CAMPAIGN_SORT_OPTIONS = [
  {
    label: 'Afgeleverd: Laag naar Hoog',
    value: 'delivered',
  },
  {
    label: 'Afgeleverd: Hoog naar Laag',
    value: '-delivered',
  },
  {
    label: 'Opent: Laag naar Hoog',
    value: 'open',
  },
  {
    label: 'Opent: Hoog naar Laag',
    value: '-open',
  },
  {
    label: 'Klikken: Laag naar Hoog',
    value: 'click',
  },
  {
    label: 'Klikken: Hoog naar Laag',
    value: '-click',
  },
  {
    label: 'Gestart op: A - Z',
    value: 'created_at',
  },
  {
    label: 'Gestart op: Z - A',
    value: '-created_at',
  },
  {
    label: 'Naam: A - Z',
    value: 'name',
  },
  {
    label: 'Naam: Z - A',
    value: '-name',
  },
];

export const CAMPAIGN_STATUS_OPTIONS = [
  {
    label: 'Allemaal',
    value: '',
  },
  {
    label: 'Actief',
    value: 'true',
  },
  {
    label: 'Inactief',
    value: 'false',
  },
];

export const TRIGGERS = [
  {
    value: 'on_ordered',
    title: 'Bestelling',
    helpText: 'Wanneer een klant een artikel bestelt',
  },
  {
    value: 'on_shipped',
    title: 'Verzending',
    helpText: 'Wanneer bol.com de verzending start',
  },
  {
    value: 'on_delivered',
    title: 'Levering',
    helpText:
      'Verwachte levertijd voor een bestelling zoals vermeld op bol platform',
  },
  {
    value: 'on_request',
    title: 'Retour aangevraagd',
    helpText: 'Wanneer een retour is aangevraagd door klant',
  },
  {
    value: 'on_refund',
    title: 'Retour behandeld',
    helpText: 'Wanneer een retour succesvol is verwerkt',
  },
];

export const CAMPAIGN_EMAIL_EVENT_KEY = 'event_type';
export const CAMPAIGN_EMAIL_COUNT_KEY = 'total_unique_count';
export const CAMPAIGN_DETAIL_EMAIL_EVENT_KEY = 'event';
export const CAMPAIGN_DETAIL_EMAIL_COUNT_KEY = 'unique_count';

export const DELIVERED_EMAIL_STATUS = 'delivered';
export const OPEN_EMAIL_STATUS = 'open';
export const CLICKED_EMAIL_STATUS = 'click';
export const EMAIL_STATUSES = [
  DELIVERED_EMAIL_STATUS,
  OPEN_EMAIL_STATUS,
  CLICKED_EMAIL_STATUS,
];

export const LABELS_ARRAY = [
  'Afgeleverd',
  'Uniek geopende mails',
  'Unieke clicks',
];
export const ADDED_EMAIL_TASK_STATUS = 'task_added';
export const COMPLETED_EMAIL_TASK_STATUS = 'task_completed';

export const DEFAULT_CAMPAIGNS_SORT = '-delivered';
export const DEFAULT_CAMPAIGNS_STATUS = '';

export const SELECT_FR_TEMPLATE = 'select';
export const AUTO_TRANSLATE_FR_TEMPLATE = 'translate';
export const NO_EMAIL_FR_TEMPLATE = 'noEmail';

export const FR_EMAIL_OPTIONS = [
  {
    value: SELECT_FR_TEMPLATE,
    title: 'Selecteer sjabloon',
    icon: 'template',
    helpText: '',
  },
  {
    value: AUTO_TRANSLATE_FR_TEMPLATE,
    title: 'Automatisch vertalen',
    icon: 'language',
    helpText: 'Nederlandse e-mail wordt automatisch vertaald',
  },
  {
    value: NO_EMAIL_FR_TEMPLATE,
    title: 'Geen e-mail',
    icon: 'no-email',
    helpText: 'Er wordt geen e-mail verzonden naar klanten in België (FR)',
  },
];

export const DUTCH_LANGUAGE_CODE = 'DU';
export const FRENCH_LANGUAGE_CODE = 'FR';

export const NETHERLANDS_FLAG = '🇳🇱';
export const FRANCE_FLAG = '🇫🇷';

// Refer: https://www.iana.org/assignments/media-types/media-types.xhtml#text
export const XLS_FILES = '.xls,.xlsx,.ods,.numbers';
export const PPT_FILES = '.odp,.ppt,.pptx,.pps,.key';
export const DOC_FILES =
  '.doc,.docx,.rtf,.odt,.ott,.pdf,.pub,.pages,.mobi,.epub,.txt';
export const MS_FILES =
  'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-word.document.macroEnabled.12,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const FILE_MIME_TYPES = 'audio/*,video/*,image/*,text/*,message/*';

export const EMAIL_ATTACHMENT_FILE_TYPES =
  XLS_FILES + PPT_FILES + DOC_FILES + MS_FILES + FILE_MIME_TYPES;
