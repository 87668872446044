import React from 'react';
import { Card, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { When, Choose, Otherwise } from 'tsx-control-statements/components';

import './style.css';
import { BOLOO_AI_BASE_ROUTE } from '../../../routes';

function Title({ isBaseRoute = false }: { isBaseRoute: boolean }) {
  const history = useHistory();
  return (
    <Card bordered={false} className="title">
      <Row
        justify="space-between"
        style={{ alignItems: 'center', background: '#fff' }}
      >
        <Col span={10}>
          <Choose>
            <When condition={!isBaseRoute}>
              <b
                className="ant-card-head-title"
                onClick={() => history.replace(BOLOO_AI_BASE_ROUTE)}
              >
                <ArrowLeftOutlined />
                <span className="title-text">Terug naar tools</span>
              </b>
            </When>
            <Otherwise>
              <h1 className="boloo-ai-welcome-header">
                Welkom bij Boloo AI! 🤖
              </h1>
              <p>Groei jouw e-commerce business met Boloo AI</p>
            </Otherwise>
          </Choose>
        </Col>
        {/* See https://app.asana.com/0/1204914767749750/1205210050128770/f */}
        {/* <Col span={14} style={{ textAlign: 'right' }} flex="auto"> */}
        {/*  <div className="wrapper"> */}
        {/*    <If condition={!screens.xs}> */}
        {/*      <TokensCountBox /> */}
        {/*    </If> */}
        {/*  </div> */}
        {/* </Col> */}
      </Row>
    </Card>
  );
}

export default Title;
