export const DEFAULT_COUNTRIES = [
  {
    value: 'NL',
    label: 'Nederland (NL)',
    icon: '🇳🇱',
  },
  {
    value: 'BE',
    label: 'België (BE)',
    icon: '🇧🇪',
  },
];

export const DEFAULT_COUNTRY = DEFAULT_COUNTRIES[0].value;

export const STORE_COUNTRIES = [
  {
    value: '',
    label: 'Alle Landen',
  },
  ...DEFAULT_COUNTRIES,
];

export const STORE_COUNTRY_LABELS = ['NL', 'BE'];

// Retailer order invoice statuses
export const RETAILER_ORDER_INVOICE_NOT_SENT = 'invoice_not_sent';
export const RETAILER_ORDER_INVOICE_SENT = 'invoice_sent';
export const RETAILER_ORDER_INVOICE_UPLOADED = 'invoice_uploaded';
