import { AxiosError } from 'axios';

export const MAX_RETRIES_FOR_BACKOFF_NETWORK_ERROR = 6;
export const MAX_RETRIES_FOR_BACKOFF_ANY_ERROR = 3;

export class ApiAxiosError<T> extends AxiosError {
  static readonly PROCESSING = 'PROCESSING';

  data?: T;
}
