import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LoggedInRoute } from './utilities';
import { PLANS_ROUTE, PLANS_PAYMENT_ROUTE } from '../routes';
import ProfileHook from '../../hooks/profile';

const PlansList = lazy(
  () => import(/* webpackPrefetch: true */ '../planManagement/list'),
);

const PlansPayment = lazy(
  () => import(/* webpackPrefetch: true */ '../planManagement/payment'),
);

const ReactivateSubAccount = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../planManagement/reactivateSubAccount'
    ),
);

function PlansRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <Switch>
      <Suspense fallback={<Spin className="app-spinner" />}>
        {profileData.is_parent ? (
          <LoggedInRoute
            exact
            path={PLANS_ROUTE}
            component={PlansList}
            isAuthenticated={isAuthenticated}
          />
        ) : (
          <LoggedInRoute
            exact
            path={PLANS_ROUTE}
            component={ReactivateSubAccount}
            isAuthenticated={isAuthenticated}
          />
        )}
        <LoggedInRoute
          exact
          path={PLANS_PAYMENT_ROUTE}
          component={PlansPayment}
          isAuthenticated={isAuthenticated}
        />
      </Suspense>
    </Switch>
  );
}

export default ProfileHook(PlansRoute);
