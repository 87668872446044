import React from 'react';
import { Tooltip } from 'antd';
import { TOOLTIP_COLOUR } from '../../../../constants';

function CostByCategoryTableTitle() {
  const toolTipText = (
    <p>
      Lees{' '}
      <a href="https://help.boloo.co/nl/articles/9399165-boloo-s-dashboard-beta">
        hier
      </a>{' '}
      alles over de kosten per categorie
    </p>
  );

  return (
    <Tooltip
      placement="right"
      color={TOOLTIP_COLOUR}
      className="bear-has-tooltip"
      title={toolTipText}
    >
      <div className="ant-card-head-title">Kosten per categorie</div>
    </Tooltip>
  );
}

export default CostByCategoryTableTitle;
