import React from 'react';
import { Col, Row } from 'antd';
import { Selection } from '../../types';
import useCampaignStatistics, {
  CampaignEventStatistics,
} from '../../../services/campaignStatisticsService/api';
import CommonCard from '../../../components/commonCard';
import CommonStatistic from '../../../components/commonStatistic';

import './style.scss';

const CAMPAIGN_STATISTIC_ROWS: {
  [key in CampaignEventStatistics['event_type']]: string;
} = {
  delivered: 'AVGELEVERD',
  open: 'GEOPENDE MAILS',
  click: 'CLICKS',
};
function CampaignStatistics({ selection }: { selection: Selection }) {
  const { campaignStatistics, isLoading } = useCampaignStatistics(selection);

  return (
    <CommonCard title="Campagne statistieken" className="campaign-statistics">
      {Object.entries(CAMPAIGN_STATISTIC_ROWS).map(([type, title], index) => (
        <Row key={index}>
          <Col>
            <CommonStatistic
              title={title}
              loading={isLoading}
              value={campaignStatistics.reduce(
                (total, stats) =>
                  total +
                  stats.data.reduce(
                    (totalForStat, stat) =>
                      stat.event_type === type
                        ? totalForStat + stat.total_count
                        : totalForStat,
                    0,
                  ),
                0,
              )}
              precision={0}
            />
          </Col>
        </Row>
      ))}
    </CommonCard>
  );
}
export default CampaignStatistics;
