import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  INDEX_ROUTE,
  REGISTER_ROUTE,
  REGISTER_PAYMENT_ROUTE,
  REGISTER_BILLING_ROUTE,
  REGISTER_YEAR_DEAL_ROUTE,
} from '../routes';
import ProfileHook from '../../hooks/profile';
import { useSubscription } from '../services/subscriptionService/api';

const Register = lazy(
  () => import(/* webpackPrefetch: true */ '../accountManagement/register'),
);

const RegisterBilling = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../accountManagement/register/billing'),
);

const RegisterPayment = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../accountManagement/register/payment'),
);

const RegisterYearDeal = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../accountManagement/register/yeardeal'
    ),
);

function RegisterRouteService({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          if (rest.path === REGISTER_YEAR_DEAL_ROUTE) {
            return <Component {...props} />;
          }

          if (rest.isActiveSubscription && rest.isRegistrationComplete) {
            return <Redirect to={INDEX_ROUTE} />;
          }
          if (rest.isActiveSubscription) {
            if (rest.path === REGISTER_BILLING_ROUTE) {
              return <Component {...props} />;
            }
            return <Redirect to={REGISTER_BILLING_ROUTE} />;
          }
          if (rest.path === REGISTER_PAYMENT_ROUTE) {
            return <Component {...props} />;
          }
          return <Redirect to={REGISTER_PAYMENT_ROUTE} />;
        }
        if (rest.path === REGISTER_ROUTE) {
          return <Component {...props} />;
        }
        return <Redirect to={REGISTER_ROUTE} />;
      }}
    />
  );
}

function RegisterRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;
  const { isActiveSubscription } = useSubscription(isAuthenticated);
  const isRegistrationComplete = isAuthenticated
    ? profileData.is_registration_complete
    : false;

  return (
    <Switch>
      <Suspense fallback={<Spin className="app-spinner" />}>
        <RegisterRouteService
          exact
          path={REGISTER_ROUTE}
          component={Register}
          isAuthenticated={isAuthenticated}
          isActiveSubscription={isActiveSubscription}
          isRegistrationComplete={isRegistrationComplete}
        />
        <RegisterRouteService
          exact
          path={REGISTER_PAYMENT_ROUTE}
          component={RegisterPayment}
          isAuthenticated={isAuthenticated}
          isActiveSubscription={isActiveSubscription}
          isRegistrationComplete={isRegistrationComplete}
        />
        <RegisterRouteService
          exact
          path={REGISTER_BILLING_ROUTE}
          component={RegisterBilling}
          isAuthenticated={isAuthenticated}
          isActiveSubscription={isActiveSubscription}
          isRegistrationComplete={isRegistrationComplete}
        />
        <RegisterRouteService
          exact
          path={REGISTER_YEAR_DEAL_ROUTE}
          component={RegisterYearDeal}
          isAuthenticated={isAuthenticated}
          isActiveSubscription={isActiveSubscription}
          isRegistrationComplete={isRegistrationComplete}
        />
      </Suspense>
    </Switch>
  );
}

export default ProfileHook(RegisterRoute);
