import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export enum PreselectPeriods {
  Custom,
  Today,
  Yesterday,
  Last7Days,
  Last30Days,
  Last90Days,
  LastMonth,
  ThisWeek,
  ThisMonth,
  ThisYear,
  Last180Days,
  Last365Days,
}

// Please make sure that you add startOf('day') and endOf('day') for the subtract ops. Not needed to chain 2 startOf or endOf
// See https://day.js.org/docs/en/manipulate/start-of
export const PreselectPeriodsDates: Record<
  PreselectPeriods,
  { label: string; fixed?: boolean; startDate?: Dayjs; endDate?: Dayjs }
> = {
  [PreselectPeriods.Custom]: {
    label: 'Custom',
  },
  [PreselectPeriods.Today]: {
    label: 'Vandaag',
    startDate: dayjs.utc().startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.Yesterday]: {
    label: 'Gisteren',
    startDate: dayjs.utc().subtract(1, 'day').startOf('day'),
    endDate: dayjs.utc().subtract(1, 'day').endOf('day'),
  },
  [PreselectPeriods.Last7Days]: {
    label: 'Laatste 7 dagen',
    startDate: dayjs.utc().subtract(6, 'day').startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.Last30Days]: {
    label: 'Laatste 30 dagen',
    startDate: dayjs.utc().subtract(29, 'day').startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.ThisWeek]: {
    label: 'Deze week',
    startDate: dayjs.utc().startOf('week'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.ThisMonth]: {
    label: 'Deze maand',
    startDate: dayjs.utc().startOf('month'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.LastMonth]: {
    label: 'Vorige maand',
    startDate: dayjs.utc().subtract(1, 'month').startOf('month').startOf('day'),
    endDate: dayjs.utc().subtract(1, 'month').endOf('month').endOf('day'),
  },
  [PreselectPeriods.Last90Days]: {
    label: 'Laatste 90 dagen',
    startDate: dayjs.utc().subtract(89, 'day').startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.ThisYear]: {
    label: 'Dit jaar',
    startDate: dayjs.utc().startOf('year'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.Last180Days]: {
    label: 'Laatste 180 dagen',
    startDate: dayjs.utc().subtract(179, 'day').startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
  [PreselectPeriods.Last365Days]: {
    label: 'Laatste 365 dagen',
    startDate: dayjs.utc().subtract(364, 'day').startOf('day'),
    endDate: dayjs.utc().endOf('day'),
  },
};

export const PreselectPeriodsDatesInOrder = [
  PreselectPeriods.Today,
  PreselectPeriods.Yesterday,
  PreselectPeriods.ThisWeek,
  PreselectPeriods.ThisMonth,
  PreselectPeriods.Last7Days,
  PreselectPeriods.Last30Days,
  PreselectPeriods.LastMonth,
  PreselectPeriods.Last90Days,
  PreselectPeriods.Last180Days,
  PreselectPeriods.Last365Days,
  PreselectPeriods.ThisYear,
];
