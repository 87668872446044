import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { useKBar } from 'kbar';

import { If } from 'tsx-control-statements/components';
import useLocalStorage from '../../../hooks/useLocalStorage';
import {
  BOLOO_FORWARDING_PAGE,
  BOLOO_FACEBOOK_COMMUNITY,
} from '../../../constants';
import AppHeaderDrowdown from '../../appHeaderDrowdown';
import {
  isImpersonating,
  sendTokenToChromeExtension,
  stopImpersonating,
} from '../../../../actions';

function SearchIcon() {
  return (
    <svg width="24" height="24" fill="none" aria-hidden="true">
      <path
        d="m19 19-3.5-3.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="11"
        cy="11"
        r="6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function AppHeader({
  title,
  isMobile,
  isCollapsed,
  hasFeatureVideo,
  onSideBarTriggerClick,
  onProductTourClick,
  helpDocLink,
}: {
  title: string;
  isMobile: boolean;
  isCollapsed: boolean;
  hasFeatureVideo: boolean;
  onSideBarTriggerClick: () => void;
  onProductTourClick: () => void;
  helpDocLink?: string;
}) {
  const parentToken = useSelector(
    (state: { tokensReducer: { parentToken: string } }) =>
      state.tokensReducer.parentToken,
  );
  const [isDarkMode, setDarkMode] = useLocalStorage('isDarkModeEnabled', false);
  const root = document.documentElement;
  const { query } = useKBar();

  useEffect(() => {
    sendTokenToChromeExtension(parentToken);
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      root.classList.add('dark-mode');
    }
  }, [isDarkMode]);

  function switchDarkMode() {
    setDarkMode(!isDarkMode);
    root.classList.toggle('dark-mode');
    root.classList.toggle('tranform-dark');
  }

  return (
    <div className="bear-flex-sb bear-flex-ac app-layout-header">
      <div className="app-layout-header-title">
        <If condition={isCollapsed}>
          <i
            role="presentation"
            className="fad icon-bars app-sidebar-trigger"
            onClick={() => onSideBarTriggerClick()}
          />
        </If>
        <div className="bear-bold-title page-title">{title}</div>
        <If condition={hasFeatureVideo}>
          <div
            role="presentation"
            onClick={() => onProductTourClick()}
            className="feature-tour-trigger bear-flex bear-flex-ac"
          >
            <i className="icon-video bear-hide-sm" />
            <span>Hoe werkt dit?</span>
          </div>
        </If>
        <If condition={!!helpDocLink}>
          <div
            role="presentation"
            onClick={() => window.open(helpDocLink, '_blank')}
            className="feature-tour-trigger bear-flex bear-flex-ac"
          >
            <i className="icon-video bear-hide-sm" />
            <span>Hoe werkt dit?</span>
          </div>
        </If>
      </div>
      <If condition={isImpersonating()}>
        <div
          className="app-layout-right-header-block"
          style={{ backgroundColor: '#e6f7ff' }}
        >
          <p>
            {' '}
            Je bent momenteel aan het impersonaten.{' '}
            <Button
              size="small"
              type="link"
              onClick={() => stopImpersonating()}
            >
              {' '}
              Klik hier{' '}
            </Button>{' '}
            om te stoppen.{' '}
          </p>{' '}
        </div>
      </If>

      <div className="app-layout-right-header-block">
        <div
          className="site-search-icon bear-light-text-400 bear-mar-b-4 bear-mar-r-16 bear-cp"
          onClick={query.toggle}
        >
          <SearchIcon />
        </div>
        <div
          className="bear-light-text-400 bear-mar-b-4 bear-mar-r-16 bear-cp"
          onClick={switchDarkMode}
        >
          {isDarkMode ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              width="18"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              width="18"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          )}
        </div>
        <a
          className="bear-flex bear-cp secondary no-border bear-flex-ac bear-mar-r-20"
          href={BOLOO_FORWARDING_PAGE}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fas icon-supplier" />
          <span className="bear-hide-xxl bear-hide-sm">Cargomate</span>
        </a>
        <div
          className="bear-light-text-400 bear-mar-r-16 bear-cp"
          id="beamer-trigger"
        >
          <i className="fas icon-bell" />
          <span className="bear-hide-xxl bear-hide-sm">Boloo Updates</span>
        </div>
        <a
          className="bear-flex bear-cp secondary bear-flex-ac no-border"
          href={BOLOO_FACEBOOK_COMMUNITY}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab icon-facebook" />
          <span className="bear-hide-xxl bear-hide-sm">Boloo Community</span>
        </a>
        <AppHeaderDrowdown />
      </div>
    </div>
  );
}

export default AppHeader;
