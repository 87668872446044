import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, Spin, Row, Col, Tooltip, Empty, Space } from 'antd';

import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';
import { DEFAULT_EMPTY_DESCRIPTION, TOOLTIP_COLOUR } from '../../constants';

import './style.scss';
import { ROW_GUTTER } from '../../retailer/constants';

function getAlignmentClass(item) {
  if (item.center) {
    return 'list-text-c';
  }
  if (item.right) {
    return 'list-text-r';
  }
  return '';
}

function BearInfiniteList({
  data,
  items,
  hasMore,
  isLoading,
  handeLoadMore,
  handleItemClick,
  footer = '',
  className = '',
  useWindow = false,
  emptyDescription = DEFAULT_EMPTY_DESCRIPTION,
  disableLookupKey = '',
  itemLoadingLookupKey = '',
  readOnly = false,
}: {
  data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  items: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  hasMore: boolean;
  isLoading: boolean;
  handeLoadMore?: () => void;
  handleItemClick?: (item: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  footer?: string;
  className?: string;
  useWindow?: boolean;
  emptyDescription?: string;
  disableLookupKey?: string;
  itemLoadingLookupKey?: string;
  readOnly?: boolean;
}) {
  const renderItem = (dataItem) =>
    items.map((item, index) => (
      <Col
        key={index}
        xxl={{
          order: index + 1,
          span: item.size,
        }}
        xl={{
          order: index + 1,
          span: item.size,
        }}
        lg={{
          order: index + 1,
          span: item.size,
        }}
        md={{
          order: index + 1,
          span: item.size,
        }}
        sm={{
          order: item.mobile ? item.mobile.order : index + 1,
          ...(item.mobile && item.mobile.span
            ? {
                span: item.mobile.span,
              }
            : {}),
        }}
        xs={{
          order: item.mobile ? item.mobile.order : index + 1,
          ...(item.mobile && item.mobile.span
            ? {
                span: item.mobile.span,
              }
            : {}),
        }}
        className={`bear-mar-b-sm-16 ${
          item.className || ''
        } ${getAlignmentClass(item)}`}
        style={{
          filter: dataItem.hidden_by_customer ? 'grayscale(100%)' : 'none',
        }}
      >
        <If condition={item.title}>
          <p className="bear-light-help-text bear-list-col-title">
            {typeof item.title === 'function' ? item.title() : item.title}
          </p>
        </If>
        {item.render(dataItem)}
      </Col>
    ));

  return (
    <div className="infinite-scroll-wrapper">
      <InfiniteScroll
        pageStart={0}
        threshold={300}
        initialLoad={false}
        useWindow={useWindow}
        className={`bear-list ${readOnly ? 'read-only' : ''} ${className}`}
        hasMore={!isLoading && hasMore}
        loadMore={() => {
          if (!isLoading && hasMore) {
            handeLoadMore();
          }
        }}
      >
        <List
          header={
            <Row
              gutter={{
                xxl: 24,
                xl: 24,
                lg: 24,
                md: 12,
                sm: 12,
                xs: 12,
              }}
            >
              {items.map((item) => {
                return (
                  <Col
                    key={item.key}
                    span={item.size}
                    className={getAlignmentClass(item)}
                  >
                    <Choose>
                      <When condition={item.tooltip}>
                        <Tooltip title={item.tooltip} color={TOOLTIP_COLOUR}>
                          <span className="bear-has-tooltip">
                            {typeof item.title === 'function'
                              ? item.title()
                              : item.title}
                          </span>
                        </Tooltip>
                      </When>
                      <Otherwise>
                        {typeof item.title === 'function'
                          ? item.title()
                          : item.title}
                      </Otherwise>
                    </Choose>
                  </Col>
                );
              })}
            </Row>
          }
          footer={footer}
          dataSource={data}
          renderItem={(item) => {
            return (
              <Spin
                spinning={
                  itemLoadingLookupKey && !!item[itemLoadingLookupKey]
                    ? item[itemLoadingLookupKey]
                    : false
                }
              >
                <List.Item
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  // @ts-ignore
                  key={item.id}
                  onClick={() => (handleItemClick ? handleItemClick(item) : '')}
                  className={
                    disableLookupKey && item[disableLookupKey]
                      ? 'item-disabled'
                      : ''
                  }
                >
                  <Row
                    gutter={{
                      xxl: 24,
                      xl: 24,
                      lg: 24,
                      md: 12,
                      sm: 12,
                      xs: 12,
                    }}
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    key={item.id}
                    className="bear-width bear-flex-ac"
                  >
                    {renderItem(item)}
                  </Row>
                </List.Item>
              </Spin>
            );
          }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <If condition={isLoading}>
              <Row gutter={ROW_GUTTER} justify="center">
                <Col flex={1}>
                  <Spin style={{ width: '100%' }} />
                </Col>
              </Row>
            </If>
            <If condition={!isLoading && !data.length}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>{emptyDescription}</span>}
              />
            </If>
          </Space>
        </List>
      </InfiniteScroll>
    </div>
  );
}

export default BearInfiniteList;
