import React from 'react';
import { Select } from 'antd';

import { FULFILMENT_METHODS } from '../../../constants';

const { Option } = Select;

function FulfilmentMethodSelector({
  selectedValue,
  onChange,
  className,
}: {
  selectedValue: string;
  onChange: (value: string) => void;
  className?: string;
}) {
  return (
    <Select
      size="large"
      optionLabelProp="label"
      value={selectedValue}
      className={`bear-min-input-width ${className}`}
      onChange={(value) => onChange(value)}
    >
      <Option key="" value="" label="Fulfilment: Alle">
        All
      </Option>
      {FULFILMENT_METHODS.map((item) => {
        return (
          <Option
            key={item.value}
            value={item.value}
            label={`Fulfilment: ${item.label}`}
          >
            {item.label}
          </Option>
        );
      })}
    </Select>
  );
}

export default FulfilmentMethodSelector;
