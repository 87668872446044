/*eslint-disable*/
import React, { useReducer, useContext, createContext } from 'react';

const AcademyCoursesContext = createContext();

export const AcademyCoursesProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <AcademyCoursesContext.Provider value={contextValue}>
      {children}
    </AcademyCoursesContext.Provider>
  );
};

export const AcademyCoursesConsumer = () => {
  const contextValue = useContext(AcademyCoursesContext);
  return contextValue;
};
