import {
  STORE_INVENTORY_PRODUCT_DETAIL_ROUTE,
  STORE_INVENTORY_PRODUCT_EDIT_ROUTE,
} from '../../routes';
import { StockFilter } from '../../services/productsService/api';

export const INVENTORY_COLS = [
  {
    label: 'Product',
    size: 6,
  },
  {
    label: 'Stock',
    size: 3,
  },
  {
    label: 'Stock Days',
    size: 3,
  },
  {
    label: 'Sold-out',
    size: 3,
  },
  {
    label: 'Selling Price',
    size: 3,
  },
  {
    label: 'Total Value',
    size: 3,
  },
  {
    label: 'Fullfilment',
    size: 2,
  },
];

export const EDIT_MENU_ITEM = 'edit';
export const PERFORMANCE_MENU_ITEM = 'performance';

export const PRODUCT_DETAIL_MENU_ITEMS = [
  {
    key: PERFORMANCE_MENU_ITEM,
    value: 'Prestaties',
    path: STORE_INVENTORY_PRODUCT_DETAIL_ROUTE,
  },
  {
    key: EDIT_MENU_ITEM,
    value: 'Bewerk productinformatie',
    path: STORE_INVENTORY_PRODUCT_EDIT_ROUTE,
  },
];

export const MAXIMUM_NOTIFICATION_COUNT = 3;
export const MAXIMUM_DYNAMIC_PRICES_COUNT = 3;

export const STOCK_STATUS_OPTIONS: Record<StockFilter, string> = {
  all: 'Alle producten',
  low_stock: 'Lage voorraad',
  zero_stock: 'Geen voorraad',
};
export const INVENTORY_SORT_OPTIONS = [
  {
    label: 'Product (A - Z)',
    value: 'title',
  },
  {
    label: 'Product (Z - A)',
    value: '-title',
  },
  {
    label: 'Voorraad (laag naar hoog)',
    value: 'stock',
  },
  {
    label: 'Voorraad (hoog naar laag)',
    value: '-stock',
  },
];

export const DEFAULT_SORT = '-stock';

export const INVENTORY_PRODUCT_GEOGRAPHICAL_TABS = [
  {
    value: 'revenue',
    label: 'Omzet',
    is_amount: true,
  },
  {
    value: 'profit',
    label: 'Winst',
    is_amount: true,
  },
  {
    value: 'sales',
    label: 'Verkocht',
  },
  {
    value: 'visitors',
    label: 'Bezoekers',
  },
  {
    value: 'conversion',
    label: 'CR',
    is_percent: true,
    hide_split_percentage: true,
  },
];

export const ADDITIONAL_COST_OPTIONS = [
  {
    value: 'transport_cost',
    label: 'Transportkosten',
  },
  {
    value: 'packaging_cost',
    label: 'Verpakkingskosten',
  },
  {
    value: 'branding_cost',
    label: 'Branding (Stickers & Insert cards etc.)',
  },
  {
    value: 'transaction_cost',
    label: 'Transactiekosten',
  },
  {
    value: 'other_cost',
    label: 'Overige kosten',
  },
];

export const COST_PRICE_OPTIONS: { value: string; label: string }[] = [
  {
    value: 'product_cost_price',
    label: 'Inkoopprijs',
  },
  {
    value: 'shipping',
    label: 'Verzendkosten',
  },
  {
    value: 'transport',
    label: 'Transportkosten',
  },
  {
    value: 'packaging',
    label: 'Verpakkingskosten',
  },
  {
    value: 'branding',
    label: 'Branding (Stickers & Insert cards etc.)',
  },
  {
    value: 'transaction',
    label: 'Transactiekosten',
  },
  {
    value: 'other',
    label: 'Overige kosten',
  },
  {
    value: 'pick_pack',
    label: 'Pick & Pack',
  },
];
