/* eslint-disable import/prefer-default-export */

export const defaultOrderData = {
  customer: {
    first_name: 'Emma',
    last_name: 'van Boloo',
    email: 'support@boloo.co',
    city: 'Eindhoven',
    country_code: 'NL',
    zip_code: '5651GW',
    house_number: '261',
    street_number: 'Klokgebouw',
    house_number_extended: null,
    delivery_phone_number: null,
    vat_number: 'NL999999999B01',
    chamber_of_commerce_number: '12345678',
    company: 'Boloo',
  },
  order_items: [
    {
      order_item_id: '2397834004',
      title: `Aroma Diffuser - Speciaal Voor Etherische Oliën - 500mL tank`,
      sold_quantity: 2,
      price: 99.99,
      revenue: 199.98,
      vat: 21,
      vat_percentage: 21,
    },
  ],
  sub_total: 165.27,
  total_vat: 34.7,
  total_revenue: 199.98,
  order_id: 1234567890,
  get_invoice_number: undefined,
  order_date: undefined,
};
