/*eslint-disable*/
export const initialState = {
  courses: [],
  selectedCourse: {},
  selectedSection: {},
  selectedLesson: {},
};

const SET_ACADEMY_VISIT = 'EVENT/SET_UNIQUE_VISIT';
const SET_COURSES_DATA = 'EVENT/SET_COURSES_DATA';
const SET_SELECTED_COURSE_DATA = 'EVENT/SET_SELECTED_COURSE_DATA';
const SET_SELECTED_LESSON_DATA = 'EVENT/SET_SELECTED_LESSON_DATA';
const SET_SELECTED_SECTION_DATA = 'EVENT/SET_SELECTED_SECTION_DATA';

export const setAcademyVisit = (value) => {
  return {
    type: SET_ACADEMY_VISIT,
    payload: value,
  };
};

export const setCourses = (courses) => {
  return {
    type: SET_COURSES_DATA,
    payload: courses,
  };
};

export const setSelectedCourse = (course) => {
  return {
    type: SET_SELECTED_COURSE_DATA,
    payload: course,
  };
};

export const setSelectedSection = (section) => {
  return {
    type: SET_SELECTED_SECTION_DATA,
    payload: section,
  };
};

export const setSelectedLesson = (lesson) => {
  return {
    type: SET_SELECTED_LESSON_DATA,
    payload: lesson,
  };
};

export const academyCoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSES_DATA:
      return {
        ...state,
        courses: [...action.payload],
      };
    case SET_SELECTED_COURSE_DATA:
      return {
        ...state,
        selectedCourse: action.payload,
      };
    case SET_SELECTED_SECTION_DATA:
      return {
        ...state,
        selectedSection: action.payload,
      };
    case SET_SELECTED_LESSON_DATA:
      return {
        ...state,
        selectedLesson: action.payload,
      };
    case SET_ACADEMY_VISIT:
      return {
        ...state,
        is_visited: action.payload,
      };
    default:
      return state;
  }
};
