import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { Spin } from 'antd';
import { Selection } from '../../types';

import { getTooltipFormat, mapToGraphData } from '../../helpers';

import {
  getCosts,
  getProfit,
  getRevenue,
} from '../../../services/financialReportingService/api';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import { formatNumberToCurrency } from '../../../../utilities';
import CommonCard from '../../../components/commonCard';

interface StorePerformanceGraphProps {
  selection: Selection;
}

function StorePerformanceGraph({ selection }: StorePerformanceGraphProps) {
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      x: {
        format: getTooltipFormat(selection),
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return formatNumberToCurrency(value);
        },
      },
    },
    colors: [
      'rgba(69,83,240,0.9)',
      'rgba(156,39,176,0.9)',
      'rgba(76,175,80,0.9)',
    ],
  };
  const [series, setSeries] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [profit, setProfit] = useState([]);
  const [costs, setCosts] = useState([]);
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    if (selection == null) {
      return;
    }

    Promise.all([
      getRevenue(selection, selection.group_by),
      getCosts(selection, selection.group_by),
      getProfit(selection, selection.group_by),
    ])
      .then((responses) => {
        setRevenue(responses[0].data);
        setCosts(responses[1].data);
        setProfit(responses[2].data);
      })
      .catch(captureErrorAndShowMessage)
      .finally(() => {
        setAllLoaded(true);
      });
  }, [selection]);

  useEffect(() => {
    if (selection == null) {
      return;
    }
    setSeries([
      {
        name: 'Omzet',
        data: mapToGraphData(selection, revenue),
      },
      {
        name: 'Kosten',
        data: mapToGraphData(selection, costs, true),
      },
      {
        name: 'Winst',
        data: mapToGraphData(selection, profit),
      },
    ]);
  }, [revenue, costs, profit]);

  return (
    <CommonCard title="Winkelprestaties">
      {!allLoaded && (
        <div className="graph-loading">
          <Spin />
        </div>
      )}
      {allLoaded && (
        <ReactApexChart
          width="100%"
          height="250px"
          options={options}
          series={series}
          type="area"
        />
      )}
    </CommonCard>
  );
}

export default StorePerformanceGraph;
