import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Slider,
  Switch,
  Tooltip,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { If } from 'tsx-control-statements/components';
import { TOOLTIP_COLOUR, DEFAULT_THEME_COLOUR } from '../../../constants';
import { hexToRgb, isNonWhiteSpectrum } from '../../../utilities';

import { uploadOrderInvoiceLogo } from '../../actions/store';
import {
  ACCEPTED_IMAGE_FORMATS,
  INVOICE_TRIGGER_OPTIONS,
  MAX_FOOTER_DESCRIPTION_LENGTH,
  MAX_FOOTER_TITLE_LENGTH,
  STORE_FORM_CHECKBOX_OPTIONS,
  UPLOAD_TO_BOL_INVOICE_TRIGGER,
} from '../constants';
import StoreInformationEditModal from '../storeInformationEditModal';
import { COMPLETE_STORE_INFORMATION_WARNING } from '../../constants';
import { useUserProfile } from '../../services/userService/api';

const { Panel } = Collapse;
const { Option } = Select;

function InvoiceTemplateSettings({
  form,
  initalValues,
  onFormSubmit,
  onLogoChange,
  onLogoRemove,
  onFormValuesChange,
}) {
  const [fileList, setFileList] = useState([]);
  const [isLogoUploading, setLogoUploading] = useState(false);
  const [isLogoUploadFailed, setLogoUploadFailed] = useState(false);
  const [titleCount, setTitleCount] = useState(initalValues.title.length || 0);
  const [descriptionCount, setDescriptionCount] = useState(
    initalValues.description.length || 0,
  );

  const [transitionInvoiceNumber, setTransitionInvoiceNumber] = useState(true);

  const [showStoreInformationEditModal, setStoreInformationEditModalVisiblity] =
    useState(false);

  const { user } = useUserProfile();

  const description = Form.useWatch('description', form);

  useEffect(() => setDescriptionCount(description?.length), [description]);

  function getFileList(file, isTemp = true) {
    const pieces = file.split(/[\s/]+/);
    const fileName = pieces[pieces.length - 1];
    return [
      {
        uid: 'uid',
        name: fileName,
        status: 'done',
        ...(isTemp ? {} : { url: file }),
      },
    ];
  }

  useEffect(() => {
    const { logo } = initalValues;
    if (logo) {
      setFileList(getFileList(logo, false));
    }
  }, [initalValues]);

  function handleLogoRemove() {
    setFileList([]);
    onLogoRemove();
  }

  function handleLogoChange(file) {
    setLogoUploading(true);
    uploadOrderInvoiceLogo(file)
      .then((response) => {
        const { data } = response;
        fileList[0].status = 'done';
        fileList[0].url = data.relative_url;
        setFileList([...fileList]);
        setLogoUploading(false);
        onLogoChange(data);
      })
      .catch(() => {
        setLogoUploading(false);
        setLogoUploadFailed(true);
      });
  }

  function beforeUpload(file) {
    const isLessThan1MB = file.size / 1024 / 1024 < 1;
    if (!isLessThan1MB) {
      setLogoUploadFailed(true);
    } else {
      setLogoUploadFailed(false);
      file.status = 'uploading';
      setFileList([file]);
    }
    return isLessThan1MB;
  }

  function getTooltipValue(checkboxItem) {
    if (user?.retailer[checkboxItem.lookupKey]) {
      return `${checkboxItem.label}: ${user?.retailer[checkboxItem.lookupKey]}`;
    }
    return 'Niet beschikbaar, voeg informatie toe. ';
  }

  function onAllFormValueChanges(allValues) {
    const rgbVal = hexToRgb(allValues.theme_color);
    const themeColor = isNonWhiteSpectrum(rgbVal)
      ? allValues.theme_color
      : DEFAULT_THEME_COLOUR;
    allValues.theme_color = themeColor;
    const values = { ...initalValues, ...allValues };
    if (values.trigger === 'never') {
      form.setFieldsValue({
        sender_email: (values.sender_email = initalValues.sender_email),
      });
    }
    onFormValuesChange(values);
    if (!allValues.auto_send_to_public_customers) {
      form.setFieldsValue({
        ...values,
        auto_send_to_private_customers: false,
      });
    }
  }

  function handleInvoiceTriggerChange() {
    if (!user?.can_send_invoice) {
      message.warning(COMPLETE_STORE_INFORMATION_WARNING);
    }
    if (form.getFieldValue('trigger') === 'never') {
      form.setFieldsValue({
        send_bcc_email: false,
      });
    }
  }

  const bccToggleDisabled = form.getFieldValue('trigger') === 'never';
  const showEmailConfiguration =
    form.getFieldValue('trigger') !== 'never' &&
    form.getFieldValue('trigger') !== UPLOAD_TO_BOL_INVOICE_TRIGGER;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initalValues}
      onFinish={onFormSubmit}
      onValuesChange={(value, allValues) => onAllFormValueChanges(allValues)}
    >
      <Form.Item noStyle className="bear-no-margin" name={['id']}>
        <Input type="hidden" />
      </Form.Item>
      {/* API mandates this to be sent in request */}
      <Form.Item
        noStyle
        className="bear-no-margin"
        name={['customer_variables']}
      >
        <Input type="hidden" />
      </Form.Item>
      <If condition={showStoreInformationEditModal}>
        <StoreInformationEditModal
          handleOnClose={() => setStoreInformationEditModalVisiblity(false)}
        />
      </If>
      <Collapse
        ghost
        expandIconPosition="end"
        defaultActiveKey={['customiseTemplate', 'configuration']}
      >
        <Panel header="Template aanpassen" key="customiseTemplate" forceRender>
          <div className="logo-section">
            <Row gutter={16}>
              <Col className="invoicing-mar-r" span={12}>
                <div className="branding-box bear-flex-col">
                  <p className="bear-mar-b-20 bear-medium-text">Logo </p>
                  <div className="bear-flex-col bear-flex-sa">
                    <If condition={!initalValues.logo}>
                      <Upload
                        name="file"
                        accept={ACCEPTED_IMAGE_FORMATS.join(',')}
                        fileList={fileList}
                        className="upload-logo"
                        onRemove={() => handleLogoRemove()}
                        customRequest={(file) => handleLogoChange(file.file)}
                        beforeUpload={(file) => beforeUpload(file)}
                      >
                        <If condition={!isLogoUploading}>
                          <p className="bear-link bear-mar-t-20">
                            Logo uploaden
                          </p>
                        </If>
                      </Upload>
                    </If>
                    <If condition={isLogoUploadFailed}>
                      <p className="error-message bear-mar-t-16">Max. 1 MB</p>
                    </If>
                    <If condition={initalValues.logo}>
                      <Row align="middle">
                        <Col span={14}>
                          <div className="branding-logo-box">
                            <img
                              src={initalValues.logo_url}
                              alt="company-logo"
                            />
                          </div>
                        </Col>
                        <Col>
                          <i
                            tabIndex={0}
                            role="button"
                            aria-label="remove-logo"
                            data-testid="remove-logo"
                            onClick={() => handleLogoRemove()}
                            className="fad icon-trash calculator-delete-item-action bear-table-action"
                          />
                        </Col>
                      </Row>
                      <Row className="bear-pad-8">
                        <Col span="24">
                          <Form.Item name="logo_height">
                            <Slider
                              min={3}
                              max={9}
                              step={3}
                              marks={{
                                3: 'S',
                                6: 'M',
                                9: 'L',
                              }}
                              tooltip={{
                                formatter: null,
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </If>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="branding-box bear-mar-l-16">
                  <p className="bear-mar-b-20 bear-medium-text">
                    Factuur Template Kleur
                  </p>
                  <div className="bear-flex bear-flex-ac bear-mar-t-20">
                    <Form.Item className="bear-no-margin" name="theme_color">
                      <Input
                        className="branding-theme-color-input"
                        type="color"
                      />
                    </Form.Item>
                    <Form.Item noStyle name="logo">
                      <Input type="hidden" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="invoice-form-section">
            <p className="bear-mar-b-16 bear-medium-text">
              Store Informatie
              {['store_name', 'store_address']
                .map((item) => !!user?.retailer[item])
                .some((item) => !item) && (
                <i
                  className="icon-warning bear-mar-l-8"
                  style={{ color: 'red' }}
                />
              )}
              <i
                data-testid="edit-store-information"
                className="bear-link fas icon-pencil bear-mar-l-8"
                onClick={() => setStoreInformationEditModalVisiblity(true)}
              />
            </p>

            <Row className="checkbox-group">
              {STORE_FORM_CHECKBOX_OPTIONS.map((item) => {
                const isDisabled =
                  item.isRequired || !user?.retailer[item.lookupKey];
                return (
                  <Col span={12} key={item.name}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name={[item.name]}
                    >
                      <Checkbox disabled={isDisabled}>
                        <Tooltip
                          placement="right"
                          title={getTooltipValue(item)}
                          color={TOOLTIP_COLOUR}
                        >
                          {item.label}
                        </Tooltip>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className="invoice-form-section">
            <p className="bear-mar-b-16 bear-medium-text">Footer</p>
            <div className="character-limit">
              <span className="bear-mar-r-8 bear-light-text-400">{`${titleCount}/${MAX_FOOTER_TITLE_LENGTH}`}</span>
            </div>

            <Form.Item name="title" label="Titel">
              <Input
                size="large"
                data-testid="footer-title"
                maxLength={MAX_FOOTER_TITLE_LENGTH}
                onChange={(e) => setTitleCount(e.target.value.length)}
              />
            </Form.Item>
            <div className="character-limit">
              <span className="bear-mar-r-8 bear-light-text-400">{`${descriptionCount}/${MAX_FOOTER_DESCRIPTION_LENGTH}`}</span>
            </div>
            <Form.Item
              name="description"
              label="Subtitel"
              data-testid="footer-subtitle"
              className="bear-no-margin"
            >
              <Input size="large" maxLength={MAX_FOOTER_DESCRIPTION_LENGTH} />
            </Form.Item>
            <Form.Item noStyle name="id">
              <Input type="hidden" />
            </Form.Item>
          </div>
        </Panel>
        <Panel header="Instellingen" key="configuration" forceRender>
          <div className="invoice-settings-form">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="trigger"
                  label="Wil je de facturen automatisch uploaden naar bol.com?"
                >
                  <Select
                    placeholder="Choose event"
                    size="large"
                    disabled={!user?.can_send_invoice}
                    onChange={() => handleInvoiceTriggerChange()}
                  >
                    {INVOICE_TRIGGER_OPTIONS.map((option) => {
                      return (
                        <Option
                          value={option.value}
                          key={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <p>
                  <span className="bear-light-help-text">
                    Let op: Vanaf 1 juni 2023 is het niet meer toegestaan om
                    klantfacturen te versturen per e-mail. Wij kunnen de
                    facturen vanaf nu ook automatisch uploaden naar bol.com,
                    selecteer deze optie hierboven.
                  </span>
                </p>
              </Col>
              <Col span={24}>
                <If condition={showEmailConfiguration}>
                  <Form.Item
                    name="sender_email"
                    label="Emailadres voor vragen over facturen"
                    rules={[
                      {
                        required: true,
                        message: 'Voer je e-mailadres in',
                      },
                      {
                        type: 'email',
                        message: 'Voer alsjeblieft een geldig e-mailadres in',
                      },
                    ]}
                  >
                    <Input size="large" disabled={!initalValues.id} />
                  </Form.Item>
                </If>
              </Col>
            </Row>
            <If condition={showEmailConfiguration}>
              <Row>
                <Form.Item
                  className="send-type-form-item"
                  label="Naar welke klanten wil je automatisch facturen versturen?"
                >
                  <Form.Item
                    valuePropName="checked"
                    className="bear-no-margin"
                    name="auto_send_to_public_customers"
                  >
                    <Checkbox>
                      <span className="checkbox-content">
                        <span>Zakelijke klanten</span>
                        <div className="bear-light-help-text">
                          <p>
                            Zakelijke klanten worden herkend door de
                            aanwezigheid van een bedrijfsnaam en/of BTW-nummer.
                          </p>
                          <p className="bear-mar-t-8">
                            <b>Let op: </b>
                            vanwege de API van bol.com kunnen wij niet altijd
                            met 100% zekerheid aannemen dat een klant via een
                            zakelijk account bestelt. Daarom raden wij je aan om
                            ook naar particuliere kopers automatische facturen
                            te sturen.
                          </p>
                        </div>
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    valuePropName="checked"
                    name="auto_send_to_private_customers"
                  >
                    <Checkbox
                      disabled={
                        !form.getFieldValue('auto_send_to_public_customers')
                      }
                    >
                      <span className="checkbox-content">
                        <span>Particuliere klanten</span>
                        <span className="bear-light-help-text">
                          Particuliere klanten worden herkend door het gebrek
                          aan een bedrijfsnaam en/of BTW-nummer
                        </span>
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Form.Item>
              </Row>
            </If>
            <Row className="bear-mar-t-12" align="middle">
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  name="send_bcc_email"
                  label="Stuur een kopie naar jezelf"
                >
                  <Switch disabled={bccToggleDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row className="bear-mar-t-12" align="middle">
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  name="has_no_vat_rule"
                  label="KOR / Btw-vrijstellingsregeling toepassen?"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row className="bear-mar-t-12" align="middle">
              <Col span={24}>
                <Form.Item
                  valuePropName="checked"
                  name="show_paid_icon"
                  label="Betaald sticker weergeven"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="start_from"
                  label={
                    <>
                      <span>Factuur startnummer</span>
                      <i
                        className="icon-pencil bear-link bear-mar-l-8"
                        onClick={() => setTransitionInvoiceNumber(false)}
                      />
                    </>
                  }
                >
                  <InputNumber disabled={transitionInvoiceNumber} />
                </Form.Item>
                <p>
                  <span className="bear-light-help-text">
                    Let op: schakel je over van je huidige factuursysteem naar
                    Boloo? Vul hier dan het eerstvolgende factuurnummer in en
                    facturen worden juist genummerd.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
    </Form>
  );
}

export default InvoiceTemplateSettings;
