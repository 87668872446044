import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import Picker, { EmojiClickData } from 'emoji-picker-react';
import { ACCOUNT_SETTINGS_BACKGROUND_COLOR } from '../../constants';
import { TOOLTIP_COLOUR } from '../../../constants';

function EmojiPicker({ chosenEmoji, setChosenEmoji }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  function onEmojiClick(emojiObject: EmojiClickData) {
    setChosenEmoji(emojiObject.emoji);
    setIsPopoverOpen(false);
  }

  const handlePopoverChange = (isVisible) => {
    setIsPopoverOpen(isVisible);
  };

  return (
    <div>
      <Popover
        open={isPopoverOpen}
        onOpenChange={handlePopoverChange}
        content={
          <Picker
            onEmojiClick={(emojiData: EmojiClickData) =>
              onEmojiClick(emojiData)
            }
          />
        }
      >
        <Button
          type="dashed"
          style={{
            color: TOOLTIP_COLOUR,
            backgroundColor: ACCOUNT_SETTINGS_BACKGROUND_COLOR,
          }}
        >
          {chosenEmoji || 'Kies icoon'}
        </Button>
      </Popover>
    </div>
  );
}

export default EmojiPicker;
