export enum CostType {
  PRODUCT_COST_PRICE = 'product_cost_price',
  SHIPPING = 'shipping',
  PACKAGING = 'packaging',
  BRANDING = 'branding',
  TRANSACTION = 'transaction',
  TRANSPORT = 'transport',
  OTHER = 'other',
  SURCHARGE_BE_SHIPMENT = 'surcharge_be_shipment',
  PICK_PACK = 'pick_pack',
}
