import axios from 'axios';
import { store } from '../../store';

let baseURL = 'https://shipments.boloo.co';

if (process.env.TARGET_ENV === 'development') {
  baseURL = 'http://localhost:8002';
}

if (process.env.TARGET_ENV === 'stage') {
  baseURL = 'https://shipments.staging.boloo.co';
}

const shipmentsAxios = axios.create({
  baseURL,
  timeout: 250000,
  headers: {
    // eslint-disable-next-line no-undef
    'Content-Type': 'application/json',
  },
});

shipmentsAxios.interceptors.request.use((config) => {
  // Fetch token from store
  const token = store.getState().tokensReducer.activeToken;
  if (token) {
    // Set token in Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

shipmentsAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default {
  getOrderShipment(orderId) {
    const path = `/orders/${orderId}/shipments`;
    return shipmentsAxios.get(path);
  },
  uploadInvoice(orderId) {
    const path = `/orders/${orderId}/upload-invoice`;
    return shipmentsAxios.post(path);
  },
};
