import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/nl';
import { SubscriptionPaymentMethods } from '../retailer/services/subscriptionService/api';

const SET_PROFILE_DATA = 'EVENT/SET_PROFILE_DATA';

interface UserProfileCompanyInterface {
  id: number;
  name: string;
  street_name: string;
  house_number: string;
  location: string;
  country: string;
  zip_code: string;
  vat: string;
  type: string;
  is_complete: boolean;
  phone_number: string;
}

interface UserProfileCustomerInterface {
  id: number;
  customer_id: string;
  subscription: {
    subscription_id: string;
    status: string;
    payment_method: string;
    plan: {
      id: number;
      plan_id: string;
      name: string;
      amount: number;
      amount_with_vat: number;
      interval_days: number;
      cost: string;
      discount_amount: number;
      product: {
        name: string;
        is_active: boolean;
        hash: string;
        image: string;
        has_free_online_coaching: boolean;
        has_free_ebook: boolean;
        can_access_boloo_community: boolean;
        tracking_count: number;
        is_niche_detector_enabled: boolean;
        is_keyword_planner_enabled: boolean;
        is_seller_store_enabled: boolean;
        is_course_enabled: boolean;
        is_accounting_enabled: boolean;
      };
    };
    ended_at: string;
    started_on: string;
    last_payment_date?: string;
    amount: number;
  };
  is_trial_used: boolean;
}

export interface UserProfileInterface {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  registration_type: string;
  icon: string | null;
  nickname: string;
  company: UserProfileCompanyInterface | null;
  customer: UserProfileCustomerInterface | null;
  invoice_email: string | null;
  is_registration_complete: boolean;
  is_store_data_complete: boolean;
  is_verified: boolean;
  is_active: boolean;
  is_active_subscription: boolean | null;
  is_bol_seller: boolean;
  is_cancelled_subscription: boolean;
  is_accounting_enabled: boolean;
  is_store_enabled: boolean;
  is_parent: boolean;
  is_admin: boolean;
  can_send_invoice: boolean;
  is_tax_applicable: boolean | null;
  timezone: string;
  retailer: null | Retailer;
  source_form: string;
  payment_method?: SubscriptionPaymentMethods;
}

export interface Retailer {
  id: number;
  active: boolean;
  client_id: string;
  client_secret: string;
  advertising_client_id: string | null;
  advertising_client_secret: string | null;
  status: string;
  is_inventory_synced: boolean;
  is_shipments_synced: boolean;
  is_open_orders_synced: boolean;
  is_returns_synced: boolean;
  is_sales_notification_enabled: boolean;
  created_at: string;
  updated_at: string;
  is_accounting_enabled: boolean;
  store_name: string;
  store_address: string;
  is_daily_report_enabled: boolean;
  can_trigger_re_sync: boolean;
  is_hijacker_alerts_enabled: boolean;
  vat_number: string;
  coc_number: string;
  iban_number: string;
  has_no_vat_rule: boolean;
  upload_invoices_to_bol: boolean;
  is_valid_credentials: boolean;
  is_valid_advertising_credentials: boolean;
}

export enum ProfileDataActions {
  SetProfileData = SET_PROFILE_DATA,
}

export const setProfileData = (
  userProfile: UserProfileInterface,
): { type: ProfileDataActions; payload: UserProfileInterface } => {
  return {
    type: ProfileDataActions.SetProfileData,
    payload: userProfile,
  };
};

export const profileDataReducer = (
  state,
  action: { type: ProfileDataActions; payload: UserProfileInterface },
) => {
  switch (action.type) {
    case ProfileDataActions.SetProfileData:
      // From this line after we will be using the timezone but not when calling utc or exports
      dayjs.extend(relativeTime);
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.locale('nl');
      dayjs.tz.setDefault(action.payload.timezone);
      return {
        ...(state || {
          detail: {},
        }),
        detail: action.payload,
      };
    default:
      throw new Error('profileDataReducer action unknown');
  }
};
