import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Row, Col } from 'antd';

import {
  getInventoryProductMetrics,
  getInventoryProductVisitors,
  getInventoryProductStatistics,
  getInventoryReturnsStatistics,
} from '../../../actions/store';
import { getLastMonthRange } from '../../../../utilities/date';
import { INVENTORY_PRODUCT_GEOGRAPHICAL_TABS } from '../constants';
import { getCountryValue, getStatisticValue } from '../../utilities';

import BearChart from '../../../../components/bearCharts';
import BearStatistics from '../../../../components/bearStatistics';
import BearDatePicker from '../../../../components/bearDatePicker';
import StoreCountrySelector from '../../components/countrySelector';
import StoreStatisticSubValue from '../../components/statisticSubValue';
import GeographicMetricsCard from '../../components/geographicalMetricsCard';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import { OfferProductInterface } from '../../../services/productsService/api';

dayjs.extend(utc);

const statisticsSpan = {
  span: {
    lg: 4,
    xs: 8,
  },
};

function StoreProductPerformance({
  offerProduct,
}: {
  offerProduct: OfferProductInterface;
}) {
  const [statistics, setStatistics] = useState({});
  const [returnStatistics, setReturnStatistics] = useState({});
  const [visitorStatistics, setVisitorStatistics] = useState({});

  const [metrics, setMetrics] = useState([]);
  const [metricsUnit, setMetricsUnit] = useState();
  const [visitorInsights, setVisitorInsights] = useState([]);
  const [rawVisitorsData, setRawVisitorsData] = useState([]);
  const [insightsUnit, setInsightsUnit] = useState();

  const [selectedCountry, setSelectedCountry] = useState('');
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [insightsLoading, setInsightsLoading] = useState(true);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [returnStatisticsLoading, setReturnStatisticsLoading] = useState(true);

  const [pickerValue, setPickerValue] = useState(getLastMonthRange());

  function normaliseVisitorsData(visitorsData) {
    return visitorsData.map((item) => {
      const { period, geographical } = item;
      const date = new Date(period.year, period.month - 1, period.day || 1);
      return [
        date,
        getCountryValue(geographical, 'value', item.total, selectedCountry),
      ];
    });
  }

  function getProductVisitors() {
    setInsightsLoading(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getInventoryProductVisitors(offerProduct.product.ean, ...pickerValue)
      .then((response) => {
        const responseData = response.data;
        const data = normaliseVisitorsData(responseData.periods);
        setRawVisitorsData(responseData.periods);
        setVisitorStatistics({
          visitors: {
            total: responseData.total,
            geographical: responseData.geographical,
          },
        });
        setInsightsUnit(response.data.unit);
        setVisitorInsights(data);
        setInsightsLoading(false);
      })
      .catch(captureErrorAndShowMessage);
  }

  function getMetrics() {
    setMetricsLoading(true);
    getInventoryProductMetrics(
      offerProduct.product.ean,
      selectedCountry,
      ...pickerValue,
    )
      .then((response) => {
        setMetricsUnit(response.data.unit);
        const data = response.data.periods.map((item) => {
          return [
            dayjs.unix(item.period).utc().format(),
            item.sales || 0,
            item.revenue || 0,
            item.cost_price || 0,
            item.profit || 0,
          ];
        });
        setMetrics(data);
        setMetricsLoading(false);
      })
      .catch((e) => captureErrorAndShowMessage(e));
  }

  const getStatistics = () => {
    setStatisticsLoading(true);
    getInventoryProductStatistics(offerProduct.product.ean, ...pickerValue)
      .then((response) => {
        setStatistics(response.data);
        setStatisticsLoading(false);
      })
      .catch(captureErrorAndShowMessage);
  };

  function getReturnStatistics() {
    setReturnStatisticsLoading(true);
    getInventoryReturnsStatistics(offerProduct.product.ean, ...pickerValue)
      .then((response) => {
        setReturnStatistics(response.data);
        setReturnStatisticsLoading(false);
      })
      .catch((e) => captureErrorAndShowMessage(e));
  }

  useEffect(() => {
    getStatistics();
    getProductVisitors();
    getReturnStatistics();
  }, [pickerValue]);

  useEffect(() => {
    getMetrics();
    const visitorsData = normaliseVisitorsData(rawVisitorsData);
    setVisitorInsights(visitorsData);
  }, [pickerValue, selectedCountry]);

  function renderStatisticsValue(fieldKey) {
    return getStatisticValue({
      statistics,
      selectedCountry,
      valueKey: fieldKey,
      isLoading: statisticsLoading,
    });
  }

  function getGeographicalStatistics() {
    const data = { ...statistics, ...visitorStatistics };
    if (
      Object.keys(statistics).length &&
      Object.keys(visitorStatistics).length
    ) {
      const salesGeo =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (statistics.sales && statistics.sales.geographical) || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { visitors = {} } = data;
      const geo = visitors.geographical || {};
      const vistorBEVal = (geo.BE && geo.BE.value) || 0;
      const vistorNLVal = (geo.NL && geo.NL.value) || 0;
      const salesBEVal = (salesGeo.BE && salesGeo.BE.value) || 0;
      const salesNLVal = (salesGeo.NL && salesGeo.NL.value) || 0;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.conversion = {
        geographical: {
          BE: { value: vistorBEVal ? (salesBEVal / vistorBEVal) * 100 : 0 },
          NL: { value: vistorNLVal ? (salesNLVal / vistorNLVal) * 100 : 0 },
        },
      };
    }
    return data;
  }

  const perfomanceDataSet = [
    {
      fill: '+2',
      spanGaps: true,
      label: 'Omzet',
      lineTension: 0,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: '#4553F0',
      data: metrics.map((item) => item[2]),
      backgroundColor: 'rgba(69,83,240, 0.2)',
    },
    {
      fill: 'origin',
      spanGaps: true,
      label: 'Kosten',
      lineTension: 0,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: '#9C27B0',
      data: metrics.map((item) => item[3]),
      backgroundColor: 'rgba(156, 39, 176, 0.2)',
    },
    {
      fill: '-1',
      label: 'Winst',
      lineTension: 0,
      pointRadius: 0,
      spanGaps: true,
      borderWidth: 2,
      borderColor: '#4CAF50',
      data: metrics.map((item) => item[4]),
      backgroundColor: 'rgba(102, 187, 106, 0.2)',
    },
  ];

  const statisticsItems = [
    {
      ...statisticsSpan,
      key: 'revenue',
      title: {
        text: 'Omzet',
      },
      loading: statisticsLoading,
      value: renderStatisticsValue('revenue'),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="revenue"
            tooltip={`De stijging / daling vergeleken met de vorige periode. Als je de laatste 7 dagen 
            Oktober selecteert in het dashboard, dan zal je de stijging / daling zien vergeleken met de 
            maand ervoor, oftewel September. Voorbeeld: Als de omzet in Oktober 15K is en in 
            September 10K, dan zie je onder de omzet een stijging van 5K.`}
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
          />
        ),
      },
    },
    {
      ...statisticsSpan,
      key: 'cost',
      title: {
        text: 'Kosten',
      },
      loading: statisticsLoading,
      value: renderStatisticsValue('price'),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="price"
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
          />
        ),
      },
    },
    {
      ...statisticsSpan,
      key: 'profit',
      title: {
        text: 'Winst',
      },
      loading: statisticsLoading,
      value: renderStatisticsValue('profit'),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="profit"
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
          />
        ),
      },
    },
    {
      ...statisticsSpan,
      key: 'visitors',
      loading: insightsLoading,
      title: { text: 'Bezoekers' },
      value: getStatisticValue({
        statistics: visitorStatistics,
        selectedCountry,
        valueKey: 'visitors',
        isLoading: insightsLoading,
        isAmount: false,
        defaultValue: '-',
      }),

      sub_value: {
        text: (
          <StoreStatisticSubValue
            isAmount={false}
            showAlways={false}
            valueKey="visitors"
            statistics={visitorStatistics}
            selectedCountry={selectedCountry}
            isLoading={insightsLoading}
          />
        ),
      },
    },
    {
      ...statisticsSpan,
      key: 'sales',
      title: {
        text: 'Producten verkocht',
      },
      loading: statisticsLoading,
      value: getStatisticValue({
        statistics,
        selectedCountry,
        valueKey: 'sales',
        isLoading: statisticsLoading,
        isAmount: false,
      }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            valueKey="sales"
            isAmount={false}
            statistics={statistics}
            selectedCountry={selectedCountry}
            isLoading={statisticsLoading}
          />
        ),
      },
    },
    {
      ...statisticsSpan,
      key: 'returns',
      title: { text: 'Retouren' },
      loading: returnStatisticsLoading,
      value: getStatisticValue({
        statistics: returnStatistics,
        selectedCountry,
        valueKey: 'returns',
        isLoading: returnStatisticsLoading,
        isAmount: false,
      }),
      sub_value: {
        text: (
          <StoreStatisticSubValue
            isAmount={false}
            isOppositeCorrect
            valueKey="returns"
            statistics={returnStatistics}
            selectedCountry={selectedCountry}
            isLoading={returnStatisticsLoading}
          />
        ),
      },
    },
  ];

  return (
    <>
      <Row
        className="bear-mar-t-20"
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
      >
        <Col xs={24} lg={12} className="bear-col-fa">
          <BearDatePicker onApply={(value) => setPickerValue(value)} />
        </Col>
        <Col xs={24} lg={12}>
          <StoreCountrySelector
            selectedValue={selectedCountry}
            onChange={(value) => setSelectedCountry(value)}
          />
        </Col>
      </Row>
      <BearStatistics items={statisticsItems} />
      <Row
        className="bear-mar-t-16"
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
      >
        <Col lg={12} md={12} sm={24} xs={24} className="bear-mar-b-sm-16">
          <BearChart
            type="line"
            title="Prestaties"
            isAmount
            unit={metricsUnit}
            isLoading={metricsLoading}
            datasetArray={perfomanceDataSet}
            labelsArray={metrics.map((item) => item[0])}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <BearChart
            type="bar"
            unit={metricsUnit}
            isLoading={metricsLoading}
            title="Verkochte producten in deze periode"
            labelsArray={metrics.map((item) => item[0])}
            datasetArray={[
              {
                label: 'Verkopen',
                backgroundColor: 'rgba(106,117,243,1)',
                hoverBackgroundColor: 'rgba(106,117,243, 0.8)',
                barThickness: metricsUnit === 'month' ? 16 : 8,
                data: metrics.map((item) => item[1]),
              },
            ]}
          />
        </Col>
      </Row>
      <Row
        className="bear-mar-t-16"
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
      >
        <Col lg={12} md={12} sm={24} xs={24} className="bear-mar-b-sm-16">
          <GeographicMetricsCard
            title="NL versus BE"
            defaultTab="revenue"
            isLoading={statisticsLoading}
            statistics={getGeographicalStatistics()}
            tabs={INVENTORY_PRODUCT_GEOGRAPHICAL_TABS}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <BearChart
            type="bar"
            title="Bezoekers"
            unit={insightsUnit}
            isLoading={insightsLoading}
            labelsArray={visitorInsights.map((item) => item[0])}
            emptyText="Geen data voor de geselecteerde periode"
            datasetArray={[
              {
                label: 'Bezoekers',
                backgroundColor: 'rgba(102,187,106, 0.9)',
                hoverBackgroundColor: 'rgba(102,187,106, 0.8)',
                barThickness: metricsUnit === 'month' ? 16 : 8,
                data: visitorInsights.map((item) => item[1]),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

export default StoreProductPerformance;
