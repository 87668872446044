import React, { useEffect } from 'react';
import ProfileHook from '../../hooks/profile';
import { trackUserDataChange } from './googleAnalytics';
import { addCustomerDetailsToGTM } from '../utilities/app';
import { UserProfileInterface } from '../../reducers/profileReducer';

function AnalyticsTracking({
  profileData,
}: {
  profileData: UserProfileInterface;
}) {
  useEffect(() => {
    trackUserDataChange(profileData);
    addCustomerDetailsToGTM(profileData);
  }, [profileData]);
  return <></>;
}

export default ProfileHook(AnalyticsTracking);
