import useSWR from 'swr';
import instance from '../../../axios';
import { UserProfileInterface } from '../../../reducers/profileReducer';
import captureErrorAndShowMessage from '../../../utilities/errors';

let baseURL = 'https://api.boloo.co';

if (process.env.TARGET_ENV === 'development') {
  baseURL = 'http://localhost:8000';
}

if (process.env.TARGET_ENV === 'stage') {
  baseURL = 'https://api.staging.boloo.co';
}

const API_ENDPOINT = `${baseURL}/api/v1/`;

export function useUserProfile(): {
  user: UserProfileInterface | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${API_ENDPOINT}user/`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        if (e.response.status !== 401) {
          captureErrorAndShowMessage(e);
        }
      },
    },
  );

  return {
    user: data || null,
    isLoading,
    isError: error,
  };
}
