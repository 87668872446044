import React from 'react';

import { Gutter } from 'antd/lib/grid/row';
import {
  ACTIVE_SUBSCRIPTION_STATUS,
  TRIALING_SUBSCRIPTION_STATUS,
  PAST_DUE_SUBSCRIPTION_STATUS,
  CANCELED_SUBSCRIPTION_STATUS,
  EXPIRED_SUBSCRIPTION_STATUS,
} from '../constants';
import { SETTING_STORE_ROUTE } from './routes';

export {
  ACTIVE_SUBSCRIPTION_STATUS,
  TRIALING_SUBSCRIPTION_STATUS,
  PAST_DUE_SUBSCRIPTION_STATUS,
  CANCELED_SUBSCRIPTION_STATUS,
  EXPIRED_SUBSCRIPTION_STATUS,
};

export const STORE_ORDER_OPEN_STATUS = 'open';
export const STORE_ORDER_SHIPPED_STATUS = 'shipped';
export const STORE_ORDER_CANCELLED_STATUS = 'customer_cancelled';
export const INVOICING_OPTIONS_TABS = [
  STORE_ORDER_OPEN_STATUS,
  STORE_ORDER_SHIPPED_STATUS,
  STORE_ORDER_CANCELLED_STATUS,
];

export const isInvoivingDropdownVisible = (orderStatus) => {
  return INVOICING_OPTIONS_TABS.includes(orderStatus);
};

export const STORE_ORDER_STATUS_OPTIONS = [
  {
    key: STORE_ORDER_OPEN_STATUS,
    value: 'Openstaand',
  },
  {
    key: STORE_ORDER_SHIPPED_STATUS,
    value: 'Verzonden',
  },
  {
    key: STORE_ORDER_CANCELLED_STATUS,
    value: 'Geannuleerd',
  },
  {
    key: 'returns',
    value: 'Retouren',
  },
  {
    key: 'returns_handled',
    value: 'Behandelde retouren',
  },
];

export const isReturnStatus = (status) => {
  return ['returns', 'returns_handled'].indexOf(status) > -1;
};

export const BOL_VAT_TARIFFS = [0, 9, 21];

export const RETAILER_ORDER_ITEM_SHIPPED_STATUS = 'shipped';
export const RETAILER_ORDER_ITEM_OPEN_STATUS = 'open';
export const RETAILER_ORDER_ITEM_CUSTOMER_CANCELLED_STATUS =
  'customer_cancelled';
export const RETAILER_ORDER_ITEM_SELLER_ACCEPTED_STATUS = 'seller_accepted';

export const DEFAULT_PAGE_SIZE = 10;
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Maart',
  'Apr',
  'Mei',
  'Juni',
  'Juli',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const MONTHS_FULL_NAME = [
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December',
];

export const MOBILE_BREAKPOINTS = ['xs', 'sm'];
export const MOBILE_AND_TABLET_BREAKPOINTS = ['xs', 'sm', 'md', 'lg'];
export const LARGE_DESKTOP_BREAKPOINTS = ['2xlg'];

export const METHOD_IDEAL = 'ideal';
export const METHOD_CREDIT_CARD = 'creditcard';
export const METHOD_BANCONTACT = 'bancontact';

export const DIRECT_DEBIT = 'directdebit';

export const PAYMENT_PROVIDER_MOLLIE = 'mollie';

export const TRIAL_DAYS = 7;

export const BILLING_TYPE_COMPANY = 'company';
export const BILLING_TYPE_PERSONAL = 'personal';

export const BILLING_TYPES = {
  [BILLING_TYPE_PERSONAL]: {
    label: 'Persoonlijk',
    icon: 'fad icon-user',
    title: 'Persoonlijke gegevens en factuuradres',
    fields: {
      street_name: {
        label: 'Straatnaam',
        placeholder: 'Voer je straatnaam in',
      },
    },
  },
  [BILLING_TYPE_COMPANY]: {
    label: 'Zakelijk',
    icon: 'fad icon-store',
    title: 'Bedrijfsgegevens en factuuradres',
    fields: {
      street_name: {
        label: 'Straatnaam',
        placeholder: 'Voer je straatnaam in',
      },
    },
  },
};

export const REGISTER_TYPE_COURSE = 'registered_for_course';
export const UTM_PARAMS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
];

export const UTM_PARAMS_MAX_LENGTH = 32;
export const HTTP_REFERRER_MAX_LENGTH = 200;

export const BOLOO_API_URL =
  process.env.TARGET_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://api.boloo.co';

export const ONESIGNAL_WEB_SDK_URL =
  'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
export const ONESIGNAL_SCRIPT_URL = `/static/OneSignalScript.js`;

export const STOCK_NOTIFICATION_LIMIT = 3;
export const TERMS_CONDITION_PAGE = 'https://boloo.co/algemene-voorwaarden/';
export const PERIVACY_PAGE = 'https://boloo.co/privacy-statement-boloo-bv/';
export const CHROME_EXATENSION_PAGE =
  'https://chrome.google.com/webstore/detail/boloo-buddy/fefpocfmkbkncjkocjbejmnmpgkhpnap';
export const BOLOO_FORWARDING_PAGE = 'https://www.cargomate.nl/';
export const BOLOO_UPDATES_PAGE = 'https://app.getbeamer.com/boloo/nl?all';
export const BOLOO_FACEBOOK_COMMUNITY =
  'https://www.facebook.com/groups/BolooCommunity/';

export const STORE_DATA_MISSING_STATUS = 'store_data_missing';
export const ORDER_INVOICE_DATA_MISSING_STATUS = 'data_missing';
export const ORDER_VALID_DATA_STATUS = 'valid';
export const FIELD_TYPE_DATE = 'date';

// TODO: get from docker env
export const SENTRY_DSN =
  'https://567e744f8b8c47e4912cd7b29a4e5e94@o298552.ingest.sentry.io/5402856';

export const INITIAL_PAGE = 1;

export const DEFAULT_VAT_RATES = [0, 9, 21];

export const FBB_FULFILMENT_METHOD = 'FBB';
export const FBR_FULFILMENT_METHOD = 'FBR';

export const FULFILMENT_METHODS = [
  {
    label: 'LVB (Logistiek via bol.com)',
    value: FBB_FULFILMENT_METHOD,
  },
  {
    label: 'Eigen fulfilment',
    value: FBR_FULFILMENT_METHOD,
  },
];

export const BOX_SIZE_XS = 'XS';
export const BOX_SIZE_SMALL = 'S';
export const BOX_SIZE_2XS = 'XXS';
export const BOX_SIZE_3XS = '3XS';
export const BOX_SIZE_LARGE = 'L';
export const BOX_SIZE_MEDIUM = 'M';

export const BOL_BOX_SIZE_OPTIONS = [
  {
    value: BOX_SIZE_3XS,
    cost_per_item: 1.4,
    delivery_cost: 1.15,
    total_cost: 2.55,
    label: '3XS (Max. 23.5 x 16.5 x 3 CM 1 KG)',
  },
  {
    value: BOX_SIZE_2XS,
    cost_per_item: 1.5,
    delivery_cost: 1.76,
    total_cost: 3.26,
    label: 'XXS (Max. 37.5 x 26 x 3 CM 2 KG)',
  },
  {
    value: BOX_SIZE_XS,
    cost_per_item: 1.65,
    delivery_cost: 3.05,
    total_cost: 4.7,
    label: 'XS (Max. 37.5 x 26 x 5 CM 5 KG)',
  },
  {
    value: BOX_SIZE_SMALL,
    cost_per_item: 2.07,
    delivery_cost: 3.53,
    total_cost: 5.6,
    label: 'S (Max. 45 x 30 x 8 CM 5 KG)',
  },
  {
    value: BOX_SIZE_MEDIUM,
    cost_per_item: 2.19,
    delivery_cost: 3.57,
    total_cost: 5.76,
    label: 'M (Max. 55 x 35 x 20 CM 8 KG)',
  },
  {
    value: BOX_SIZE_LARGE,
    cost_per_item: 2.93,
    delivery_cost: 3.89,
    total_cost: 6.82,
    label: 'L (Max. 72 x 50 x 41 CM 16 KG)',
  },
];

export const BOL_BOX_SIZE_OPTIONS_UNDER_16_EURO = [
  {
    value: BOX_SIZE_3XS,
    cost_per_item: 1.4,
    delivery_cost: 0.95,
    total_cost: 2.35,
    label: '3XS (Max. 23.5 x 16.5 x 3 CM 1 KG)',
  },
  {
    value: BOX_SIZE_2XS,
    cost_per_item: 1.5,
    delivery_cost: 1.56,
    total_cost: 3.06,
    label: 'XXS (Max. 37.5 x 26 x 3 CM 2 KG)',
  },
  {
    value: BOX_SIZE_XS,
    cost_per_item: 1.65,
    delivery_cost: 2.3,
    total_cost: 3.95,
    label: 'XS (Max. 37.5 x 26 x 5 CM 5 KG)',
  },
  {
    value: BOX_SIZE_SMALL,
    cost_per_item: 2.07,
    delivery_cost: 2.78,
    total_cost: 4.85,
    label: 'S (Max. 45 x 30 x 8 CM 5 KG)',
  },
  {
    value: BOX_SIZE_MEDIUM,
    cost_per_item: 2.19,
    delivery_cost: 2.82,
    total_cost: 5.01,
    label: 'M (Max. 55 x 35 x 20 CM 8 KG)',
  },
  {
    value: BOX_SIZE_LARGE,
    cost_per_item: 2.93,
    delivery_cost: 3.14,
    total_cost: 6.07,
    label: 'L (Max. 72 x 50 x 41 CM 16 KG)',
  },
];

export const EURO = '€';

export const ACCOUNT_SETTINGS_BACKGROUND_COLOR = '#EAEAEA';

export const COMPLETE_STORE_INFORMATION_WARNING = (
  <>
    Voordat je deze optie aan kunt zetten moet je eerst al je informatie van je
    store invullen, dat kan <a href={SETTING_STORE_ROUTE}>hier</a>
  </>
);

export const TRY_AGAIN_OR_CONTACT_BOLOO_MSG =
  'Probeer het opnieuw of neem contact op met Boloo.';
export const COPY_PRODUCT_COSTS = 'Kopieer product kostprijzen';

export const PRC_MAX_SEARCH_TERMS_PER_PRODUCT = 10;

export const GA4_MEASUREMENT_ID = 'G-KL3SFLR5W6';

export const DEFAULT_CAMPAIGN_ACCESS_USER_ID = undefined;

export const ROW_GUTTER: [Gutter, Gutter] = [12, 12];
