import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';
import { Layout, Modal, Spin, notification } from 'antd';
import dayjs from 'dayjs';

import { If } from 'tsx-control-statements/components';
import { updatePageMetaData } from '../../../utilities/dom';

import { SUBMENU } from './appSideBar/config';
import { ACCOUNT_SETTING_MEMBERSHIP_ROUTE } from '../../routes';
import { isMobileDevice } from '../../utilities/breakpoint';
import { resendVerificationEmail } from '../../actions';

import ProfileHook from '../../../hooks/profile';
import AppHeaderTitleHook from '../../../hooks/appHeaderTitle';

import AppSidebar from './appSideBar';
import AppAlert from './appAlert';
import AppHeader from './appHeader';

import './style.scss';
import {
  SubscriptionStatuses,
  useSubscription,
} from '../../services/subscriptionService/api';

const { Header, Content, Footer } = Layout;

function AppContainer({
  title,
  setTitle,
  profileData,
  isAuthenticated,
  isActiveSubscription,
  children,
}) {
  const isMobile = isMobileDevice();
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  const [showFeatureTourModal, setShowFeatureTourModal] = useState(false);

  const { subscription } = useSubscription();

  const remainingTrailDays = useMemo(() => {
    if (!subscription) {
      return null;
    }
    return dayjs(subscription.next_payment_date).diff(dayjs(), 'day');
  }, [subscription]);

  useEffect(() => {
    SUBMENU.map((item) => {
      const matchedPath = matchPath(pathname, {
        path: item.path,
        exact: true,
      });
      if (matchedPath) {
        setSelectedMenuItem(item);
        updatePageMetaData({
          title: item.title,
          metaDescription: item.pageTitle,
        });
      }
    });
    window.setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, [pathname]);

  useEffect(() => {
    if (!selectedMenuItem) {
      return;
    }
    setTitle(selectedMenuItem.title);
    updatePageMetaData({ title: selectedMenuItem.pageTitle });
  }, [selectedMenuItem]);

  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  function handleMenuClick() {
    if (!(isMobile && !isCollapsed)) {
      return;
    }
    window.setTimeout(() => {
      setIsCollapsed(true);
    }, 300);
  }

  function handleResendVerificationEmail() {
    resendVerificationEmail().then(() => {
      notification.success({
        message: 'Verificatiemail is verzonden!',
      });
    });
  }

  if (isAuthenticated && isActiveSubscription) {
    return (
      <>
        <Layout
          style={{ minHeight: '100vh' }}
          className="bear-min-height"
          hasSider
        >
          <AppSidebar
            isCollapsed={isCollapsed}
            selectedMenuItemKey={selectedMenuItem?.key}
            profileData={profileData}
            onMenuClick={() => handleMenuClick()}
            onSideBarTriggerClick={() => setIsCollapsed(!isCollapsed)}
          />
          <Layout
            className="app-layout"
            style={{ marginInlineStart: isCollapsed || isMobile ? 0 : 240 }}
          >
            <Header>
              <If condition={!profileData.is_verified}>
                <AppAlert
                  message={
                    <>
                      <span>
                        {`Verifieer je e-mailadres alsjeblieft. Verificatielink is verzonden naar ${profileData.email}`}
                        &emsp;
                      </span>
                      <a onClick={() => handleResendVerificationEmail()}>
                        Verzend de verificatie-e-mail opnieuw
                      </a>
                    </>
                  }
                />
              </If>
              <If
                condition={
                  subscription?.status === SubscriptionStatuses.Trialing &&
                  remainingTrailDays > 0
                }
              >
                <AppAlert
                  message={
                    <>
                      <span>
                        {`Je proefperiode eindigt in ${remainingTrailDays} ${
                          remainingTrailDays > 1 ? 'dagen' : 'dag'
                        }! `}
                      </span>
                      <Link to={ACCOUNT_SETTING_MEMBERSHIP_ROUTE}>
                        Jouw abonnement
                      </Link>
                    </>
                  }
                />
              </If>
              <AppHeader
                title={title}
                isMobile={isMobile}
                isCollapsed={isCollapsed}
                hasFeatureVideo={!!selectedMenuItem?.video_id}
                onProductTourClick={() => setShowFeatureTourModal(true)}
                onSideBarTriggerClick={() => setIsCollapsed(!isCollapsed)}
                helpDocLink={selectedMenuItem?.helpDocLink}
              />
            </Header>
            <Content>{children}</Content>
            <Footer>
              Gemaakt met ❤️ door Boloo © {new Date().getFullYear()}
            </Footer>
          </Layout>
        </Layout>
        <If condition={showFeatureTourModal}>
          <Modal
            open
            footer={null}
            width="70%"
            wrapClassName="feature-tour-modal"
            onCancel={() => setShowFeatureTourModal(false)}
          >
            <div
              className={`wistia_embed wistia_async_${selectedMenuItem?.video_id} videoFoam=true autoPlay=True`}
            />
            <Spin />
          </Modal>
        </If>
      </>
    );
  }
  return { children };
}

export default ProfileHook(AppHeaderTitleHook(AppContainer));
