import { message } from 'antd';
import productsAxios from '../axiosInstance';
import captureErrorAndShowMessage from '../../../../utilities/errors';

export async function uploadCSVFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  return productsAxios
    .post(`/cost_prices/upload-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch(() => {});
}

export async function getProductsCsv(bol_store_name: string) {
  return productsAxios
    .get(`/cost_prices/start-export-csv-file?store_name=${bol_store_name}`)
    .then((res) => res.data)
    .catch((e) => {
      captureErrorAndShowMessage(e, e.response.data.detail);
    });
}

export async function getProductDownloadCsvStatus(jobId: string) {
  return productsAxios
    .get(`/cost_prices/get-csv-download-status/${jobId}`)
    .then((res) => res.data)
    .catch((e) => {
      captureErrorAndShowMessage(e, e.response.data.detail);
    });
}

export const downloadCsvFile = async (jobId, storeName, signal) => {
  try {
    const formatter = new Intl.DateTimeFormat('nl-NL', {
      timeZone: 'Europe/Amsterdam',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const parts = formatter.formatToParts(new Date());
    const formattedDate = `${parts[0].value}-${parts[2].value}-${parts[4].value} ${parts[6].value}.${parts[8].value}.${parts[10].value}`;
    const filename = `${storeName} - Producten Export - ${formattedDate}.csv`;

    const response = await productsAxios.get(
      `/cost_prices/download-csv-file/${jobId}`,
      {
        responseType: 'blob',
        signal, // Add the AbortSignal here
      },
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    if (error.name !== 'CanceledError') {
      message.error('An error occurred while downloading the CSV file.', error);
    }
    throw error;
  }
};

export async function getProductsCsvStatus(jobId: string) {
  return productsAxios
    .get(`/cost_prices/get-csv-status/${jobId}`)
    .then((res) => res.data)
    .catch((e) => {
      captureErrorAndShowMessage(e, e.response.data.detail);
    });
}

export async function getBoxSizeOptions() {
  return productsAxios
    .get('/box_sizes')
    .then((res) => res.data)
    .catch(() => []);
}
