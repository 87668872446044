import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin, Row, Col, Form, Input, Button, message, Select } from 'antd';

import {
  getOrderDetails,
  sendOrderInvoice,
  getOrderInvoice,
  updateOrderCustomer,
  getOrderInvoiceSettings,
} from '../../../actions/store';
import AppHeaderTitleHook from '../../../../hooks/appHeaderTitle';

import { STORE_ORDERS_ROUTE } from '../../../routes.ts';

import GenericCard from '../../../../components/bearCards/genericCard';
import OrderInvoiceTemplate from '../../../components/orderInvoiceTemplate';
import BearSuccessModal from '../../../components/bearSuccessModal';

import { INVOICE_COUNTRIES } from './constants';

import './style.scss';
import { IMAGE_STATIC_PATH } from '../../../../constants.ts';

function StoreEditOrderInvoice({ history, match, setTitle }) {
  const { orderId } = match.params;
  const [form] = Form.useForm();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState({});
  const [invoiceTemplate, setInvoiceTemplate] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    getOrderDetails(orderId).then((response) => {
      setTitle(response.data.order_id);
      setOrderDetail(response.data);
      form.setFieldsValue(response.data.customer);
      getOrderInvoiceSettings().then((invoiceData) => {
        setInvoiceTemplate(invoiceData.data);
        setLoading(false);
      });
    });
  }, [orderId]);

  function onSubmit(values) {
    setSaving(true);
    updateOrderCustomer(values, orderDetail.customer.id).then(() => {
      sendOrderInvoice(orderId, true).then(() => {
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 2 * 1000);
        setSaving(false);
      });
    });
  }

  function downloadInvoice() {
    const downloadingMessageKey = 'downloadingProgress';
    message.loading({
      content: 'Invoice wordt gedownload',
      downloadingMessageKey,
      duration: 10,
    });
    const updatedFormValues = form.getFieldsValue();
    updateOrderCustomer(updatedFormValues, orderDetail.customer.id).then(() => {
      getOrderInvoice(orderId, true)
        .then((response) => {
          if (response.data.invoice) {
            message.destroy(downloadingMessageKey);
            message.success({
              content: 'Invoice is gedownload',
              downloadingMessageKey,
              duration: 2,
            });
            window.open(response.data.invoice);
          }
        })
        .catch(() => {
          message.destroy(downloadingMessageKey);
        });
    });
  }

  function onFormValuesChange(values) {
    orderDetail.customer = {
      ...orderDetail.customer,
      ...values,
    };
    setOrderDetail({ ...orderDetail });
  }

  if (loading) {
    return (
      <div className="app-spinner">
        <Spin />
      </div>
    );
  }

  return (
    <div className="edit-order-invoice">
      <If condition={showSuccessModal}>
        <BearSuccessModal
          title="Factuur is verzonden"
          successImage="campain_success"
          handleOnClose={() => setShowSuccessModal(false)}
        />
      </If>
      <div className="back-block bear-light-regular-text">
        <a
          onClick={() => history.push(STORE_ORDERS_ROUTE)}
          className="bear-back-text bear-light-regular-text"
        >
          <i className="fad icon-long-arrow-left" />
          <span>Alle bestellingen</span>
        </a>
      </div>
      <Row gutter={16}>
        <Col lg={14} md={14} sm={24} xs={24}>
          <OrderInvoiceTemplate
            orderData={orderDetail}
            templateData={invoiceTemplate}
          />
        </Col>
        <Col lg={10} md={10} sm={24} xs={24} className="order-edit-cards">
          <GenericCard title="Klantgegevens">
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => onSubmit(values)}
              onValuesChange={(value, values) => onFormValuesChange(values)}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Voornaam"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'Voornaam is verplicht',
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Achternaam" name="last_name">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Bedrijfsnaam" name="company">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Huisnummer" name="house_number">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Straatnaam" name="street_number">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Postcode" name="zip_code">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Woonplaats" name="city">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Land" name="country_code">
                    <Select
                      showSearch
                      size="large"
                      optionFilterProp="children"
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {INVOICE_COUNTRIES.map((item) => (
                        <Select.Option
                          key={item.code}
                          label={item.name}
                          value={item.code}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Btw-nummer" name="vat_number">
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="KVK nummer"
                    name="chamber_of_commerce_number"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Telefoonnummer"
                    name="delivery_phone_number"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </GenericCard>
          <If condition={orderDetail.is_invoice_sent}>
            <div className="bear-flex bear-flex-ac bear-mar-t-12">
              <img
                src={`${IMAGE_STATIC_PATH}/alert.png`}
                className="alert-icon"
                alt="boloo-alert"
              />
              <p className="bear-light-help-text bear-mar-l-8">
                Er bestaat al een factuur voor deze bestelling. Opnieuw
                downloaden of versturen vervangt de voorgaande factuur.
              </p>
            </div>
          </If>
          <div className="bear-mar-t-12 bear-ta-right">
            <Button
              className="bear-mar-r-16"
              size="large"
              type="primary"
              role="button"
              data-testid="download-invoice"
              onClick={() => downloadInvoice()}
              tabIndex={0}
            >
              Downloaden
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default AppHeaderTitleHook(withRouter(StoreEditOrderInvoice));
