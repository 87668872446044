export const initialState = {
  title: '',
};

const SET_APP_HEADER_TITLE = 'EVENT/SET_APP_HEADER_TITLE';

export const setAppHeaderTitle = (data) => {
  return {
    type: SET_APP_HEADER_TITLE,
    payload: data,
  };
};

export const appHeaderTitleReducer = (state = initialState, action = null) => {
  switch (action?.type) {
    case SET_APP_HEADER_TITLE:
      return {
        ...state,
        title: action?.payload,
      };
    default:
      return state;
  }
};

export default appHeaderTitleReducer;
