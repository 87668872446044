import useSWR, { KeyedMutator } from 'swr';
import type { StockNotificationsInterface } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useStockNotifications(bolOfferId: string | null): {
  stockNotifications: StockNotificationsInterface;
  isLoading: boolean;
  isError: Error;
  mutate: KeyedMutator<StockNotificationsInterface>;
} {
  const { data, error, isLoading, mutate } = useSWR(
    bolOfferId ? `/offers/${bolOfferId}/stock_setting` : null,
    (url) => productsAxios.get(url).then((res) => res.data),

    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    stockNotifications: data || null,
    isLoading,
    isError: error,
    mutate,
  };
}
