import React from 'react';
import { Card, CardProps } from 'antd';

import './style.scss';

/**
 * CommonCard is a functional React component that displays a card
 * with a customizable title, description, and optional extra actions.
 * We override some styles for skeleton on body loading and also no hover over the title
 */
function CommonCard({
  children,
  title,
  ...props
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
} & CardProps) {
  return (
    <Card title={title} className="common-card" {...props}>
      {children}
    </Card>
  );
}

export default CommonCard;
