import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Col, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { TOOLTIP_COLOUR } from '../../../../constants';

import './style.scss';

/*
const ShipmentItemToolTipContent = ({ item }) => {
  return (
    <div className="shipment-item-container">
      <div>Fulfilment: {item.order_item.fulfilment_display || '-'}</div>
      <div className="product-title">Product: {item.order_item.title}</div>
      <div>Aantal stuks: {item.order_item.quantity}</div>
    </div>
  );
};
<Tooltip
                color={TOOLTIP_COLOUR}
                overlayClassName="shipment-items-tooltip"
                placement="topLeft"
                title={
                  <>
                    {shipment.shipment_items.map((item, index) => (
                      <ShipmentItemToolTipContent item={item} key={index} />
                    ))}
                    {shipment.shipment_items.length === 0 &&
                      'Er zijn geen shipment items'}
                  </>
                }
              >
                <div className="shipment-id">#{shipment.shipment_id}</div>
              </Tooltip>
 */

const TrackAndTraceLink = ({ shipment }) => {
  if (!shipment.track_and_trace_link) {
    return (
      <span data-testid="track-and-trace-link">
        {shipment.track_and_trace_code}
        <br />
        {shipment.transporter_display}
      </span>
    );
  }

  return (
    <span data-testid="track-and-trace-link" className="track-and-trace">
      <a href={shipment.track_and_trace_link} target="_blank" rel="noreferrer">
        {shipment.track_and_trace_code}
      </a>
      <br />
      {shipment.transporter_display}
    </span>
  );
};

const ShipmentList = ({ shipments }) => {
  return (
    <div>
      {shipments.map((shipment, index) => {
        return (
          <Row
            key={shipment.shipment_id}
            gutter={16}
            align="middle"
            className="shipments-container"
          >
            <Col span={2}>
              <Avatar
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #78909c',
                  verticalAlign: 'middle',
                  borderRadius: '25%',
                  fontWeight: 'bold',
                  color: TOOLTIP_COLOUR,
                }}
                shape="square"
              >
                {index + 1}
              </Avatar>
            </Col>

            <Col span={10}>
              <div className="shipment-id">#{shipment.shipment_id}</div>
              <div className="bear-light-help-text">
                Datum:
                {dayjs(shipment.shipment_date_time).format(
                  'MMM DD, YYYY HH:mm'
                )}
              </div>
            </Col>
            <Col span={10}>
              <TrackAndTraceLink shipment={shipment} />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

ShipmentList.propTypes = {
  shipments: PropTypes.array.isRequired,
};

export default ShipmentList;
