import React, { lazy, Suspense } from 'react';

import { Switch, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { LoggedInRoute } from './utilities';
import {
  BOLOO_AI_ASK_ROUTE,
  BOLOO_AI_BASE_ROUTE,
  BOLOO_AI_KEYWORD_EXTRACT,
  BOLOO_AI_LISTING_ROUTE,
  BOLOO_AI_REVIEW_ANALYSER,
} from '../routes';
import ProfileHook from '../../hooks/profile';
import Header from '../bolooAi/components/header';

const Base = lazy(
  () => import(/* webpackPrefetch: true */ '../bolooAi/pages/base'),
);
const Listing = lazy(
  () => import(/* webpackPrefetch: true */ '../bolooAi/pages/listing'),
);
const Ask = lazy(
  () => import(/* webpackPrefetch: true */ '../bolooAi/pages/ask'),
);

const KeywordExtractor = lazy(
  () => import(/* webpackPrefetch: true */ '../bolooAi/pages/keywordExtractor'),
);

const ReviewAnalyser = lazy(
  () => import(/* webpackPrefetch: true */ '../bolooAi/pages/reviewAnalyser'),
);

function BolooAiRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;
  const location = useLocation();
  return (
    <>
      <Header
        isBaseRoute={[
          BOLOO_AI_BASE_ROUTE,
          `${BOLOO_AI_BASE_ROUTE.slice(0, -1)}`,
        ].includes(location.pathname)}
      />
      <Switch>
        <Suspense fallback={<Spin className="app-spinner" />}>
          <LoggedInRoute
            exact
            path={BOLOO_AI_BASE_ROUTE}
            component={Base}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={BOLOO_AI_LISTING_ROUTE}
            component={Listing}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={BOLOO_AI_ASK_ROUTE}
            component={Ask}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={BOLOO_AI_KEYWORD_EXTRACT}
            component={KeywordExtractor}
            isAuthenticated={isAuthenticated}
          />
          <LoggedInRoute
            exact
            path={BOLOO_AI_REVIEW_ANALYSER}
            component={ReviewAnalyser}
            isAuthenticated={isAuthenticated}
          />
        </Suspense>
      </Switch>
    </>
  );
}

export default ProfileHook(BolooAiRoute);
