import useSWR from 'swr';
import instance from '../../axios';
import * as endpoints from '../endpoints';
import { USER_API_PATH } from '../../endpoints';
import {
  CREATE_SUB_ACCOUNT_PATH,
  LIST_ACCOUNTS_PATH,
  USER_GET_TOKENS_PATH,
} from '../endpoints';
import { setAccounts } from '../reducers/accountsReducer';
import { setTokens } from '../reducers/tokensReducer';
import captureErrorAndShowMessage from '../../utilities/errors';

export const getTimezones = () => {
  return instance.get(endpoints.TIMEZONE_LIST_API_PATH);
};

// Academy
export const getCourses = () => {
  return instance.get(endpoints.COURSES_LIST_API_PATH);
};
export const sendAcademyView = () => {
  return instance.post(endpoints.ADD_ACADEMY_VIEW_API_PATH, {
    hideNotification: true,
  });
};
export const updateLessonStatus = (data) => {
  return instance.post(endpoints.UPDATE_LESSON_STATUS_API_PATH, data);
};

export const updateInvoiceEmail = (data) => {
  return instance.post(endpoints.UPDATE_INVOICE_EMAIL_API_PATH, data);
};

export const getCompany = () => {
  return instance.get(endpoints.COMPANY_API_PATH);
};

export async function createOrUpdateCompany(data, companyId: number | null) {
  let path = endpoints.COMPANY_API_PATH;
  let method = 'post';
  if (companyId) {
    path = path.concat(String(companyId), '/');
    method = 'patch';
  }
  return instance.request({
    method,
    url: path,
    data,
  });
}

export const createSubAccount = (data) => {
  return instance.post(CREATE_SUB_ACCOUNT_PATH, data);
};

export interface UserAccountInterface {
  id: number;
  icon: string;
  nickname: string;
  is_active_subscription: boolean;
  is_parent: boolean;
  is_store_enabled: boolean;
}

export async function getUserAccounts(): Promise<UserAccountInterface[]> {
  return instance.get(LIST_ACCOUNTS_PATH).then((response) => response.data);
}

// Remove reduce that is calling this multiple times TODO
export function getAccounts() {
  return (dispatch) => {
    getUserAccounts()
      .then((response) => dispatch(setAccounts(response)))
      .catch((e) => {
        captureErrorAndShowMessage(e);
        dispatch(setAccounts(null));
      });
  };
}

export const getTokens = () => {
  return (dispatch) => {
    instance
      .post(USER_GET_TOKENS_PATH)
      .then((response) => {
        dispatch(setTokens(response.data));
      })
      .catch((e) => {
        captureErrorAndShowMessage(e);
        dispatch(setTokens(null));
      });
  };
};

export const createOrUpdateUserProfile = (data, userId) => {
  let path = USER_API_PATH;
  let method = 'post';
  if (userId) {
    path = path.concat(userId, '/');
    method = 'patch';
  }
  return instance.request({
    method,
    url: path,
    data,
  });
};

export const isUserAlreadyExists = (email) => {
  return instance.post(endpoints.USER_EXISTS_API_PATH, { email });
};

export const registerUser = (data) => {
  return instance.post(endpoints.USER_REGISTER_API_PATH, data);
};

export const unsubscribeSubaccount = (unsubscribeUserId = undefined) => {
  let path = endpoints.UNSUBSCRIBE_SUBACCOUNT_API_PATH;
  if (unsubscribeUserId) {
    path = path.concat('?unsubscribe_user_id=', unsubscribeUserId);
  }

  return instance.post(path);
};

export const getActiveSubaccounts = () => {
  return instance.get(endpoints.LIST_ACTIVE_SUBACCOUNTS_API_PATH);
};

export const updateSubaccountSubscription = (data) => {
  return instance.post('payments/update_subscription/', data);
};

export const loginUser = (data) => {
  return instance.post(endpoints.LOGIN_API_PATH, data);
};

export const resetUserPassword = (data) => {
  return instance.post(endpoints.PASSWORD_RESET_PATH, data);
};

export const getTopolEditorSettings = () => {
  return instance.get(endpoints.TOPOL_EDITOR_SETTINGS_API_PATH);
};

export const resendVerificationEmail = () => {
  return instance.post(endpoints.RESEND_VERIFICATION_EMAIL_API_PATH);
};

export interface Country {
  code: string;
  name: string;
}
export function useSupportedCountries(): {
  countries: Country[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    endpoints.COUNTRIES_LIST_API_PATH,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    countries: data,
    isLoading,
    isError: error,
  };
}

export const createLead = (
  firstName,
  email,
  utmSource,
  utmCampaign,
  utmMedium,
  utmContent,
  utmTerm,
  sourceForm,
) => {
  return instance.post(endpoints.OPTIN_CREATE_LEAD, {
    first_name: firstName,
    email,
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
    utm_content: utmContent,
    utm_term: utmTerm,
    source_form: sourceForm,
  });
};
