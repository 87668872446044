import React from 'react';
import { Row, Col, Spin, Space } from 'antd';

import { Choose, Otherwise, When } from 'tsx-control-statements/components';
import { DASHBOARD_PRODUCT_GEOGRAPHICAL_TABS } from '../constants';
import { getDashboardCampaignStatistics } from '../../utilities';
import GeographicMetricsCard from '../../components/geographicalMetricsCard';
import ProductDashboardStats from '../../../productRankingChecker/components/productDashboardStats';
import { Retailer } from '../../../../reducers/profileReducer';
import LvbBoxSizeChecker from '../../../financialReportingService/components/LvbBoxSizeChecker';
import { ROW_GUTTER } from '../../../constants';
import CommonCard from '../../../components/commonCard';

function StoreDashboardInsights({
  isLoading,
  statistics,
  campaignStatistics,
  campaignStatisticsLoading,
  selectedStore = {} as Partial<Retailer>,
  dateRange = null,
}) {
  const [delivered, opens, clicks] = getDashboardCampaignStatistics(
    campaignStatistics,
    selectedStore,
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row gutter={ROW_GUTTER}>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <GeographicMetricsCard
            title="NL versus BE"
            defaultTab="revenue"
            isLoading={isLoading}
            statistics={statistics || {}}
            tabs={DASHBOARD_PRODUCT_GEOGRAPHICAL_TABS}
            selectedStore={selectedStore}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <CommonCard title="Campagne statistieken">
            <Choose>
              <When condition={campaignStatisticsLoading}>
                <Spin size="small" />
              </When>
              <Otherwise>
                {delivered > 0 && (
                  <div key="delivered">
                    <p className="bear-light-regular-text bear-text-uc statistics-title">
                      AFGELEVERD
                    </p>
                    <p className="bear-bold-title statistics-value">
                      {delivered}
                    </p>
                  </div>
                )}
                {opens > 0 && (
                  <div key="opened-emails">
                    <p className="bear-light-regular-text bear-text-uc statistics-title">
                      Geopende mails
                    </p>
                    <p className="bear-bold-title statistics-value">{opens}</p>
                  </div>
                )}
                {clicks > 0 && (
                  <div key="clicks">
                    <p className="bear-light-regular-text bear-text-uc statistics-title">
                      Clicks
                    </p>
                    <p className="bear-bold-title statistics-value">{clicks}</p>
                  </div>
                )}
              </Otherwise>
            </Choose>
          </CommonCard>
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <LvbBoxSizeChecker dateRange={dateRange} />
          <ProductDashboardStats dateRange={dateRange} />
        </Col>
      </Row>
    </Space>
  );
}

export default StoreDashboardInsights;
