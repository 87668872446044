import instance from '../axios';
import * as endpoints from '../endpoints';
import { CHROME_EXTENSION_ID, DEFAULT_PAGE_SIZE } from '../constants';
import {
  resetAllTokens,
  setActiveToken,
  setAdminToken,
  setParentToken,
} from '../retailer/reducers/tokensReducer';
import { store } from '../retailer/store';
import { resetAllAccounts } from '../retailer/reducers/accountsReducer';
import { resetCampaignData } from '../retailer/reducers/campaignReducer';
import { UserProfileInterface } from '../reducers/profileReducer';

export async function getUserProfile(): Promise<{
  data: UserProfileInterface;
}> {
  return instance.get(endpoints.USER_API_PATH);
}

export const addAccessUserId = (path, accessUserId) => {
  if (accessUserId) {
    return path.concat('?access_user_id=', accessUserId);
  }

  return path;
};

export const updatePathWithTimeValues = (path, startTime, endTime) => {
  let filterPath = path.concat('?start_date=', startTime);
  if (endTime) filterPath = filterPath.concat('&end_date=', endTime);
  return filterPath;
};

export const updatePathWithPagination = (path, pageNum) => {
  let updatedPath = path.concat('?page=', pageNum);
  updatedPath = updatedPath.concat('&page_size=', DEFAULT_PAGE_SIZE);
  return updatedPath;
};

export const updatePathWithPaginationAndTimeValues = (
  path,
  pageNum,
  interval,
) => {
  let updatedPath = updatePathWithPagination(path, pageNum);
  updatedPath = updatePathWithTimeValues(
    updatedPath,
    interval[0],
    interval[1],
  ).replace('?start_date=', '&start_date=');
  return updatedPath;
};

export const requestResetPassword = (data) => {
  return instance.post(endpoints.PASSWORD_RESET_REQUEST_PATH, data);
};

export const clearSessionData = () => {
  store.dispatch(resetAllTokens());
  store.dispatch(resetAllAccounts());
  store.dispatch(resetCampaignData());
};

export const sendTokenToChromeExtension = (jwt) => {
  try {
    const isChrome =
      navigator.userAgent.includes('Chrome') &&
      chrome.runtime &&
      navigator.vendor.includes('Google Inc'); // This line is getting deprecated can be removed
    // Only send token to chrome browsers
    if (isChrome) {
      // eslint-disable-next-line
      console.log('Sending JWT to Boloo Buddy');
      chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { jwt }, (response) => {
        if (chrome.runtime.lastError) {
          console.warn('Extension not installed');
          return false;
        }
        if (!response) {
          console.warn('No response from exntension');
          return false;
        }
        if (!response.success) {
          // eslint-disable-next-line
          console.warn('Did not receive a success from extension', response);
          return response;
        }
        // eslint-disable-next-line
        console.log('Chrome extension JWT set!');
        return true;
      });
    }
  } catch (error) {
    // eslint-disable-next-line
    console.warn('Unable to send token to chrome extension', error);
  }
};

export const isImpersonating = () => {
  return store.getState().tokensReducer.adminToken !== null;
};

export const impersonate = (userId) => {
  const { parentToken } = store.getState().tokensReducer;
  const { adminToken } = store.getState().tokensReducer;

  // Check if we are already impersonating
  if (adminToken !== null) {
    // Restore the admin session before switching to a new one
    store.dispatch(setParentToken(adminToken));
    store.dispatch(setActiveToken(adminToken));
  } else if (parentToken === null) {
    alert('Cannot, no parent_user_token present!');
    return;
  }
  const path = 'user/get_impersonation_token/';
  instance.post(path, { target_id: userId }).then((response) => {
    if (!response.data.token) {
      return;
    }

    // Set the admin token, so we can switch back and
    // detect if we are impersonating another user
    const currentToken = store.getState().tokensReducer.parentToken;
    store.dispatch(setAdminToken(currentToken));
    store.dispatch(setParentToken(response.data.token));
    store.dispatch(setActiveToken(response.data.token));
    document.location = '/';
  });
};

export const stopImpersonating = () => {
  const { adminToken } = store.getState().tokensReducer;
  store.dispatch(setParentToken(adminToken));
  store.dispatch(setActiveToken(adminToken));
  store.dispatch(setAdminToken(null));
  document.location = '/';
};

export const getActiveUserToken = () => {
  return store.getState().tokensReducer.activeToken;
};
