import React from 'react';
import { Button } from 'antd';

function DefaultContent({
  onClick,
}: {
  onClick: (e: React.MouseEvent) => void;
}) {
  return (
    <>
      <p className="ant-upload-text">
        Klik of sleep het bestand naar dit gebied om het te uploaden
      </p>
      <Button type="link" onClick={onClick} style={{ whiteSpace: 'normal' }}>
        Download hier het ingevulde template voor het importeren van kostprijzen
      </Button>
    </>
  );
}

export default DefaultContent;
