import React, { useEffect, useState } from 'react';
import { Card, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { TRIGGERS } from '../../../../campaignManagement/constants';
import { getOrderSentEmails } from '../../../../actions/store';
import ViewEmailModal from '../../../../campaignManagement/common/viewEmailModal';

import './style.scss';

const StoreDrawerSentEmails = ({ order = {} }) => {
  const [loading, setLoading] = useState(true);
  const [sentEmails, setSentEmails] = useState([]);
  const [showViewEmailModal, setShowViewEmailModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOrderSentEmails(order.id).then((response) => {
      setLoading(false);
      setSentEmails(response.data.results);
    });
  }, [order]);

  function toggleShowViewEmailModal() {
    setShowViewEmailModal(!showViewEmailModal);
  }

  return (
    <Card
      title="Verzonden emails"
      className="retailer-drawer-sent-emails bear-mar-t-16 bear-mar-b-16"
      bordered={false}
      loading={loading}
      extra={sentEmails.length}
    >
      {sentEmails.length
        ? sentEmails.map((email) => {
            return (
              <div key={email.id} className="sent-email-details">
                <p>
                  <span className="bear-light-regular-text">Campagne</span>
                  <a
                    href={`/campaigns/${email.campaign_id}/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{email.campaign_name || '-'}</span>
                  </a>
                </p>
                <p>
                  <span className="bear-light-regular-text">Onderwerp</span>
                  <a
                    onClick={() => toggleShowViewEmailModal()}
                    className="email-subject"
                  >
                    {email.subject || '-'}
                  </a>
                </p>
                <p>
                  <span className="bear-light-regular-text">Trigger</span>
                  <span>
                    {TRIGGERS.map((item) =>
                      item.value === email.trigger_type ? (
                        <Tooltip
                          key={item.value}
                          placement="left"
                          title={item.helpText}
                        >
                          {item.title}
                        </Tooltip>
                      ) : (
                        ''
                      )
                    )}
                  </span>
                </p>
                <p>
                  <span className="bear-light-regular-text">Met factuur</span>
                  <span>{email.has_invoice ? 'Ja' : 'Nee'}</span>
                </p>
                <p>
                  <span className="bear-light-regular-text">Verzonden op</span>
                  <span>
                    {dayjs(email.due_date).format('MMM DD, YYYY HH:mm')}
                  </span>
                </p>
                <Divider />
                <If condition={showViewEmailModal}>
                  <ViewEmailModal
                    campaignId={email.campaign_id}
                    selectedEmailTask={email}
                    handleClose={() => toggleShowViewEmailModal()}
                  />
                </If>
              </div>
            );
          })
        : 'Geen verzonden e-mails gevonden'}
    </Card>
  );
};

export default StoreDrawerSentEmails;
