import React from 'react';
import { Modal } from 'antd';

import './style.scss';
import { IMAGE_STATIC_PATH } from '../../../constants';

function BearSuccessModal({ title, successImage, handleOnClose }) {
  return (
    <Modal
      open
      centered
      width={437}
      className="success-modal-container"
      closable={false}
      footer={null}
      onCancel={() => handleOnClose()}
    >
      <div className="campaign-success bear-width bear-flex-col bear-flex-ac">
        <p className="bear-heading-2-bold bear-mar-b-20">{title}</p>
        <img
          alt="successful-content"
          src={`${IMAGE_STATIC_PATH}/${successImage}.png`}
        />
      </div>
    </Modal>
  );
}

export default BearSuccessModal;
