import React from 'react';
import { Switch } from 'react-router-dom';

import * as routes from '../routes';

import { LoggedInRoute } from './utilities';

import Impersonation from '../impersonation';
import ProfileHook from '../../hooks/profile';

function ImpersonationRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;
  return (
    <Switch>
      <LoggedInRoute
        exact
        component={Impersonation}
        isAuthenticated={isAuthenticated}
        path={routes.IMPERSONATION_ROUTE}
      />
    </Switch>
  );
}

export default ProfileHook(ImpersonationRoute);
