import { saveAs } from 'file-saver';
import {
  formatNumber,
  formatNumberToDutch,
  formatNumberToCurrency,
  toFixedValue,
  formatAmount,
  formatValue,
} from '../../utilities';

export {
  formatNumber,
  formatNumberToDutch,
  formatNumberToCurrency,
  toFixedValue,
  formatAmount,
  formatValue,
};

export const getParameterByName = (name, url?) => {
  try {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (e) {
    return null;
  }
};

// eslint-disable-next-line consistent-return
export const getCookieByName = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const arraymove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const fieldsSorter = (fields) => {
  return function (a, b) {
    return fields
      .map(function (o) {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) return dir;
        if (a[o] < b[o]) return -dir;
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p || n;
      }, 0);
  };
};

export const removeEmpty = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') removeEmpty(val);
    else if (val == null || (val === '' && typeof val === 'string'))
      delete obj[key];
  });
};

export const reduceSum = (items, key) => {
  return items.reduce((total, item) => {
    return total + parseFloat(item[key]);
  }, 0);
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const allowNumbersOnly = (e, acceptDecimal = true) => {
  const code = e.which ? e.which : e.keyCode;
  if (code !== 37 && code !== 39) {
    const isNotNumberCode =
      code > 31 && (code < 48 || code > 57) && (code < 96 || code > 105);
    if (acceptDecimal) {
      if (code !== 190 && isNotNumberCode) {
        e.preventDefault();
      }
    } else if (isNotNumberCode) {
      e.preventDefault();
    }
  }
};

export const exportToCSV = (filename, rows) => {
  const processRow = (row) => {
    let finalVal = '';
    for (let j = 0; j < row.length; j += 1) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, filename); // TODO Test
};
