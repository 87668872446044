import React from 'react';
import { Switch } from 'react-router-dom';

import * as routes from '../routes';
import { LoggedInRoute } from './utilities';

import ProfileHook from '../../hooks/profile';
import StoreInventory from '../storeManagement/inventory';
import StoreOrdersManagement from '../storeManagement/orders';
import InventoryProductDetail from '../storeManagement/inventory/detail';
import ProductPerformance from '../storeManagement/productsPerformance';
import StoreEditOrderInvoice from '../storeManagement/orders/editOrderInvoice';
import InvoiceManagement from '../invoiceManagement';
import StoreNotConnected from '../components/notConnectedState';

function StoreRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;
  return (
    <Switch>
      <LoggedInRoute
        exact
        path={routes.STORE_INVENTORY_ROUTE}
        component={StoreInventory}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.DASHBOARD_PRODUCTS_ROUTE}
        component={ProductPerformance}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.STORE_INVENTORY_PRODUCT_DETAIL_ROUTE}
        component={InventoryProductDetail}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.STORE_INVENTORY_PRODUCT_EDIT_ROUTE}
        component={InventoryProductDetail}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.STORE_ORDERS_ROUTE}
        component={StoreOrdersManagement}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.STORE_ORDERS_EDIT_ROUTE}
        component={StoreEditOrderInvoice}
        isAuthenticated={isAuthenticated}
      />
      <LoggedInRoute
        exact
        path={routes.INVOICING_BASE_ROUTE}
        component={
          profileData.is_store_enabled ? InvoiceManagement : StoreNotConnected
        }
        isAuthenticated={isAuthenticated}
      />
    </Switch>
  );
}

export default ProfileHook(StoreRoute);
