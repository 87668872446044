import { getProductIdFromUrl } from '../../../utilities/app';
import productsAxios from '../axiosInstance';

import type { OfferProductInterface, ProductInterface } from './types';

export async function getBolProductByBolId(
  bolId: string,
): Promise<ProductInterface> {
  return productsAxios
    .get(`/products/product_id/${bolId}`)
    .then((res) => res.data);
}

export async function getOfferProductByEan(
  ean: string,
): Promise<OfferProductInterface | null> {
  return productsAxios
    .get(`/offers?eans=${ean}`)
    .then((res) => res?.data?.results?.[0] || null);
}

export async function getProductEanFromBolUrl(value: string): Promise<string> {
  const productValue = getProductIdFromUrl(value);
  if (!productValue && /^\d+$/.test(value)) {
    return value;
  }
  if (!productValue) {
    throw new Error('No product value');
  }
  const { ean } = await getBolProductByBolId(productValue);
  return ean;
}

export async function getProductByEan(
  ean: string,
  includeCategory: boolean = false,
) {
  // Fetch the product details using the product API
  const productRes = await productsAxios.get(`/products/ean/${ean}`);
  const product = productRes.data;
  if (!includeCategory) {
    return product;
  }
  const categoryRes = await productsAxios.get(
    `/products/ean/${ean}/categories`,
  );
  product.category = categoryRes.data;
  return product;
}

export function getProductVisits(ean, start_date, end_date, country) {
  return productsAxios.get(
    `/products/ean/${ean}/visits?start_date=${start_date}&end_date=${end_date}&country=${country}`,
  );
}
