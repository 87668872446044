import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LoggedInRoute } from './utilities';
import {
  CAMPAIGN_BASE_ROUTE,
  CAMPAIGN_CREATE_ROUTE,
  CAMPAIGN_EDIT_ROUTE,
  CAMPAIGN_DETAIL_ROUTE,
} from '../routes';
import ProfileHook from '../../hooks/profile';

const CampaignManagement = lazy(
  () => import(/* webpackPrefetch: true */ '../campaignManagement'),
);

const CampaignAddOrEdit = lazy(
  () =>
    import(
      /* webpackPrefetch: true */ '../campaignManagement/campaignAddOrEdit'
    ),
);

const CampaignDetail = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../campaignManagement/campaignDetail'),
);

function CampaignRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <Suspense fallback={<Spin className="app-spinner" />}>
      <Switch>
        <LoggedInRoute
          exact
          path={CAMPAIGN_BASE_ROUTE}
          component={CampaignManagement}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={CAMPAIGN_CREATE_ROUTE}
          component={CampaignAddOrEdit}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={CAMPAIGN_EDIT_ROUTE}
          component={CampaignAddOrEdit}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={CAMPAIGN_DETAIL_ROUTE}
          component={CampaignDetail}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </Suspense>
  );
}

export default ProfileHook(CampaignRoute);
