import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import instance from '../../../axios';
import captureErrorAndShowMessage from '../../../utilities/errors';
import {
  DIRECT_DEBIT,
  METHOD_BANCONTACT,
  METHOD_CREDIT_CARD,
  METHOD_IDEAL,
} from '../../constants';

let baseURL = 'https://subscriptions.boloo.co';

if (process.env.TARGET_ENV === 'development') {
  baseURL = 'http://localhost:8006';
}

if (process.env.TARGET_ENV === 'stage') {
  baseURL = 'https://subscriptions.staging.boloo.co';
}

export enum SubscriptionCustomerTypes {
  Personal = 'personal',
  Business = 'business',
}

export interface SubscriptionCustomer {
  id: number;
  full_name: string;
  company_name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  vat_number: string;
  email: string;
  customer_type: SubscriptionCustomerTypes;
  send_invoice_to_email: boolean;
}

export enum SubscriptionPaymentMethods {
  Ideal = METHOD_IDEAL,
  CreditCard = METHOD_CREDIT_CARD,
  BanContact = METHOD_BANCONTACT,
  DirectDebit = DIRECT_DEBIT,
}

export enum SubscriptionPlanIntervals {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export interface SubscriptionPlanInterface {
  created_at: string;
  updated_at: string | null;
  id: number;
  name: string;
  slug: string;
  price: number;
  product_tracking_limit: number;
  interval: SubscriptionPlanIntervals;
  is_addon: boolean;
}

export interface SubscriptionInterface {
  created_at: string;
  updated_at: string | null;
  id: number;
  boloo_user_id: number;
  status: SubscriptionStatuses;
  next_payment_date: string | null;
  start_date: string | null;
  end_date: string | null;
  customer_id: number;
  plan: SubscriptionPlanInterface;
}

export enum SubscriptionStatuses {
  Active = 'active',
  Unsubscribed = 'unsubscribed',
  Trialing = 'trialing',
  Expired = 'expired',
  PastDue = 'past_due',
  Canceled = 'canceled',
}

export const ActiveSubscriptionStatuses = [
  SubscriptionStatuses.Active,
  SubscriptionStatuses.Trialing,
];

export interface SubscriptionPaymentInterface {
  created_at: string;
  updated_at: string;
  id: number;
  status: SubscriptionPaymentStatus;
  invoice_id: number;
}

enum SubscriptionPaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Processing = 'processing',
  Authorized = 'authorized',
  Expired = 'expired',
  Refunded = 'refunded',
  Failed = 'failed',
  Paid = 'paid',
}

export interface SubscriptionInvoiceInterface {
  created_at: string;
  updated_at: string;
  id: number;
  date: string;
  invoice_number: string;
  is_paid: boolean;
  payments: SubscriptionPaymentInterface[];
  customer_id: number;
  total_price: number;
  description: string;
  boloo_user_id: number;
}

export enum PlanPacks {
  Enterprise,
  Starter,
  Business,
  Employee,
  Trial,
  Subaccount,
}

export enum PlanFeatures {
  CanAccessBolooCommunity,
  IsKeywordExplorerEnabled,
  IsSellerStoreEnabled,
  IsRankingCheckerEnabled,
}

export const PlanPackImageMap: Record<PlanPacks, string> = {
  [PlanPacks.Enterprise]: 'plan_enterprise.png',
  [PlanPacks.Business]: 'plan_business.png',
  [PlanPacks.Starter]: 'plan_starter.png',
  [PlanPacks.Subaccount]: 'plan_subaccount.png',
  [PlanPacks.Trial]: 'plan_business.png',
  [PlanPacks.Employee]: 'plan_business.png',
};

const PlanPackFeatureMap: Record<PlanPacks, PlanFeatures[]> = {
  [PlanPacks.Enterprise]: [
    PlanFeatures.CanAccessBolooCommunity,
    PlanFeatures.IsKeywordExplorerEnabled,
    PlanFeatures.IsSellerStoreEnabled,
    PlanFeatures.IsRankingCheckerEnabled,
  ],
  [PlanPacks.Business]: [
    PlanFeatures.CanAccessBolooCommunity,
    PlanFeatures.IsKeywordExplorerEnabled,
    PlanFeatures.IsSellerStoreEnabled,
    PlanFeatures.IsRankingCheckerEnabled,
  ],
  [PlanPacks.Subaccount]: [
    PlanFeatures.IsKeywordExplorerEnabled,
    PlanFeatures.IsSellerStoreEnabled,
    PlanFeatures.IsRankingCheckerEnabled,
  ],
  [PlanPacks.Employee]: [
    PlanFeatures.CanAccessBolooCommunity,
    PlanFeatures.IsKeywordExplorerEnabled,
    PlanFeatures.IsSellerStoreEnabled,
    PlanFeatures.IsRankingCheckerEnabled,
  ],
  [PlanPacks.Trial]: [
    PlanFeatures.CanAccessBolooCommunity,
    PlanFeatures.IsKeywordExplorerEnabled,
    PlanFeatures.IsSellerStoreEnabled,
    PlanFeatures.IsRankingCheckerEnabled,
  ],
  [PlanPacks.Starter]: [],
};

export enum PlanSlugs {
  StarterMonthly = 'starter-monthly',
  StarterYearly = 'starter-yearly',
  BusinessMonthly = 'business-monthly',
  BusinessYearly = 'business-yearly',
  EntrerpriseMonthly = 'enterprise-monthly',
  EnterpriseYearly = 'enterprise-yearly',
  EmployeePlan = 'employee-plan',
  TrialAccountMonthly = 'trail-account-monthly',
  SubAccountMonthly = 'subaccount-monthly',
}

export function getPlanPackFromPlanSlug(
  plan: SubscriptionPlanInterface,
): PlanPacks {
  switch (plan.slug) {
    case PlanSlugs.StarterMonthly:
    case PlanSlugs.StarterYearly:
      return PlanPacks.Starter;
    case PlanSlugs.BusinessMonthly:
    case PlanSlugs.BusinessYearly:
      return PlanPacks.Business;
    case PlanSlugs.EntrerpriseMonthly:
    case PlanSlugs.EnterpriseYearly:
      return PlanPacks.Enterprise;
    case PlanSlugs.EmployeePlan:
      return PlanPacks.Employee;
    case PlanSlugs.SubAccountMonthly:
      return PlanPacks.Subaccount;
    case PlanSlugs.TrialAccountMonthly:
      return PlanPacks.Trial;
    default:
      return PlanPacks.Business;
  }
}

export function getPlanImageName(plan: SubscriptionPlanInterface): string {
  return PlanPackImageMap[getPlanPackFromPlanSlug(plan)];
}

export function isPlanFeatureAvailable(
  plan: SubscriptionPlanInterface,
  planFeature: PlanFeatures,
): boolean {
  return PlanPackFeatureMap[getPlanPackFromPlanSlug(plan)]?.includes(
    planFeature,
  );
}

export function isSubscriptionStarterPlan(
  subscription: SubscriptionInterface,
): boolean {
  return getPlanPackFromPlanSlug(subscription.plan) === PlanPacks.Starter;
}

export const OldPlanIdsToNewSlugs = {
  plan_GscFxEL5SndYfp: PlanSlugs.StarterMonthly,
  plan_GscHr65k6msquh: PlanSlugs.BusinessMonthly,
  plan_GscDa9SViACdjD: PlanSlugs.EntrerpriseMonthly,
};

export function useSubscriptionPlans(): {
  plans: SubscriptionPlanInterface[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/plans`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    plans: data,
    isLoading,
    isError: error,
  };
}

export function useSubscriptionSubAccountPlan(): {
  plan: SubscriptionPlanInterface | null;
  isLoading: boolean;
  isError: Error;
} {
  const { plans, isError, isLoading } = useSubscriptionPlans();
  const plan = useMemo(() => {
    if (!plans || !plans.length) {
      return null;
    }
    return plans.find((it) => it.slug === PlanSlugs.SubAccountMonthly) || null;
  }, [plans]);

  return {
    plan,
    isLoading,
    isError,
  };
}

export function useSubscriptionDefaultPlan(): {
  defaultPlan: SubscriptionPlanInterface | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/plans/default`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    defaultPlan: data,
    isLoading,
    isError: error,
  };
}

export function useSubscriptions(): {
  subscriptions: SubscriptionInterface[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/subscriptions`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    subscriptions: data,
    isLoading,
    isError: error,
  };
}

export function useSubscription(isLoggedIn = true): {
  subscription: SubscriptionInterface | null;
  isActiveSubscription: boolean | null;
  isTrial: boolean | null;
  subscriptionPlanFeatures: PlanFeatures[] | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR<SubscriptionInterface>(
    isLoggedIn ? `${baseURL}/subscription` : null,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  const {
    subscription,
    isActiveSubscription,
    subscriptionPlanFeatures,
    isTrial,
  } = useMemo(() => {
    if (!data || !Object.keys(data).length) {
      return {
        subscription: null,
        isActiveSubscription: null,
        isTrial: null,
        subscriptionPlanFeatures: null,
      };
    }
    return {
      subscription: data,
      isActiveSubscription: ActiveSubscriptionStatuses.includes(data.status),
      isTrial: data.status === SubscriptionStatuses.Trialing,
      subscriptionPlanFeatures:
        PlanPackFeatureMap[getPlanPackFromPlanSlug(data.plan)],
    };
  }, [data]);
  return {
    subscription,
    isActiveSubscription,
    subscriptionPlanFeatures,
    isTrial,
    isLoading,
    isError: error,
  };
}

export function useSubscriptionPlan(planId: string | null = null): {
  plan: SubscriptionPlanInterface | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    planId !== null ? `${baseURL}/plans/${planId}` : null,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    plan: data,
    isLoading,
    isError: error,
  };
}

export function useSubscriptionPaymentMethod(): {
  paymentMethod: SubscriptionPaymentMethods | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/customer/payment-method`,
    (url) => instance.get(url).then((res) => res.data.payment_method),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    paymentMethod: data || null,
    isLoading,
    isError: error,
  };
}

export async function subscribeToPlan(
  planId: number,
  paymentMethod: SubscriptionPaymentMethods,
): Promise<{ checkout_url: string }> {
  return instance
    .post(`${baseURL}/subscribe`, {
      plan_id: planId,
      payment_method: paymentMethod,
    })
    .then((res) => res.data);
}

export async function updateSubscriptionPlan(
  planId: number,
): Promise<{ checkout_url: string }> {
  return instance
    .post(`${baseURL}/update-plan`, {
      plan_id: planId,
    })
    .then((res) => res.data);
}

export async function unsubscribeFromSubscription(
  subscriptionId: number,
  cancelationReason?: string,
  cancelationReasonText?: string,
  cancelationFeedback?: string,
): Promise<{ checkout_url: string }> {
  return instance
    .post(`${baseURL}/unsubscribe/${subscriptionId}`, {
      cancellation_reason: cancelationReason,
      cancellation_reason_text: cancelationReasonText,
      cancellation_feedback: cancelationFeedback,
    })
    .then((res) => res.data);
}

export function useSubscriptionInvoices(): {
  invoices: SubscriptionInvoiceInterface[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/invoices`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    invoices: data || [],
    isLoading,
    isError: error,
  };
}

export async function downloadSubscriptionInvoice(
  invoiceId: number,
): Promise<Blob> {
  return instance({
    method: 'GET',
    url: `${baseURL}/invoices/${invoiceId}/generate-pdf`,
    responseType: 'blob',
  }).then((res) => res.data);
}

export function useSubscriptionPlanNextPaymentDate(planId: string): {
  nextPaymentDate: string | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    `${baseURL}/plans/${planId}/next-payment-date`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    nextPaymentDate: data?.next_payment_date,
    isLoading,
    isError: error,
  };
}

export function useSubscriptionsCustomer(): {
  customer: SubscriptionCustomer;
  isLoading: boolean;
  mutate: KeyedMutator<Partial<SubscriptionCustomer>>;
  isError: Error;
} {
  const { data, error, isLoading, mutate } = useSWR(
    `${baseURL}/customer`,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );

  return {
    customer: data,
    isLoading,
    mutate,
    isError: error,
  };
}

export async function updateSubscriptionsCustomer(
  customer: Partial<SubscriptionCustomer>,
) {
  return instance
    .patch(`${baseURL}/customer`, customer)
    .then((res) => res.data);
}

export async function updateSubscriptionPaymentMethod(
  paymentMethod: SubscriptionPaymentMethods,
): Promise<{ checkout_url: string }> {
  return instance
    .post(`${baseURL}/update-payment-method`, {
      payment_method: paymentMethod,
    })
    .then((res) => res.data);
}
