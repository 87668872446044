import React from 'react';
import { Card, Avatar } from 'antd';

import './style.scss';

const StoreDrawerCustomerCard = ({ customer = {} }) => {
  const firstLetterOfName = customer.full_name
    ? customer.full_name.substring(0, 1)
    : '-';
  return (
    <Card
      title="Klant"
      className="retailer-drawer-customer-card bear-mar-b-16"
      bordered={false}
    >
      <div className="bear-flex bear-flex-ac customer-profile">
        <Avatar size={40}>{firstLetterOfName}</Avatar>
        <div>
          <p className="bear-medium-text">{customer.full_name || '-'}</p>
        </div>
      </div>
      <div className="customer-card-tile">
        <p className="bear-medium-text">Afleveradres</p>
        <p>
          <span className="bear-light-regular-text">Voornaam</span>
          <span>{customer.first_name || '-'}</span>
        </p>
        <p>
          <span className="bear-light-regular-text">Achternaam</span>
          <span>{customer.last_name || '-'}</span>
        </p>
        <p>
          <span className="bear-light-regular-text">Adres</span>
          <a
            href={`https://www.google.com/maps/place/${customer.street_number}+${customer.house_number}+${customer.zip_code}+${customer.city}`}
            target="_blank"
            rel="noreferrer"
          >
            <span>{`${customer.country_code} - ${customer.city} - ${
              customer.street_number
            } ${customer.house_number} ${
              customer.house_number_extended
                ? customer.house_number_extended
                : ''
            }`}</span>
          </a>
        </p>
        <p>
          <span className="bear-light-regular-text">Postcode</span>
          <span>{customer.zip_code}</span>
        </p>
      </div>
    </Card>
  );
};

export default StoreDrawerCustomerCard;
