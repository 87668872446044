/*eslint-disable*/
const initialState = {
  data: [],
};

const SET_DEFAULT_CAMPAIGNS = 'EVENT/SET_DEFAULT_CAMPAIGNS';

export const setDefaultCampaigns = (campaigns) => {
  return {
    type: SET_DEFAULT_CAMPAIGNS,
    payload: campaigns,
  };
};

const defaultCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_CAMPAIGNS:
      return {
        ...state,
        data: [...action.payload],
      };
    default:
      return state;
  }
};

export default defaultCampaignReducer;
