import React from 'react';
import dayjs from 'dayjs';
import { Row, Col } from 'antd';

import {
  hexToRgb,
  isNonWhiteSpectrum,
  formatNumberToCurrency,
  roundUp,
} from '../../../utilities/index.ts';
import { defaultOrderData } from './constants';
import { DEFAULT_THEME_COLOUR, IMAGE_STATIC_PATH } from '../../../constants.ts';

import ProfileHook from '../../../hooks/profile.tsx';
import GenericCard from '../../../components/bearCards/genericCard';

import './style.scss';

function OrderInvoiceTemplate({
  profileData,
  templateData,
  headerExtra,
  orderData = defaultOrderData,
  isDummy = false,
}) {
  const customerVariables = {
    use_name: true,
    use_city: true,
    use_email: true,
    use_phone: true,
    use_country: true,
    use_address: true,
    use_vat_number: true,
    use_coc_number: true,
    use_postal_code: true,
    use_company: true,
  };

  const templateThemeColor = templateData.theme_color;
  const rgbValue = hexToRgb(templateThemeColor);

  const themeColor = isNonWhiteSpectrum(rgbValue)
    ? templateThemeColor
    : DEFAULT_THEME_COLOUR;
  const templateColor = `rgba(${Object.values(rgbValue).join(',')},0.1)`;
  const lightThemeColor = isNonWhiteSpectrum(rgbValue)
    ? `rgba(${Object.values(rgbValue).join(',')},0.4)`
    : DEFAULT_THEME_COLOUR;
  const { customer } = orderData;
  const { retailer = {} } = profileData;

  function getAddress(addressType) {
    const pieces = [];
    pieces.push(addressType.country_code);
    pieces.push(addressType.city);
    pieces.push(addressType.street_number);
    pieces.push(addressType.house_number);
    pieces.push(
      addressType.house_number_extended
        ? addressType.house_number_extended
        : '',
    );
    return pieces;
  }

  const customerAddress = getAddress(customer);

  function getInvoiceNumber() {
    if (isDummy) {
      return `${dayjs().format('YYYY')}-${(templateData.start_from || 1)
        .toString()
        .padStart(5, '0')}`;
    }
    return templateData.get_invoice_number;
  }

  const retailerCountryCode = retailer.vat_number?.slice(0, 2);
  let isVatShifted = false;
  if (retailerCountryCode && customer.country_code !== retailerCountryCode) {
    // Only shift the VAT when all company fields are filled is set for the customer.
    isVatShifted =
      customer.company &&
      customer.vat_number &&
      customer.chamber_of_commerce_number;
  }

  return (
    <GenericCard
      extra={headerExtra}
      className="order-invoice-template"
      title="Factuur Template"
    >
      <div className="header" style={{ backgroundColor: templateColor }}>
        <Row gutter={32} className="logo-row">
          <Col span={10} className="logo-col">
            <If condition={templateData.logo_url}>
              <img
                src={templateData.logo_url}
                alt="company-logo"
                style={{ height: `${templateData.logo_height}rem` }}
              />
            </If>
          </Col>
          <Col span={14} className="bear-light-help-text invoice-details">
            <p>
              <span>Bestelnummer: &nbsp;</span>
              <strong>{orderData.order_id}</strong>
            </p>
            <p>
              <span>Besteldatum: &nbsp;</span>
              <strong>
                {dayjs(orderData.order_date).format('DD-MM-YYYY') ||
                  dayjs().subtract(1, 'days').format('DD-MM-YYYY')}
              </strong>
            </p>
            <p>
              <span>Factuurnummer: &nbsp;</span>
              <strong>{getInvoiceNumber()}</strong>
            </p>
            <p>
              <span>Factuurdatum: &nbsp;</span>
              <span>
                <strong>{dayjs().format('DD-MM-YYYY')}</strong>
              </span>
            </p>
          </Col>
        </Row>
        <Row gutter="32" className="bear-mar-b-12">
          <Col>
            <p className="bear-regular-title">Factuur</p>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12} className="bear-regular-text">
            <p
              className="bear-bold-text bear-text-uc title"
              style={{ color: themeColor }}
            >
              Klant
            </p>
            <If condition={customerVariables.use_name}>
              <p className="bear-medium-text bear-flex">
                <span className="bear-text-el">
                  {`${customer.first_name ? customer.first_name : ''} ${
                    customer.last_name ? customer.last_name : ''
                  }`}
                </span>
              </p>
            </If>
            <If condition={customerVariables.use_company && customer.company}>
              <p className="bear-text-el">{customer.company}</p>
            </If>
            <If condition={customerAddress.length}>
              <p className="bear-text-wrap bear-text-el">
                {customerAddress.filter(Boolean).join(' - ')}
              </p>
            </If>
            <If condition={customer.zip_code}>
              <p className="bear-text-el">{customer.zip_code}</p>
            </If>
            <If
              condition={
                customerVariables.use_phone && customer.delivery_phone_number
              }
            >
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  Telefoonnummer:
                </span>
                <span className="bear-text-el">
                  {customer.delivery_phone_number}
                </span>
              </p>
            </If>
            <If
              condition={
                customerVariables.use_vat_number && customer.vat_number
              }
            >
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  Btw-nummer:
                </span>
                <span className="bear-text-el">{customer.vat_number}</span>
              </p>
            </If>
            <If
              condition={
                customerVariables.use_coc_number &&
                customer.chamber_of_commerce_number
              }
            >
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  KVK nummer:
                </span>
                <span className="bear-text-el">
                  {customer.chamber_of_commerce_number}
                </span>
              </p>
            </If>
          </Col>
          <Col span={12} className="bear-regular-text">
            <p
              style={{ color: themeColor }}
              className="bear-bold-text bear-text-uc title"
            >
              Verkoper
            </p>
            <If condition={templateData.use_name}>
              <p className="bear-medium-text">{retailer.store_name}</p>
            </If>
            <If condition={templateData.use_address && retailer.store_address}>
              <p className="bear-text-wrap">{retailer.store_address}</p>
            </If>
            <If condition={templateData.use_vat_number && retailer.vat_number}>
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  Btw-nummer:
                </span>
                <span className="bear-text-el"> {retailer.vat_number} </span>
              </p>
            </If>
            <If
              condition={templateData.use_iban_number && retailer.iban_number}
            >
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  IBAN nummer:
                </span>
                <span className="bear-text-el"> {retailer.iban_number} </span>
              </p>
            </If>
            <If condition={templateData.use_coc_number && retailer.coc_number}>
              <p className="bear-flex">
                <span className="bear-medium-text bear-flex-fit bear-mar-r-4">
                  KVK nummer:
                </span>
                <span className="bear-text-el"> {retailer.coc_number} </span>
              </p>
            </If>
          </Col>
        </Row>
      </div>
      <div className="products">
        <Row
          gutter={32}
          className="header-row"
          style={{ color: themeColor, borderBottomColor: lightThemeColor }}
        >
          <Col span={8}>Product</Col>
          <Col span={4}>Verkoopprijs</Col>
          <Col span={4} className="bear-text-r">
            Aantal
          </Col>
          <Col span={4} className="bear-text-r">
            {!templateData.has_no_vat_rule && !isVatShifted && 'BTW %'}
          </Col>
          <Col span={4} className="bear-text-r">
            Totaal
          </Col>
        </Row>
        {orderData.order_items.map((item) => {
          return (
            <Row gutter={32} className="item-row" key={item.order_item_id}>
              <Col span={8} className="product-title">
                {item.title}
              </Col>
              <Col span={4}>{formatNumberToCurrency(item.price)}</Col>
              <Col span={4} className="bear-text-r">
                {item.sold_quantity}
              </Col>
              <Col span={4} className="bear-text-r">
                {!templateData.has_no_vat_rule &&
                  !isVatShifted &&
                  `${roundUp(item.vat_percentage)}%`}
              </Col>
              <Col span={4} className="bear-text-r">
                {formatNumberToCurrency(item.revenue)}
              </Col>
            </Row>
          );
        })}
        <Row className="total-row" style={{ borderTopColor: lightThemeColor }}>
          <Col span={14}>
            {templateData.show_paid_icon && (
              <img alt="Paid" src={`${IMAGE_STATIC_PATH}/invoice_paid.png`} />
            )}
          </Col>
          <Col span={10}>
            <Row gutter={32}>
              {!templateData.has_no_vat_rule && !isVatShifted && (
                <Col span={24} className="total-col">
                  <p>Subtotaal (ex Btw):</p>
                  <p>{formatNumberToCurrency(orderData.sub_total)}</p>
                </Col>
              )}
              <Col span={24} className="total-col">
                {templateData.has_no_vat_rule && (
                  <p>Btw vrijgesteld volgens KOR</p>
                )}
                {!templateData.has_no_vat_rule && isVatShifted && (
                  <p>Btw verlegd</p>
                )}
                {!templateData.has_no_vat_rule && !isVatShifted && (
                  <>
                    <p>Btw</p>
                    <p>{formatNumberToCurrency(orderData.total_vat)}</p>
                  </>
                )}
              </Col>
              <Col
                span={24}
                className="total-cell total-col"
                style={{ color: themeColor }}
              >
                <p>Totaal</p>
                <p>{formatNumberToCurrency(orderData.total_revenue)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="footer" style={{ borderTopColor: lightThemeColor }}>
        <p className="bear-regular-text">{templateData.title}</p>
        <p className="bear-light-help-text">{templateData.description}</p>
      </div>
    </GenericCard>
  );
}

export default ProfileHook(OrderInvoiceTemplate);
