/*eslint-disable*/
const SET_MERGE_TAGS = 'EVENT/SET_MERGE_TAGS';

export const setMergeTags = (mergeTags) => {
  return {
    type: SET_MERGE_TAGS,
    payload: mergeTags,
  };
};

const mergeTagsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_MERGE_TAGS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default mergeTagsReducer;
