/*eslint-disable*/
import React, { useReducer, useContext, createContext } from 'react';

const TrackerCategoriesContext = createContext();

export const TrackerCategoriesProvider = ({
  reducer,
  initialState,
  children,
}) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <TrackerCategoriesContext.Provider value={contextValue}>
      {children}
    </TrackerCategoriesContext.Provider>
  );
};

export const TrackerCategoriesConsumer = () => {
  const contextValue = useContext(TrackerCategoriesContext);
  return contextValue;
};
