import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Spin, Tooltip } from 'antd';

import { Selection } from '../../types';
import { formatNumberToCurrency } from '../../../../utilities';
import {
  getCosts,
  getOrderQuantity,
  getProductsQuantity,
  getProfit,
  getReturnQuantity,
  getRevenue,
} from '../../../services/financialReportingService/api';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import { TOOLTIP_COLOUR } from '../../../../constants';
import { KPI_TOOLTIP } from '../../constants';

interface KpiBarProps {
  selection: Selection;
}

interface KpiProps {
  selection: Selection;
  label: string;
  is_currency: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getter: any;
  invert_color?: boolean;
}

function Kpi({
  selection,
  label,
  is_currency,
  getter,
  invert_color = false,
}: KpiProps) {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previousValue, setPreviousValue] = useState(null);

  function getNumberFromResponse(response) {
    let number = response.data.length === 0 ? 0 : response.data[0].value;
    if (number < 0) {
      number = 0 - number;
    }
    return number;
  }

  useEffect(() => {
    if (selection == null) {
      return;
    }

    const previousSelection = { ...selection };
    previousSelection.start_date =
      selection.start_date - (selection.end_date - selection.start_date);
    previousSelection.end_date = selection.start_date;

    setLoading(true);
    Promise.all([
      getter(selection, 'total'),
      getter(previousSelection, 'total'),
    ])
      .then(([response, previousResponse]) => {
        setValue(getNumberFromResponse(response));
        setPreviousValue(getNumberFromResponse(previousResponse));
      })
      .catch(captureErrorAndShowMessage)
      .finally(() => setLoading(false));
  }, [selection]);

  const difference = useMemo(() => {
    return value - previousValue;
  }, [value, previousValue]);

  const differenceSign = difference > 0 ? '+' : '';

  const getDifferenceClass = () => {
    if (invert_color) {
      return difference > 0 ? 'bear-red-tc' : 'bear-green-tc';
    }
    return difference > 0 ? 'bear-green-tc' : 'bear-red-tc';
  };

  return (
    <Col lg={4} xs={8}>
      <p className="bear-light-regular-text bear-text-uc statistics-title">
        {KPI_TOOLTIP[label] ? (
          <Tooltip
            title={KPI_TOOLTIP[label]}
            placement="bottom"
            color={TOOLTIP_COLOUR}
            className="bear-has-tooltip"
          >
            {label}
          </Tooltip>
        ) : (
          label
        )}
      </p>
      {loading && <Spin />}
      {!loading && (
        <div className="bear-bold-title statistics-value">
          {is_currency ? formatNumberToCurrency(value) : value}
        </div>
      )}
      {!loading && difference !== null && (
        <span className={getDifferenceClass()}>
          {differenceSign}
          {is_currency ? formatNumberToCurrency(difference) : difference}
        </span>
      )}
    </Col>
  );
}

function KpiBar({ selection }: KpiBarProps) {
  return (
    <div className="bear-statistics-row">
      <Row
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
      >
        <Kpi
          selection={selection}
          getter={getRevenue}
          is_currency
          label="Omzet"
        />
        <Kpi
          selection={selection}
          getter={getCosts}
          is_currency
          label="Kosten"
          invert_color
        />
        <Kpi
          selection={selection}
          getter={getProfit}
          is_currency
          label="Winst"
        />
        <Kpi
          selection={selection}
          getter={getOrderQuantity}
          is_currency={false}
          label="Bestellingen"
        />
        <Kpi
          selection={selection}
          getter={getProductsQuantity}
          is_currency={false}
          label="Producten"
        />
        <Kpi
          selection={selection}
          getter={getReturnQuantity}
          is_currency={false}
          invert_color
          label="Retouren"
        />
      </Row>
    </div>
  );
}

export default KpiBar;
