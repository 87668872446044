import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import nlNl from 'antd/es/locale/nl_NL';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { persistor, store } from './store';
import { ProfileDataProvider } from '../context/profileData';
import { profileDataReducer } from '../reducers/profileReducer';

import App from './app';
import SentryConfig from './components/ErrorBoundary/sentryConfig';
import { getGA4Config } from './analyticsTracking/googleAnalytics';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

import '../style/bear.scss';
import { SW_INIT, SW_UPDATE } from '../constants';

const isProd = process.env.NODE_ENV === 'production';
const isStaging = process.env.TARGET_ENV === 'stage';
const rootElement = document.getElementById('app');

function render(Component) {
  ReactDOM.render(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Provider store={store}>
      <ProfileDataProvider reducer={profileDataReducer}>
        <ConfigProvider
          locale={nlNl}
          theme={{
            components: {
              Layout: {
                headerBg: '#ffffff',
                bodyBg: '#f5f5fa',
                siderBg: '#ffffff',
              },
              Menu: { itemHoverBg: 'tansparent' },
              Progress: { defaultColor: '#4553F0' },
              Card: { borderRadiusLG: 4 },
              Table: {
                headerColor: 'rgba(0,0,0,.85)',
              },
              Statistic: { contentFontSize: 18 },
            },
            token: {
              fontFamily: 'Ubuntu',
              colorPrimary: '#4553F0',
              colorText: '#37474F',
              colorTextSecondary: '#78909C',
              borderRadiusLG: 4,
            },
            hashed: false,
          }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <Component />
          </PersistGate>
        </ConfigProvider>
      </ProfileDataProvider>
    </Provider>,
    rootElement,
  );
}

if (isProd) {
  Sentry.init(SentryConfig);
  TagManager.initialize({
    gtmId: 'GTM-K6M5VSQ',
  });
}

if (isProd || isStaging) {
  ReactGA.initialize([getGA4Config(isProd)]);
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register({
    onSuccess: () => {
      store.dispatch({ type: SW_INIT });
    },
    onUpdate: (reg) => {
      store.dispatch({ type: SW_UPDATE, payload: reg });
    },
  });
} else {
  serviceWorkerRegistration.unregister();
}

render(App);
