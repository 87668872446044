import useSWR from 'swr';
import productsAxios from '../../axiosInstance';

import type { ProductInterface } from '../../product';
import { ProductSearchQueryInterface } from '../types';
import { CountedResponse } from '../../../types';
import { appendQueryParams } from '../../../../utilities/params';

/**
 * Hook to search for products in the Bol database
 * @param query The query to search for products
 * @param shouldFetch Whether to fetch the data or not
 */
export function useSearchProducts(
  query: ProductSearchQueryInterface,
  shouldFetch = true,
) {
  const getKey = () => {
    const path = `/search`;
    return appendQueryParams<ProductSearchQueryInterface>(path, query);
  };

  const { data, error, isLoading, mutate, isValidating } = useSWR(
    shouldFetch ? getKey : null,
    (url) =>
      productsAxios
        .get(url)
        .then((res) => res.data as CountedResponse<ProductInterface>),
  );

  return {
    results: data?.results,
    totalItems: data?.count,
    isValidating,
    mutate,
    isLoading,
    isError: error,
  };
}
