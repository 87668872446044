import { RETAILER_ORDER_INVOICE_NOT_SENT, RETAILER_ORDER_INVOICE_SENT, RETAILER_ORDER_INVOICE_UPLOADED } from "../constants";

export const FILTERS = [
  {
    label: 'Own stock',
    value: 'FBR',
  },
  {
    label: 'Logistic via Bol (LVB)',
    value: 'FBB',
  },
];

export const INVOICE_STATUS_FILTERS = [
  {
    label: 'Niet verzonden/geüpload',
    value: RETAILER_ORDER_INVOICE_NOT_SENT,
    disabled_for_return: false,
  },
  {
    label: 'Verzonden',
    value: RETAILER_ORDER_INVOICE_SENT,
    disabled_for_return: false,
  },
  {
    label: 'Geüpload',
    value: RETAILER_ORDER_INVOICE_UPLOADED,
    disabled_for_return: true,
  }
];

export const ORDER_SORT_OPTIONS = [
  {
    label: 'Datum (Nieuwste eerst)',
    value: '-order_date',
  },
  {
    label: 'Datum (Oudste eerst)',
    value: 'order_date',
  },
  {
    label: 'Klant (A - Z)',
    value: 'customer__first_name',
  },
  {
    label: 'Klant (Z - A)',
    value: '-customer__first_name',
  },
  {
    label: 'Aantal stuks (laag naar hoog)',
    value: 'total_pieces',
  },
  {
    label: 'Aantal stuks (hoog naar laag',
    value: '-total_pieces',
  },
  {
    label: 'Bestelwaarde (laag naar hoog)',
    value: 'total',
  },
  {
    label: 'Bestelwaarde (hoog naar laag',
    value: '-total',
  },
];

export const RETURN_SORT_OPTIONS = [
  {
    label: 'Date (Newest First)',
    value: '-return_date',
  },
  {
    label: 'Date (Oldest First)',
    value: 'return_date',
  },
  {
    label: 'Klant (A - Z)',
    value: 'return_items__order_item__order__customer__first_name',
  },
  {
    label: 'Klant (Z - A)',
    value: '-return_items__order_item__order__customer__first_name',
  },
];

export const DEFAULT_ORDER_SORT_OPTION = '-order_date';
