import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Input } from 'antd';

import { createOrUpdateStoreCredentials } from '../../actions/store';

import ProfileHook from '../../../hooks/profile';
import BearFormModal from '../../components/bearFormModal';

const StoreInformationEditModal = ({
  profileData,
  setProfileData,
  handleOnClose,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { retailer } = profileData;

  useEffect(() => {
    form.setFieldsValue(retailer);

    if (!retailer.store_name || !retailer.store_address)
      form.validateFields(['store_name', 'store_address']);
  }, [retailer]);

  function onSubmit(values) {
    setLoading(true);
    createOrUpdateStoreCredentials(values, retailer.id)
      .then((response) => {
        setProfileData({ ...profileData, ...{ retailer: response.data } });
        message.success('Store informatie aangepast');
        handleOnClose();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400) {
          message.error(err.response.data.detail);
        }
      });
  }

  return (
    <BearFormModal
      width={700}
      title="Store Informatie"
      form={form}
      isLoading={isLoading}
      onSubmit={onSubmit}
      handleOnClose={handleOnClose}
      okText="Opslaan"
      cancelText="Annuleren"
    >
      <Row gutter={24}>
        <Form.Item noStyle name={['client_id']}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name={['client_secret']}>
          <Input type="hidden" />
        </Form.Item>
        <Col sm={24} md={12}>
          <Form.Item
            label="Winkelnaam"
            name="store_name"
            rules={[{ required: true, message: 'Voer je Winkelnaam in' }]}
          >
            <Input placeholder="Voer je Winkelnaam in" size="large" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item label="Btw-nummer" name="vat_number">
            <Input placeholder="Btw-nummer" size="large" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item label="KVK nummer" name="coc_number">
            <Input placeholder="KVK nummer" size="large" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item label="IBAN nummer" name="iban_number">
            <Input placeholder="IBAN nummer" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col sm={24} md={12}>
          <Form.Item
            label="Bedrijfsadres"
            name="store_address"
            rules={[{ required: true, message: 'Voer je Bedrijfsadres in' }]}
          >
            <Input.TextArea
              data-testid="storeAddress"
              placeholder="Straatnaam 123 &#13;Postcode plaatsnaam &#13"
              size="large"
              rows={4}
            />
          </Form.Item>
        </Col>
      </Row>
    </BearFormModal>
  );
};

export default ProfileHook(StoreInformationEditModal);
