/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Row, Col } from 'antd';

import { getAreaData, getBarData, isEmpty } from '../../utilities';
import { DEFAULT_STORE_OPTION } from '../constants';

import BearChart from '../../../../components/bearCharts';
import { Retailer } from '../../../../reducers/profileReducer';
import { ROW_GUTTER } from '../../../constants';

dayjs.extend(utc);

function DashboardGraph({
  isLoading,
  graphData,
  unit,
  stores,
  selectedStore = {} as Partial<Retailer>,
}) {
  const areaDataEntry = getAreaData(graphData, selectedStore);
  const barDataEntry = getBarData(graphData, stores, selectedStore);

  const timestampArray = areaDataEntry.map((item) =>
    dayjs.unix(item.period).utc().format(),
  );

  const areaData = [
    {
      fill: '+2',
      spanGaps: true,
      label: 'Omzet',
      lineTension: 0,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: '#4553F0',
      backgroundColor: 'rgba(69,83,240, 0.2)',
      data: areaDataEntry.map((item) => item.revenue || 0),
    },
    {
      fill: 'origin',
      spanGaps: true,
      label: 'Kosten',
      lineTension: 0,
      pointRadius: 0,
      borderWidth: 2,
      borderColor: '#9C27B0',
      backgroundColor: 'rgba(156, 39, 176, 0.2)',
      data: areaDataEntry.map((item) => item.cost_price || 0),
    },
    {
      fill: '-1',
      label: 'Winst',
      lineTension: 0,
      pointRadius: 0,
      spanGaps: true,
      borderWidth: 2,
      borderColor: '#4CAF50',
      backgroundColor: 'rgba(102, 187, 106, 0.2)',
      data: areaDataEntry.map((item) => item.profit || 0),
    },
  ];

  let barData = [];
  const isStacked =
    !isEmpty(selectedStore) && selectedStore.id === DEFAULT_STORE_OPTION.id;

  if (isStacked) {
    barData = barDataEntry.map((dataEntry) => {
      return {
        label: dataEntry.nickname,
        backgroundColor: `rgba(${dataEntry.color},1)`,
        hoverBackgroundColor: `rgba(${dataEntry.color},0.8)`,
        data: dataEntry.data.periods.map((item) => item.sales || 0),
        barThickness: unit === 'month' ? 16 : 8,
      };
    });
  } else {
    barData = [
      {
        label: 'Producten verkocht',
        backgroundColor: 'rgba(106,117,243,1)',
        hoverBackgroundColor: 'rgba(106,117,243, 0.8)',
        data: barDataEntry.map((item) => item.sales || 0),
      },
    ];
  }

  return (
    <Row gutter={ROW_GUTTER}>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="bear-mar-b-sm-16">
        <BearChart
          type="line"
          unit={unit}
          isLoading={isLoading}
          datasetArray={areaData}
          title="Winkelprestaties"
          labelsArray={timestampArray}
          isAmount
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <BearChart
          type="bar"
          unit={unit}
          isLoading={isLoading}
          datasetArray={barData}
          labelsArray={timestampArray}
          title="Verkochte producten in deze periode"
          isStacked={isStacked}
        />
      </Col>
    </Row>
  );
}

DashboardGraph.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  graphData: PropTypes.array.isRequired,
};

export default DashboardGraph;
