import useSWRMutation from 'swr/mutation';
import type { OfferProductInterface } from '../types';
import productsAxios from '../../axiosInstance';

export function useOffersProductEdit(
  offerId: string | null,
): (
  offerProduct: Partial<OfferProductInterface>,
) => Promise<OfferProductInterface> {
  const { trigger } = useSWRMutation(
    offerId ? `/offers/${offerId}/` : null,
    (url: string, { arg }: { arg: Partial<OfferProductInterface> }) =>
      productsAxios
        .put(url, JSON.stringify(arg))
        .then((response) => response.data as OfferProductInterface),
  );
  return trigger;
}
