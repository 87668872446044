import React, { ReactNode, useMemo } from 'react';
import { Skeleton, Tooltip } from 'antd';

import './style.scss';
import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { AxiosError } from 'axios';
import { IMAGE_STATIC_PATH } from '../../../constants';
import Rating from '../rating';
import {
  ProductInterface,
  useBolProduct,
} from '../../services/productsService/api';

function ProductCard({
  ean,
  url = null,
  image = null,
  title = null,
  name = null,
  rating = null,
  isRoundImage = false,
  tooltipPlacement = 'top',
  children = null,
  className = '',
  shouldFetch = false,
  hiddenByCustomer = false,
  showReviewCount = true,
}: {
  url?: string | null;
  ean: string;
  image?: string | null;
  title?: string | null;
  name?: string | null;
  rating?: number | null;
  isRoundImage?: boolean;
  tooltipPlacement?: TooltipPlacement;
  children?: ReactNode;
  className?: string;
  shouldFetch?: boolean;
  hiddenByCustomer?: boolean;
  showReviewCount?: boolean;
}) {
  const productFromProps: Partial<ProductInterface> = {
    ean,
    url,
    image,
    name: title || name,
    rating,
    is_active: true,
    is_updating: shouldFetch,
    review_count: null,
  };

  const { product: productFromService, error } = useBolProduct(
    shouldFetch ? ean : null,
  );

  const product = useMemo(() => {
    if (
      error &&
      error instanceof AxiosError &&
      error?.response?.status === 404
    ) {
      return productFromProps;
    }
    return shouldFetch ? productFromService : productFromProps;
  }, [productFromService, error]);

  return (
    <div className="product-card">
      <Skeleton loading={!product} title={false} active={product?.is_updating}>
        <img
          src={
            product?.image ||
            `${IMAGE_STATIC_PATH}/icons/placeholder_default_image.svg`
          }
          className={isRoundImage ? ' round' : ''}
          alt={product?.name}
        />
        <div className={`product-info ${className}`}>
          <If condition={product?.rating !== null}>
            <Rating
              rating={product?.rating}
              hiddenByCustomer={hiddenByCustomer}
            />
          </If>
          <If condition={showReviewCount && product?.review_count !== null}>
            ({product?.review_count} reviews)
          </If>
          <Tooltip
            title={product?.name}
            placement={tooltipPlacement as TooltipPlacement}
          >
            <p className="product-card-title">
              <Choose>
                <When condition={product?.url}>
                  <a
                    href={product?.url}
                    target="_blank"
                    rel="noreferrer"
                    className="secondary no-border"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {product?.name}
                  </a>
                </When>
                <Otherwise>{product?.name}</Otherwise>
              </Choose>
            </p>
          </Tooltip>
          <If condition={ean || product?.ean}>
            <p className="bear-light-regular-text product-card-ean">
              EAN: {ean || product?.ean}
            </p>
          </If>
          {children}
        </div>
      </Skeleton>
    </div>
  );
}

export default ProductCard;
