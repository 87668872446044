import { useEffect } from 'react';
import ProfileHook from '../../hooks/profile';
import { getIntercomSettings } from './actions';

const isProd = process.env.NODE_ENV === 'production';
const isStaging = process.env.TARGET_ENV === 'stage';

function Intercom({ profileData }) {
  useEffect(() => {
    if (!(isProd || isStaging)) {
      return;
    }
    getIntercomSettings().then((response) => {
      const settings = response.data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('boot', settings);
    });
  }, [profileData]);
  return null;
}

export default ProfileHook(Intercom);
