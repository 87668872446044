import { Col, Form, Input, Modal, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { If } from 'tsx-control-statements/components';
import SubAccountPlan from '../plan';
import EmojiPicker from '../../../../components/emojiPicker';
import { createSubAccount } from '../../../../actions';
import { setActiveToken } from '../../../../reducers/tokensReducer';
import { GENERIC_ERROR_MSG } from '../../../../../constants';
import { useSubscriptionSubAccountPlan } from '../../../../services/subscriptionService/api';

function AddSubAccountModal({
  isModalVisible: isModalOpen,
  setIsModalVisible,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [loading, setLoading] = useState(false);

  const { plan } = useSubscriptionSubAccountPlan();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function resetForm() {
    setChosenEmoji(null);
    form.resetFields();
  }

  function onSubmit(values) {
    values.icon = chosenEmoji;
    setLoading(true);
    createSubAccount(values)
      .then((response) => {
        dispatch(setActiveToken(response.data.token));
        document.location = '/';
      })
      .catch(() => {
        notification.error({ message: GENERIC_ERROR_MSG });
      })
      .finally(() => {
        resetForm();
        setIsModalVisible(false);
        setLoading(false);
      });
  }

  return (
    <Modal
      title="Extra store toevoegen"
      open={isModalOpen}
      onOk={form.submit}
      okText="Extra store toevoegen"
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <If condition={plan}>
        <SubAccountPlan plan={plan} />
        <Form
          form={form}
          onFinish={(values) => onSubmit(values)}
          layout="vertical"
        >
          <Row>
            <Col span={16}>
              {' '}
              <Form.Item
                name="nickname"
                label="Naam store"
                rules={[
                  {
                    required: true,
                    message: 'Vul de naam van je ',
                  },
                  {
                    whitespace: true,
                    message: 'Vul je naam store in',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <div className="icon-picker-wrapper">
                <Form.Item
                  name="NotIcon"
                  label="Icon"
                  rules={[
                    {
                      required: true,
                      validator: () => {
                        if (chosenEmoji) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Icoon is vereist'));
                      },
                    },
                  ]}
                >
                  <EmojiPicker
                    chosenEmoji={chosenEmoji}
                    setChosenEmoji={setChosenEmoji}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>

        <p className="bear-light-help-text subaccount-disclaimer">
          Door op &quot;Extra store toevoegen&quot; te klikken, machtig je Boloo
          om via Mollie, onze betalingsdienstaanbieder, je rekening maandelijks
          te debiteren overeenkomstig de prijs van een extra store. Je kunt elke
          maand de extra store opzeggen
        </p>
      </If>
    </Modal>
  );
}

export default AddSubAccountModal;
