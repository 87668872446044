import useSWRMutation from 'swr/mutation';
import { DynamicPricers } from '../types';
import productsAxios from '../../axiosInstance';

export function useDynamicPricersEdit(
  bolOfferId: string,
): (dynamicPricers: DynamicPricers[]) => Promise<DynamicPricers[]> {
  const { trigger } = useSWRMutation(
    bolOfferId ? `/offers/${bolOfferId}/dynamic_price` : null,
    (url: string, { arg }: { arg: DynamicPricers[] }) =>
      productsAxios
        .post(url, JSON.stringify(arg))
        .then((response) => response.data as DynamicPricers[]),
  );
  return trigger;
}
