/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Button } from 'antd';

import updatePageMetaData from '../../../utilities/dom';
import { INDEX_ROUTE } from '../../routes';
import { getParameterByName } from '../../utilities';
import { resetUserPassword } from '../../actions';

import AccountManagementLayout from '../layout';
import ResetPasswordForm from './form';
import { clearSessionData } from '../../../actions';

const ResetPassword = ({ history }) => {
  const [processing, setProcessing] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  useEffect(() => {
    clearSessionData();
    updatePageMetaData({ title: 'Reset password' });
  });

  const handleSubmit = (values) => {
    const data = {
      password: values.password,
      token: getParameterByName('t'),
    };
    setProcessing(true);
    resetUserPassword(data).then((response) => {
      setProcessing(false);
      setIsPasswordChanged(true);
    });
  };

  return (
    <AccountManagementLayout
      title="Stel een nieuw wachtwoord in 🗝️"
      backgroundImage="forgot_password.png"
      className="forgot-password-container"
    >
      <Choose>
        <When condition={isPasswordChanged}>
          <Alert
            className="bear-mar-t-12 bear-mar-b-12"
            message="Successfully changed the password."
            type="success"
            showIcon
          />
          <Button
            type="primary"
            className="bear-mar-t-20"
            onClick={() => history.push(INDEX_ROUTE)}
            block
          >
            <span>Sign In</span>
          </Button>
        </When>
        <Otherwise>
          <ResetPasswordForm
            onSubmit={(values) => handleSubmit(values)}
            processing={processing}
          />
        </Otherwise>
      </Choose>
    </AccountManagementLayout>
  );
};

export default withRouter(ResetPassword);
