import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { Selection } from '../../types';
import BearInfiniteList from '../../../../components/bearList';
import ProductCard from '../../../components/productCard';
import { formatNumberToCurrency, formatAmount } from '../../../../utilities';
import { getProductStats } from '../../../services/financialReportingService/api';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import { getProductVisits } from '../../../services/productsService/api';
import CommonCard from '../../../components/commonCard';

interface ProductListProps {
  selection: Selection;
}

const sortOptions = [
  {
    title: 'Verkoop (hoog naar laag)',
    value: 'sales_asc',
  },
  {
    title: 'Verkoop (laag naar hoog)',
    value: 'sales_desc',
  },
  {
    title: 'Omzet (hoog naar laag)',
    value: 'revenue_asc',
  },
  {
    title: 'Omzet (laag naar hoog)',
    value: 'revenue_desc',
  },
  {
    title: 'Winst (hoog naar laag)',
    value: 'profit_asc',
  },
  {
    title: 'Winst (laag naar hoog)',
    value: 'profit_desc',
  },
];

function PerformanceProductList({ selection }: ProductListProps) {
  const [sortBy, setSortBy] = useState(sortOptions[0].value);
  const [data, setData] = useState(null);
  const [productVisits, setProductVisits] = useState({});

  useEffect(() => {
    if (selection === null) {
      return;
    }
    setData(null);
    getProductStats(selection, sortBy)
      .then((response) => {
        setData(response.data);
        response.data.forEach((product) => {
          getProductVisits(
            product.product_ean,
            selection.start_date,
            selection.end_date,
            selection.country,
          ).then((visitsResponse) => {
            setProductVisits((prevState) => ({
              ...prevState,
              [product.product_ean]: visitsResponse.data.total,
            }));
          });
        });
      })
      .catch(captureErrorAndShowMessage);
  }, [selection, sortBy]);

  function formatConversionRateCell(record) {
    if (!productVisits[record.product_ean]) {
      return <Spin />;
    }
    if (record.items_sold === 0 || productVisits[record.product_ean] === 0) {
      return '0%';
    }

    const perc = (record.items_sold / productVisits[record.product_ean]) * 100;
    return `${formatAmount(perc, '')}%`;
  }

  const listItems = [
    {
      title: 'Product',
      key: 'product',
      size: 7,
      mobile: {
        span: 22,
        order: 1,
      },
      render: (record) => <ProductCard ean={record.product_ean} shouldFetch />,
    },
    {
      title: 'Verkopen',
      key: 'sales',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (record) => (
        <p className="bear-medium-text">{record.items_sold}</p>
      ),
    },
    {
      title: 'Omzet',
      key: 'revenue',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (record) => (
        <p className="bear-medium-text">
          {formatNumberToCurrency(record.revenue)}
        </p>
      ),
    },
    {
      title: 'Winst',
      key: 'profit',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (record) => (
        <p className="bear-medium-text">
          {formatNumberToCurrency(record.profit)}
        </p>
      ),
    },
    {
      title: 'Bezoekers',
      key: 'visitors',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (record) => (
        <p className="bear-medium-text">
          {productVisits[record.product_ean] ? (
            productVisits[record.product_ean]
          ) : (
            <Spin />
          )}
        </p>
      ),
    },
    {
      title: 'CR',
      key: 'cr',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (record) => (
        <p className="bear-medium-text">{formatConversionRateCell(record)}</p>
      ),
    },
  ];
  return (
    <CommonCard
      title="Prestaties per product"
      extra={
        <div className="bear-flex-ac">
          <Select
            size="large"
            value={sortBy}
            optionLabelProp="label"
            onChange={(value) => setSortBy(value)}
          >
            {sortOptions.map((option) => (
              <Select.Option
                key={option.value}
                value={option.value}
                label={`Sorteren op: ${option.title}`}
              >
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      }
    >
      {!data && <Spin />}
      {data && (
        <BearInfiniteList
          data={data}
          items={listItems}
          hasMore={false}
          isLoading={false}
        />
      )}
    </CommonCard>
  );
}

export default PerformanceProductList;
