import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Tooltip, message, Button, Space } from 'antd';

import { If } from 'tsx-control-statements/components';
import dayjs from 'dayjs';
import { allowNumbersOnly, formatAmount } from '../../../../utilities';
import GenericCard from '../../../../../components/bearCards/genericCard';

import './style.scss';
import {
  OfferProductInterface,
  useProductStocks,
  useProductStockEdit,
  StockType,
} from '../../../../services/productsService';
import captureErrorAndShowMessage from '../../../../../utilities/errors';
import { ROW_GUTTER } from '../../../../constants';
import { getStockStatusClassName } from '../../../utilities';

function ProductStockSettings({
  offerProduct,
}: {
  offerProduct: OfferProductInterface;
}) {
  const { stocks } = useProductStocks(String(offerProduct?.id));

  const editStock = useProductStockEdit(String(offerProduct?.id));

  const [form] = Form.useForm<{
    storage_stock: number;
    stock: number;
    lvb_stock: number;
    retailer_stock: number;
  }>();
  const [isSaving, setIsSaving] = useState(false);

  const [showRetailerStock, setShowRetailerStock] = useState(false);
  const [showLvbStock, setShowLvbStock] = useState(false);

  useEffect(() => {
    const retailerStock =
      stocks?.find((stock) => stock.type === StockType.RETAILER)?.stock || 0;
    const lvbStock =
      stocks?.find((stock) => stock.type === StockType.LVB)?.stock || 0;

    form.setFieldValue('lvb_stock', lvbStock);
    form.setFieldValue('retailer_stock', retailerStock);

    setShowRetailerStock(retailerStock > 0);
    setShowLvbStock(lvbStock > 0);

    form.setFieldValue(
      'storage_stock',
      stocks?.find((stock) => stock.type === StockType.STORAGE)?.stock || 0,
    );
  }, [stocks]);

  return (
    <GenericCard title="Voorraad">
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        scrollToFirstError
        onFinish={async (values) => {
          try {
            setIsSaving(true);
            await editStock({
              ...stocks?.find((stock) => stock.type === StockType.STORAGE),
              ...{
                type: StockType.STORAGE,
                stock: Number(values.storage_stock),
              },
            });
            message.success(`Je voorrad instellingen zijn opgeslagen`);
          } catch (e) {
            captureErrorAndShowMessage(e);
          } finally {
            setIsSaving(false);
          }
        }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row
            gutter={{
              xxl: 24,
              xl: 24,
              lg: 24,
              md: 12,
              sm: 12,
              xs: 12,
            }}
          >
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Huidige voorraad bij LVB/FBB"
                name="lvb_stock"
                hidden={!showLvbStock}
              >
                <Input size="large" disabled />
              </Form.Item>
              <Form.Item
                label="Huidige voorraad bij Bol FBR"
                name="retailer_stock"
                hidden={!showRetailerStock}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Eigen Voorraad (own)" name="storage_stock">
                <Input
                  min={0}
                  size="large"
                  onKeyDown={(event) => allowNumbersOnly(event, false)}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className="product-edit-static-section">
                <div className="bear-flex-sb bear-regular-text">
                  <p>Verkopen/dag (afgelopen 30 dagen)</p>
                  <p>{offerProduct.avg_daily_sales}</p>
                </div>
                <div className="bear-flex-sb bear-regular-text">
                  <p>Dagen voorraad</p>
                  <p className="bear-stock-days-cell">
                    <span
                      className={getStockStatusClassName(
                        offerProduct.stock_days,
                      )}
                    >
                      {offerProduct.avg_daily_sales
                        ? offerProduct.stock_days
                        : '--'}
                      <If
                        condition={
                          offerProduct.stock_days &&
                          !offerProduct.avg_daily_sales
                        }
                      >
                        <Tooltip
                          className="bear-form-label-tooltip"
                          title="`Dagen voorraad` kan niet worden berekend zonder verkopen in de afgelopen 7 dagen!"
                        >
                          <span
                            style={{
                              textUnderlineOffset: '0.15em',
                              textDecorationStyle: 'dotted',
                            }} // Needs a lowered dotted style as the bear-form-label-tooltip's 'dashed' is not visible for `?`
                          >
                            ?
                          </span>
                        </Tooltip>
                      </If>
                    </span>
                  </p>
                </div>
                <div className="bear-flex-sb bear-regular-text">
                  <p>Uitverkocht op</p>
                  <p>
                    {offerProduct.avg_daily_sales
                      ? dayjs()
                          .add(offerProduct.stock_days, 'd')
                          .format('D MMMM YYYY')
                      : '-'}
                  </p>
                </div>
                <div className="bear-flex-sb bear-regular-text">
                  <p>Totale voorraadwaarde</p>
                  <p>{formatAmount(offerProduct.sales_value)}</p>
                </div>
                <div className="bear-flex-sb bear-regular-text">
                  <p>Fulfilment locatie</p>
                  <p>{offerProduct.fulfilment_method}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col>
              <Button
                size="large"
                type="primary"
                loading={isSaving}
                onClick={() => form.submit()}
              >
                Opslaan
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </GenericCard>
  );
}

export default ProductStockSettings;
