export function updatePageMetaData(
  data: {
    title?: string;
    metaDescription?: string;
  } | null,
) {
  const seoData = {
    title: null,
    metaDescription: null,
  };
  seoData.title = data?.title || 'Boloo dashboard';
  seoData.metaDescription =
    data?.metaDescription || 'De product research tool voor bol.com';
  document.title = seoData.title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', seoData.metaDescription);
}

export default updatePageMetaData;
