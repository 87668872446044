import useSWRInfinite from 'swr/infinite';
import { useMemo } from 'react';

import captureErrorAndShowMessage from '../../../../../utilities/errors';

import { CountedResponse, InfiniteHookResult } from '../../../types';

import type { ProductInterface } from '../../product';
import productsAxios from '../../axiosInstance';

import { ProductSearchQueryInterface } from '../types';
import { appendQueryParams } from '../../../../utilities/params';

export const useSearchProductsInfinite = (
  query: ProductSearchQueryInterface,
): InfiniteHookResult<ProductInterface> => {
  const getKey = (
    pageIndex: number,
    previousPageData: CountedResponse<ProductInterface>,
  ) => {
    if (previousPageData && !previousPageData.results) return null;
    const path = `/search?page=${pageIndex + 1}`;
    return appendQueryParams<ProductSearchQueryInterface>(path, query);
  };

  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite(
      getKey,
      (url) =>
        productsAxios
          .get(url)
          .then((res) => res.data as CountedResponse<ProductInterface>),
      {
        revalidateFirstPage: false,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError(e) {
          captureErrorAndShowMessage(e, null);
        },
      },
    );

  const result = useMemo((): CountedResponse<ProductInterface> => {
    if (!data) {
      return {
        count: 0,
        results: [],
      };
    }
    const combinedResults = data.flatMap((page) => page.results);
    const { count } = data[0];

    return {
      count,
      results: combinedResults,
    };
  }, [data]);

  return {
    results: result.results,
    totalItems: result.count,
    isValidating,
    mutate,
    isLoading,
    size,
    setSize,
    isError: error,
  };
};
