/*eslint-disable*/
import React from 'react';
import { Alert } from 'antd';

const AppAlert = ({ message }) => {
  return (
    <Alert
      message={message}
      banner
      icon={<i className="fad icon-envelope-open-text" />}
      className="app-layout-main-alert"
    />
  );
};

export default AppAlert;
