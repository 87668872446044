import React from 'react';
import { If } from 'tsx-control-statements/components';
import { Col, Row, Tooltip } from 'antd';

import { formatAmount } from '../../../../utilities';
import { FormValuesInterface } from '../productCostPricesForm/types';
import SellingPriceBreakupPopover from '../sellingPriceBreakupPopover';
import CostPriceBreakupPopover from '../costPriceBreakupPopover';
import GenericCard from '../../../../../components/bearCards/genericCard';
import * as styles from './ProfitCalculator.module.scss';

export interface ProfitCalculatorProps {
  totals: {
    total: number;
    profit: number;
  };
  costs: FormValuesInterface;
  ean: string;
  vat: number;
  price: number;
  commission: {
    fixedAmount: number;
    percentage: number;
  };
}
export default function ProfitCalculator({
  values,
}: {
  values: ProfitCalculatorProps;
}) {
  const profit = values?.totals?.profit || 0;
  const sellingPriceExVAT =
    (values?.price ?? 0) / (1 + (values?.vat ?? 0) / 100) || 0;
  const profitPercentage = (profit * 100) / sellingPriceExVAT;
  const isFiniteValue = Number.isFinite(profitPercentage);

  return (
    <GenericCard title="Winstmarge" className={styles.noShadow}>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="product-edit-static-section">
            <div className="bear-flex-sb bear-regular-text">
              <p>Verkoopprijs (excl. BTW)</p>
              <SellingPriceBreakupPopover
                priceExclVat={sellingPriceExVAT}
                priceInclVat={values?.price}
                vatPercentage={values?.vat}
              />
            </div>
            <div className="bear-flex-sb bear-regular-text">
              <p>Totale kosten per product (ex. BTW)</p>
              <p>
                <CostPriceBreakupPopover
                  price={values?.price}
                  commission={
                    values?.commission ?? { percentage: 0, fixedAmount: 0 }
                  }
                  label={formatAmount(values?.totals.total ?? 0)}
                  formValues={values?.costs}
                />
                <If
                  condition={
                    !values?.commission ||
                    !Object.keys(values?.commission).length
                  }
                >
                  <Tooltip
                    title={`De kosten kunnen niet correct worden berekend, omdat het
                      commissiebedrag er niet is. Vernieuw de pagina en probeer het opnieuw`}
                  >
                    <i className="fad icon-exclamation-triangle commission-error" />
                  </Tooltip>
                </If>
              </p>
            </div>
            <If condition={isFiniteValue}>
              <div className="bear-flex-sb bear-regular-text">
                <p>Winst</p>
                <p className={profit > 0 ? 'green-text' : 'red-text'}>
                  {formatAmount(profit)}
                </p>
              </div>
              <div className="bear-flex-sb bear-regular-text">
                <p>Winstmarge</p>
                <p className={profit > 0 ? 'green-text' : 'red-text'}>
                  {formatAmount(profitPercentage, '')}%
                </p>
              </div>
            </If>
          </div>
        </Col>
      </Row>
    </GenericCard>
  );
}
