/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import { isMobileDevice } from '../../../utilities/breakpoint';
import { FORGOT_PASSWORD_ROUTE } from '../../../routes';

const LoginForm = ({ loading, onSubmit }) => {
  const isMobile = isMobileDevice();
  const [form] = Form.useForm();

  return (
    <Form
      layout={isMobile ? 'horizontal' : 'vertical'}
      className="bear-mar-t-20"
      form={form}
      onFinish={(values) => onSubmit(values)}
    >
      <Form.Item
        name="email"
        data-testid="signin-username"
        rules={[
          {
            required: true,
            message: 'Voer je e-mailadres in',
          },
          {
            type: 'email',
            message: 'Voer alsjeblieft een geldig e-mailadres in',
          },
        ]}
      >
        <Input placeholder="Voer je e-mailadres in" size="large" />
      </Form.Item>
      <div className="login-forgot-password-block">
        <Form.Item
          name="password"
          data-testid="signin-password"
          rules={[
            {
              required: true,
              message: 'Voer alsjeblieft je wachtwoord in',
            },
          ]}
        >
          <Input.Password placeholder="Voer je wachtwoord in" size="large" />
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          type="primary"
          block
          size="large"
          htmlType="submit"
          loading={loading}
          data-testid="signin-submit"
        >
          <span>Inloggen</span>
        </Button>
      </Form.Item>
      <Link
        to={FORGOT_PASSWORD_ROUTE}
        className="forgot-password-link secondary"
      >
        <span>Wachtwoord vergeten?</span>
      </Link>
    </Form>
  );
};

export default LoginForm;
