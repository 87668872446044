export const sortOptions = [
  {
    title: 'Verkoop (laag naar hoog)',
    value: 'sales',
  },
  {
    title: 'Verkoop (hoog naar laag)',
    value: '-sales',
  },
  {
    title: 'Opbrengst (laag naar hoog)',
    value: 'revenue',
  },
  {
    title: 'Opbrengst (hoog naar laag)',
    value: '-revenue',
  },
  {
    title: 'Winst (laag naar hoog)',
    value: 'profit',
  },
  {
    title: 'Winst (hoog naar laag)',
    value: '-profit',
  },
];

export const countrySelectOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Netherlands',
    value: 'NL',
  },
  {
    label: 'Belgium',
    value: 'BE',
  },
];

export const DEFAULT_PRODUCTS_SORT = '-sales';
export const DEFAULT_COUNTRY_FILTER = countrySelectOptions[0].value;

export const DEFAULT_STORE_OPTION = {
  id: -1,
  icon: '',
  nickname: 'Alle stores',
  is_active_subscription: true,
  is_store_enabled: false,
  is_parent: false,
};

export const DASHBOARD_PRODUCT_GEOGRAPHICAL_TABS = [
  {
    value: 'revenue',
    label: 'Omzet',
    is_amount: true,
  },
  {
    value: 'profit',
    label: 'Winst',
    is_amount: true,
  },
  {
    value: 'sales',
    label: 'Verkocht',
  },
];
