import axios from 'axios';
import { store } from '../../store';
import getBaseURL from './config';

const productsAxios = axios.create({
  baseURL: getBaseURL(),
  timeout: 250000,
  headers: {
    'Content-Type': 'application/json',
  },
});

productsAxios.interceptors.request.use((config) => {
  const token = store.getState().tokensReducer.activeToken;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default productsAxios;
