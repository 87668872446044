import React from 'react';

import './style.scss';
import GenericCard from '../../../../components/bearCards/genericCard';

function StoreDataMissingWarning() {
  return (
    <GenericCard className="store-warning bear-mar-t-16">
      Je hebt automatische klantfacturen aanstaan, maar je hebt niet alle
      gegevens van je store (correct) ingevuld. Vul deze gegevens aan en schakel
      de feature weer in.
    </GenericCard>
  );
}

export default StoreDataMissingWarning;
