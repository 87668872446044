import useSWR from 'swr';
import instance from '../../../axios';
import captureErrorAndShowMessage from '../../../utilities/errors';
import { API_ENDPOINT } from '../../../endpoints';
import { Selection } from '../../financialReportingService/types';

export interface CampaignEventStatistics {
  event_type: 'delivered' | 'open' | 'click';
  total_count: number;
  total_unique_count: number;
}

interface CampaignStatistics {
  id: number;
  data: CampaignEventStatistics[];
}

function useCampaignStatistics(
  selection: Selection,
  allAccounts = true,
): {
  campaignStatistics: CampaignStatistics[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    selection
      ? `${API_ENDPOINT}campaign/statistics/?start_date=${selection.start_date}&end_date=${selection.end_date}&all_accounts=${allAccounts}`
      : null,
    (url) => instance.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    campaignStatistics: data || [],
    isLoading,
    isError: error,
  };
}

export default useCampaignStatistics;
