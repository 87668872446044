import { Space, Tag, Typography } from 'antd';
import React from 'react';
import './style.scss';
import { LiteralUnion } from 'antd/es/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors';
import { If } from 'tsx-control-statements/components';

const { Text } = Typography;

function Rating({
  rating,
  color = 'default',
  hiddenByCustomer = false,
}: {
  rating: number;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType>;
  hiddenByCustomer?: boolean;
}) {
  return (
    <div className="rating">
      <Tag className="rating-tag" color={color}>
        <i
          className="fas icon-star"
          style={color === 'default' ? { color: '#ffc122' } : null}
        />
        <span>{rating ? rating.toFixed(1) : 0}</span>
        <Space direction="vertical">
          <If condition={hiddenByCustomer}>
            <Text italic disabled>
              (Verborgen)
            </Text>
          </If>
        </Space>
      </Tag>
    </div>
  );
}

export default Rating;
