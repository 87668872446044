import useSWR from 'swr';
import type { StockInterface } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useProductStocks(offerId: string | null): {
  stocks: StockInterface[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    offerId ? `/offers/${offerId}/stocks/` : null,
    (url) => productsAxios.get(url).then((res) => res.data),

    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    stocks: data || null,
    isLoading,
    isError: error,
  };
}
