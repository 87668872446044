import axios from 'axios';

import useSWR from 'swr';
import { store } from '../../store';
import { ProductInterface } from '../productsService/api';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRODUCT_SEARCH,
} from '../../productRankingChecker/constants';
import captureErrorAndShowMessage from '../../../utilities/errors';

let baseURL = 'https://ppt.boloo.co';
if (process.env.TARGET_ENV === 'development') {
  baseURL = 'http://localhost:8003';
}

if (process.env.TARGET_ENV === 'stage') {
  baseURL = 'https://ppt.staging.boloo.co';
}

const productPositionTrackerAxios = axios.create({
  baseURL,
  timeout: 250000,
  headers: {
    // eslint-disable-next-line no-undef
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

productPositionTrackerAxios.interceptors.request.use((config) => {
  // Fetch token from store
  const token = store.getState().tokensReducer.activeToken;

  if (token) {
    // Set token in Authorization header if token exists
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

productPositionTrackerAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

interface SearchTerm {
  average_page: { nl: null | string; be: null | string };
  average_position: { nl: null | string; be: null | string };
  average_total_results: null | string;
  created_at: string;
  id: number;
  is_tracking_paused: { nl: boolean; be: boolean };
  keywords: string;
  position_change: { difference: null | string; direction: null | string };
  product_id: number;
  tracking_results: any[];
  updated_at: null | string;
}

export interface ProductWithSearchTerms extends ProductInterface {
  id: string;
  search_terms: SearchTerm[];
  updated_at: null | string;
}
interface Rank {
  categoryId: string | null;
  searchTerm: string | null;
  wasSponsored: boolean;
  rank: number;
  impressions: number;
}
export default {
  getProducts(
    search = DEFAULT_PRODUCT_SEARCH,
    startDate = null,
    endDate = null,
    filterBy = null,
    orderBy = null,
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
  ) {
    let path = `/products?search=${search}&page=${page}&size=${pageSize}`;
    if (startDate && endDate) {
      path = path.concat(`&start_date=${startDate}&end_date=${endDate}`);
    }

    if (filterBy) {
      path = path.concat(`&filter_by=${filterBy}`);
    }

    if (orderBy) {
      path = path.concat(`&order_by=${orderBy}`);
    }

    return productPositionTrackerAxios.get(path);
  },
  async getProductDashboardStats(
    startDate = null,
    endDate = null,
  ): Promise<{ gainers: any[]; losers: any[]; products_count: number }> {
    let path = '/get_dashboard_stats';
    if (startDate && endDate) {
      path = path.concat(`?start_date=${startDate}&end_date=${endDate}`);
    }

    return productPositionTrackerAxios.get(path) as Promise<{
      gainers: any[];
      losers: any[];
      products_count: number;
    }>;
  },
  addProduct(name, bolProductId, image, ean) {
    return productPositionTrackerAxios.post('/products', {
      name,
      bol_product_id: bolProductId,
      image,
      ean,
    });
  },
  getProductSearches(
    productId,
    catalog,
    startDate = null,
    endDate = null,
    filterBy = null,
    orderBy = null,
  ): Promise<ProductWithSearchTerms> {
    let path = `/products/${productId}/searches?catalog=${catalog}`;
    if (startDate && endDate) {
      path = path.concat(`&start_date=${startDate}&end_date=${endDate}`);
    }

    if (filterBy) {
      path = path.concat(`&filter_by=${filterBy}`);
    }

    if (orderBy) {
      path = path.concat(`&order_by=${orderBy}`);
    }

    return productPositionTrackerAxios.get(path);
  },
  addSearchTerm(productId, keywords) {
    return productPositionTrackerAxios.post(
      `/products/${productId}/searches/`,
      {
        keywords,
      },
    );
  },
  removeSearchTerm(productId, searchTermId) {
    return productPositionTrackerAxios.delete(
      `/products/${productId}/searches/${searchTermId}`,
    );
  },
  removeProduct(productId) {
    return productPositionTrackerAxios.delete(`/products/${productId}`);
  },
  getAnnotations(productId, startDate = null, endDate = null): Promise<any[]> {
    let path = `/products/${productId}/annotations`;
    if (startDate && endDate) {
      path = path.concat(`?start_date=${startDate}&end_date=${endDate}`);
    }

    return productPositionTrackerAxios.get(path);
  },
  addAnnotation(productId, text, date) {
    return productPositionTrackerAxios.post(
      `/products/${productId}/annotations`,
      { text, date },
    );
  },
  editAnnotation(productId, annotationId, text, date) {
    return productPositionTrackerAxios.patch(
      `/products/${productId}/annotations/${annotationId}`,
      { text, date },
    );
  },
  removeAnnotation(productId, annotationId) {
    return productPositionTrackerAxios.delete(
      `/products/${productId}/annotations/${annotationId}`,
    );
  },
  continueTracking(searchTermId) {
    return productPositionTrackerAxios.post(
      `/searches/${searchTermId}/continue_tracking`,
    );
  },
  useProductRanking(ean: string | null): {
    productRanks: Rank[];
    isLoading: boolean;
    error: Error;
  } {
    const { data, error, isLoading } = useSWR(
      ean ? `/products/rankings/${ean}` : null,
      (url) =>
        productPositionTrackerAxios
          .get(url)
          .then((res) => (res as unknown as { ranks: Rank[] }).ranks),
      {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        onError(e) {
          captureErrorAndShowMessage(e);
        },
      },
    );

    return {
      productRanks: data || [],
      isLoading,
      error,
    };
  },
};
