import React from 'react';
import { Row, Col, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { If } from 'tsx-control-statements/components';

import dayjs from 'dayjs';
import {
  RETAILER_ORDER_ITEM_SHIPPED_STATUS,
  RETAILER_ORDER_ITEM_OPEN_STATUS,
  RETAILER_ORDER_ITEM_CUSTOMER_CANCELLED_STATUS,
  RETAILER_ORDER_ITEM_SELLER_ACCEPTED_STATUS,
} from '../../../../constants';
import { formatAmount } from '../../../utilities';

import ProductCard from '../../../../components/productCard';
import { TOOLTIP_COLOUR } from '../../../../../constants';

function StoreDrawerOrderItems({ tax, items, revenue, subTotal }) {
  const orderStatusColor = {
    [RETAILER_ORDER_ITEM_SHIPPED_STATUS]: 'light-success',
    [RETAILER_ORDER_ITEM_OPEN_STATUS]: 'red',
    [RETAILER_ORDER_ITEM_CUSTOMER_CANCELLED_STATUS]: 'light-orange',
    [RETAILER_ORDER_ITEM_SELLER_ACCEPTED_STATUS]: 'light-orange',
  };
  return (
    <div className="store-order-drawer-items">
      {items.map((item) => {
        return (
          <Row gutter={32} className="drawer-item-row" key={item.order_item_id}>
            <Col
              xxl={13}
              xl={13}
              md={24}
              sm={24}
              xs={24}
              className="bear-mar-b-sm-12"
            >
              <ProductCard ean={item.product.ean} shouldFetch>
                <p className="bear-light-help-text">
                  <span>{item.fulfilment_display}</span>
                  <Tooltip
                    color={TOOLTIP_COLOUR}
                    placement="top"
                    title={`Uiterste leverdatum: ${dayjs(
                      item.latest_delivery_date,
                    ).format('MMM DD, YYYY')}`}
                  >
                    <i className="fad icon-calendar-alt bear-mar-l-8" />
                  </Tooltip>
                </p>
                <Tag
                  className={orderStatusColor[item.status]}
                  style={{ marginBottom: '1em' }}
                >
                  {item.status_display}
                </Tag>
              </ProductCard>
            </Col>
            <Col xxl={6} xl={6} lg={5} md={5} sm={12} xs={12}>
              <p className="bear-regular-text">{`${
                item.sold_quantity
              } x ${formatAmount(item.price)}`}</p>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
              <p className="bear-regular-text">{formatAmount(item.revenue)}</p>
            </Col>
          </Row>
        );
      })}
      <div className="order-drawer-total">
        <If condition={items.length === 1}>
          {items[0].other_costs.map((cost) => {
            return (
              <Row gutter={32} key={cost.type}>
                <Col
                  xl={{
                    span: 6,
                    offset: 13,
                  }}
                  md={{
                    span: 12,
                    offset: 0,
                  }}
                  sm={{
                    span: 12,
                    offset: 0,
                  }}
                  xs={{
                    span: 12,
                    offset: 0,
                  }}
                >
                  <p className="bear-light-regular-text">{cost.type_display}</p>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                  <p className="bear-regular-text">
                    {formatAmount(cost.amount)}
                  </p>
                </Col>
              </Row>
            );
          })}
        </If>
        <Row gutter={32}>
          <Col
            xl={{
              span: 6,
              offset: 13,
            }}
            md={{
              span: 12,
              offset: 0,
            }}
            sm={{
              span: 12,
              offset: 0,
            }}
            xs={{
              span: 12,
              offset: 0,
            }}
          >
            <p className="bear-regular-text">Totaal</p>
          </Col>
          <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
            <Tooltip
              placement="left"
              className="bear-has-tooltip"
              title={
                <div className="order-item-total-tooltip">
                  <p>
                    <span>Subtotaal (excl. BTW)</span>
                    <span>{formatAmount(subTotal)}</span>
                  </p>
                  <p>
                    <span>Belasting</span>
                    <span>{formatAmount(tax)}</span>
                  </p>
                </div>
              }
            >
              <span className="bear-bold-text">{formatAmount(revenue)}</span>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StoreDrawerOrderItems;

StoreDrawerOrderItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
