import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Form } from 'antd';
import { isMobileDevice } from '../../utilities/breakpoint';

function BearFormModal({
  title,
  okText,
  cancelText,
  onSubmit,
  handleOnClose,
  isLoading,
  children,
  className,
  form,
  open,
  width,
}) {
  const isMobile = isMobileDevice();

  return (
    <Modal
      centered
      closable
      open={open}
      width={width}
      title={title}
      className={className}
      destroyOnClose
      okButtonProps={{
        size: 'large',
        htmlType: 'submit',
        className: 'bear-mar-b-12',
        id: 'modal-submit-button',
      }}
      cancelButtonProps={{ size: 'large', className: 'bear-mar-b-12' }}
      okText={okText}
      cancelText={cancelText}
      onOk={() => form.submit()}
      onCancel={() => handleOnClose()}
      confirmLoading={isLoading}
    >
      <Form
        name="modal-form"
        form={form}
        preserve={false}
        layout={!isMobile ? 'vertical' : ''}
        requiredMark="optional"
        onFinish={(values) => onSubmit(values)}
      >
        {children}
      </Form>
    </Modal>
  );
}

BearFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  form: PropTypes.any.isRequired,
  width: PropTypes.number,
};

BearFormModal.defaultProps = {
  okText: 'Opslaan',
  cancelText: 'Annuleren',
  className: '',
  open: true,
  width: 600,
};

export default BearFormModal;
