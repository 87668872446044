import * as Sentry from '@sentry/react';
import { message as messagingService } from 'antd';
import { GENERIC_ERROR_MSG } from '../constants';

export default function captureErrorAndShowMessage(
  e: Error,
  message: string | null = GENERIC_ERROR_MSG,
  duration?: number,
) {
  Sentry.captureException(e);
  if (message) {
    if (duration) messagingService.error(message, duration);
    else messagingService.error(message);
  }
  if (console) {
    // eslint-disable-next-line no-console
    console?.error(e);
  }
}
