export const TIMEZONE_LIST_API_PATH = 'timezones/';
export const CURRENCY_EXCHANGE_RATE_API_PATH =
  'get_currency_exchange_rate/?base={}';

export const STORE_CREDENTIALS_API_PATH = 'retailer/';
export const STORE_SYNCHRONIZATION_API_PATH = 'retailer/initial_sync/';
export const STORE_RESTART_SYNCHRONIZATION_API_PATH = 'retailer/restart_sync/';
export const STORE_ABORT_SYNCHRONIZATION_API_PATH = 'retailer/abort_sync/';
export const STORE_DELETE_API_PATH = 'retailer/delete/';
export const STORE_IS_NOTIFICATION_ENABLED_API_PATH =
  'retailer/is_notification_enabled/';
export const STORE_DELETE_ADVERTISER_API_PATH = 'retailer/delete_advertiser/';
export const STORE_STATISTICS_API_PATH = 'retailer/order_items/statistics/';
export const STORE_RETURN_STATISTICS_API_PATH =
  'retailer/order_items/returns_statistics/';
export const STORE_GRAPH_API_PATH = 'retailer/order_items/performance/';
export const STORE_INVENTORY_API_PATH = 'retailer/inventory/';
export const STORE_INVENTORY_TOP_PRODUCTS_API_PATH =
  'retailer/inventory/top_products/';
export const STORE_INVENTORY_TOP_SALES_API_PATH =
  'retailer/inventory/top_sales/';
export const STORE_INVENTORY_SUMMARY_API_PATH = 'retailer/inventory/summary';
export const STORE_ORDERS_API_PATH = 'retailer/orders/';
export const STORE_RETURNS_API_PATH = 'retailer/returns/';
export const STORE_INVENTORY_SEARCH_TERM_VOLUMES =
  'retailer/get_search_term_volumes';
export const STORE_RETURNS_CREDIT_INVOICE_API_PATH =
  'retailer/returns/{}/credit_invoice/';
export const STORE_INVENTORY_COMMISSION_API_PATH =
  'retailer/inventory/get_commission?id={}';
export const STORE_STOCK_SETTINGS = 'retailer/inventory/{}/stock_settings/';
export const STORE_DYNAMIC_PRICE_SETTINGS =
  'retailer/inventory/{}/dynamic_price_settings/';
export const STORE_INVENTORY_BULK_EDIT_STOCK_SETTINGS =
  'retailer/inventory/bulk_update_stock_settings/';
export const STORE_INVENTORY_UPDATE_PRODUCT_IS_HIDDEN =
  'retailer/inventory/{}/toggle_hidden_status/';
export const STORE_INVENTORY_COPY_PRODUCT_COSTS =
  'retailer/inventory/copy_product_costs/';
export const STORE_GET_ORDER_CUSTOMER_DETAILS = 'retailer/customers/{}/';
export const STORE_GET_ORDER_DETAILS = 'retailer/orders/{}/';
export const STORE_GET_ORDER_SENT_EMAILS = 'retailer/orders/{}/sent_emails/';
export const STORE_SEND_ORDER_INVOICE = 'retailer/orders/{}/send_invoice/';
export const STORE_GET_ORDER_INVOICE = 'retailer/orders/{}/get_invoice/';

export const STORE_GET_ORDER_CREDIT_INVOICE =
  'retailer/orders/{}/get_credit_invoice/';
export const STORE_ORDER_CREDIT_INVOICE =
  'retailer/orders/{}/credit_order_invoice/';

export const STORE_CANCEL_ORDER = 'retailer/orders/{}/cancel/';
export const STORE_INVENTORY_PRODUCT_DETAIL_API_PATH = 'retailer/inventory/{}/';
export const STORE_INVENTORY_PRODUCT_OVERVIEW_API_PATH =
  'retailer/inventory/get_overview_by_product_ids';
export const STORE_ORDER_INVOICE_SETTINGS_API_PATH =
  'retailer/orders/invoice_settings/';
export const STORE_ORDER_INVOICE_LOGO_UPLOAD_API_PATH =
  'retailer/orders/invoice_settings/upload_logo/';

export const CSV_UPLOAD_API_PATH = 'retailer/inventory/upload-csv/';
export const CSV_PROCESSING_STATUS_API_PATH =
  'retailer/inventory/get-csv-status/';
export const CSV_GET_FILE = 'retailer/inventory/get-csv-file/';

export const RESEARCH_PRODUCTS_LIST_API_PATH = 'bol/products/';
export const RESEARCH_BOLOO_DATABASE_API_PATH = 'research/database/es/';

export const CATEGORY_COMMISSIONS_API_PATH =
  'research/tracker/groups/commissions/';
export const ALIBABA_SUPPLIER_FINDER_PATH =
  'research/product-alibaba-supplier-finder/{}';

// TRACKER

export const TRACKER_GROUPS_API_PATH = 'research/tracker/groups/';
export const TRACKER_PRODUCTS_API_PATH = 'research/tracker/products/';
export const TRACKER_PRODUCT_DETAIL_API_PATH = `${TRACKER_PRODUCTS_API_PATH}{}/`;
export const TRACKER_GROUP_PRODUCTS_API_PATH = `${TRACKER_GROUPS_API_PATH}{category_id}/products/`;
export const TRACKER_PRODUCTS_OVERVIEW_API_PATH = `${TRACKER_PRODUCTS_API_PATH}overview/`;
export const TRACKER_PRODUCTS_SEARCH_API_PATH = `${TRACKER_PRODUCTS_API_PATH}search/?term={}`;
export const TRACKER_PRODUCT_GROUP_UPDATE_API_PATH = `${TRACKER_PRODUCTS_API_PATH}{}/update_group/`;
export const TRACKER_ADD_PRODUCT_API_PATH =
  'research/tracker/groups/{}/add_product/';
export const TRACKER_PRODUCT_GRAPH_METRICS_API_PATH = `${TRACKER_PRODUCTS_API_PATH}{}/graph/`;

export const TRACKER_PRODUCTS_DELETE_BY_EAN_API_PATH = `${TRACKER_PRODUCTS_API_PATH}delete-by-ean/`;

// Database

export const RESEARCH_DATABASE_FILTERS_API_PATH = 'research/database/filters/';

// KEYWORD RESEARCH
export const KEYWORD_LOOKUP_API_PATH =
  'bol/keyword_lookup?q={}&loc_id={loc_id}';

// ACADEMY

export const COURSES_LIST_API_PATH = 'academy/';
export const UPDATE_LESSON_STATUS_API_PATH = 'academy/update_lesson_status/';
export const ADD_ACADEMY_VIEW_API_PATH = 'academy/add_first_academy_view/';

// SETTINGS

export const COMPANY_API_PATH = 'user/company/';
export const RESEND_VERIFICATION_EMAIL_API_PATH =
  'user/resend_verification_email/';
export const USER_CAN_TRACK_MORE_PRODUCTS_API_PATH =
  'user/can_track_more_products/';
export const CREATE_SUB_ACCOUNT_PATH = 'user/create_subaccount/';
export const LIST_ACCOUNTS_PATH = 'user/list_accounts/';
export const USER_GET_TOKENS_PATH = 'user/get_tokens/';
export const USER_REGISTER_API_PATH = 'signup/';
export const USER_EXISTS_API_PATH = 'signup/is_already_registered/';
export const PARTIAL_SIGNUP_COMPLETE_API = '/complete/facebook/';

export const UNSUBSCRIBE_SUBACCOUNT_API_PATH =
  'payments/unsubscribe_subaccount/';

export const UPDATE_INVOICE_EMAIL_API_PATH = 'payments/update_invoice_email/';

export const LIST_ACTIVE_SUBACCOUNTS_API_PATH =
  'payments/list_active_subaccounts/';

export const LOGIN_API_PATH = 'login/';
export const PASSWORD_RESET_PATH = 'reset_password/';

export const COUNTRIES_LIST_API_PATH = 'countries/';
export const TOPOL_EDITOR_SETTINGS_API_PATH = 'topol_editor_settings/';

// CAMPAIGN

export const CAMPAIGN_LIST_API_PATH = 'campaign/';
export const CAMPAIGN_INVENTORY_LIST_API_PATH = 'campaign/inventory/';
export const DEFAULT_CAMPAIGN_LIST_API_PATH = 'campaign/default/';
export const CAMPAIGN_TRANSLATE_API_PATH = 'campaign/translate/';
export const CAMPAIGN_ANALYTICS_API_PATH = 'campaign/analytics/';
export const DEFAULT_EMAIL_CATEGORIES_LIST_API_PATH =
  'email/campaign/default_categories/';
export const USER_EMAIL_CATEGORIES_LIST_API_PATH = 'email/campaign/categories/';
export const DEFAULT_EMAIL_TEMPLATES_LIST_API_PATH =
  'email/campaign/default_templates/';
export const USER_EMAIL_TEMPLATES_LIST_API_PATH = 'email/campaign/templates/';
export const CAMPAIGN_MERGE_TAGS = 'campaign/merge_tags/';

export const CAMPAIGN_TAGS_API_PATH = 'campaign/tags';
export const CAMPAIGN_STATISTICS_API_PATH = 'campaign/statistics';
export const CAMPAIGN_DETAILS_API_PATH = 'campaign/{campaign_id}/';
export const CAMPAIGN_FLOW_API_PATH = 'campaign/{campaign_id}/flows/';
export const CAMPAIGN_FLOW_TEST_EMAIL_API_PATH =
  'campaign/{campaign_id}/flows/{flow_id}/send_test_email/';
export const CAMPAIGN_FLOW_DUPLICATE_API_PATH =
  'campaign/{campaign_id}/flows/{flow_id}/duplicate/';
export const CAMPAIGN_DUPLICATE_API_PATH = 'campaign/{campaign_id}/duplicate/';
export const CAMPAIGN_EMAILS_API_PATH = 'campaign/{campaign_id}/emails/';
export const CAMPAIGN_LINK_STATISTICS_API_PATH =
  'campaign/{campaign_id}/link_statistics';
export const CAMPAIGN_DETAIL_STATISTICS_API_PATH =
  'campaign/{campaign_id}/statistics';

// ACCOUNTING

export const ACCOUNTING_RESULTS = 'accounting/results/?interval={}';
export const ACCOUNTING_VAT_DECLARATION =
  'accounting/vat_declaration/?quarter={}';
export const ACCOUNTING_USER_COST_INVOICE = 'accounting/user_cost_invoice/';
export const ACCOUNTING_USER_COST_INVOICE_LIST =
  'accounting/user_cost_invoice/?interval={}';
export const ACCOUNTING_USER_REVENUE_INVOICE =
  'accounting/user_revenue_invoice/';
export const ACCOUNTING_USER_REVENUE_INVOICE_LIST =
  'accounting/user_revenue_invoice/?interval={}';
export const ACCOUNTING_UPLOAD_INVOICE = '/accounting/upload_invoice/';
export const ACCOUNTING_COST_CATEGORIES = 'accounting/cost_categories/';
export const ACCOUNTING_USER_VATS = 'accounting/user_vats/';

export const PROFIT_CALCULATIONS_LIST = 'research/calculator/';

export const INVOICING_SEND_TEST_EMAIL_API_PATH = '/send-test-email/';

// Optin APIs

export const OPTIN_CREATE_LEAD = 'optin/create_lead';
