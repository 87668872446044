import React, {
  useReducer,
  useContext,
  createContext,
  Reducer,
  ReactNode,
} from 'react';

export const ProfileDataContext = createContext(undefined);

export function ProfileDataProvider({
  reducer,
  initialState = {},
  children,
}: {
  reducer: Reducer<any, any>;
  initialState?: any;
  children: ReactNode;
}) {
  const contextValue = useReducer(reducer, initialState);
  return (
    <ProfileDataContext.Provider value={contextValue}>
      {children}
    </ProfileDataContext.Provider>
  );
}

export const ProfileDataConsumer = () => {
  const contextValue = useContext(ProfileDataContext);
  return contextValue;
};
