export const DEFAULT_PAGE_SIZE = 10;
export const TOOLTIP_COLOUR = '#37474F';

export const ACTIVE_SUBSCRIPTION_STATUS = 'active';
export const TRIALING_SUBSCRIPTION_STATUS = 'trialing';
export const PAST_DUE_SUBSCRIPTION_STATUS = 'past_due';
export const CANCELED_SUBSCRIPTION_STATUS = 'cancelled';
export const EXPIRED_SUBSCRIPTION_STATUS = 'expired';
export const RENEWED_SUBSCRIPTION_STATUS = 'renewed';
export const CANCELLABLE_SUBSCRIPTION_STATUS = [
  ACTIVE_SUBSCRIPTION_STATUS,
  TRIALING_SUBSCRIPTION_STATUS,
  PAST_DUE_SUBSCRIPTION_STATUS,
];
export const ACTIVE_SUBSCRIPTION_STATUSES = [
  ACTIVE_SUBSCRIPTION_STATUS,
  TRIALING_SUBSCRIPTION_STATUS,
];
export const MOLLIE_PAYMENT_PROCESSED_STATUSES = [
  'paid',
  'expired',
  'failed',
  'canceled',
];

export const EXTRA_PRESELECT_OPTIONS = [
  {
    value: 270,
    label: 'Laatste 270 dagen',
  },
];

export const DEFAULT_EMPTY_DESCRIPTION = 'Geen gegevens';

export const RGB_WHITE_SPECTRUM = 225;
export const DEFAULT_THEME_COLOUR = '#000000';
export const CHROME_EXTENSION_ID = 'fefpocfmkbkncjkocjbejmnmpgkhpnap';

export const MONTH_DAYS = 29; // NOTE if changed here -> reflect in the backend

export const GENERIC_ERROR_MSG = 'Oeps! Er is iets fout gegaan.';

export const IMAGE_STATIC_PATH = '/static/images';

export const SW_INIT = 'SW_INITIALIZE';
export const SW_UPDATE = 'SW_UPDATE';
