import { Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Choose, Otherwise, When } from 'tsx-control-statements/components';
import { campaignViewEmail } from '../../../actions/campaign';

import './style.scss';

function ViewEmailModal({ campaignId, selectedEmailTask, handleClose }) {
  const [viewEmailHTML, setViewEmailHTML] = useState(null);
  const [isViewEmailLoading, setIsViewEmailLoading] = useState(false);

  useEffect(() => {
    setIsViewEmailLoading(true);

    campaignViewEmail(campaignId, selectedEmailTask.id).then((response) => {
      setViewEmailHTML(response.data);
      setIsViewEmailLoading(false);
    });
  }, []);

  return (
    <Modal open footer={null} width="80vw" onCancel={() => handleClose()}>
      <Choose>
        <When condition={isViewEmailLoading}>
          <Spin />
        </When>
        <Otherwise>
          <iframe
            id="responsive-iframe"
            srcDoc={viewEmailHTML}
            title="preview"
          />
        </Otherwise>
      </Choose>
    </Modal>
  );
}

export default ViewEmailModal;
