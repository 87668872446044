import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { If } from 'tsx-control-statements/components';
import {
  INDEX_ROUTE,
  SETTING_ACCOUNTING_ROUTE,
  SETTING_INVOICES_ROUTE,
  SETTING_MEMBERSHIP_DEACTIVATE_ROUTE,
  SETTING_MEMBERSHIP_ROUTE,
  SETTING_NOTIFICATIONS_ROUTE,
  SETTING_STORE_ROUTE,
  SETTINGS_ROUTE,
} from '../routes';

import { LoggedInRoute } from './utilities';
import ProfileHook from '../../hooks/profile';
import {
  PlanFeatures,
  useSubscription,
} from '../services/subscriptionService/api';

const UnsubscribeManagement = lazy(
  () => import(/* webpackPrefetch: true */ '../unsubscribeManagement'),
);

const UserSettings = lazy(
  () => import(/* webpackPrefetch: true */ '../userSettingsManagement'),
);

function UnsubscribeRouteService({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!rest.isCancelledSubscription) {
          return <Component {...props} />;
        }
        return <Redirect to={INDEX_ROUTE} />;
      }}
    />
  );
}

function SettingsRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  const { isActiveSubscription, subscriptionPlanFeatures } = useSubscription();

  return (
    <Switch>
      <Suspense fallback={<Spin className="app-spinner" />}>
        <LoggedInRoute
          exact
          path={SETTINGS_ROUTE}
          component={UserSettings}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={SETTING_MEMBERSHIP_ROUTE}
          component={UserSettings}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={SETTING_INVOICES_ROUTE}
          component={UserSettings}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={SETTING_NOTIFICATIONS_ROUTE}
          component={UserSettings}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={SETTING_ACCOUNTING_ROUTE}
          component={UserSettings}
          isAuthenticated={isAuthenticated}
        />
        <If
          condition={subscriptionPlanFeatures?.includes(
            PlanFeatures.IsSellerStoreEnabled,
          )}
        >
          <LoggedInRoute
            exact
            path={SETTING_STORE_ROUTE}
            component={UserSettings}
            isAuthenticated={isAuthenticated}
          />
        </If>
        <UnsubscribeRouteService
          exact
          path={SETTING_MEMBERSHIP_DEACTIVATE_ROUTE}
          component={UnsubscribeManagement}
          isAuthenticated={isAuthenticated}
          isCancelledSubscription={!isActiveSubscription}
        />
      </Suspense>
    </Switch>
  );
}

export default ProfileHook(SettingsRoute);
