import React from 'react';
import { Popover } from 'antd';

import { formatAmount } from '../../../utilities';
import { COST_PRICE_OPTIONS } from '../../constants';

import './style.scss';
import { FormValuesInterface } from '../productCostPricesForm/types';

function CostPriceBreakupPopover({
  label,
  price,
  commission,
  formValues,
}: {
  label: string;
  price: number;
  commission: {
    percentage: number;
    fixedAmount: number;
  };
  formValues: FormValuesInterface;
}) {
  const costPrice = formValues?.costPrice || 0;
  const otherCosts = formValues?.otherCosts || [];
  const values = otherCosts.filter((i) => i && i.type && i.amount);

  function deductVAT(value) {
    return Math.round((value / 1.21) * 100) / 100;
  }

  function getAdditionalCostLabel(type) {
    const index = COST_PRICE_OPTIONS.findIndex((item) => item.value === type);
    const value = index > -1 ? COST_PRICE_OPTIONS[index].label : type;
    return `${value}`;
  }

  const percentage = deductVAT(commission.percentage || 0);

  return (
    <Popover
      overlayClassName="inventory-cost-price-breakup-popover"
      content={
        <>
          <div className="bear-flex-sb">
            <p>Vaste bijdrage</p>
            <p>{formatAmount(deductVAT(commission.fixedAmount))}</p>
          </div>
          <div className="bear-flex-sb">
            <p>{`Percentage (${percentage}%) van de verkoopprijs`}</p>
            <p>{formatAmount((price * commission.percentage) / 121)}</p>
          </div>
          <div className="bear-flex-sb">
            <p>Inkoopprijs</p>
            <p>{formatAmount(costPrice)}</p>
          </div>
          {values.map((item) => {
            return (
              <div className="bear-flex-sb" key={item.type}>
                <p>{getAdditionalCostLabel(item.type)}</p>
                <p>{formatAmount(item.amount)}</p>
              </div>
            );
          })}
        </>
      }
      title="Details"
      className="bear-has-tooltip"
    >
      {label}
    </Popover>
  );
}

export default CostPriceBreakupPopover;
