import useSWR from 'swr';
import { CostPrice } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useOfferCostPrices(offerId: string | null): {
  offerCostPrices: CostPrice[];
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    offerId ? `/offers/${offerId}/cost_prices` : null,
    (url) => productsAxios.get(url).then((res) => res.data),

    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    offerCostPrices: data || null,
    isLoading,
    isError: error,
  };
}
