/*eslint-disable*/
import React from 'react';
import { Button, Form, Input } from 'antd';

import { isMobileDevice } from '../../../utilities/breakpoint';

const ResetPasswordForm = ({ processing, onSubmit }) => {
  const isMobile = isMobileDevice();
  const [form] = Form.useForm();

  return (
    <Form
      layout={isMobile ? 'horizontal' : 'vertical'}
      className="bear-mar-t-20"
      form={form}
      onFinish={(values) => onSubmit(values)}
    >
      <Form.Item
        name="password"
        label="New Password"
        rules={[
          {
            required: true,
            message: 'Please enter password',
          },
        ]}
      >
        <Input.Password placeholder="Enter password" size="large" />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label="Confirm Password"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please enter password',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                'The two passwords that you entered do not match!'
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="Enter password" size="large" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          block
          size="large"
          htmlType="submit"
          loading={processing}
        >
          <span>Set Password</span>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
