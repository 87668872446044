import React, { useEffect, useState } from 'react';

import { DatePicker, Select } from 'antd';
import { If } from 'tsx-control-statements/components';
import dayjs, { Dayjs } from 'dayjs';

import { isMobileDevice } from '../../retailer/utilities/breakpoint';

import {
  PreselectPeriods,
  PreselectPeriodsDates,
  PreselectPeriodsDatesInOrder,
} from './utils';

import './style.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const DEFAULT_PERIOD = PreselectPeriods.Last30Days;

function BearDatePicker({
  defaultValues,
  onApply,
  isCapped,
}: {
  defaultValues?: [number, number]; // Unix
  onApply: (value: [number, number], isCapped?: boolean) => void;
  isCapped?: boolean;
}) {
  const [selectedPeriod, setSelectedPeriod] = useState(DEFAULT_PERIOD);
  const [startAndEndDateForRangePicker, setStartAndEndDateForRangePicker] =
    useState(
      defaultValues?.length === 2
        ? {
            startDate: dayjs.unix(defaultValues[0]).utc(),
            endDate: dayjs.unix(defaultValues[1]).utc(),
          }
        : {
            startDate: dayjs(
              PreselectPeriodsDates[DEFAULT_PERIOD].startDate.toISOString(),
            ).utc(),
            endDate: dayjs(
              PreselectPeriodsDates[DEFAULT_PERIOD].endDate.toISOString(),
            ).utc(),
          },
    );

  const isMobile = isMobileDevice();

  // When a period is selected we change the picker ranges and call on apply
  function handleCalendarChange(dates: [Dayjs, Dayjs]) {
    const [valueStartDate, valueEndDate] = dates;

    if (!valueStartDate || !valueEndDate) {
      return;
    }
    const startDate = valueStartDate.utc().startOf('day');
    const endDate = valueEndDate.utc().endOf('day');
    setStartAndEndDateForRangePicker({
      startDate,
      endDate,
    });
    onApply([startDate.unix(), endDate.unix()], isCapped);
  }

  // When the picker ranges change we trigger a select find to see if we can hit the select option
  useEffect(
    () =>
      setSelectedPeriod(
        Number(
          Object.entries(PreselectPeriodsDates).find(
            ([, preselectPeriodDate]) =>
              dayjs(preselectPeriodDate.startDate?.toISOString())
                .utc()
                .unix() === startAndEndDateForRangePicker.startDate.unix() &&
              dayjs(preselectPeriodDate.endDate?.toISOString()).utc().unix() ===
                startAndEndDateForRangePicker.endDate.unix(),
          )?.[0],
        ) || PreselectPeriods.Custom,
      ),
    [startAndEndDateForRangePicker],
  );

  // When select changes then we set the range for the picker and we call on apply
  function handleSelectChange(value: PreselectPeriods) {
    setSelectedPeriod(value);

    if (value === PreselectPeriods.Custom) {
      return;
    }
    setStartAndEndDateForRangePicker({
      startDate: dayjs(
        PreselectPeriodsDates[value].startDate.toISOString(),
      ).utc(),
      endDate: dayjs(PreselectPeriodsDates[value].endDate.toISOString()).utc(),
    });
    onApply(
      [
        PreselectPeriodsDates[value].startDate.unix(),
        PreselectPeriodsDates[value].endDate.unix(),
      ],
      isCapped,
    );
  }

  return (
    <div className="bear-date-picker">
      <Select
        size="large"
        value={
          selectedPeriod !== PreselectPeriods.Custom
            ? selectedPeriod
            : PreselectPeriodsDates[PreselectPeriods.Custom].label
        }
        onChange={(value) => {
          handleSelectChange(Number(value));
        }}
      >
        {PreselectPeriodsDatesInOrder.map((key) => {
          const value = PreselectPeriodsDates[key];
          return (
            <Option key={Number(key)} value={Number(key)}>
              {value.label}
            </Option>
          );
        })}
      </Select>
      <If condition={!isMobile}>
        <RangePicker
          size="large"
          format="DD-MMM"
          allowClear={false}
          value={[
            startAndEndDateForRangePicker.startDate,
            startAndEndDateForRangePicker.endDate,
          ]}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
          onCalendarChange={(dates) => handleCalendarChange(dates)}
          picker="date"
        />
      </If>
    </div>
  );
}

export default BearDatePicker;
