import React from 'react';
import AppHeaderDrowdown from '../../components/appHeaderDrowdown';

import './style.scss';
import { IMAGE_STATIC_PATH } from '../../../constants';

import {
  SubscriptionPaymentMethods,
  updateSubscriptionPaymentMethod,
  useSubscriptionPaymentMethod,
} from '../../services/subscriptionService/api';
import captureErrorAndShowMessage from '../../../utilities/errors';
import PaymentMethods from '../../components/paymentMethods';

function PaymentMethodSettings() {
  const { paymentMethod } = useSubscriptionPaymentMethod();
  async function onPaymentMethodSelect(
    newPaymentMethod: SubscriptionPaymentMethods,
  ) {
    try {
      const response = await updateSubscriptionPaymentMethod(newPaymentMethod);
      window.location.href = response.checkout_url;
    } catch (e) {
      captureErrorAndShowMessage(e);
    }
  }

  return (
    <div className="payment-method-settings bear-height">
      <div className="payment-method-header bear-flex-sb bear-flex-ac bear-pad-20">
        <img
          alt="boloo"
          src={`${IMAGE_STATIC_PATH}/boloo_color_logo.png`}
          className="company-logo"
        />
        <AppHeaderDrowdown />
      </div>
      <div className="payment-method-settings-content bear-pad-t-40 bear-pad-b-20">
        <p className="bear-bold-title bear-pad-b-8">
          Update je betalingsmethode
        </p>
        <div className="bear-mar-t-40">
          <PaymentMethods
            initialPaymentMethod={paymentMethod}
            onPaymentMethodSelect={(selectedPaymentMethod) =>
              onPaymentMethodSelect(selectedPaymentMethod)
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodSettings;
