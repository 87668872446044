import React, { createContext, useContext, useReducer } from 'react';

const AppHeaderTitleContext = createContext(undefined);

export function AppHeaderTitleProvider({ reducer, initialState, children }) {
  const contextValue = useReducer(reducer, initialState);
  return (
    <AppHeaderTitleContext.Provider value={contextValue}>
      {children}
    </AppHeaderTitleContext.Provider>
  );
}

export const AppHeaderTitleConsumer = () => {
  return useContext(AppHeaderTitleContext);
};
