/*eslint-disable*/
import { RGB_WHITE_SPECTRUM } from '../constants';

export const countDecimals = (value) => {
  value = typeof value == 'string' ? parseInt(value) : value;
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
};

export const formatNumber = (value, prefix = '', defaultValue = 0) => {
  return `${prefix}${(Number(value) || defaultValue)
    .toFixed(2)
    .toString()
    .replace('.', ',')}`;
};

export const formatNumberToCurrency = (
  value,
  currencySymbol = '€',
  decimals = 2,
): string => {
  const locale = currencySymbol === '€' ? 'nl-NL' : 'en-US';
  const selectedCurrency = currencySymbol === '€' ? 'EUR' : 'USD';

  const formattedCurrencyValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: selectedCurrency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);

  return formattedCurrencyValue.replace(/\s+/g, '');
};

export const formatNumberToDutch = (
  value,
  prefix = '',
  defaultValue = 0,
  ignoreDecimals = false,
) => {
  let parseValue = (value || defaultValue).toLocaleString('nl-BE');
  if (value && !ignoreDecimals) {
    const decimalCount = countDecimals(value);
    if (decimalCount === 0) {
      parseValue += ',00';
    }
    if (decimalCount === 1) {
      parseValue += '0';
    }
  }
  return `${prefix}${parseValue}`;
};

export const toFixedValue = (value, fixedPos = 2, parse = true) => {
  const fixedValue = value ? value.toFixed(fixedPos) : 0;
  if (parse) {
    return parseFloat(fixedValue);
  }
  return fixedValue;
};

export const formatAmount = (value, suffix = '€') => {
  return formatNumberToDutch(toFixedValue(value, 2, true), suffix);
};

// In ES2020 -> use compactDisplay from Intl.NumberFormat
export const abbreviateAmount = (value, fixedPos = 2) => {
  const SI_SYMBOL = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const pattern = /\.0+€|(\.[0-9]*[1-9])0+€/;
  let currentSiSymbol;
  for (
    currentSiSymbol = SI_SYMBOL.length - 1;
    currentSiSymbol > 0;
    currentSiSymbol -= 1
  ) {
    if (value >= SI_SYMBOL[currentSiSymbol].value) {
      break;
    }
  }
  const formattedValue = (value / SI_SYMBOL[currentSiSymbol].value)
    .toFixed(fixedPos)
    .replace(pattern, '€1');
  return formattedValue + SI_SYMBOL[currentSiSymbol].symbol;
};

export const roundUp = (num, decimalPlaces = 2) => {
  // @ts-ignore
  return Number(Math.round(num + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

// Note: if Red or Green or Blue is above 225 it appears whitish
export function isNonWhiteSpectrum(rgbVal) {
  return Object.values(rgbVal).some((i: number) => i < RGB_WHITE_SPECTRUM);
}

export const formatValue = (value, prefix = '') => {
  const fixedValue = roundUp(value);
  return formatNumberToDutch(fixedValue, prefix);
};

const PLURALS = {
  Subcategorie: 'subcategorieën',
  resultaat: 'resultaten',
  product: 'producten',
};

export const pluralize = (val, word?, plural = word + 's') => {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object')
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

export const autoPluralize = pluralize(PLURALS);

export function roundFloatTo2Decimals(float: number) {
  return Math.round((float + Number.EPSILON) * 100) / 100;
}
