import useSWR from 'swr';
import { ProductCommission } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useProductCommissionByPrice(
  productEan: string | null,
  productPrice: number,
): {
  commission: ProductCommission | null;
  isLoading: boolean;
  isError: Error;
} {
  const { data, error, isLoading } = useSWR(
    productEan ? `/product/commission/${productEan}/${productPrice}` : null,
    (url) => productsAxios.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    commission: data || null,
    isLoading,
    isError: error,
  };
}
