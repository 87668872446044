import useSWR from 'swr';
import { backOff } from 'exponential-backoff';
import type { Category } from '../../category';
import productsAxios from '../../axiosInstance';
import {
  ApiAxiosError,
  MAX_RETRIES_FOR_BACKOFF_ANY_ERROR,
  MAX_RETRIES_FOR_BACKOFF_NETWORK_ERROR,
} from '../../../axiosApi';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

function resolveOrRejectBasedOnCategoriesStatus(
  categories: Category[],
): Promise<Category[]> {
  const areCategoriesBeingUpdated = categories.some(
    (category) => category.is_updating,
  );
  if (!areCategoriesBeingUpdated) {
    return Promise.resolve(categories);
  }
  const error = new ApiAxiosError(undefined, ApiAxiosError.PROCESSING);
  error.data = categories;
  return Promise.reject(error);
}

export function useBolProductCategories(ean?: string | null): {
  categories: Category[];
  isLoading: boolean;
  error: Error;
} {
  const { data, error, isLoading } = useSWR(
    ean ? `/products/ean/${ean}/categories` : null,
    (url) =>
      backOff(
        async () =>
          resolveOrRejectBasedOnCategoriesStatus(
            (await productsAxios.get(url)).data,
          ),
        {
          timeMultiple: 2,
          maxDelay: 3000,
          numOfAttempts: 100,
          jitter: 'none',
          delayFirstAttempt: true,
          retry: (e: ApiAxiosError<Category[]>, attemptNumber) => {
            switch (e.code) {
              case ApiAxiosError.PROCESSING: {
                return true;
              }
              case ApiAxiosError.ERR_NETWORK: {
                return attemptNumber < MAX_RETRIES_FOR_BACKOFF_NETWORK_ERROR;
              }
              case ApiAxiosError.ERR_BAD_REQUEST: {
                return e.response.status === 404
                  ? false
                  : attemptNumber < MAX_RETRIES_FOR_BACKOFF_ANY_ERROR;
              }
              default: {
                return attemptNumber < MAX_RETRIES_FOR_BACKOFF_ANY_ERROR;
              }
            }
          },
        },
      ).then((res) => res),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e, null);
      },
    },
  );
  return {
    categories: data,
    isLoading,
    error,
  };
}
