import useSWR, { KeyedMutator } from 'swr';
import { CategoryInterface } from '../types';
import productsAxios from '../../axiosInstance';
import captureErrorAndShowMessage from '../../../../../utilities/errors';

export function useBolCategories(): {
  bolCategories: CategoryInterface[];
  isLoading: boolean;
  isError: Error;
  mutate: KeyedMutator<CategoryInterface[]>;
  isValidating: boolean;
} {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    '/categories',
    (url) => productsAxios.get(url).then((res) => res.data),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      onError(e) {
        captureErrorAndShowMessage(e);
      },
    },
  );
  return {
    bolCategories: data,
    mutate,
    isLoading,
    isValidating,
    isError: error,
  };
}
