import { BolSearchSortOptions } from '../enums';
import { QueryParamMappings, SubCategorizedQuery } from '../../../types';

interface SearchQueryBase {
  sort?: BolSearchSortOptions;
  page?: number;
}

/**
 * Interface for search query
 * @description Interface for search query with filters
 */
export interface SearchQueryInterface extends SearchQueryBase {
  q?: string;
  category_ids?: number[];
}

export const defaultSearchQueryFiltersMapping: QueryParamMappings<
  SubCategorizedQuery<SearchQueryInterface>
> = {
  category_ids: (value) => value.split(',').map(Number),
  sub_categories: (value) => value.split(',').map(Number),
  q: (value) => value,
  page: (value) => Number(value),
};
