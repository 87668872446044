export const USER_API_PATH = 'user/';
export const PASSWORD_RESET_REQUEST_PATH = 'request_reset_password/';

let apiUrl = 'https://api.boloo.co';

if (process.env.TARGET_ENV === 'stage') {
  apiUrl = 'https://api.staging.boloo.co';
}

if (process.env.TARGET_ENV === 'development') {
  apiUrl = 'http://localhost:8000';
}

export const API_URL = apiUrl;
export const API_ENDPOINT = `${apiUrl}/api/v1/`;
