import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';

import { impersonate } from '../../actions';

function Impersonation() {
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    impersonate(id);
  }, []);
  return (
    <Row align="middle" style={{ marginTop: '2em' }}>
      <Col span={24}>
        <h1 className="bear-ta-center">
          <Spin />
          <br />
          Impersonating user #{id}...
        </h1>
      </Col>
    </Row>
  );
}

export default Impersonation;
