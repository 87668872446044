import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LoggedInRoute } from './utilities';
import {
  ACCOUNTING,
  ACCOUNTING_USER_COST_INVOICES,
  ACCOUNTING_USER_COST_INVOICE_ADD,
  ACCOUNTING_USER_COST_INVOICE_EDIT,
  ACCOUNTING_USER_REVENUE_INVOICE_ADD,
  ACCOUNTING_USER_REVENUE_INVOICE_EDIT,
  ACCOUNTING_USER_REVENUE_INVOICES,
  ACCOUNTING_DECLARATION,
} from '../routes';
import ProfileHook from '../../hooks/profile';

const AccountingResult = lazy(
  () => import(/* webpackPrefetch: true */ '../accounting/accoutingResult'),
);

const AccoutingUserCostInvoice = lazy(
  () => import(/* webpackPrefetch: true */ '../accounting/userCostInvoice'),
);

const AccoutingUserRevenueInvoice = lazy(
  () => import(/* webpackPrefetch: true */ '../accounting/userRevenueInvoice'),
);

const AccountingVatDeclaration = lazy(
  () => import(/* webpackPrefetch: true */ '../accounting/vatDeclaration'),
);

const AccountingUserCostInvoiceList = lazy(
  () => import(/* webpackPrefetch: true */ '../accounting/userCostInvoiceList'),
);

const AccountingUserRevenueInvoiceList = lazy(
  () =>
    import(/* webpackPrefetch: true */ '../accounting/userRevenueInvoiceList'),
);

function AccountingRoute({ profileData }) {
  const isAuthenticated = !!Object.keys(profileData).length;

  return (
    <Switch>
      <Suspense fallback={<Spin className="app-spinner" />}>
        <LoggedInRoute
          exact
          path={ACCOUNTING}
          component={AccountingResult}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_COST_INVOICES}
          component={AccountingUserCostInvoiceList}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_COST_INVOICE_ADD}
          component={AccoutingUserCostInvoice}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_COST_INVOICE_EDIT}
          component={AccoutingUserCostInvoice}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_REVENUE_INVOICES}
          component={AccountingUserRevenueInvoiceList}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_REVENUE_INVOICE_ADD}
          component={AccoutingUserRevenueInvoice}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_USER_REVENUE_INVOICE_EDIT}
          component={AccoutingUserRevenueInvoice}
          isAuthenticated={isAuthenticated}
        />
        <LoggedInRoute
          exact
          path={ACCOUNTING_DECLARATION}
          component={AccountingVatDeclaration}
          isAuthenticated={isAuthenticated}
        />
      </Suspense>
    </Switch>
  );
}

export default ProfileHook(AccountingRoute);
