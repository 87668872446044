export const UPLOAD_TO_BOL_INVOICE_TRIGGER = 'upload_to_bol';

export const INVOICE_TRIGGER_OPTIONS = [
  {
    label: 'Wanneer een bestelling is geplaatst',
    value: 'on_ordered',
    disabled: true,
  },
  {
    label: 'Wanneer een bestelling is verzonden',
    value: 'on_shipped',
    disabled: true,
  },
  {
    label: 'Automatisch uploaden naar Bol.com',
    value: UPLOAD_TO_BOL_INVOICE_TRIGGER,
    disabled: false,
  },
  {
    label: 'Niet',
    value: 'never',
    disabled: false,
  },
];

export const STORE_FORM_CHECKBOX_OPTIONS = [
  {
    name: 'use_name',
    label: 'Naam',
    lookupKey: 'store_name',
    isRequired: true,
  },
  {
    name: 'use_address',
    label: 'Adres',
    lookupKey: 'store_address',
    isRequired: true,
  },
  {
    name: 'use_vat_number',
    label: 'Btw-nummer',
    lookupKey: 'vat_number',
  },
  {
    name: 'use_iban_number',
    label: 'IBAN nummer',
    lookupKey: 'iban_number',
  },
  {
    name: 'use_coc_number',
    label: 'KVK nummer',
    lookupKey: 'coc_number',
  },
];

export const MAX_FOOTER_TITLE_LENGTH = 60;
export const MAX_FOOTER_DESCRIPTION_LENGTH = 100;

export const ACCEPTED_IMAGE_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
];
