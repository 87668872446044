import React from 'react';
import { Menu } from 'antd';

import './style.scss';

import { getMenuItem } from '../../utilities/app';

function AppFeatureMenu({
  options,
  selectedOption,
  onOptionSelection,
}: {
  options: { key: string; value: string; icon?: React.ReactNode }[];
  selectedOption: string;
  onOptionSelection: (key: string) => void;
}) {
  return (
    <Menu
      mode="horizontal"
      className="app-feature-menu"
      selectedKeys={[selectedOption]}
      items={options.map((option) =>
        getMenuItem(
          <span
            className="bear-flex bear-flex-ac bear-flex-pc menu-item-text"
            onClick={() => {
              onOptionSelection(option.key);
            }}
          >
            {option.value}
          </span>,
          option.key,
          option.icon,
        ),
      )}
    />
  );
}

export default AppFeatureMenu;
