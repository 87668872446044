import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN } from '../../constants';

const SentryConfig = {
  dsn: SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  environment: process.env.NODE_ENV,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  integrations: [new Integrations.BrowserTracing()],
  SampleRate: 0.25,
};

export default SentryConfig;
