import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { Spin } from 'antd';
import { Selection } from '../../types';
import { getTooltipFormat, mapToGraphData } from '../../helpers';
import { getProductsQuantity } from '../../../services/financialReportingService/api';
import captureErrorAndShowMessage from '../../../../utilities/errors';
import CommonCard from '../../../components/commonCard';

interface ProductsSoldGraphProps {
  selection: Selection;
}

function ProductsSoldGraph({ selection }: ProductsSoldGraphProps) {
  const [series, setSeries] = useState(null);
  const options: ApexOptions = {
    xaxis: {
      type: 'datetime',
    },
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        format: getTooltipFormat(selection),
      },
    },
  };

  useEffect(() => {
    if (selection == null) {
      return;
    }
    getProductsQuantity(selection, selection.group_by)
      .then((response) => {
        const { data } = response;
        setSeries([
          {
            name: 'Aantal verkochte producten',
            data: mapToGraphData(selection, data),
          },
        ]);
      })
      .catch(captureErrorAndShowMessage);
  }, [selection]);

  return (
    <CommonCard title="Verkochte producten in deze periode">
      {!series && (
        <div className="graph-loading">
          <Spin />
        </div>
      )}
      {series && (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height="250"
        />
      )}
    </CommonCard>
  );
}

export default ProductsSoldGraph;
