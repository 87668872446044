import React from 'react';
import { Statistic, StatisticProps } from 'antd';
import CountUp from 'react-countup';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

function CommonStatistic({
  useValueColors = false,
  useValueArrowPrefix = false,
  ...props
}: {
  useValueColors?: boolean;
  useValueArrowPrefix?: boolean;
} & StatisticProps) {
  return (
    <Statistic
      {...props}
      style={props.style || { fontWeight: 'bold', fontSize: '18px' }}
      valueStyle={
        !useValueColors
          ? null
          : { color: (props.value as number) < 0 ? '#cf1322' : '#3f8600' }
      }
      prefix={
        !useValueArrowPrefix ? (
          props.prefix
        ) : (props.value as number) < 0 ? (
          <>
            <ArrowDownOutlined />
            {props.prefix}
          </>
        ) : (
          <>
            <ArrowUpOutlined />
            {props.prefix}
          </>
        )
      }
      {...(props.formatter !== false
        ? {
            formatter:
              props.formatter ||
              ((value: number) => (
                <CountUp
                  end={value}
                  separator=","
                  decimal="."
                  decimals={props.precision}
                />
              )),
          }
        : {
            formatter: (value: number) =>
              new Intl.NumberFormat('nl-NL', {
                minimumFractionDigits: props.precision,
                maximumFractionDigits: props.precision,
              }).format(value),
          })}
    />
  );
}

export default CommonStatistic;
