import { useEffect } from 'react';
import { ONESIGNAL_SCRIPT_URL, ONESIGNAL_WEB_SDK_URL } from '../constants';
import { getIsNotificationEnabled } from './actions';
import captureErrorAndShowMessage from '../../utilities/errors';
import { useUserProfile } from '../services/userService/api';

const isProd = process.env.NODE_ENV === 'production';
const isStaging = process.env.TARGET_ENV === 'stage';

function OneSignal() {
  const { user } = useUserProfile();
  // https://stackoverflow.com/a/9469983
  function removeScript(filename) {
    const tags = document.getElementsByTagName('script');
    for (let i = tags.length; i >= 0; i -= 1) {
      if (
        tags[i] &&
        tags[i].getAttribute('src') != null &&
        tags[i].getAttribute('src').indexOf(filename) !== -1
      ) {
        tags[i].parentNode.removeChild(tags[i]);
      }
    }
  }

  useEffect(() => {
    if (!(isProd || isStaging) || !user) {
      return;
    }
    getIsNotificationEnabled()
      .then((response) => {
        const notificationEnabled = response.data.message;
        if (notificationEnabled) {
          const oneSignalSDK = document.createElement('script');
          oneSignalSDK.src = ONESIGNAL_WEB_SDK_URL;
          oneSignalSDK.async = true;
          document.body.appendChild(oneSignalSDK);

          const oneSignalJS = document.createElement('script');
          oneSignalJS.src = ONESIGNAL_SCRIPT_URL;
          oneSignalJS.type = 'text/javascript';
          document.body.appendChild(oneSignalJS);
        } else {
          removeScript(ONESIGNAL_WEB_SDK_URL);
          removeScript(ONESIGNAL_SCRIPT_URL);
        }
      })
      .catch((e) => captureErrorAndShowMessage(e));
  }, [user]);
  return null;
}

export default OneSignal;
