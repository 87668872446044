import React, { useState } from 'react';
import { Col, Row, Space } from 'antd';
import SelectionBar from '../SelectionBar';
import { Selection } from '../../types';

import KpiBar from '../KpiBar';
import StorePerformanceGraph from '../StorePerformanceGraph';
import ProductsSoldGraph from '../ProductsSoldGraph';
import PerformanceProductList from '../PerformanceProductList';
import './style.css';
import ProductDashboardStats from '../../../productRankingChecker/components/productDashboardStats';
import CountryDonut from '../CountryDonut';
import CampaignStatistics from '../campaignStatistics';

import { ROW_GUTTER } from '../../../constants';
import CostsByCategoryTable from '../CostsByCategoryTable/index.';
import LvbBoxSizeChecker from '../LvbBoxSizeChecker';

function Dashboard() {
  const [selection, changeSelection] = useState<Selection | null>(null);
  return (
    <div className="dashboard-container">
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        {/* Third Row */}
        <Row gutter={ROW_GUTTER}>
          {/* First Column (Full) - Selection Bar */}
          <Col span={24}>
            <SelectionBar selectionChanged={changeSelection} />
          </Col>
          {/* Second Column (Full): KPI Bar */}
          <Col span={24}>
            <KpiBar selection={selection} />
          </Col>
          {/* Third Column (1/2 - Full): Store Performance Graph */}
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <StorePerformanceGraph selection={selection} />
          </Col>
          {/* Fourth Column (1/2 - Full): Products Sold Graph */}
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <ProductsSoldGraph selection={selection} />
          </Col>
          {/* Fifth Column (Full): Performance Product List */}
          <Col span={24}>
            <PerformanceProductList selection={selection} />
          </Col>
          {/* Sixth Column (1/2 - Full): Country Donut, Campaign Statistics, LVB Box Size Checker */}
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Space direction="vertical" size="small" style={{ width: '100%' }}>
              <CountryDonut selection={selection} />
              <CampaignStatistics selection={selection} />
              {selection && (
                <LvbBoxSizeChecker
                  dateRange={[selection.start_date, selection.end_date]}
                />
              )}
            </Space>
          </Col>
          {/* Seventh Column (1/2 - Full): Costs By Category Table, Product Dashboard Stats */}
          {selection && (
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: '100%' }}
              >
                <CostsByCategoryTable
                  dateRange={[selection.start_date, selection.end_date]}
                />

                <ProductDashboardStats
                  dateRange={[selection.start_date, selection.end_date]}
                />
              </Space>
            </Col>
          )}
        </Row>
      </Space>
    </div>
  );
}

export default Dashboard;
