import React from 'react';

// eslint-disable-next-line import/no-cycle
import { MenuProps, message } from 'antd';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';
// eslint-disable-next-line import/no-cycle
import { setActiveToken } from '../reducers/tokensReducer';
import { UserProfileInterface } from '../../reducers/profileReducer';
import {
  SubscriptionInterface,
  isSubscriptionStarterPlan,
} from '../services/subscriptionService/api';

export const getProductIdFromUrl = (productUrl: string) => {
  let items = productUrl.split('?');
  if (items.length) {
    items = items[0].split('/');
    const count = items.length;
    if (count > 4) {
      if (items[count - 1]) {
        return items[count - 1];
      }
      return items[count - 2];
    }
  }
  return null;
};

export const getSubscriptionProduct = (profile) => {
  if (profile.customer && profile.customer.subscription) {
    return profile.customer.subscription.plan.product;
  }
  return {};
};

export const getAllProducts = (categories) => {
  const products = [];
  categories.map((category) => {
    category.products.map((product) => {
      products.push(product.ean);
    });
  });
  return products;
};
export const switchAccount = (account, accountTokens, redirect?) => {
  const activeToken = accountTokens.find(
    (entry) => entry.id === account.id,
  ).token;

  // Escape if no token found
  if (!activeToken) {
    console.error('No active token found for switching to account');
    return;
  }

  store.dispatch(setActiveToken(activeToken));

  message.info(
    `Een moment geduld asjeblieft, we switchen naar je andere store`,
  );

  setTimeout(() => {
    if (redirect) {
      document.location = redirect;
    } else {
      window.location.reload();
    }
  }, 1500);
};

// TODO Check it this should be used / called. It looks fishy and contains data that should have concent for GA4
export const addCustomerDetailsToGTM = (values) => {
  // Push the email, first name, and last name if they exist to GTM
  const attributes = {};

  if (values.email) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    attributes.email = values.email;
  }
  if (values.first_name) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    attributes.first_name = values.first_name;
  }
  if (values.last_name) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    attributes.last_name = values.last_name;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push({
    event: 'user_data',
    attributes,
  });
};

export const hasBolStoreWarning = (
  profileData: UserProfileInterface,
  subscription?: SubscriptionInterface,
) => {
  if (subscription && isSubscriptionStarterPlan(subscription)) {
    return false;
  }
  return !profileData.retailer?.is_valid_credentials;
};

export function hasBolStoreDataMissingWarning(
  profileData: UserProfileInterface,
) {
  return !profileData.is_store_data_complete;
}

export const BOL_STORE_WARNING = (
  <i
    className="icon-warning"
    data-testid="icon-warning"
    style={{ color: 'red', margin: '0 5 0 5' }}
  />
);

type MenuItem = Required<MenuProps>['items'][number];
export function getMenuItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
