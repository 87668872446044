import React from 'react';
import { Space } from 'antd';

import { OfferProductInterface } from '../../../services/productsService/api';

import './style.scss';
import ProductStockNotificationSettings from './productStockNotificationSettings';
import ProductDynamicPriceSettings from './productDynamicPriceSettings';
import ProductStockSettings from './productStockSettings';
import ProductCostPricesForm from './productCostPricesForm';

function StoreProductEdit({
  offerProduct,
}: {
  offerProduct: OfferProductInterface | null;
}) {
  return (
    <div className="store-product-edit">
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <ProductCostPricesForm offerProduct={offerProduct} />

        <ProductDynamicPriceSettings offerProduct={offerProduct} />

        <ProductStockSettings offerProduct={offerProduct} />

        <ProductStockNotificationSettings offerProduct={offerProduct} />
      </Space>
    </div>
  );
}

export default StoreProductEdit;
