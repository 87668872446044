import React from 'react';
import { Alert } from 'antd';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Choose, If, Otherwise, When } from 'tsx-control-statements/components';
import ErrorFallback from '../components/ErrorBoundary/ErrorFallback';

import * as routes from '../routes';
import { LoggedInRoute, NonLoggedInRoute } from './utilities';
import { AppHeaderTitleProvider } from '../../context/appHeaderTitle';
import {
  appHeaderTitleReducer,
  initialState as appHeaderTitleInitialState,
} from '../../reducers/appHeaderTitleReducer';

import ProfileHook from '../../hooks/profile';
import ResetPassword from '../accountManagement/resetPassword';
import PaymentMethodSettings from '../userSettingsManagement/paymentMethodSettings';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AppContainer from '../components/appContainer';
import Login from '../accountManagement/login';
import StoreDashboard from '../storeManagement/dashboard';

import AcademyRoute from './academyRoute';
import AccountingRoute from './accountingRoute';
import BolooAiRoute from './bolooAiRoute';
import CampaignRoute from './campaignRoute';
import FeedbackRoute from './feedbackRoute';
import ForgotPasswordRoute from './forgotPasswordRoute';
import ImpersonationRoute from './impersonationRoute';
import PlansRoute from './plansRoute';
import RegisterRoute from './registerRoute';
import ResearchRoute from './researchRoute';
import SettingsRoute from './settingsRoute';
import StoreRoute from './storeRoute';

import CommandBar from '../components/bearCommandBar';
import UpgradeAccount from '../components/upgradeAccountEmptystate';
import { PlanFeatures } from '../services/subscriptionService/api';
import { UserProfileInterface } from '../../reducers/profileReducer';
import Dashboard from '../financialReportingService/components/Dashboard';

function RouteService({
  profileData,
  isActiveSubscription,
  subscriptionPlanFeatures,
}: {
  profileData: UserProfileInterface;
  isActiveSubscription: boolean;
  subscriptionPlanFeatures: PlanFeatures[];
}) {
  const isAuthenticated = !!Object.keys(profileData).length;
  const isRegistrationComplete = isAuthenticated
    ? profileData.is_registration_complete
    : false;
  const isAccountingEnabled = isAuthenticated
    ? profileData.is_accounting_enabled
    : false;

  return (
    <>
      <If
        condition={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.globalMessage
        }
      >
        <Alert
          message={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.globalMessage
          }
          type="success"
          banner
          closable
          className="email-verified-alert"
        />
      </If>
      <Router>
        <ErrorBoundary
          fallback={ErrorFallback}
          beforeCapture={(scope) =>
            scope
              .setLevel('error')
              .setTag('boloo-UserId', profileData.id || '')
              .setTag('isAuthenticated', !!profileData.id)
          }
        >
          <Switch>
            <Redirect
              from={routes.ACCOUNT_SETTING_STORE_ROUTE}
              to={routes.SETTING_STORE_ROUTE}
            />
            <Redirect
              from={routes.ACCOUNT_SETTING_MEMBERSHIP_DEACTIVATE_ROUTE}
              to={routes.SETTING_MEMBERSHIP_DEACTIVATE_ROUTE}
            />
            <Redirect
              from={routes.ACCOUNT_SETTING_MEMBERSHIP_ROUTE}
              to={routes.SETTING_MEMBERSHIP_ROUTE}
            />
            <Redirect
              from={routes.ACCOUNT_SETTING_PAYMENT_METHOD_ROUTE}
              to={routes.SETTING_PAYMENT_METHOD_ROUTE}
            />
            <Redirect
              from={routes.ACCOUNT_SETTINGS_ROUTE}
              to={routes.SETTINGS_ROUTE}
            />
            <Route path={routes.REGISTER_ROUTE} component={RegisterRoute} />
            <Route
              path={routes.FORGOT_PASSWORD_ROUTE}
              component={ForgotPasswordRoute}
            />
            <NonLoggedInRoute
              exact
              path={routes.RESET_PASSWORD_ROUTE}
              component={ResetPassword}
              isAuthenticated={isAuthenticated}
            />
            <Route path={routes.PLANS_ROUTE} component={PlansRoute} />
            <LoggedInRoute
              exact
              path={routes.SETTING_PAYMENT_METHOD_ROUTE}
              component={PaymentMethodSettings}
              isAuthenticated={isAuthenticated}
              isActiveSubscription={isActiveSubscription}
            />
            <LoggedInRoute
              exact
              path={routes.IMPERSONATION_ROUTE}
              component={ImpersonationRoute}
              isAuthenticated={isAuthenticated}
              isActiveSubscription={isActiveSubscription}
            />
            <Choose>
              <When
                condition={
                  isAuthenticated &&
                  isActiveSubscription &&
                  isRegistrationComplete
                }
              >
                <Route>
                  <CommandBar>
                    <AppHeaderTitleProvider
                      initialState={appHeaderTitleInitialState}
                      reducer={appHeaderTitleReducer}
                    >
                      <AppContainer
                        isAuthenticated={isAuthenticated}
                        isActiveSubscription={isActiveSubscription}
                      >
                        <LoggedInRoute
                          exact
                          index
                          path={routes.INDEX_ROUTE}
                          component={StoreDashboard}
                          isAuthenticated={isAuthenticated}
                          isActiveSubscription={isActiveSubscription}
                          isStoreEnabled={subscriptionPlanFeatures?.includes(
                            PlanFeatures.IsSellerStoreEnabled,
                          )}
                        />

                        <LoggedInRoute
                          exact
                          index
                          path={routes.FRS_DASHBOARD}
                          component={Dashboard}
                          isAuthenticated={isAuthenticated}
                          isActiveSubscription={isActiveSubscription}
                          isStoreEnabled={subscriptionPlanFeatures?.includes(
                            PlanFeatures.IsSellerStoreEnabled,
                          )}
                        />
                        <Route
                          path={routes.STORE_BASE_ROUTE}
                          component={
                            subscriptionPlanFeatures?.includes(
                              PlanFeatures.IsSellerStoreEnabled,
                            )
                              ? StoreRoute
                              : UpgradeAccount
                          }
                        />
                        <Route
                          path={routes.CAMPAIGN_BASE_ROUTE}
                          component={
                            subscriptionPlanFeatures?.includes(
                              PlanFeatures.IsSellerStoreEnabled,
                            )
                              ? CampaignRoute
                              : UpgradeAccount
                          }
                        />
                        <Route
                          path={routes.BOLOO_AI_BASE_ROUTE}
                          component={BolooAiRoute}
                        />
                        <If condition={isAccountingEnabled}>
                          <Route
                            path={routes.ACCOUNTING}
                            component={AccountingRoute}
                          />
                        </If>
                        <Route
                          path={routes.RESEARCH_BASE_ROUTE}
                          render={(props) => (
                            <ResearchRoute
                              {...props}
                              profileData={profileData}
                              subscriptionPlanFeatures={
                                subscriptionPlanFeatures
                              }
                            />
                          )}
                        />
                        <Route
                          path={routes.SETTINGS_ROUTE}
                          component={SettingsRoute}
                        />
                        <Route
                          path={routes.FEEDBACK_ROUTE}
                          component={FeedbackRoute}
                        />
                        <Route
                          path={routes.ACADEMY_ROUTE}
                          component={AcademyRoute}
                        />
                      </AppContainer>
                    </AppHeaderTitleProvider>
                  </CommandBar>
                </Route>
              </When>
              <When condition={isAuthenticated && !isRegistrationComplete}>
                <Redirect to={routes.REGISTER_BILLING_ROUTE} />
              </When>
              <When
                condition={
                  isAuthenticated &&
                  isRegistrationComplete &&
                  !isActiveSubscription
                }
              >
                <Redirect to={routes.PLANS_ROUTE} />
              </When>
              <Otherwise>
                <NonLoggedInRoute
                  exact
                  path={routes.INDEX_ROUTE}
                  component={Login}
                  isAuthenticated={isAuthenticated}
                />
              </Otherwise>
            </Choose>
            <Route render={() => <Redirect to={routes.INDEX_ROUTE} />} />
          </Switch>
        </ErrorBoundary>
      </Router>
    </>
  );
}

export default ProfileHook(RouteService);
