import dayjs from 'dayjs';
import { Selection } from './types';

export function mapToGraphData(
  selection: Selection,
  data,
  invertNegatives = false,
) {
  data = data.map((item) => {
    return {
      x: item.period,
      y: item.value,
    };
  });
  // Fill in all the missing days
  const dateStart = dayjs.unix(selection.start_date);
  const dateEnd = dayjs.unix(selection.end_date);
  const unit = selection.group_by === 'day' ? 'd' : 'M';
  const format = selection.group_by === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM';

  // Prepare empty array to hold all the dates
  const filledData = [];
  for (let dt = dateStart; dt <= dateEnd; dt = dt.add(1, unit)) {
    // Format the date as "yyyy-mm-dd"
    const dateFormatted: string = dt.format(format);

    // Check if this date exists in 'data', and if so, find that object
    const existingObj = data.find((d) => d.x === dateFormatted);

    // If the date already exists in 'data', add that object to 'dates';
    // otherwise, add a new object with y = 0
    filledData.push(existingObj || { x: dateFormatted, y: 0 });
  }
  // lastly, convert the dates to unix timestamps so apex knows what to do with it.
  return filledData.map((item) => {
    return {
      x: new Date(item.x).getTime(),
      y: invertNegatives && item.y < 0 ? 0 - item.y : item.y,
    };
  });
}

export function getTooltipFormat(selection: Selection) {
  return selection && selection.group_by === 'day' ? 'dd MMM yy' : 'MMM yy';
}
