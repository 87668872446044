import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  KBarAnimator,
  KBarProvider,
  KBarPortal,
  useMatches,
  KBarPositioner,
  KBarSearch,
  KBarResults,
} from 'kbar';
import {
  ACCOUNTING_USER_COST_INVOICE_ADD,
  ACCOUNTING_USER_REVENUE_INVOICE_ADD,
  CAMPAIGN_CREATE_ROUTE,
  RESEARCH_CALCULATION_CREATE_ROUTE,
  SETTING_STORE_ROUTE,
  SETTING_MEMBERSHIP_ROUTE,
  SETTING_INVOICES_ROUTE,
  SETTING_NOTIFICATIONS_ROUTE,
  PLANS_ROUTE,
} from '../../routes.ts';
import { BOLOO_FORWARDING_PAGE } from '../../constants.tsx';

import './style.scss';

export default function CommandBar(props) {
  const history = useHistory();

  const actions = [
    {
      id: 'forwarding',
      name: 'Cargomate',
      shortcut: ['f'],
      keywords: 'forwarding',
      section: 'Algemeen',
      perform: () => window.open(BOLOO_FORWARDING_PAGE, '_blank'),
    },
    {
      id: 'beamer-updates',
      name: 'Updates',
      shortcut: ['u'],
      keywords: 'updates,beamer,latest,releases',
      section: 'Algemeen',
      perform: () =>
        window.open('https://app.getbeamer.com/boloo/nl/', '_blank'),
    },
    {
      id: 'faq',
      name: 'FAQ',
      shortcut: ['g', 'f'],
      keywords: 'FAQ, help',
      section: 'Algemeen',
      perform: () => window.open('https://help.boloo.co/nl/', '_blank'),
    },
    {
      id: 'facebook',
      name: 'Facebook Community',
      shortcut: ['g', 'c'],
      keywords: 'facebook,community',
      section: 'Algemeen',
      perform: () =>
        window.open(
          'https://www.facebook.com/groups/BolooCommunity/',
          '_blank',
        ),
    },
    {
      id: 'create-campaign',
      name: 'Nieuwe emailcampagne',
      shortcut: ['n', 'c'],
      keywords: 'create-campaign,new campaign',
      section: 'Nieuw',
      perform: () => history.push(CAMPAIGN_CREATE_ROUTE),
    },
    {
      id: 'create-calculation',
      name: 'Nieuwe Winstcalculator',
      shortcut: ['n', 'p'],
      keywords: 'create-calculation,profit-calculation,calculator',
      section: 'Nieuw',
      perform: () => history.push(RESEARCH_CALCULATION_CREATE_ROUTE),
    },
    {
      id: 'create-cost-invoice',
      name: 'Nieuwe inkoopfactuur',
      shortcut: ['n', 'a'],
      keywords: 'cost-invoice',
      section: 'Nieuw',
      perform: () => history.push(ACCOUNTING_USER_COST_INVOICE_ADD),
    },
    {
      id: 'create-revenue-invoice',
      name: 'Nieuwe verkoopfactuur',
      shortcut: ['n', 'i'],
      keywords: 'revenue-invoice',
      section: 'Nieuw',
      perform: () => history.push(ACCOUNTING_USER_REVENUE_INVOICE_ADD),
    },
    {
      id: 'home',
      name: 'Home',
      shortcut: ['g', 'h'],
      keywords: 'go-home',
      section: 'Ga naar',
      perform: () => history.push('/'),
    },
    {
      id: 'goto-store-settings',
      name: 'Winkel instellingen',
      shortcut: ['g', 's'],
      keywords: 'store-settings',
      section: 'Ga naar',
      perform: () => history.push(SETTING_STORE_ROUTE),
    },
    {
      id: 'goto-membership-settings',
      name: 'Mijn abonnement',
      shortcut: ['g', 'm'],
      keywords: 'membership-settings',
      section: 'Ga naar',
      perform: () => history.push(SETTING_MEMBERSHIP_ROUTE),
    },
    {
      id: 'goto-invoices',
      name: 'Mijn facturen',
      shortcut: ['g', 'i'],
      keywords: 'invoices-list, download invoice',
      section: 'Ga naar',
      perform: () => history.push(SETTING_INVOICES_ROUTE),
    },
    {
      id: 'goto-notifications',
      name: 'Notificatie instellingen',
      shortcut: ['g', 'n'],
      keywords: 'notification-settings',
      section: 'Ga naar',
      perform: () => history.push(SETTING_NOTIFICATIONS_ROUTE),
    },
    {
      id: 'goto-plans',
      name: 'Abonnement wijzigen',
      shortcut: ['c', 'p'],
      keywords: 'plans-settings',
      section: 'Ga naar',
      perform: () => history.push(PLANS_ROUTE),
    },
    {
      id: 'theme',
      name: 'Verander Thema',
      keywords: 'interface color dark light',
      section: 'Instellingen',
    },
    {
      id: 'darkTheme',
      name: 'Donker thema inschakelen',
      keywords: 'dark theme',
      shortcut: ['d'],
      perform: () => document.documentElement.classList.add('dark-mode'),
      parent: 'theme',
    },
    {
      id: 'lightTheme',
      name: 'Licht thema inschakelen',
      shortcut: ['l'],
      keywords: 'light theme',
      perform: () => document.documentElement.classList.remove('dark-mode'),
      parent: 'theme',
    },
  ];

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner className="kbar-positioner">
          <KBarAnimator className="kbar-animator">
            <KBarSearch
              className="kbar-search"
              placeholder="Type om te zoeken…"
            />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {props.children}
    </KBarProvider>
  );
}

function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={
        // eslint-disable-next-line react/no-unstable-nested-components
        ({ item, active }) =>
          typeof item === 'string' ? (
            <div className="group-name">{item}</div>
          ) : (
            <ResultItem action={item} active={active} />
          )
      }
    />
  );
}

const ResultItem = React.forwardRef(({ action, active }, ref) => {
  return (
    <div ref={ref} className="search-result-item">
      <div className="action">
        {action.icon && action.icon}
        <div className="action-row">
          <span>{action.name}</span>
        </div>
      </div>
      {action.shortcut?.length ? (
        <div aria-hidden className="shortcut">
          {action.shortcut.map((shortcut, i) => (
            <>
              <kbd key={shortcut} className="kbd">
                {shortcut}
              </kbd>
              <span className="bear-pad-t-4">
                {action.shortcut.length - 1 === i ? `` : `+`}
              </span>
            </>
          ))}
        </div>
      ) : null}
    </div>
  );
});
