import { useEffect } from 'react';

const useScript = (url, id) => {
  useEffect(() => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.id = id;
      script.src = url;
      script.async = true;
      script.type = 'text/javascript';
      document.body.appendChild(script);
    }
  }, [url]);
};

export default useScript;
