import React from 'react';
import { Row, Col } from 'antd';

import './style.scss';
import { If } from 'tsx-control-statements/components';
import { IMAGE_STATIC_PATH } from '../../../constants';

function AccountManagementLayout({
  title,
  description,
  backgroundImage,
  hideHeader = false,
  offset = 0,
  className,
  headerExtra,
  children,
}: {
  title: string;
  description: string;
  backgroundImage: string;
  hideHeader?: boolean;
  offset?: number;
  className?: string;
  headerExtra?: React.JSX.Element;
  children?: React.ReactNode;
}) {
  return (
    <div className={`account-management-container ${className}`}>
      <Row
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
        className="bear-height"
      >
        <Col
          xxl={12}
          xl={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          className="account-management-column"
          offset={offset}
        >
          <If condition={!hideHeader}>
            <div className="bear-flex-sb bear-flex-ac">
              <img
                src={`${IMAGE_STATIC_PATH}/boloo_color_logo.png`}
                className="company-logo"
              />
              <If condition={headerExtra}>{headerExtra}</If>
            </div>
          </If>
          <div className="account-management-form-layout">
            <div>
              <p className="bear-bold-title">{title}</p>
              <If condition={description}>
                <p className="bear-light-regular-text">{description}</p>
              </If>
              {children}
            </div>
          </div>
        </Col>
      </Row>
      <If condition={backgroundImage}>
        <img
          src={`${IMAGE_STATIC_PATH}/${backgroundImage}`}
          className="account-management-background-img"
        />
      </If>
    </div>
  );
}

export default AccountManagementLayout;
