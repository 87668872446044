export const initialState = {
  accounts: [],
};

const SET_ACCOUNTS = 'EVENT/SET_ACCOUNTS';
const RESET_ACCOUNTS = 'EVENT/RESET_ACCOUNTS';

export const setAccounts = (data) => {
  return {
    type: SET_ACCOUNTS,
    payload: data,
  };
};

export const resetAllAccounts = () => {
  return {
    type: RESET_ACCOUNTS,
  };
};

export const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return { accounts: action.payload };
    case RESET_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
};

export default accountsReducer;
