import dayjs from 'dayjs';
import { formatAmount } from '../utilities';
import {
  RETAILER_ORDER_INVOICE_NOT_SENT,
  RETAILER_ORDER_INVOICE_SENT,
  RETAILER_ORDER_INVOICE_UPLOADED,
  STORE_COUNTRY_LABELS,
} from './constants';
import { DEFAULT_STORE_OPTION } from './dashboard/constants';
import { Retailer } from '../../reducers/profileReducer';

export { formatAmount };

export const getCountryValue = (item, fieldKey, total, country) => {
  if (country) {
    return item[country] ? item[country][fieldKey] : 0;
  }
  return total;
};

export const getProductImages = (items = []) => {
  return items.map((item) => (item.product ? item.product.image : null));
};

export const getOrderListQuery = (
  status,
  searchTerm,
  sorter = '',
  fulfilmentMethod = '',
  statusFilter = '',
  dateRange = [],
) => {
  let pathQuery = `&order_items__status__in=${status}&search=${searchTerm}`;

  if (fulfilmentMethod) {
    pathQuery = pathQuery.concat(
      '&order_items__fulfilment_method=',
      fulfilmentMethod,
    );
  }

  if (statusFilter !== '') {
    if (statusFilter === RETAILER_ORDER_INVOICE_NOT_SENT) {
      pathQuery = pathQuery.concat(
        '&is_invoice_sent=false&is_invoice_uploaded=false',
      );
    }

    if (statusFilter === RETAILER_ORDER_INVOICE_SENT) {
      pathQuery = pathQuery.concat(
        '&is_invoice_sent=true&is_invoice_uploaded=false',
      );
    }

    if (statusFilter === RETAILER_ORDER_INVOICE_UPLOADED) {
      pathQuery = pathQuery.concat('&is_invoice_uploaded=true');
    }
  }

  if (sorter) {
    pathQuery = pathQuery.concat('&ordering=', sorter);
  }

  if (dateRange.length) {
    pathQuery = pathQuery.concat('&start_date=', dateRange[0]);
    pathQuery = pathQuery.concat('&end_date=', dateRange[1]);
  }

  return pathQuery;
};

export const getTopProductListQuery = (
  status,
  dateRange,
  selectedCountry,
  sort,
) => {
  let pathQuery = '';
  pathQuery = pathQuery.concat('&start_time=', dateRange[0]);
  pathQuery = pathQuery.concat('&end_time=', dateRange[1]);
  pathQuery = pathQuery.concat('&status=', status);
  if (selectedCountry !== 'all') {
    pathQuery = pathQuery.concat(
      '&order__customer__country_code=',
      selectedCountry,
    );
  }
  pathQuery = pathQuery.concat('&sort=', sort);
  return pathQuery;
};

export const getReturnListQuery = (
  isHandled,
  searchTerm,
  sorter = '',
  fulfilmentMethod = '',
  statusFilter = '',
  dateRange = [],
) => {
  let pathQuery = `&items__is_handled=${isHandled}&search=${searchTerm}`;

  if (fulfilmentMethod) {
    pathQuery = pathQuery.concat('&fulfilment_method=', fulfilmentMethod);
  }

  if (statusFilter !== '') {
    if (statusFilter === RETAILER_ORDER_INVOICE_NOT_SENT) {
      pathQuery = pathQuery.concat('&is_credit_invoice_sent=false');
    }

    if (statusFilter === RETAILER_ORDER_INVOICE_SENT) {
      pathQuery = pathQuery.concat('&is_credit_invoice_sent=true');
    }
  }

  if (sorter) {
    pathQuery = pathQuery.concat('&ordering=', sorter);
  }

  if (dateRange.length) {
    const rangeValue = dateRange
      .map((item) => dayjs.unix(item).format('YYYY-MM-DD'))
      .join(',');
    pathQuery = pathQuery.concat('&return_date__range=', rangeValue);
  }

  return pathQuery;
};

export const getInventoryListQuery = (
  searchTerm,
  sorter,
  stockStatus = '',
  fulfilmentMethod = '',
  removeHiddenProducts = false,
) => {
  let pathQuery = `&search=${searchTerm}&ordering=${sorter}`;
  if (fulfilmentMethod) {
    pathQuery = pathQuery.concat('&fulfilment_method=', fulfilmentMethod);
  }
  if (stockStatus) {
    pathQuery = pathQuery.concat('&stock=', stockStatus);
  }
  pathQuery = pathQuery.concat(
    '&remove_hidden_products=',
    String(removeHiddenProducts),
  );
  return pathQuery;
};

export const isEmpty = (object) => {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unreachable-loop
  for (const property in object) {
    return false;
  }

  return true;
};

export const getParsedStatisticValue = ({
  statistics,
  selectedCountry,
  valueKey,
  isAmount = true,
  defaultValue = '0',
  selectedStore,
}) => {
  let statsValue = 0;
  const item = statistics[valueKey];
  if (item) {
    const { geographical } = item;
    let value = item.total;
    if (selectedCountry) {
      const geoItem = geographical[selectedCountry];
      value = geoItem ? geoItem.value : 0;
    }
    if (
      !isEmpty(selectedStore) &&
      selectedStore.id === DEFAULT_STORE_OPTION.id
    ) {
      return value || defaultValue;
    }
    statsValue = isAmount ? formatAmount(value, item.currency || '') : value;
  }
  return statsValue || defaultValue;
};

export const getStatisticValue = ({
  statistics,
  selectedCountry,
  valueKey,
  isLoading,
  isAmount = true,
  defaultValue = '0',
  selectedStore = {} as Partial<Retailer>,
}) => {
  if (isLoading || isEmpty(statistics)) {
    return '';
  }

  if (!isEmpty(selectedStore) && selectedStore.id === DEFAULT_STORE_OPTION.id) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let combinedStatisticsValue: number = 0;

    statistics.forEach((accountStatistics) => {
      const statisticsValue = getParsedStatisticValue({
        statistics: accountStatistics.data,
        selectedCountry,
        valueKey,
        isAmount,
        defaultValue,
        selectedStore,
      });

      combinedStatisticsValue += parseFloat(statisticsValue);
    });

    let finalValue: number | string;
    if (isAmount) {
      finalValue = formatAmount(combinedStatisticsValue);
    } else {
      finalValue = combinedStatisticsValue.toString();
    }

    return finalValue;
  }

  if (!isEmpty(selectedStore)) {
    const oneAccountStatistics = statistics.find(
      (accountStatistics) => accountStatistics.id === selectedStore.id,
    );
    statistics = oneAccountStatistics.data;
  }

  return getParsedStatisticValue({
    statistics,
    selectedCountry,
    valueKey,
    isAmount,
    defaultValue,
    selectedStore,
  });
};

export const getParsedStatisticSubValue = (
  statistics,
  selectedCountry,
  valueKey,
) => {
  const item = statistics[valueKey];
  if (item) {
    const { geographical } = item;
    let value = item.change;
    if (selectedCountry) {
      const geoItem = geographical[selectedCountry];
      value = geoItem ? geoItem.change : 0;
    }
    return { value, currency: item.currency };
  }
  return { value: 0 };
};

export const getStatisticSubValue = (
  statistics,
  selectedCountry,
  valueKey,
  isLoading,
  selectedStore: Retailer,
) => {
  if (isLoading || isEmpty(statistics)) {
    return '';
  }

  if (!isEmpty(selectedStore) && selectedStore.id === DEFAULT_STORE_OPTION.id) {
    const combinedStatisticsSubValue: { value: number; currency: string } = {
      value: 0,
      currency: null,
    };

    statistics.forEach((accountStatistics) => {
      const statisticsSubValue = getParsedStatisticSubValue(
        accountStatistics.data,
        selectedCountry,
        valueKey,
      );

      combinedStatisticsSubValue.value += statisticsSubValue.value;

      if ('currency' in statisticsSubValue) {
        combinedStatisticsSubValue.currency = statisticsSubValue.currency;
      }
    });

    return combinedStatisticsSubValue;
  }

  if (!isEmpty(selectedStore)) {
    const oneAccountStatistics = statistics.find(
      (accountStatistics) => accountStatistics.id === selectedStore.id,
    );
    statistics = oneAccountStatistics.data;
  }

  return getParsedStatisticSubValue(statistics, selectedCountry, valueKey);
};

export const getStockStatusClassName = (stockDays) => {
  let className = '';
  if (stockDays < 30 && stockDays > 10) {
    className = 'enough-stock';
  } else if (stockDays <= 10 && stockDays > 0) {
    className = 'medium-stock';
  } else if (stockDays === 0) {
    className = 'low-stock';
  } else if (stockDays === null) {
    className = 'unknown-stock';
  }
  return className;
};

export const getProductVAT = (price, vat) => {
  return price * (vat / (100 + vat));
};

export const getGeographicalDataByStore = (
  combinedCurrentStatistics,
  combinedGeographical,
  tabOption,
  accountStatistics,
) => {
  const tabObject = accountStatistics.data[tabOption];

  if ('total' in combinedCurrentStatistics) {
    combinedCurrentStatistics.total += tabObject.total;
  } else {
    combinedCurrentStatistics.total = tabObject.total;
  }

  STORE_COUNTRY_LABELS.forEach((label) => {
    if (label in combinedGeographical) {
      if (
        label in tabObject.geographical &&
        'change' in tabObject.geographical[label]
      ) {
        combinedGeographical[label].change +=
          tabObject.geographical[label].change;
      }

      if (
        label in tabObject.geographical &&
        'value' in tabObject.geographical[label]
      ) {
        combinedGeographical[label].value +=
          tabObject.geographical[label].value;
      }
    } else {
      combinedGeographical[label] = { change: 0.0, value: 0.0 };

      if (
        label in tabObject.geographical &&
        'change' in tabObject.geographical[label]
      ) {
        combinedGeographical[label].change =
          tabObject.geographical[label].change;
      }

      if (
        label in tabObject.geographical &&
        'value' in tabObject.geographical[label]
      ) {
        combinedGeographical[label].value = tabObject.geographical[label].value;
      }
    }
  });

  return [combinedCurrentStatistics, combinedGeographical];
};

export const getGeographicalData = (
  statistics,
  tabOption,
  selectedStore: Retailer,
) => {
  let combinedCurrentStatistics = {};
  let combinedGeographical = {};

  if (selectedStore.id === DEFAULT_STORE_OPTION.id) {
    statistics.forEach((accountStatistics) => {
      [combinedCurrentStatistics, combinedGeographical] =
        getGeographicalDataByStore(
          combinedCurrentStatistics,
          combinedGeographical,
          tabOption,
          accountStatistics,
        );
    });
  } else {
    const accountStatistics = statistics.find(
      (item) => item.id === selectedStore.id,
    );
    [combinedCurrentStatistics, combinedGeographical] =
      getGeographicalDataByStore(
        combinedCurrentStatistics,
        combinedGeographical,
        tabOption,
        accountStatistics,
      );
  }

  return [combinedCurrentStatistics, combinedGeographical];
};

export const getDashboardCampaignStatistics = (
  campaignStatistics,
  selectedStore: Partial<Retailer>,
) => {
  let delivered: any = 0; // eslint-disable-line
  let opens: any = 0; // eslint-disable-line
  let clicks: any = 0; // eslint-disable-line

  if (!isEmpty(selectedStore)) {
    if (selectedStore.id === DEFAULT_STORE_OPTION.id) {
      campaignStatistics.forEach((accountStatistics) => {
        const accountData = accountStatistics.data;

        accountData.forEach((row) => {
          if (row.event_type === 'delivered' && row.total_count) {
            delivered += row.total_count;
          }

          if (row.event_type === 'open' && row.total_count) {
            opens += row.total_unique_count;
          }

          if (row.event_type === 'click' && row.total_count) {
            clicks += row.total_count;
          }
        });
      });

      return [delivered, opens, clicks];
    }

    const oneCampaignStatistics = campaignStatistics.find(
      (campaignStats) => campaignStats.id === selectedStore.id,
    );
    campaignStatistics = oneCampaignStatistics.data;
  }

  delivered = campaignStatistics.find((row) => row.event_type === 'delivered');
  opens = campaignStatistics.find((row) => row.event_type === 'open');
  clicks = campaignStatistics.find((row) => row.event_type === 'click');

  delivered = delivered ? delivered.total_count : 0;
  opens = opens ? opens.total_count : 0;
  clicks = clicks ? clicks.total_count : 0;

  return [delivered, opens, clicks];
};

export const getParsedPeriods = (graphData) => {
  const combinedPeriods = {};
  const normalisedPeriods = [];

  graphData.forEach((accountGraph) => {
    accountGraph.data.periods.forEach((periodObject) => {
      if (periodObject.period in combinedPeriods) {
        if (periodObject.profit) {
          combinedPeriods[periodObject.period].profit += periodObject.profit;
        }
        if (periodObject.revenue) {
          combinedPeriods[periodObject.period].revenue += periodObject.revenue;
        }
        if (periodObject.sales) {
          combinedPeriods[periodObject.period].sales += periodObject.sales;
        }
        if (periodObject.cost_price) {
          combinedPeriods[periodObject.period].cost_price +=
            periodObject.cost_price;
        }
      } else {
        combinedPeriods[periodObject.period] = {};
        combinedPeriods[periodObject.period].period = periodObject.period;
        combinedPeriods[periodObject.period].profit = periodObject.profit
          ? periodObject.profit
          : 0.0;
        combinedPeriods[periodObject.period].revenue = periodObject.revenue
          ? periodObject.revenue
          : 0.0;
        combinedPeriods[periodObject.period].sales = periodObject.sales
          ? periodObject.sales
          : 0;
        combinedPeriods[periodObject.period].cost_price =
          periodObject.cost_price ? periodObject.cost_price : 0.0;
      }
    });
  });

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in combinedPeriods) {
    normalisedPeriods.push(combinedPeriods[key]);
  }

  return normalisedPeriods;
};

export const getAreaData = (graphData, selectedStore: Partial<Retailer>) => {
  let areaData = graphData;

  if (!isEmpty(selectedStore)) {
    if (selectedStore.id === DEFAULT_STORE_OPTION.id) {
      areaData = getParsedPeriods(graphData);
    } else {
      const accountGraph = graphData.find(
        (item) => item.id === selectedStore.id,
      );
      areaData = accountGraph.data.periods;
    }
  }

  return areaData;
};

export const getBarData = (
  graphData,
  stores,
  selectedStore: Partial<Retailer>,
) => {
  let barData = graphData;

  if (!isEmpty(selectedStore)) {
    if (selectedStore.id === DEFAULT_STORE_OPTION.id) {
      barData.map((dataEntry) => {
        const store = stores.find((item) => item.id === dataEntry.id);
        dataEntry.nickname =
          store?.nickname === undefined ? '' : store.nickname;
      });
    } else {
      const accountGraph = graphData.find(
        (item) => item.id === selectedStore.id,
      );
      barData = accountGraph.data.periods;
    }
  }

  return barData;
};
