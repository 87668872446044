import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Row,
  Col,
  Tag,
  Select,
  message,
  Modal,
  Button,
  Switch,
  Progress,
  Card,
  Space,
  Typography,
  Spin,
} from 'antd';
import { If } from 'tsx-control-statements/components';
import {
  CloudDownloadOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CloseOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import Dragger from 'antd/es/upload/Dragger';
import { useSelector } from 'react-redux';
import { formatNumberToDutch } from '../../utilities';
import { formatAmount, getStockStatusClassName } from '../utilities';
import {
  DEFAULT_SORT,
  STOCK_STATUS_OPTIONS,
  INVENTORY_SORT_OPTIONS,
} from './constants';

import { getInventoryProductDetailRoute } from '../../routes';

import ProductCard from '../../components/productCard';
import BearInfiniteList from '../../../components/bearList';
import BearStatistics from '../../../components/bearStatistics';
import BearSearchInput from '../../components/bearSearchInput';
import FulfilmentMethodSelector from '../components/fulfilmentMethodSelector';

import './style.scss';
import captureErrorAndShowMessage from '../../../utilities/errors';
import DefaultContent from './uploadCsv/DefaultContent';
import UploadedContent from './uploadCsv/UploadedContent';
import ImportExportMenu from './uploadCsv/ImportExportMenu';
import {
  OfferProductInterface,
  StockType,
  toggleOfferIsHidden,
  uploadCSVFile,
  getProductsCsvStatus,
  useOffersProductListInfinate,
  StockFilter,
  getProductsCsv,
  getProductDownloadCsvStatus,
  downloadCsvFile,
  useOffersStats,
} from '../../services/productsService';
import { ROW_GUTTER } from '../../constants';

const { Option } = Select;
const { Title, Text } = Typography;
function DownloadStatus({
  isDownloadInProgressParam,
  isDownloadCompleteParam,
}) {
  if (isDownloadCompleteParam) {
    return (
      <div className="download-status-complete">
        <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
        <Text strong style={{ marginTop: '8px', color: '#52c41a' }}>
          Download voltooid!
        </Text>
      </div>
    );
  }
  if (isDownloadInProgressParam) {
    return (
      <div className="download-status-progress">
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
        />
        <Text strong style={{ marginTop: '8px' }}>
          Bezig met downloaden...
        </Text>
      </div>
    );
  }
  return null;
}

function StoreInventory() {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const accounts = useSelector((state) => state.accountsReducer.accounts);
  const [sorter, setSorter] = useState(DEFAULT_SORT);
  const [searchTerm, setSearchTerm] = useState(null);
  const [stockFilter, setStockFilter] = useState<StockFilter>('all');
  const [fulfilmentFilter, setFulfilmentFilter] = useState('');

  const [showDragger, setShowDragger] = useState(false);
  const [isDraggerDisabled, setIsDraggerDisabled] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progressData, setProgressData] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const pollingRef = useRef(null);
  const hasShownMessageRef = useRef(false);
  const [isTerminationStateReached, setIsTerminationStateReached] =
    useState(false);
  const [loadingStatesVisibility, setLoadingStatesVisibility] = useState<{
    [key: string]: boolean;
  }>(null); // Tracks loading state for each product
  const [removeHiddenProducts, setRemoveHiddenProducts] = useState(false);

  // export functionality
  const [showExportProgress, setShowExportProgress] = useState(false);
  const [exportDownloadPercentage, setExportDownloadPercentage] = useState(0);
  const pollingRefDownloadStatus = useRef(null);
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);
  const abortControllerRef = useRef(null);

  const [file, setFile] = useState(null);
  const { offersProductList, setSize, isLoading, hasMore, mutate } =
    useOffersProductListInfinate(
      !removeHiddenProducts,
      searchTerm,
      fulfilmentFilter,
      sorter,
      stockFilter,
    );

  const { stats, isLoading: isLoadingStats } = useOffersStats();
  function handleProductItemClick(offerProductId: string) {
    history.push(getInventoryProductDetailRoute(offerProductId));
  }

  function renderIcon(product: OfferProductInterface) {
    return product.is_hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />;
  }

  async function toggleProductVisibility(
    event,
    offerProduct: OfferProductInterface,
  ) {
    event.stopPropagation();
    setLoadingStatesVisibility((prevState) => ({
      ...prevState,
      [offerProduct.id]: true,
    }));

    try {
      await toggleOfferIsHidden(offerProduct.bol_offer_id);
    } catch (error) {
      captureErrorAndShowMessage(error);
    } finally {
      mutate();
      setLoadingStatesVisibility((prevState) => ({
        ...prevState,
        [offerProduct.id]: false,
      }));
    }
  }

  const STOCK_LABEL_MAPPINGS = {
    LVB: 'Bol (FBB)',
    STORAGE: 'Own',
    RETAILER: 'Bol (FBR)',
  };

  const INVENTORY_LIST_ITEMS = [
    {
      title: 'Product',
      key: 'product',
      size: 5,
      mobile: {
        span: 22,
        order: 2,
      },
      render: (offerProduct: OfferProductInterface) => {
        return <ProductCard ean={offerProduct.product.ean} shouldFetch />;
      },
    },
    {
      title: 'Voorraad',
      key: 'stock',
      size: 3,
      mobile: {
        span: 8,
        order: 3,
      },
      render: (offerProduct: OfferProductInterface) => (
        <>
          {offerProduct.stocks.map((stock) => {
            return (
              <p
                key={stock.id}
                className="bear-medium-text bear-mar-b-4 product-stock-cell"
              >
                <span>{stock.stock}</span>{' '}
                <Tag
                  className={`${stock.type === StockType.STORAGE ? '' : 'primary'} responsive-tag`}
                >
                  {STOCK_LABEL_MAPPINGS[stock.type]}
                </Tag>
              </p>
            );
          })}
        </>
      ),
    },
    {
      title: 'Dagen voorraad',
      key: 'stock_days',
      tooltip: `Het geschatte aantal dagen dat je nog voorraad hebt voor het product in dagen. 
      Dit is een berekening gebaseerd op je voorraad en het aantal sales dat je gemiddeld per dag maakt. 
      Voorbeeld: Je voorraad is 10 stuks en je hebt gemiddeld 1 verkoop per dag. Je voorraadstatus is 10 dagen, 
      want je hebt nog voor 10 dagen voorraad en dan ben je uitverkocht. Dit kan alleen worden berekend als er recente sales zijn.`,
      size: 2,
      mobile: {
        span: 8,
        order: 4,
      },
      render: (offerProduct: OfferProductInterface) => (
        <If condition={offerProduct.stock_days !== 0}>
          <p className="bear-medium-text bear-stock-days-cell">
            <span className={getStockStatusClassName(offerProduct.stock_days)}>
              {offerProduct.stock_days != null ? offerProduct.stock_days : '?'}
            </span>
          </p>
        </If>
      ),
    },
    {
      title: 'Uitverkocht',
      key: 'sold_out',
      tooltip: `De geschatte datum wanneer een product uitverkocht zal zijn. 
      Dit is een berekening gebaseerd op je voorraad en het aantal sales dat je gemiddeld per dag maakt.`,
      size: 2,
      mobile: {
        span: 8,
        order: 5,
      },
      render: (offerProduct: OfferProductInterface) =>
        offerProduct.stock_days !== null ? (
          <div>
            <p className="bear-medium-text">
              {dayjs().add(offerProduct.stock_days, 'd').format('D MMMM YYYY')}
            </p>
            <p className="bear-light-help-text bear-pad-t-4">
              {Math.round(offerProduct.avg_daily_sales * 10) / 10} verkopen/dag
            </p>
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'Verkoopprijs',
      key: 'price',
      size: 2,
      mobile: {
        span: 8,
        order: 6,
      },
      render: (offerProduct: OfferProductInterface) => (
        <div className="stock-settings-cell">
          {/* <If condition={offerProduct.dynamic_pricers.length}> */}
          {/*  <i className="fad icon-sort-alt" /> */}
          {/* </If> */}
          <p className="bear-medium-text">
            {formatNumberToDutch(offerProduct.price, '€')}
          </p>
        </div>
      ),
    },
    {
      title: 'Voorraadwaarde',
      key: 'stock_value',
      tooltip: `De totale voorraadwaarde van je voorraad.
       De berekening: De inkoopprijs van een product x de totale voorraad
       van het product voor alle producten die je in je winkel hebt bij elkaar opgeteld.`,
      size: 3,
      mobile: {
        span: 8,
        order: 7,
      },
      render: (offerProduct: OfferProductInterface) => (
        <p className="bear-medium-text">
          {formatNumberToDutch(offerProduct.stock_value, '€')}
        </p>
      ),
    },
    {
      title: 'Verkoopwaarde',
      key: 'sales_value',
      tooltip: `De totale verkoopwaarde van je voorraad. De berekening:
       De verkoopprijs van een product x de totale voorraad van het product 
       voor alle producten die je in je winkel hebt bij elkaar opgeteld.`,
      size: 3,
      mobile: {
        span: 8,
        order: 8,
      },
      render: (offerProduct: OfferProductInterface) => (
        <p className="bear-medium-text">
          {formatNumberToDutch(offerProduct.sales_value, '€')}
        </p>
      ),
    },
    {
      title: 'Fulfilment',
      key: 'fulfilment_method',
      size: 2,
      mobile: {
        span: 8,
        order: 9,
      },
      render: (offerProduct: OfferProductInterface) => (
        <p className="bear-medium-text">{offerProduct.fulfilment_method}</p>
      ),
    },
    {
      title: '',
      key: 'zichtbaarheid',
      tooltip: `Schakel de zichtbaarheid van een product.
       Dit is alleen een visuele verandering.
        Het product wordt dan niet meegenomen
         in de berekening boven aan de huidige pagina.`,
      size: 1,
      mobile: {
        span: 4,
        order: 10,
      },
      render: (offerProduct: OfferProductInterface) => (
        <p className="bear-medium-text" style={{ textAlign: 'center' }}>
          <Button
            loading={loadingStatesVisibility?.[offerProduct.id]} // Set the loading state based on the product ID
            icon={renderIcon(offerProduct)}
            onClick={(e) => toggleProductVisibility(e, offerProduct)}
          />
        </p>
      ),
    },
  ];

  const statisticsItems = [
    {
      key: 'revenue',
      title: {
        text: 'Producten',
      },
      span: {
        md: 4,
        xs: 24,
      },
      value: stats?.offer_count,
    },
    {
      key: 'cost',
      title: {
        text: 'Voorraadwaarde',
        tooltip: `De totale voorraadwaarde van je voorraad. De berekening:
         De inkoopprijs van een product x de totale voorraad van het
          product voor alle producten die je in je winkel hebt bij elkaar opgeteld.`,
      },
      span: {
        md: 4,
        xs: 24,
      },
      value: formatAmount(stats?.stock_value),
    },
    {
      key: 'sale_value',
      title: {
        text: 'Verkoopwaarde',
        tooltip: ` De totale verkoopwaarde van je voorraad. De berekening:
         De verkoopprijs van een product x de totale voorraad van het 
         product voor alle producten die je in je winkel hebt bij elkaar opgeteld.`,
      },
      span: {
        md: 4,
        xs: 24,
      },
      value: formatAmount(stats?.sales_value),
    },

    {
      key: 'profit',
      title: {
        text: 'Voorraadstatus',
      },
      span: {
        md: 8,
        xs: 24,
      },
      value: (
        <Row gutter={ROW_GUTTER}>
          <Col span={12} className="bear-flex bear-flex-ac bear-mar-r-24">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{stats?.no_stock}</span>
              <Tag style={{ margin: '0px 4px' }} className="bear-mar-l-8">
                Geen voorraad
              </Tag>
            </div>
          </Col>
          <Col span={12} className="bear-flex bear-flex-ac">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{stats?.low_stock}</span>
              <Tag style={{ margin: '0px 4px' }} className="red bear-mar-l-8">
                Lage voorraad
              </Tag>
            </div>
          </Col>
        </Row>
      ),
    },
  ];
  const resetDraggerState = (hideDragger = true, forceReset = true) => {
    if (hideDragger) {
      setShowDragger(false);
    }
    // forceReset is used to reset the dragger state when the user has completed a full cycle of uploading
    // to a termination state (finished, errored etc.).
    // in the middle of an upload a user might (visually) close the dragger,
    // in which case we don't want to reset the state
    // incase they open it again, we can continue from where exactly where we left off, without waiting on a poll.
    if (forceReset) {
      setIsDraggerDisabled(false);
      setUploadedFileName(null);
      setFile(null);
      setProgressData(null);
      setPercentage(0);
    }
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
    }
  };
  const startPolling = (jobId: string) => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
    }
    const poll = async () => {
      try {
        const data = await getProductsCsvStatus(jobId);
        setProgressData(data);
        setPercentage(data.progress.percentage);

        // If this is the first successful poll, update UI and inform user.
        if (!uploadedFileName && !hasShownMessageRef.current) {
          message.info(
            'Je hebt nog een taak die wordt verwerkt. We halen nu de status op.',
          );
          setUploadedFileName('Fetching...');
          setShowDragger(true);
          setIsDraggerDisabled(true);
          hasShownMessageRef.current = true;
        }

        if (
          data.status === 'completed' ||
          data.status === 'failed' ||
          data.status === 'completed-partially'
        ) {
          clearInterval(pollingRef.current);
          localStorage.removeItem('csv_processing_job_id');
          setIsTerminationStateReached(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Job ID not found, remove from local storage
          localStorage.removeItem('csv_processing_job_id');
          resetDraggerState();
        } else {
          captureErrorAndShowMessage(error);
        }
      }
    };

    // Call the polling function immediately
    poll();

    // Then set the interval to continue calling it
    pollingRef.current = setInterval(poll, 5000);
  };

  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  const stopPolling = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
    }
  };

  useEffect(() => {
    if (showDragger) {
      const jobId = localStorage.getItem('csv_processing_job_id');
      if (jobId) {
        startPolling(jobId);
      } else {
        stopPolling();
      }
    } else {
      stopPolling();
    }
  }, [showDragger]);

  useEffect(() => {
    const jobId = localStorage.getItem('csv_processing_job_id');
    if (jobId) {
      // Start polling immediately without updating UI or showing messages.
      startPolling(jobId);
    }
  }, []);

  const handleUpload = async () => {
    if (!file) {
      return;
    }
    try {
      const response = await uploadCSVFile(file);
      if (response && response?.status !== 'failed') {
        setProgressData({
          status: 'queued',
          progress: {
            current_step: 0,
            total_steps: 100,
            percentage: 0,
          },
          message: 'Bezig met verwerken...',
        });
        setFile(null);
        if (response?.job_id) {
          localStorage.setItem('csv_processing_job_id', response.job_id);
        }
        startPolling(response?.job_id);
      } else {
        throw new Error();
      }
    } catch (error) {
      if (!error.response || !error.response.data) {
        message.error(
          'File upload failed. Please make sure it is correctly formatted and try again.',
        );
        return;
      }
      // Handle already queued task.
      if (error.response.data.status === 'failed-already-active') {
        if (error.data.job_id) {
          localStorage.setItem('csv_processing_job_id', error.data.job_id);
        }
        message.warning(
          'Je hebt nog steeds een actieve taak die wordt verwerkt.' +
            ' Wacht alsjeblieft tot deze klaar is.' +
            ' De pagina toont nu het voltooiingspercentage van de vorige taak',
          20,
        );
        startPolling(error.response.data.job_id);
      } else {
        setProgressData(error.response.data);
        setPercentage(0);
      }
    }
  };

  const stopPollingDownloadStatus = () => {
    if (pollingRefDownloadStatus.current) {
      clearInterval(pollingRefDownloadStatus.current);
    }
  };
  const downloadCSVFile = async (jobId) => {
    setIsDownloadInProgress(true);

    // Create a new AbortController for this download
    abortControllerRef.current = new AbortController();

    try {
      await downloadCsvFile(
        jobId,
        accounts[0].nickname,
        abortControllerRef.current.signal,
      );
      setIsDownloadComplete(true);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        captureErrorAndShowMessage(error);
      }
    } finally {
      setIsDownloadInProgress(false);
      abortControllerRef.current = null;
    }
  };

  const startPollingDownloadStatus = (jobId: string) => {
    if (pollingRefDownloadStatus.current) {
      clearInterval(pollingRefDownloadStatus.current);
    }
    const poll = async () => {
      const { total_length: totalLength, current_iteration: currentIteration } =
        await getProductDownloadCsvStatus(jobId);

      setExportDownloadPercentage(
        Math.round((currentIteration / totalLength) * 100),
      );

      if (currentIteration === totalLength) {
        clearInterval(pollingRefDownloadStatus.current);
        await downloadCSVFile(jobId);
        stopPollingDownloadStatus();
      }
    };

    // Call the polling function immediately
    poll().then(() => {});

    // Then set the interval to continue calling it
    pollingRefDownloadStatus.current = setInterval(poll, 5000);
  };

  const onExportButtonClick = async (e) => {
    e.stopPropagation();
    try {
      message.info('Je download wordt gestart.');
      setShowExportProgress(true);
      setShowDragger(false);
      const storeName = accounts[0].nickname;
      const { job_id: jobId } = await getProductsCsv(
        storeName || 'Boloo store',
      );

      startPollingDownloadStatus(jobId);
    } catch (error) {
      captureErrorAndShowMessage(error);
    }
  };

  useEffect(() => {
    return () => {
      if (pollingRefDownloadStatus.current) {
        clearInterval(pollingRefDownloadStatus.current);
      }
    };
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleUploadClick = (e) => {
    e.stopPropagation();
    if (file) {
      showModal();
    }
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    await handleUpload(); // This will start the actual upload
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };

  const handleCloseExportProgress = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setShowExportProgress(false);
    setIsDownloadComplete(false);
    setIsDownloadInProgress(false);
    setExportDownloadPercentage(0);
    stopPollingDownloadStatus();
  };

  return (
    <div className="store-inventory-management">
      <BearStatistics
        items={statisticsItems}
        isCompleteLoading={isLoadingStats}
        dropdownMenu={
          <ImportExportMenu
            setShowDragger={setShowDragger}
            exportButtonClick={async (e) => onExportButtonClick(e)}
          />
        }
      />
      {showExportProgress && (
        <Row
          justify="center"
          style={{
            width: '100%',
            padding: '24px 0',
          }}
        >
          <div style={{ width: '100%', position: 'relative' }}>
            <Card
              className="export-progress-card"
              bodyStyle={{ padding: '24px', textAlign: 'center' }}
            >
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCloseExportProgress}
                style={{
                  position: 'absolute',
                  top: '12px',
                  right: '12px',
                  fontSize: '16px',
                }}
              />
              <Space
                direction="vertical"
                size="large"
                style={{ width: '100%' }}
              >
                {!isDownloadInProgress && !isDownloadComplete && (
                  <CloudDownloadOutlined
                    style={{ fontSize: '48px', color: '#1890ff' }}
                  />
                )}
                <Title level={4}>
                  {(() => {
                    if (isDownloadComplete) {
                      return 'Export voltooid';
                    }
                    if (isDownloadInProgress) {
                      return 'Bestand downloaden';
                    }
                    return 'Exporteren van producten';
                  })()}
                </Title>
                {!isDownloadInProgress && !isDownloadComplete && (
                  <Progress
                    percent={exportDownloadPercentage}
                    status="active"
                  />
                )}
                <DownloadStatus
                  isDownloadInProgressParam={isDownloadInProgress}
                  isDownloadCompleteParam={isDownloadComplete}
                />
                <Text type="secondary">
                  {(() => {
                    if (isDownloadComplete) {
                      return 'Je export is succesvol gedownload. Je kunt deze pagina nu sluiten.';
                    }
                    if (isDownloadInProgress) {
                      return 'Je bestand wordt nu gedownload. Dit kan even duren afhankelijk van de bestandsgrootte.';
                    }
                    return 'Even geduld, we zijn bezig met het exporteren van je producten. Sluit deze tab niet. Dit kan enkele minuten duren afhankelijk van de hoeveelheid data.';
                  })()}
                </Text>
              </Space>
            </Card>
          </div>
        </Row>
      )}
      {showDragger && (
        <Row
          style={{
            width: '100%',
            paddingTop: '24px',
            paddingBottom: '24px',
          }}
        >
          <Col span={24}>
            <Dragger
              showUploadList={false}
              className="csv-upload-dragger"
              style={{
                paddingBottom: '25px',
              }}
              accept=".csv"
              disabled={isDraggerDisabled}
              beforeUpload={(csv) => {
                setIsDraggerDisabled(true);
                setFile(csv);
                setUploadedFileName(csv.name);
                return false; // This stops antd's Upload from uploading the file immediately upon selection
              }}
            >
              <Button
                type="link"
                icon={<CloseOutlined />}
                className="close-button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (isTerminationStateReached) {
                    resetDraggerState();
                  } else {
                    resetDraggerState(true, false);
                  }
                }}
              />
              <a
                href="https://help.boloo.co/nl/articles/8438708-importeren-exporteren-kostprijzen-producten-met-csv" // Update with the actual link later
                target="_blank"
                className="how-it-works-link"
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
              >
                Hoe werkt dit?
              </a>
              {uploadedFileName ? (
                <UploadedContent
                  uploadedFileName={uploadedFileName}
                  progressData={progressData}
                  resetDraggerState={resetDraggerState}
                  handleUploadClick={handleUploadClick}
                  setUploadedFileName={setUploadedFileName}
                  setIsDraggerDisabled={setIsDraggerDisabled}
                  percentage={percentage}
                />
              ) : (
                <DefaultContent onClick={async (e) => onExportButtonClick(e)} />
              )}
            </Dragger>
          </Col>
        </Row>
      )}

      <Row
        justify="space-between"
        className="bear-mar-t-16"
        align="middle"
        gutter={{
          xxl: 24,
          xl: 24,
          lg: 24,
          md: 12,
          sm: 12,
          xs: 12,
        }}
      >
        <Col xs={24} className="bear-col-fa mobile-fw-input">
          <Row>
            <Col style={{ marginBottom: '8px', marginTop: '8px' }}>
              <BearSearchInput onSearch={(value) => setSearchTerm(value)} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={ROW_GUTTER} align="middle">
            <Col>
              <Switch
                checked={removeHiddenProducts}
                onChange={(checked) => {
                  setRemoveHiddenProducts(checked);
                }}
                className="bear-mar-r-8"
              />
              <span>Verborgen producten tonen</span>
            </Col>

            <Col xs={24} className="bear-col-fa">
              <Select
                size="large"
                value={stockFilter}
                optionLabelProp="label"
                className="bear-min-input-width"
                onChange={(value) => setStockFilter(value)}
              >
                {Object.entries(STOCK_STATUS_OPTIONS).map(([key, value]) => {
                  return (
                    <Option
                      key={key}
                      value={key}
                      label={`Voorraadstatus: ${value}`}
                    >
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={24} className="bear-col-fa">
              <FulfilmentMethodSelector
                selectedValue={fulfilmentFilter}
                onChange={(value) => setFulfilmentFilter(value)}
              />
            </Col>
            <Col xs={24} className="bear-col-fa">
              <Select
                size="large"
                value={sorter}
                optionLabelProp="label"
                className="bear-width"
                onChange={(value) => setSorter(value)}
              >
                {INVENTORY_SORT_OPTIONS.map((item) => {
                  return (
                    <Option
                      key={item.value}
                      value={item.value}
                      label={`Sorteren op: ${item.label}`}
                    >
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <BearInfiniteList
        useWindow
        data={offersProductList || []}
        isLoading={isLoading}
        items={INVENTORY_LIST_ITEMS}
        hasMore={hasMore}
        className="bear-mar-t-24 bear-mar-t-sm-8"
        handeLoadMore={() => setSize((prevSize) => prevSize + 1)}
        handleItemClick={(offerProduct: OfferProductInterface) =>
          handleProductItemClick(String(offerProduct.id))
        }
      />
      <If condition={hasMore && offersProductList?.length > 0}>
        <Row gutter={12} align="middle">
          <Col span={24}>
            <div
              style={{
                marginTop: '1em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                loading={isLoading}
                size="large"
                type="primary"
                disabled={!hasMore}
                onClick={() => setSize((prevSize) => prevSize + 1)}
              >
                Meer laden
              </Button>
            </div>
          </Col>
        </Row>
      </If>
      <Modal
        title="Kostprijzen importeren"
        open={isModalVisible}
        onOk={async () => handleOk()}
        onCancel={async () => handleCancel()}
      >
        <p>Weet je zeker dat je deze kostprijzen wilt importeren?</p>
        <p>
          Let op: Dit overschrijft alle huidige kostprijzen die zijn ingesteld
        </p>
      </Modal>
    </div>
  );
}

export default StoreInventory;
