/* eslint-disable */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Col, Row, Button } from 'antd';
import ProfileHook from '../../hooks/profile';
import { IMAGE_STATIC_PATH } from '../../constants';

const Feedback = () => {
  return (
    <Row>
      <Col span={16} offset={4}>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <img src={`${IMAGE_STATIC_PATH}/launch_button.png`} width="50%" />
            <h1>
              Boloo is gebouwd aan de hand van de feedback van onze leden. We
              willen graag van jou weten waar je tegenaan loopt, wat je mist of
              wat er beter kan. Met jouw feedback kunnen we Boloo beter maken
              voor iedereen. Sharing is caring!
            </h1>
            <br />
            <Button href={'https://feedback.boloo.co'} target={'_blank'}>
              Deel jouw feedback!
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default withRouter(Feedback);
