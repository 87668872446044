import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { MENU, MenuGroup } from '../components/appContainer/appSideBar/config';
import * as routes from '../routes';
import { UserProfileInterface } from '../../reducers/profileReducer';
import {
  PlanSlugs,
  SubscriptionInterface,
} from '../services/subscriptionService/api';

export const getMenu = (
  profileData: UserProfileInterface,
  subscription?: SubscriptionInterface,
): MenuGroup[] => {
  // .filter order is important
  return MENU.filter((menu) =>
    menu.is_enabled ? profileData[menu.is_enabled] : true,
  )
    .filter(
      (menu) =>
        menu.enabled_for_substore ||
        subscription?.plan?.slug !== PlanSlugs.SubAccountMonthly,
    )
    .filter((menu) => !menu.disabledForStarterAccounts?.(subscription))
    .map((menu) => {
      return {
        ...menu,
        items: menu.items.filter(
          (item) => !item.isHidden?.(profileData, subscription),
        ),
      };
    });
};

export function LoggedInRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          if (rest.index && !rest.isStoreEnabled) {
            return <Redirect to={routes.RESEARCH_TRACKER_ROUTE} />;
          }
          return <Component {...props} />;
        }
        return <Redirect to={routes.INDEX_ROUTE} />;
      }}
    />
  );
}

export function NonLoggedInRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isAuthenticated) {
          return <Redirect to={routes.INDEX_ROUTE} />;
        }
        return <Component {...props} />;
      }}
    />
  );
}
