import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Row, Col, Select, Button, Space } from 'antd';

import { If } from 'tsx-control-statements/components';
import {
  isReturnStatus,
  STORE_ORDER_OPEN_STATUS,
  STORE_ORDER_STATUS_OPTIONS,
} from '../../constants';

import { getLastMonthRange } from '../../../utilities/date';
import ProfileHook from '../../../hooks/profile';
import AppHeaderTitleHook from '../../../hooks/appHeaderTitle';
import { getOrders, getReturns } from '../../actions/store';
import ProductImage from '../../components/productImage';
import {
  formatAmount,
  getProductImages,
  getOrderListQuery,
  getReturnListQuery,
} from '../utilities';
import {
  ORDER_SORT_OPTIONS,
  RETURN_SORT_OPTIONS,
  DEFAULT_ORDER_SORT_OPTION,
  INVOICE_STATUS_FILTERS,
} from './constants';

import BearInfiniteList from '../../../components/bearList';
import AppFeatureMenu from '../../components/appFeatureMenu';
import BearDatePicker from '../../../components/bearDatePicker';
import BearSearchInput from '../../components/bearSearchInput';
import StoreOrderDetailDrawer from '../components/orderDetailDrawer';
import FulfilmentMethodSelector from '../components/fulfilmentMethodSelector';
import InvoicingDropdown from '../components/invoicingDropdown';
import './style.scss';
import captureErrorAndShowMessage from '../../../utilities/errors';

const INITIAL_PAGE = 1;

function StoreOrdersManagement({ setTitle }) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<{ id: string } | null>(
    null,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedSorter, setSelectedSorter] = useState(
    DEFAULT_ORDER_SORT_OPTION,
  );
  const [selectedFulfilment, setSelectedFulfilment] = useState('');
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [selectedStatus, setSelectedStatus] = useState(STORE_ORDER_OPEN_STATUS);
  const [pickerValue, setPickerValue] = useState(getLastMonthRange());

  const isReturnsTab = isReturnStatus(selectedStatus);
  const sortOptions = isReturnsTab ? RETURN_SORT_OPTIONS : ORDER_SORT_OPTIONS;

  function getResults(page = INITIAL_PAGE, hasMoreResults = true) {
    if (hasMoreResults) {
      setLoading(true);
      let func = null;
      if (isReturnsTab) {
        const isHandled = selectedStatus === 'returns_handled';
        const extraQuery = getReturnListQuery(
          isHandled,
          searchTerm,
          selectedSorter,
          selectedFulfilment,
          statusFilter,
          pickerValue,
        );
        func = getReturns(page, extraQuery);
      } else {
        const extraQuery = getOrderListQuery(
          selectedStatus,
          searchTerm,
          selectedSorter,
          selectedFulfilment,
          statusFilter,
          pickerValue,
        );
        func = getOrders(page, extraQuery);
      }
      func
        .then((response) => {
          const newResults = [
            ...(page === 1 ? [] : results),
            ...response.data.results,
          ];
          setHasMore(response.data.count > newResults.length);
          setResults(newResults);
          setCurrentPage(page);
          setLoading(false);
        })
        .catch((e) => captureErrorAndShowMessage(e));
    }
  }

  const ORDER_LIST_ITEMS = [
    {
      title: 'Bestelnummer',
      key: 'order',
      size: 3,
      mobile: {
        order: 2,
      },
      render: (order) => (
        <>
          <p className="bear-medium-text">{order.order_id}</p>
          <p className="bear-light-help-text">
            {dayjs(order.order_date).format('DD MMM YYYY')}
          </p>
        </>
      ),
    },
    {
      title: 'Klant',
      key: 'customer',
      size: 3,
      mobile: {
        span: 12,
        order: 4,
      },
      render: (order) => (
        <>
          <p className="bear-medium-text">
            {(order.customer && order.customer.full_name) || '-'}
          </p>
          <p className="bear-light-help-text">
            {order.customer && order.customer.customer_type}
          </p>
        </>
      ),
    },
    {
      title: 'Product',
      key: 'product',
      size: 3,
      mobile: {
        order: 1,
      },
      className: 'multi-image-list-item',
      render: (order) =>
        order.order_items?.map((item, index) => (
          <ProductImage ean={item.product.ean} key={index} />
        )),
    },
    {
      title: 'Aantal stuks',
      key: 'pieces',
      size: 3,
      mobile: {
        span: 12,
        order: 5,
      },
      render: (order) => (
        <p className="bear-medium-text">{order.total_pieces}</p>
      ),
    },
    {
      title: 'Land',
      key: 'location',
      size: 3,
      mobile: {
        span: 12,
        order: 6,
      },
      render: (order) => (
        <p className="bear-medium-text">
          {(order.customer && order.customer.country_code) || '-'}
        </p>
      ),
    },
    {
      title: 'Bestelwaarde',
      key: 'total_value',
      size: 3,
      mobile: {
        span: 12,
        order: 7,
      },
      render: (order) => (
        <p className="bear-medium-text">{formatAmount(order.total_revenue)}</p>
      ),
    },
    {
      title: 'Factuur',
      key: 'invoice_status',
      size: 3,
      is_beta: true,
      mobile: {
        span: 12,
        order: 7,
      },
      render: (order) => {
        let label = null;

        if (!order.is_invoice_sent && !order.is_invoice_uploaded) {
          label = (
            <p className="bear-light-regular-text">Niet verzonden/geüpload</p>
          );
        } else if (order.is_invoice_sent && !order.is_invoice_uploaded) {
          label = <p className="invoice-sent-text">Verzonden</p>;
        } else {
          label = <p className="invoice-uploaded-text">Geüpload</p>;
        }

        if (order.is_credited) {
          label = <p className="invoice-credited-text">Gecrediteerd</p>;
        }

        return label;
      },
    },
    {
      title: '',
      key: 'actions',
      size: 2,
      mobile: {
        span: 12,
        order: 7,
      },
      render: (order) => (
        <InvoicingDropdown
          order={order}
          doRefresh={() => getResults(currentPage)}
        />
      ),
    },
    {
      title: '',
      key: 'none',
      size: 24,
      mobile: {
        span: 24,
        order: 3,
      },
      render: () => '',
    },
  ];

  const RETURN_LIST_ITEMS = [
    {
      title: '#Retournummer',
      key: 'return',
      size: 4,
      mobile: {
        order: 2,
        span: 12,
      },
      render: (returnItem) => (
        <>
          <p className="bear-medium-text">{returnItem.return_id}</p>
          <p className="bear-light-help-text">
            {dayjs(returnItem.return_date).format('DD MMM YYYY')}
          </p>
        </>
      ),
    },
    {
      title: 'Klant',
      key: 'customer',
      size: 5,
      mobile: {
        order: 3,
        span: 12,
      },
      render: (returnItem) => (
        <p className="bear-medium-text">
          {(returnItem.customer && returnItem.customer.full_name) || '-'}
        </p>
      ),
    },
    {
      title: 'Product',
      key: 'product',
      size: 4,
      mobile: {
        order: 1,
      },
      className: 'multi-image-list-item',
      render: (returnItem) =>
        getProductImages(returnItem.items).map((image, index) => {
          return <img src={image} key={index} alt={returnItem.order_id} />;
        }),
    },
    {
      title: 'Fulfilment',
      key: 'fulfilment',
      size: 4,
      mobile: {
        order: 4,
        span: 12,
      },
      render: (returnItem) => (
        <p className="bear-medium-text">{returnItem.fulfilment_display}</p>
      ),
    },
    {
      title: selectedStatus === 'returns_handled' ? 'Creditfactuur' : '',
      key: 'status',
      size: 4,
      mobile: {
        order: 4,
        span: 12,
      },
      render: (returnItem) => {
        if (selectedStatus === 'returns') return null;

        return returnItem.is_credit_invoice_sent ? (
          <p className="invoice-sent-text">Verzonden</p>
        ) : (
          <p className="bear-light-regular-text">Niet verzonden</p>
        );
      },
    },
    {
      title: '',
      key: 'creditInvoice',
      size: 3,
      mobile: {
        order: 4,
        span: 12,
      },
      render: (returnItem) =>
        selectedStatus === 'returns_handled' && (
          <InvoicingDropdown
            isCreditInvoice
            isHandledReturn
            order={returnItem}
            doRefresh={() => getResults(currentPage)}
          />
        ),
    },
  ];

  useEffect(() => {
    setHasMore(true);
    setResults([]);
    setTitle('Bestellingen');
    getResults();
  }, [
    searchTerm,
    pickerValue,
    selectedStatus,
    selectedSorter,
    statusFilter,
    selectedFulfilment,
  ]);

  useEffect(() => {
    setDrawerOpen(!!selectedOrder);
  }, [selectedOrder]);

  function handleItemChange(isForward) {
    if (drawerOpen) {
      const index = results.findIndex((item) => item.id === selectedOrder?.id);
      const nextIndex = index + 1;
      const prevIndex = index - 1;
      let nextOrder = null;
      if (index > -1) {
        if (isForward) {
          if (nextIndex < results.length) {
            nextOrder = results[nextIndex];
          } else {
            [nextOrder] = results;
          }
        } else if (prevIndex > -1 && prevIndex < results.length) {
          nextOrder = results[prevIndex];
        } else {
          nextOrder = results[results.length - 1];
        }
        setSelectedOrder(nextOrder);
      }
    }
  }

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: '100%' }}
        className="store-order-management"
      >
        <AppFeatureMenu
          selectedOption={selectedStatus}
          options={STORE_ORDER_STATUS_OPTIONS}
          onOptionSelection={(option) => setSelectedStatus(option)}
        />
        <Row justify="space-between" className="bear-mar-t-24 bear-mar-b-12">
          <Col xxl={10} xl={14} lg={14} md={14} sm={24} xs={24}>
            <Row
              gutter={{
                xxl: 24,
                xl: 24,
                lg: 24,
                md: 12,
                sm: 12,
                xs: 12,
              }}
            >
              <Col xs={24} className="bear-col-fa">
                <BearDatePicker onApply={(values) => setPickerValue(values)} />
              </Col>
              <Col xs={24} className="bear-col-fa">
                <BearSearchInput
                  onSearch={(value) => setSearchTerm(value.trim())}
                />
              </Col>
            </Row>
          </Col>
          <Col xxl={14} xl={10} lg={10} md={10} sm={24} xs={24}>
            <Row
              gutter={{
                xxl: 24,
                xl: 24,
                lg: 24,
                md: 12,
                sm: 12,
                xs: 12,
              }}
              justify="end"
            >
              {selectedStatus !== 'returns' && (
                <Col xs={24} className="bear-col-fa">
                  <Select
                    size="large"
                    value={statusFilter}
                    optionLabelProp="label"
                    className="bear-min-input-width"
                    onChange={(value) => setStatusFilter(value)}
                  >
                    <Select.Option
                      key=""
                      value=""
                      label={`${
                        selectedStatus === 'returns_handled'
                          ? 'Creditfactuur'
                          : 'Factuur'
                      }: Alle`}
                    >
                      All
                    </Select.Option>
                    {INVOICE_STATUS_FILTERS.map((item) => {
                      if (
                        item.disabled_for_return &&
                        isReturnStatus(selectedStatus)
                      )
                        return null;

                      return (
                        <Select.Option
                          key={item.value}
                          value={item.value}
                          label={`${
                            selectedStatus === 'returns_handled'
                              ? 'Creditfactuur'
                              : 'Factuur'
                          }: ${item.label}`}
                        >
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
              )}
              <Col xs={24} className="bear-col-fa">
                <FulfilmentMethodSelector
                  selectedValue={selectedFulfilment}
                  onChange={(value) => setSelectedFulfilment(value)}
                />
              </Col>
              <Col xs={24} className="bear-col-fa">
                <Select
                  size="large"
                  value={selectedSorter}
                  optionLabelProp="label"
                  className="bear-min-input-width"
                  onChange={(value) => setSelectedSorter(value)}
                >
                  {sortOptions.map((item) => {
                    return (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        label={`Sorteren op: ${item.label}`}
                      >
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <BearInfiniteList
          useWindow
          data={results}
          isLoading={loading}
          items={isReturnsTab ? RETURN_LIST_ITEMS : ORDER_LIST_ITEMS}
          hasMore={!loading && hasMore}
          handeLoadMore={() => getResults(currentPage + 1, hasMore)}
          handleItemClick={(item) => setSelectedOrder(item)}
        />
        <If condition={results?.length}>
          <Row justify="center" align="middle">
            <Col span={24}>
              <div
                style={{
                  marginTop: '1em',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  type="primary"
                  disabled={!loading && !hasMore}
                  onClick={() => getResults(currentPage + 1, hasMore)}
                >
                  Meer laden
                </Button>
              </div>
            </Col>
          </Row>
        </If>
      </Space>

      {selectedOrder?.id && (
        <StoreOrderDetailDrawer
          order={selectedOrder}
          isVisible={drawerOpen}
          isReturnItem={isReturnsTab}
          selectedTab={selectedStatus}
          doRefresh={() => getResults(currentPage)}
          handleOnClose={() => setSelectedOrder(null)}
          onItemChange={(forward) => handleItemChange(forward)}
        />
      )}
    </>
  );
}

export default ProfileHook(AppHeaderTitleHook(StoreOrdersManagement));
